import { Add, Close, Delete, Edit, SaveAs, Search } from '@mui/icons-material';
import { Alert, AppBar, Button, Checkbox, Container, Dialog, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Skeleton, Slide, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import {Map, Marker} from '@vis.gl/react-google-maps';
import { AreaMemberProps, ErrorAreaMemberProps } from '../../models/Area/AreaMemberProps';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { ProvinsiProps } from '../../models/Area/ProvinsiProps';
import { KabupatenProps } from '../../models/Area/KabupatenProps';
import { KecamatanProps } from '../../models/Area/KecamatanProps';
import { DesaProps } from '../../models/Area/DesaProps';
import { RwProps } from '../../models/Area/RwProps';
import { RtProps } from '../../models/Area/RtProps';
import { TpsProps } from '../../models/Area/TpsProps';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogAdminAreaMemberProps {
    createAlamat: any;
    titleDialog: string;
    setTitleDialog: React.Dispatch<React.SetStateAction<string>>;
    formAreaMember: AreaMemberProps;
    setFormAreaMember: React.Dispatch<React.SetStateAction<AreaMemberProps>>;
    messageSnackbar: string;
    errorFormArea: ErrorAreaMemberProps;
    setErrorFormArea: React.Dispatch<React.SetStateAction<ErrorAreaMemberProps>>;
    theme: ThemeStoreProps;
    getKabupatenArea: any;
    getKecamatanArea: any;
    getDesaArea: any;
    getRwArea: any;
    getRtArea: any;
    getTpsArea: any;
    provinsiDataArea: ProvinsiProps[];
    kabupatenDataArea: KabupatenProps[];
    kecamatanDataArea: KecamatanProps[];
    desaDataArea: DesaProps[];
    rwDataArea: RwProps[];
    rtDataArea: RtProps[];
    tpsDataArea: TpsProps[];
    loadingSkeletonTableArea: boolean;
    areaData: AreaMemberProps[];
    setAreaData: React.Dispatch<React.SetStateAction<AreaMemberProps[]>>;
    loadingSkeletonArea: boolean;
    setLoadingSkeletonArea: React.Dispatch<React.SetStateAction<boolean>>;
    openModalArea: boolean;
    setOpenModalArea: React.Dispatch<React.SetStateAction<boolean>>;
    settingTableArea: {
        page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;
    }
    setSettingTableArea: React.Dispatch<React.SetStateAction<{
        page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;
    }>>;
    handleChangePageArea: any;
    handleChangeRowsPerPageArea: any;
    jadikanAlamatUtama: any;
    removeAlamat: any;
    openSnackbar: boolean;
    setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
    setMessageSnackbar: React.Dispatch<React.SetStateAction<string>>;
    setKabupatenDataArea: React.Dispatch<React.SetStateAction<KabupatenProps[]>>
    setKecamatanDataArea: React.Dispatch<React.SetStateAction<KecamatanProps[]>>
    setDesaDataArea: React.Dispatch<React.SetStateAction<DesaProps[]>>
    setRwDataArea: React.Dispatch<React.SetStateAction<RwProps[]>>
    setRtDataArea: React.Dispatch<React.SetStateAction<RtProps[]>>
    setTpsDataArea: React.Dispatch<React.SetStateAction<TpsProps[]>>
}

const DialogAdminAreaMember: React.FC<DialogAdminAreaMemberProps> = ({createAlamat, titleDialog, setTitleDialog, formAreaMember, setFormAreaMember, messageSnackbar, errorFormArea, setErrorFormArea, theme, getKabupatenArea, getKecamatanArea, getDesaArea, getRwArea, getRtArea, getTpsArea, provinsiDataArea, kabupatenDataArea, kecamatanDataArea, desaDataArea, rwDataArea, rtDataArea, tpsDataArea, loadingSkeletonTableArea, areaData, setAreaData, loadingSkeletonArea, setLoadingSkeletonArea, openModalArea, setOpenModalArea, settingTableArea, setSettingTableArea, handleChangePageArea, handleChangeRowsPerPageArea, jadikanAlamatUtama, removeAlamat, openSnackbar, setOpenSnackbar, setMessageSnackbar,  setKabupatenDataArea, setKecamatanDataArea, setDesaDataArea, setRwDataArea, setRtDataArea, setTpsDataArea}) => {

    const [search, setSearch] = React.useState<string>('');

    React.useEffect(() => {
        setSearch('');
    }, []);
    return (
        <Dialog
            fullScreen
            open={openModalArea}
            onClose={() => setOpenModalArea(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => titleDialog.split(' ').includes('Tambah') ? setTitleDialog('Detail Area ' + titleDialog.split(' ').pop()) : setOpenModalArea(false)}
                    aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {titleDialog}
                    </Typography>
                    {
                        titleDialog.split(" ").includes("Tambah") ? 
                        <Button startIcon={<SaveAs />} color="inherit" onClick={() => createAlamat()}>
                            Simpan
                        </Button> :
                        <Button startIcon={<Close />} color="inherit" onClick={() => setOpenModalArea(false)}>
                            Tutup
                        </Button>
                    }
                </Toolbar>
            </AppBar>
            <Container>
                {
                    titleDialog.split(' ').includes('Detail') ? <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                    {
                        loadingSkeletonTableArea ? <Skeleton width={'100%'} height={'500px'} /> :
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Grid container spacing={2} sx={{ paddingBottom: '20px' }}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Typography variant='h3' fontSize={'24px'}>Alamat</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Grid container spacing={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <TextField fullWidth id="cari" label="Cari By Nama TPS" variant="outlined" value={search} onChange={(e) => setSearch(e.target.value)} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3}>
                                                    <Button fullWidth startIcon={<Search />} title='cari' variant='contained' color='success' onClick={() => setSettingTableArea({...settingTableArea, search: search})}>Cari</Button>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3}>
                                                    <Button fullWidth startIcon={<Add />} title='tambah' variant='contained' color='info' onClick={() => {setTitleDialog("Tambah Data Alamat " + titleDialog.split(' ').pop()); setFormAreaMember({...formAreaMember, area_member_id: '',alamat_utama: false,provinsi_id: '',nama_provinsi: '',kabupaten_id: '',nama_kabupaten: '',kecamatan_id: '',nama_kecamatan: '',desa_id: '',nama_desa: '',rw_id: '',nama_rw: '',rt_id: '',nama_rt: '',tps_id : '',nama_tps: '',longitude: '',lattitude: '',})}}>Tambah</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                        <TableContainer sx={{ maxHeight: 540 }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Provinsi</TableCell>
                                                        <TableCell>Kabupaten</TableCell>
                                                        <TableCell>Kecamatan</TableCell>
                                                        <TableCell>Desa</TableCell>
                                                        <TableCell>RW</TableCell>
                                                        <TableCell>RT</TableCell>
                                                        <TableCell>TPS</TableCell>
                                                        <TableCell>Status</TableCell>
                                                        <TableCell>Aksi</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {
                                                    areaData.length !== 0 ? areaData.map(p => 
                                                    <TableRow key={p.area_member_id}>
                                                        <TableCell>{p.nama_provinsi}</TableCell>
                                                        <TableCell>{p.nama_kabupaten}</TableCell>
                                                        <TableCell>{p.nama_kecamatan}</TableCell>
                                                        <TableCell>{p.nama_desa}</TableCell>
                                                        <TableCell>{p.nama_rw}</TableCell>
                                                        <TableCell>{p.nama_rt}</TableCell>
                                                        <TableCell>{p.nama_tps}</TableCell>
                                                        <TableCell>{p.alamat_utama ? 'Utama' : 'Bukan'}</TableCell>
                                                        <TableCell>
                                                            {
                                                                !p.alamat_utama ? 
                                                                <Button size='small' variant='contained' color='warning' onClick={() => jadikanAlamatUtama(p, true)} sx={{ marginLeft: '5px' }} startIcon={<Edit />}>Utama</Button> : <></>
                                                            }
                                                            <Button sx={{ marginLeft: '5px' }} onClick={() => removeAlamat(p)} size='small' variant='contained' startIcon={<Delete />} color='error'>Hapus</Button>
                                                        </TableCell>
                                                    </TableRow> 
                                                    )
                                                    : <TableRow>
                                                        <TableCell colSpan={9}>
                                                            <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                        component="div"
                                        count={settingTableArea.totalElements}
                                        rowsPerPage={settingTableArea.size}
                                        page={settingTableArea.page}
                                        onPageChange={handleChangePageArea}
                                        onRowsPerPageChange={handleChangeRowsPerPageArea}
                                    />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                    </Grid> : 
                    <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}><Typography variant='h5'>Form Alamat</Typography></Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="provinsi-select">Provinsi</InputLabel>
                                        <Select
                                            labelId="provinsi-select"
                                            id="provinsi_id"
                                            value={formAreaMember.provinsi_id}
                                            disabled={provinsiDataArea.length === 0}
                                            label="Provinsi"
                                            onChange={(e) => {setFormAreaMember({...formAreaMember, provinsi_id: e.target.value, kabupaten_id: '', kecamatan_id: '', desa_id: '', rw_id: '', rt_id: '', tps_id: ''}); setKecamatanDataArea([]);setDesaDataArea([]); setRwDataArea([]); setRtDataArea([]); setTpsDataArea([]); getKabupatenArea(e.target.value)}}
                                        >
                                            {
                                                provinsiDataArea.map(p => <MenuItem key={p.provinsi_id} value={p.provinsi_id}>{p.nama_provinsi}</MenuItem>)
                                            }
                                        </Select>
                                        {(errorFormArea.provinsi_id != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormArea.provinsi_id}</Typography> : <></>}
                                    </FormControl>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="kabupaten-select">Kabupaten</InputLabel>
                                        <Select
                                            labelId="kabupaten-select"
                                            id="kabupaten_id"
                                            value={formAreaMember.kabupaten_id}
                                            disabled={kabupatenDataArea.length === 0}
                                            label="Kabupaten"
                                            onChange={(e) => {setFormAreaMember({...formAreaMember, kabupaten_id: e.target.value, kecamatan_id: '', desa_id: '', rw_id: '', rt_id: '', tps_id: ''}); setDesaDataArea([]); setRwDataArea([]); setRtDataArea([]); setTpsDataArea([]); getKecamatanArea(e.target.value)}}
                                        >
                                            {
                                                kabupatenDataArea.map(p => <MenuItem key={p.kabupaten_id} value={p.kabupaten_id}>{p.nama_kabupaten}</MenuItem>)
                                            }
                                        </Select>
                                        {(errorFormArea.kabupaten_id != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormArea.kabupaten_id}</Typography> : <></>}
                                    </FormControl>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="kecamatan-select">Kecamatan</InputLabel>
                                        <Select
                                            labelId="kecamatan-select"
                                            id="kecamatan_id"
                                            value={formAreaMember.kecamatan_id}
                                            disabled={kecamatanDataArea.length === 0}
                                            label="Kecamatan"
                                            onChange={(e) => {setFormAreaMember({...formAreaMember, kecamatan_id: e.target.value, desa_id: '', rw_id: '', rt_id: '', tps_id: ''}); setRwDataArea([]); setRtDataArea([]); setTpsDataArea([]); getDesaArea(e.target.value)}}
                                        >
                                            {
                                                kecamatanDataArea.map(p => <MenuItem key={p.kecamatan_id} value={p.kecamatan_id}>{p.nama_kecamatan}</MenuItem>)
                                            }
                                        </Select>
                                        {(errorFormArea.kecamatan_id != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormArea.kecamatan_id}</Typography> : <></>}
                                    </FormControl>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="desa-select">Desa</InputLabel>
                                        <Select
                                            labelId="desa-select"
                                            id="desa_id"
                                            disabled={desaDataArea.length === 0}
                                            value={formAreaMember.desa_id}
                                            label="Desa"
                                            onChange={(e) => {setFormAreaMember({...formAreaMember, desa_id: e.target.value, rw_id: '', rt_id: '', tps_id: ''});setRtDataArea([]); setTpsDataArea([]);getRwArea(e.target.value)}}
                                        >
                                            {
                                                desaDataArea.map(p => <MenuItem key={p.desa_id} value={p.desa_id}>{p.nama_desa}</MenuItem>)
                                            }
                                        </Select>
                                        {(errorFormArea.desa_id != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormArea.desa_id}</Typography> : <></>}
                                    </FormControl>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="rw-select">RW</InputLabel>
                                        <Select
                                            labelId="rw-select"
                                            id="rw_id"
                                            disabled={rwDataArea.length === 0}
                                            value={formAreaMember.rw_id}
                                            label="RW"
                                            onChange={(e) => {setFormAreaMember({...formAreaMember, rw_id: e.target.value, rt_id: '', tps_id: ''}); setTpsDataArea([]);getRtArea(e.target.value); getTpsArea(e.target.value, null);}}
                                        >
                                            {
                                                rwDataArea.map(p => <MenuItem key={p.rw_id} value={p.rw_id}>{p.nama_rw}</MenuItem>)
                                            }
                                        </Select>
                                        {(errorFormArea.rw_id != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormArea.rw_id}</Typography> : <></>}
                                    </FormControl>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="rt-select">RT</InputLabel>
                                        <Select
                                            labelId="rt-select"
                                            disabled={rtDataArea.length === 0}
                                            id="rt_id"
                                            value={formAreaMember.rt_id}
                                            label="RT"
                                            onChange={(e) => {setFormAreaMember({...formAreaMember, rt_id: e.target.value, tps_id: ''}); getTpsArea(formAreaMember.rw_id, e.target.value);}}
                                        >
                                            {
                                                rtDataArea.map(p => <MenuItem key={p.rt_id} value={p.rt_id}>{p.nama_rt}</MenuItem>)
                                            }
                                        </Select>
                                        {(errorFormArea.rt_id != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormArea.rt_id}</Typography> : <></>}
                                    </FormControl>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="tps-select">TPS</InputLabel>
                                        <Select
                                            labelId="tps-select"
                                            disabled={tpsDataArea.length === 0}
                                            id="rt_id"
                                            value={formAreaMember.tps_id}
                                            label="RT"
                                            onChange={(e) => {setFormAreaMember({...formAreaMember, tps_id: e.target.value});}}
                                        >
                                            {
                                                tpsDataArea.map(p => <MenuItem key={p.tps_id} value={p.tps_id}>{p.nama_tps}</MenuItem>)
                                            }
                                        </Select>
                                        {(errorFormArea.tps_id != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormArea.tps_id}</Typography> : <></>}
                                    </FormControl>
                                    <FormControlLabel control={<Checkbox checked={formAreaMember.alamat_utama} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFormAreaMember({...formAreaMember, alamat_utama: event.target.checked})} />} label="Jadikan Alamat Utama" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}><Typography variant='h5'>Titik Maps</Typography></Grid>
                                <Grid item xs={12}>
                                    <Alert severity='info'>Ketuk Peta untuk menentukan titik Alamat Anda.</Alert>
                                </Grid>
                                <Grid item xs={12}>
                                    <Map
                                        defaultZoom={8}
                                        style={{ width: '100%', height: '350px' }}
                                        defaultCenter={ { lat: process.env.REACT_APP_MAPS_LATTITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LATTITUDE) : -4.55858490, lng: process.env.REACT_APP_MAPS_LONGITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LONGITUDE) : 105.40680790 } }
                                        onClick={(e) => {
                                            setFormAreaMember({...formAreaMember, lattitude: e.detail.latLng !== null ? e.detail.latLng.lat.toString() : '', longitude: e.detail.latLng !== null ? e.detail.latLng.lng.toString() : ''});
                                        }}
                                        >
                                            {(
                                                <Marker position={{lat: parseFloat(formAreaMember.lattitude), lng: parseFloat(formAreaMember.longitude)}} />
                                            )}
                                    </Map>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <TextField
                                            required
                                            sx={{ marginTop: '15px' }}
                                            fullWidth
                                            id="longitude"
                                            label="Longitude"
                                            name="longitude"
                                            autoFocus
                                            disabled
                                            value={formAreaMember.longitude}
                                        />
                                        {(errorFormArea.longitude != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormArea.longitude}</Typography> : <></>}
                                    </FormControl>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="lattitude"
                                            label="Lattitude"
                                            name="lattitude"
                                            autoFocus
                                            disabled
                                            value={formAreaMember.lattitude}
                                        />
                                        {(errorFormArea.lattitude != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormArea.lattitude}</Typography> : <></>}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Container>
            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                message={messageSnackbar}
            />
        </Dialog>
    );
}

export default DialogAdminAreaMember;
