import React from 'react';

const Pemungutan = () => {
    return (
        <div>
            Pemungutan
        </div>
    );
}

export default Pemungutan;
