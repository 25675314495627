import * as yup from 'yup';

export const PartaiValidateForm = yup.object().shape({
    nama_partai: yup.string().required('Nama Partai perlu diisi').max(128, "Maksimal 128 Karakter"),
    singkatan: yup.string().required('Singkatan perlu diisi').max(16, "Maksimal 16 Karakter"),
    ketua_umum: yup.string().required('Ketua Umum perlu diisi').max(128, "Maksimal 128 Karakter"),
    sekjen: yup.string().required('Sekretaris Jenderal perlu diisi').max(128, "Maksimal 128 Karakter"),
    alamat: yup.string().required('Alamat perlu diisi'),
    no_telp: yup.string().required('No Telpon perlu diisi').max(16, "Maksimal 16 Karakter"),
    alamat_web: yup.string().required('Alamat Web perlu diisi').max(255, "Maksimal 255 Karakter"),
});