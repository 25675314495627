import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import {AreaMemberProps} from '../../models/Area/AreaMemberProps';

class AreaMemberServices {
    async getAll(page: number, size: number, sort: string, search: string, memberId: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/area/member?page=${page}&size=${size}&sort=${sort}&search=${search}&memberId=${memberId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(areaMemberId: string): Promise<AxiosResponse<AreaMemberProps>> {
        return axios.get(`/api/v1/area/member/${areaMemberId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: AreaMemberProps): Promise<AxiosResponse<AreaMemberProps>> {
        return axios.post(`/api/v1/area/member`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(areaMemberId: string, status : boolean): Promise<AxiosResponse<void>> {
        return axios.put(`/api/v1/area/member?areaMemberId=${areaMemberId}&status=${status}`, null, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async delete(areaMemberlId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/area/member/${areaMemberlId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AreaMemberServices();