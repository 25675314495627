import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ThemeStoreState } from '../../store';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import {RegisterRequestProps, ErrorRegisterRequestProps, RegisterRequestField, ErrorRegisterRequestField} from '../../models/Auth/RegisterRequestProps';
import { Alert, FormControl, FormLabel, Radio, RadioGroup } from '@mui/material';
import { RegistrationValidateForm } from '../../validation/RegistrationValidateForm';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import AuthServices from '../../services/Auth/AuthServices';
import { AlertField, AlertProps } from '../../models/Component/AlertProps';
import { PersonAddAlt } from '@mui/icons-material';

const Register: React.FC = () => {
    const today = dayjs();
    const defaultTheme = createTheme();
    const navigate = useNavigate();
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState);
    const [register, setRegister] = React.useState<RegisterRequestProps>(RegisterRequestField);
    const [errors, setErrors] = React.useState<ErrorRegisterRequestProps>(ErrorRegisterRequestField);
    const [alertOption, setAlertOption] = React.useState<AlertProps>(AlertField);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await RegistrationValidateForm.validate(register, {abortEarly: false});
            setErrors(ErrorRegisterRequestField);
            JSON.stringify(register);
        } catch(error: any) {
            const newErrors: any = {};
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }

        if(errors.nik === null && errors.nama === null && errors.jenis_kelamin === null && errors.tempat_lahir === null && errors.tanggal_lahir === null && errors.alamat === null && errors.kode_pos === null && errors.email === null && errors.no_hp === null && errors.no_wa === null && errors.hak_pilih === null && errors.username === null && errors.password === null && errors.nama_partai === null) {
            await AuthServices.register(register).then(res => {
                if(res.status !== 200) {
                    setAlertOption({open: true, message: 'Terjadi Kesalahan. Periksa Kembali form yang anda isi', severity: 'error'});
                }
                navigate('/login?msg=successFromRegister');
            }).catch(err => setAlertOption({open: true, message: 'Error: '  + err, severity: 'error'}))
        }

    };
    
    const [checkBoxHelper, setCheckBoxHelper] = React.useState<boolean>(false);

    const helperCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckBoxHelper(event.target.checked);
        if(event.target.checked) {
            setRegister({...register, no_wa: register.no_hp});
        }
    }

    return (
    <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}><PersonAddAlt /></Avatar>
                <Typography component="h1" variant="h5">Sign up</Typography>
                <Grid container spacing={2} sx={{ marginY: '10px' }}>
                    <Grid item xs={12}>
                    {
                        alertOption.open === true ? <Alert severity={alertOption.severity} children={alertOption.message} onClose={() => setAlertOption({...alertOption, open: false})}></Alert> : <></>
                    }
                    </Grid>
                </Grid>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="nik"
                                label="Nomor Induk Kepegawaian (NIK)"
                                name="nik"
                                autoFocus
                                helperText={register.nik.length + '/16 Karakter'}
                                onChange={(e) => setRegister({...register, nik: e.target.value})}
                                value={register.nik}
                            />
                            {(errors.nik != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.nik}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="nama"
                                label="Nama"
                                name="nama"
                                autoComplete="nama"
                                onChange={(e) => setRegister({...register, nama: e.target.value})}
                                value={register.nama}
                            />
                            {(errors.nama != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.nama}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="gender">Jenis Kelamin</FormLabel>
                            <RadioGroup
                                aria-labelledby="gender"
                                defaultValue="WANITA"
                                name="radio-buttons-group"
                                onChange={(e) => setRegister({...register, jenis_kelamin: e.target.value})}
                                value={register.jenis_kelamin}
                            >
                                <FormControlLabel value="WANITA" control={<Radio />} label="Perempuan" />
                                <FormControlLabel value="PRIA" control={<Radio />} label="Laki-Laki" />
                            </RadioGroup>
                            {(errors.jenis_kelamin != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.jenis_kelamin}</Typography> : <></>}
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="tempat_lahir"
                                name="tempat_lahir"
                                required
                                fullWidth
                                id="tempat_lahir"
                                label="Tempat Lahir"
                                onChange={(e) => setRegister({...register, tempat_lahir: e.target.value})}
                                value={register.tempat_lahir}
                            />
                            {(errors.tempat_lahir != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.tempat_lahir}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    defaultValue={today}
                                    label="Tanggal Lahir"
                                    name='tanggal_lahir'
                                    disableFuture
                                    views={['year', 'month', 'day']}
                                    onChange={(e) => setRegister({...register, tanggal_lahir: (e?.format('YYYY-MM-DD') ?? today.format('YYYY-MM-DD'))})}
                                />
                            </LocalizationProvider>
                            {(errors.tanggal_lahir != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.tanggal_lahir}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="alamat"
                                label="Alamat"
                                name='alamat'
                                required
                                fullWidth
                                multiline
                                maxRows={6}
                                onChange={(e) => setRegister({...register, alamat: e.target.value})}
                                value={register.alamat}
                            />
                            {(errors.alamat != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.alamat}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="kode_pos"
                                fullWidth
                                required
                                label="Kode Pos"
                                name='kode_pos'
                                onChange={(e) => setRegister({...register, kode_pos: e.target.value})}
                                value={register.kode_pos}
                            />
                            {(errors.kode_pos != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.kode_pos}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="no_hp"
                                label="Nomor HP"
                                name="no_hp"
                                autoComplete="no_hp"
                                onChange={(e) => 
                                    (checkBoxHelper) ? setRegister({...register, no_wa: e.target.value, no_hp: e.target.value}) : setRegister({...register, no_hp: e.target.value})   
                                }
                                value={register.no_hp}
                            />
                            {(errors.no_hp != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.no_hp}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="no_wa"
                                label="Nomor Whatsapp"
                                name="no_wa"
                                autoComplete="no_wa"
                                value={register.no_wa}
                                onChange={(e) => {setRegister({...register, no_wa: e.target.value}); setCheckBoxHelper(false);}}
                            />
                            {(errors.no_wa != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.no_wa}</Typography> : <></>}
                            <FormControlLabel control={<Checkbox checked={checkBoxHelper} onChange={helperCheckbox} />} label="Nomor Wa saya sama dengan Nomor HP" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                onChange={(e) => setRegister({...register, email: e.target.value})}
                                value={register.email}
                            />
                            {(errors.email != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.email}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                onChange={(e) => setRegister({...register, username: e.target.value})}
                                value={register.username}
                            />
                            {(errors.username != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.username}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                onChange={(e) => setRegister({...register, password: e.target.value})}
                                value={register.password}
                            />
                            {(errors.password != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.password}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="reference"
                                label="Kode Referensi (Opsional)"
                                id="reference"
                                onChange={(e) => setRegister({...register, referensi_kode: e.target.value})}
                                value={register.referensi_kode}
                            />
                            <Typography sx={{fontSize: '10px'}}>Kode Referensi didapatkan dari Relawan</Typography>
                            {(errors.referensi_kode != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.referensi_kode}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox checked={register.hak_pilih} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRegister({...register, hak_pilih: event.target.checked})} />} label="Saya Memiliki Hak Pilih di TPS Alamat Tinggal" />
                            {(errors.hak_pilih != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.hak_pilih}</Typography> : <></>}
                        </Grid>
                    </Grid>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Sign Up</Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={() => navigate('/login')} sx={{ fontSize: '12px', backgroundColor: theme.backgroundColor, color: theme.color }}>Saya Sudah Memiliki Akun</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    </ThemeProvider>
    );
}

export default Register;
