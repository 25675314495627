import { BrowserRouter, Route, Routes } from "react-router-dom";

import LayoutPortalWeb from "../pages/Layout/PortalWeb";
import Portal from "../views/Portal";

import Layout from "../pages/Layout/Dashboard";
import Dashboard from "../views/Dashboard";
import Login from "../views/Auth/Login";
import Register from "../views/Auth/Register";

import ProtectedRoute from "./ProtectedRoute";

import NotFound from "../views/Errors/NotFound";
import UnAuthorized from "../views/Errors/UnAuthorized";
import Tag from "../views/Portal/Tag";
import Kategori from "../views/Portal/Kategori";
import Berita from "../views/Portal/Berita";
import BeritaDetail from "../views/Portal/BeritaDetail";
import Profil from "../views/Dashboard/Profil";
import AreaMember from "../views/AreaMember";

import Pengaturan from "../views/Dashboard/Pengaturan";
import Admin from "../views/Admin/Admin";
import Eksekutif from "../views/Eksekutif/Eksekutif";
import Konstituen from "../views/Konstituen/Konstituen";
import Pemungutan from "../views/Pemungutan/Pemungutan";
import Perhitungan from "../views/Perhitungan/Perhitungan";
import Relawan from "../views/Relawan/Relawan";
import Saksi from "../views/Saksi/Saksi";
import Superadmin from "../views/Superadmin/Superadmin";
import PengelolaanDataRelawan from "../views/Admin/AdminPengelolaanDataRelawan";
import PengelolaanDataTps from "../views/Admin/AdminPengelolaanDataTps";
import PengelolaanDataPartai from "../views/Admin/AdminPengelolaanDataPartai";
import BantuanKontak from "../views/Admin/AdminBantuanKontak";
import PemantauanHasilPemilu from "../views/Admin/AdminPemantauanHasilPemilu";
import PengaturanSistem from "../views/Admin/AdminPengaturanSistem";
import PengelolaanDataKonstituen from "../views/Admin/AdminPengelolaanDataKonstituen";
import PengelolaanDataSaksi from "../views/Admin/AdminPengelolaanDataSaksi";
import EksekutifPemantauanHasilPemilu from "../views/Eksekutif/EksekutifPemantauanHasilPemilu";
import EksekutifAnalisisDataPemilu from "../views/Eksekutif/EksekutifAnalisisDataPemilu";
import EksekutifBantuanKontak from "../views/Eksekutif/EksekutifBantuanKontak";
import KonstituenInformasiPemilih from "../views/Konstituen/KonstituenInformasiPemilih";
import KonstituenPencarianTps from "../views/Konstituen/KonstituenPencarianTps";
import KonstituenBantuanKontak from "../views/Konstituen/KonstituenBantuanKontak";
import KonstituenPendaftaranPemilih from "../views/Konstituen/KonstituenPendaftaranPemilih";
import SuperadminBantuanKontak from "../views/Superadmin/SuperadminBantuanKontak";
import SuperadminManajemenPengguna from "../views/Superadmin/SuperadminManajemenPengguna";
import SuperadminManajemenDataMaster from "../views/Superadmin/SuperadminManajemenDataMaster";
import SuperadminMonitoringSistem from "../views/Superadmin/SuperadminMonitoringSistem";
import SuperadminPengaturanSistem from "../views/Superadmin/SuperadminPengaturanSistem";
import SaksiPemantuanHasilPemilu from "../views/Saksi/SaksiPemantuanHasilPemilu";
import SaksiPendaftaranSaksi from "../views/Saksi/SaksiPendaftaranSaksi";
import SaksiPelaporanPemungutanSuara from "../views/Saksi/SaksiPelaporanPemungutan Suara";
import SaksiBantuanKontak from "../views/Saksi/SaksiBantuanKontak";
import RelawanBantuanKontak from "../views/Relawan/RelawanBantuanKontak";
import PerhitunganBantuanKontak from "../views/Perhitungan/PerhitunganBantuanKontak";
import PemungutanPenerimaanDataSuara from "../views/Pemungutan/PemungutanPenerimaanDataSuara";
import PemungutanVerifikasiDataSuara from "../views/Pemungutan/PemungutanVerifikasiDataSuara";
import PemungutanBantuanKontak from "../views/Pemungutan/PemungutanBantuanKontak";
import AdminLayout from "../views/Admin/AdminLayout";
import EksekutifLayout from "../views/Eksekutif/EksekutifLayout";
import KonstituenLayout from "../views/Konstituen/KonstituenLayout";
import PemungutanLayout from "../views/Pemungutan/PemungutanLayout";
import PerhitunganLayout from "../views/Perhitungan/PerhitunganLayout";
import RelawanLayout from "../views/Relawan/RelawanLayout";
import SaksiLayout from "../views/Saksi/SaksiLayout";
import SuperadminLayout from "../views/Superadmin/SuperadminLayout";
import RelawanPenugasanTugas from "../views/Relawan/RelawanPenugasanTugas";
import PerhitunganPemantauanHasilPerhitungan from "../views/Perhitungan/PerhitunganPemantauanHasilPerhitungan";
import PerhitunganSuaraOtomatis from "../views/Perhitungan/PerhitunganSuaraOtomatis";
import RelawanPendaftaranRelawan from "../views/Relawan/RelawanPendaftaranRelawan";
import AdminPengelolaanArea from "../views/Admin/AdminPengelolaanArea";
import AdminPengelolaanDataAnggota from "../views/Admin/AdminPengelolaanDataAnggota";
import RelawanLaporanAktivitas from "../views/Relawan/RelawanLaporanAktivitas";
import SuperadminPengelolaanPeriode from "../views/Superadmin/SuperadminPengelolaanPeriode";
import SuperadminPengelolaanKategori from "../views/Superadmin/SuperadminPengelolaanKategori";
import SuperadminPengelolaanJenisPemilihan from "../views/Superadmin/SuperadminPengelolaanJenisPemilihan";
import SuperadminPengelolaanCalonPemimpin from "../views/Superadmin/SuperadminPengelolaanCalonPemimpin";
import SuperadminPengelolaanJenisTingkatPemilihan from "../views/Superadmin/SuperadminPengelolaanJenisTingkatPemilihan";
import SuperadminPengelolaanCalonLegislatif from "../views/Superadmin/SuperadminPengelolaanCalonLegislatif";

function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<LayoutPortalWeb />}>
                    <Route path="/" element={<Portal />}></Route>
                    <Route path="/berita" element={<Berita />}></Route>
                    <Route path="/berita/:berita" element={<BeritaDetail />}></Route>
                    <Route path="/tag" element={<Tag />}></Route>
                    <Route path="/kategori" element={<Kategori />}></Route>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/register" element={<Register />}></Route>
                </Route>
                
                <Route />
                <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
                    <Route path="/home" element={<Dashboard />}></Route>
                    <Route path="/profil" element={<Profil />}></Route>
                    <Route path="/area-member" element={<AreaMember />}></Route>
                    <Route path="/settings" element={<Pengaturan />}></Route>
                    
                    <Route path="/admin" element={<AdminLayout />}>
                        <Route path="beranda" element={<Admin />} />
                        <Route path="pengelolaan-area" element={<AdminPengelolaanArea />} />
                        <Route path="pengelolaan-data-relawan" element={<PengelolaanDataRelawan />} />
                        <Route path="pengelolaan-data-tps" element={<PengelolaanDataTps />} />
                        <Route path="pengelolaan-data-partai" element={<PengelolaanDataPartai />} />
                        <Route path="bantuan-dan-kontak" element={<BantuanKontak />} />
                        <Route path="pemantauan-hasil-pemilu" element={<PemantauanHasilPemilu />} />
                        <Route path="pengaturan-sistem" element={<PengaturanSistem />} />
                        <Route path="pengelolaan-data-konstituen" element={<PengelolaanDataKonstituen />} />
                        <Route path="pengelolaan-data-saksi" element={<PengelolaanDataSaksi />} />
                        <Route path="pengelolaan-data-anggota" element={<AdminPengelolaanDataAnggota />} />
                    </Route>
                    <Route path="/eksekutif" element={<EksekutifLayout />}>
                        <Route path="beranda" element={<Eksekutif />} />
                        <Route path="pemantauan-hasil-pemilu" element={<EksekutifPemantauanHasilPemilu />} />
                        <Route path="analisis-data-pemilu" element={<EksekutifAnalisisDataPemilu />} />
                        <Route path="bantuan-dan-kontak" element={<EksekutifBantuanKontak />} />
                    </Route>
                    <Route path="/konstituen" element={<KonstituenLayout />}>
                        <Route path="beranda" element={<Konstituen />} />
                        <Route path="informasi-pemilih" element={<KonstituenInformasiPemilih />} />
                        <Route path="pencarian-tps" element={<KonstituenPencarianTps />} />
                        <Route path="bantuan-dan-kontak" element={<KonstituenBantuanKontak />} />
                        <Route path="pendaftaran-pemilih" element={<KonstituenPendaftaranPemilih />} />
                    </Route>
                    <Route path="/pemungutan" element={<PemungutanLayout />}>
                        <Route path="beranda" element={<Pemungutan />} />
                        <Route path="penerimaan-data-suara" element={<PemungutanPenerimaanDataSuara />} />
                        <Route path="verifikasi-data-suara" element={<PemungutanVerifikasiDataSuara />} />
                        <Route path="bantuan-dan-kontak" element={<PemungutanBantuanKontak />} />
                    </Route>
                    <Route path="/relawan" element={<RelawanLayout />}>
                        <Route path="beranda" element={<Relawan />} />
                        <Route path="penugasan-tugas" element={<RelawanPenugasanTugas />} />
                        <Route path="bantuan-dan-kontak" element={<RelawanBantuanKontak />} />
                        <Route path="laporan-aktivitas" element={<RelawanLaporanAktivitas />} />
                        <Route path="pendaftaran-relawan" element={<RelawanPendaftaranRelawan />} />
                    </Route>
                    <Route path="/perhitungan" element={<PerhitunganLayout />}>
                        <Route path="beranda" element={<Perhitungan />} />
                        <Route path="bantuan-dan-kontak" element={<PerhitunganBantuanKontak />} />
                        <Route path="pemantauan-hasil-perhitungan" element={<PerhitunganPemantauanHasilPerhitungan />} />
                        <Route path="perhitungan-suara-otomatis" element={<PerhitunganSuaraOtomatis />} />
                    </Route>
                    <Route path="/saksi" element={<SaksiLayout />}>
                        <Route path="beranda" element={<Saksi />} />
                        <Route path="pemantauan-hasil-pemilu" element={<SaksiPemantuanHasilPemilu />} />
                        <Route path="pendaftaran-saksi" element={<SaksiPendaftaranSaksi />} />
                        <Route path="pelaporan-pemungutan-suara" element={<SaksiPelaporanPemungutanSuara />} />
                        <Route path="bantuan-dan-kontak" element={<SaksiBantuanKontak />} />
                    </Route>
                    <Route path="/superadmin" element={<SuperadminLayout />}>
                        <Route path="beranda" element={<Superadmin />} />
                        <Route path="bantuan-dan-kontak" element={<SuperadminBantuanKontak />} />
                        <Route path="pengaturan-sistem" element={<SuperadminPengaturanSistem />} />
                        <Route path="manajemen-pengguna" element={<SuperadminManajemenPengguna />} />
                        <Route path="manajemen-data-master" element={<SuperadminManajemenDataMaster />} />
                        <Route path="monitoring-sistem" element={<SuperadminMonitoringSistem />} />
                        <Route path="pengelolaan-periode" element={<SuperadminPengelolaanPeriode />} />
                        <Route path="pengelolaan-kategori" element={<SuperadminPengelolaanKategori />} />
                        <Route path="pengelolaan-jenis-pemilihan" element={<SuperadminPengelolaanJenisPemilihan />} />
                        <Route path="pengelolaan-jenis-tingkat-pemilihan" element={<SuperadminPengelolaanJenisTingkatPemilihan />} />
                        <Route path="pengelolaan-calon-pemimpin" element={<SuperadminPengelolaanCalonPemimpin />} />
                        <Route path="pengelolaan-calon-legislatif" element={<SuperadminPengelolaanCalonLegislatif />} />
                    </Route>

                    <Route path="/unauthorized" element={<UnAuthorized />}></Route>
                    <Route path="/*" element={<NotFound />}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default Router;
