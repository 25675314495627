import * as yup from 'yup';

export const AreaMemberValidateForm = yup.object().shape({
    member_id: yup.string().required("Member harus diisi"),
    alamat_utama: yup.boolean().required("Alamat Utama harus diisi"),
    provinsi_id: yup.string().required("Provinsi harus diisi"),
    kabupaten_id: yup.string().required("Kabupaten harus diisi"),
    kecamatan_id: yup.string().required("Kecamatan harus diisi"),
    desa_id: yup.string().required("Desa harus diisi"),
    rw_id: yup.string().required("RW harus diisi"),
    rt_id: yup.string().required("RT harus diisi"),
    tps_id : yup.string().required("TPS harus diisi"),
    longitude: yup.string().required("Longitude harus diisi"),
    lattitude: yup.string().required("Lattitude harus diisi"),
});