export interface PeriodeProps {
    periode_id: string;
    tahun: number;
    deskripsi: string;
}
export const PeriodeField: PeriodeProps = {
    periode_id: '',
    tahun: 0,
    deskripsi: '',
}
export interface ErrorPeriodeProps {
    periode_id: string | null;
    tahun: number | null;
    deskripsi: string | null;
}
export const ErrorPeriodeField: ErrorPeriodeProps = {
    periode_id: null,
    tahun: null,
    deskripsi: null,
}