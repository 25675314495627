export interface RwProps {
    rw_id: string;
    desa_id: string;
    nama_rw: string;
}

export const RwField: RwProps = {
    rw_id: '',
    desa_id: '',
    nama_rw: ''
}