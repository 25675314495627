import React from 'react';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { TransitionProps } from '@mui/material/transitions';
import { AppBar, Box, Button, Chip, Container, Dialog, FormControl, Grid, IconButton, InputLabel, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Select, Skeleton, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import { AccountBox, Add, AlternateEmail, AssuredWorkload, CalendarMonth, Cancel, Close, Delete, Female, Home, Male, Phone, ReduceCapacity, Transgender, VerifiedUser, WhatsApp } from '@mui/icons-material';
import { InfoWindow, Map, Marker } from '@vis.gl/react-google-maps';
import { DetailMemberProps } from '../../models/Member/DetailMemberProps';
import Image from "../../asset/images/default.png";
import { ProvinsiProps } from '../../models/Area/ProvinsiProps';
import { KabupatenProps } from '../../models/Area/KabupatenProps';
import { KecamatanProps } from '../../models/Area/KecamatanProps';
import { DesaProps } from '../../models/Area/DesaProps';
import { RwProps } from '../../models/Area/RwProps';
import { RtProps } from '../../models/Area/RtProps';
import { TpsProps } from '../../models/Area/TpsProps';
import ListMemberProps, { ListMemberField } from '../../models/Member/ListMemberProps';
import ModalCreateRelasiToRelawan from './ModalCreateRelasiToRelawan';

interface DetailKonstituenMemberProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    titleDialog: string;
    messageSnackbar: string;
    theme: ThemeStoreProps;
    konstituenDataDetail: DetailMemberProps;
    createPerson: any;
    loadingSkeletonTableTambah: boolean;
    loadingSkeletonTambah: boolean;
    setLoadingSkeletonTambah: React.Dispatch<React.SetStateAction<boolean>>;
    getProvinsiTambah: any;
    getKabupatenTambah: any;
    getKecamatanTambah: any;
    getDesaTambah: any;
    getRwTambah: any;
    getRtTambah: any;
    getTpsTambah: any;
    konstituenDataTambah: ListMemberProps[];
    provinsiDataTambah: ProvinsiProps[];
    kabupatenDataTambah: KabupatenProps[];
    setSettingTableTambah: React.Dispatch<React.SetStateAction<{
        page: number;
        size: number;
        sort: string;
        search: string;
        provinsiId: string;
        kabupatenId: string;
        kecamatanId: string;
        desaId: string;
        rwId: string;
        rtId: string;
        tpsId: string;
        hakPilih: string;
        totalElements: number;
        totalPages: number;
    }>>;
    settingTableTambah: {
        page: number;
        size: number;
        sort: string;
        search: string;
        provinsiId: string;
        kabupatenId: string;
        kecamatanId: string;
        desaId: string;
        rwId: string;
        rtId: string;
        tpsId: string;
        hakPilih: string;
        totalElements: number;
        totalPages: number;
    };
    kecamatanDataTambah: KecamatanProps[];
    desaDataTambah: DesaProps[];
    rwDataTambah: RwProps[];
    rtDataTambah: RtProps[];
    tpsDataTambah: TpsProps[];
    handleChangePageTambah: any;
    handleChangeRowsPerPageTambah: any;
    clearFilterTambah: any;
    openModalRelasi: boolean;
    setOpenModalRelasi: React.Dispatch<React.SetStateAction<boolean>>;
    addRelasi: any;
    removeRelasi: any;
    checkNikToRelasiRelawan: any;
    dataTempTambahRelasi: ListMemberProps;
    setDataTempTambahRelasi: React.Dispatch<React.SetStateAction<ListMemberProps>>;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DetailKonstituenMember: React.FC<DetailKonstituenMemberProps> = ({ openModal, setOpenModal, titleDialog, messageSnackbar, theme, konstituenDataDetail, createPerson, loadingSkeletonTableTambah, loadingSkeletonTambah, setLoadingSkeletonTambah, getProvinsiTambah,settingTableTambah,setSettingTableTambah, getKabupatenTambah, getKecamatanTambah, getDesaTambah, getRwTambah, getRtTambah, getTpsTambah, konstituenDataTambah, provinsiDataTambah, kabupatenDataTambah, kecamatanDataTambah, desaDataTambah, rwDataTambah, rtDataTambah, tpsDataTambah, handleChangePageTambah, handleChangeRowsPerPageTambah, clearFilterTambah, openModalRelasi, setOpenModalRelasi, addRelasi, removeRelasi, checkNikToRelasiRelawan, dataTempTambahRelasi, setDataTempTambahRelasi}) => {

    const [selectedMarker, setSelectedMarker] = React.useState<any>(null);
    const [formNik, setFormNik] = React.useState<string>('');

    React.useEffect(() => {
        setSelectedMarker(null)
        getProvinsiTambah();
    }, []);
    return (
        <Dialog
            fullScreen
            open={openModal}
            onClose={() => setOpenModal(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setOpenModal(false)}
                    aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {titleDialog}
                    </Typography>
                    <Button autoFocus startIcon={<Close />} color="inherit" onClick={() => setOpenModal(false)}>
                        Tutup
                    </Button>
                </Toolbar>
            </AppBar>
            {
                titleDialog.split(" ").includes("Tambah") ? <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container spacing={2} sx={{ paddingTop: '50px', paddingBottom: '20px' }}>
                                <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="provinsi-select">Provinsi</InputLabel>
                                        <Select
                                            labelId="provinsi-select"
                                            id="provinsi_id"
                                            value={settingTableTambah.provinsiId}
                                            disabled={provinsiDataTambah.length === 0}
                                            label="Provinsi"
                                            onChange={(e) => {setSettingTableTambah({...settingTableTambah, provinsiId: e.target.value, kecamatanId: '', kabupatenId: '', desaId: '', rwId: '', rtId: '', tpsId: ''}); getKabupatenTambah(e.target.value);}}
                                        >
                                            {
                                                provinsiDataTambah.map(p => <MenuItem key={p.provinsi_id} value={p.provinsi_id}>{p.nama_provinsi}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="kabupaten-select">Kabupaten</InputLabel>
                                        <Select
                                            labelId="kabupaten-select"
                                            id="kabupaten_id"
                                            value={settingTableTambah.kabupatenId}
                                            disabled={kabupatenDataTambah.length === 0}
                                            label="Kabupaten"
                                            onChange={(e) => {setSettingTableTambah({...settingTableTambah, kabupatenId: e.target.value, kecamatanId: '', desaId: '', rwId: '', rtId: '', tpsId: ''}); getKecamatanTambah(e.target.value)}}
                                        >
                                            {
                                                kabupatenDataTambah.map(p => <MenuItem key={p.kabupaten_id} value={p.kabupaten_id}>{p.nama_kabupaten}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="kecamatan-select">Kecamatan</InputLabel>
                                        <Select
                                            labelId="kecamatan-select"
                                            id="kecamatan_id"
                                            value={settingTableTambah.kecamatanId}
                                            disabled={kecamatanDataTambah.length === 0}
                                            label="Kecamatan"
                                            onChange={(e) => {setSettingTableTambah({...settingTableTambah, kecamatanId: e.target.value, desaId: '', rwId: '', rtId: '', tpsId: ''}); getDesaTambah(e.target.value)}}
                                        >
                                            {
                                                kecamatanDataTambah.map(p => <MenuItem key={p.kecamatan_id} value={p.kecamatan_id}>{p.nama_kecamatan}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="desa-select">Desa</InputLabel>
                                        <Select
                                            labelId="desa-select"
                                            id="desa_id"
                                            value={settingTableTambah.desaId}
                                            disabled={desaDataTambah.length === 0}
                                            label="Desa"
                                            onChange={(e) => {setSettingTableTambah({...settingTableTambah, desaId: e.target.value, rwId: '', rtId: '', tpsId: ''}); getRwTambah(e.target.value);}}
                                        >
                                            {
                                                desaDataTambah.map(p => <MenuItem key={p.desa_id} value={p.desa_id}>{p.nama_desa}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="rw-select">RW</InputLabel>
                                        <Select
                                            labelId="rw-select"
                                            id="rw_id"
                                            value={settingTableTambah.rwId}
                                            disabled={rwDataTambah.length === 0}
                                            label="RW"
                                            onChange={(e) => {setSettingTableTambah({...settingTableTambah, rwId: e.target.value, rtId: '', tpsId: ''}); getRtTambah(e.target.value); getTpsTambah(e.target.value, null)}}
                                        >
                                            {
                                                rwDataTambah.map(p => <MenuItem key={p.rw_id} value={p.rw_id}>{p.nama_rw}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="rt-select">RT</InputLabel>
                                        <Select
                                            labelId="rt-select"
                                            id="rt_id"
                                            value={settingTableTambah.rtId}
                                            disabled={rtDataTambah.length === 0}
                                            label="RT"
                                            onChange={(e) => {setSettingTableTambah({...settingTableTambah, rtId: e.target.value, tpsId: ''}); getTpsTambah(settingTableTambah.rwId, e.target.value)}}
                                        >
                                            {
                                                rtDataTambah.map(p => <MenuItem key={p.rt_id} value={p.rt_id}>{p.nama_rt}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="tps-select">TPS</InputLabel>
                                        <Select
                                            labelId="tps-select"
                                            id="tps_id"
                                            value={settingTableTambah.tpsId}
                                            disabled={tpsDataTambah.length === 0}
                                            label="TPS"
                                            onChange={(e) => setSettingTableTambah({...settingTableTambah, tpsId: e.target.value})}
                                        >
                                            {
                                                tpsDataTambah.map(p => <MenuItem key={p.tps_id} value={p.tps_id}>{p.nama_tps}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="hak_pilih-select">Hak Pilih</InputLabel>
                                        <Select
                                            labelId="hak_pilih-select"
                                            id="hak_pilih_id"
                                            value={settingTableTambah.hakPilih}
                                            label="Hak Pilih Member"
                                            onChange={(e) => setSettingTableTambah({...settingTableTambah, hakPilih: e.target.value})}
                                        >
                                            <MenuItem value={'null'}>{'Semua Hak Pilih'}</MenuItem>
                                            <MenuItem value={'true'}>{'Punya Hak Milik'}</MenuItem>
                                            <MenuItem value={'false'}>{'Tidak ada Hak Pilih'}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {
                                    settingTableTambah.provinsiId !== '' ? 
                                <Grid item xs={12} sm={12} md={4} lg={3} justifyContent={'center'} alignItems={'center'}>
                                    <Button color='error' variant='contained' title='Bersihkan Filter' startIcon={<Cancel />} onClick={() => clearFilterTambah()}>Clear Filter</Button>
                                </Grid> : <></>
                                }
                            </Grid>
                        </Grid>
                        {
                            loadingSkeletonTableTambah ? <Skeleton width={'100%'} height={'500px'} /> :
                            <Grid item xs={12} sm={12} md={12}>
                                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                        <TableContainer sx={{ maxHeight: 540 }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nama</TableCell>
                                                        <TableCell>Jenis Kelamin</TableCell>
                                                        <TableCell>TTL</TableCell>
                                                        <TableCell>Username</TableCell>
                                                        <TableCell>Aksi</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                    konstituenDataTambah.length !== 0 ? konstituenDataTambah.map(p => 
                                                        <TableRow key={p.member_id}>
                                                            <TableCell>{p.nama}</TableCell>
                                                            <TableCell>{p.jenis_kelamin}</TableCell>
                                                            <TableCell>{p.tempat_lahir + ', ' + p.tanggal_lahir}</TableCell>
                                                            <TableCell>{p.username}</TableCell>
                                                            <TableCell><Button sx={{ marginLeft: '5px' }} onClick={() => createPerson(p)} size='small' variant='contained' startIcon={<Add />} color='primary'>Tambah</Button></TableCell>
                                                    </TableRow> 
                                                        )
                                                    : <TableRow>
                                                        <TableCell colSpan={6}>
                                                            <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                            component="div"
                                            count={settingTableTambah.totalElements}
                                            rowsPerPage={settingTableTambah.size}
                                            page={settingTableTambah.page}
                                            onPageChange={handleChangePageTambah}
                                            onRowsPerPageChange={handleChangeRowsPerPageTambah}
                                        />
                                    </Paper>
                            </Grid>
                        }
                    </Grid>
                </Container> : <Container>
                    <Grid container spacing={3} sx={{ marginY: '20px' }}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant='h6'>Profil</Typography>
                                    <Box sx={{ width: '100%' }}>
                                        {
                                            konstituenDataDetail !== null ?
                                            <img src={konstituenDataDetail?.member.avatar !== null ? konstituenDataDetail?.member.avatar : Image} alt={konstituenDataDetail?.ktp.nama} width={'100%'} /> :
                                            <img src={Image} alt={'default'} width={'100%'} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant='h6'>Informasi Konstituen</Typography>
                                    <List component="nav" aria-label="main mailbox folders">
                                        <ListItemButton>
                                            <ListItemIcon><AccountBox /></ListItemIcon>
                                            <ListItemText primary={konstituenDataDetail?.ktp.nama ?? 'Belum Ada Nama'} />
                                        </ListItemButton>
                                        <ListItemButton>
                                            <ListItemIcon>{ konstituenDataDetail?.ktp.jenis_kelamin === null ? <Transgender /> : konstituenDataDetail?.ktp.jenis_kelamin === 'PRIA' ? <Male /> : <Female /> }</ListItemIcon>
                                            <ListItemText primary={konstituenDataDetail?.ktp.jenis_kelamin ?? 'Belum Ada Jenis Kelamin'} />
                                        </ListItemButton>
                                        <ListItemButton>
                                            <ListItemIcon><CalendarMonth /></ListItemIcon>
                                            <ListItemText primary={konstituenDataDetail?.ktp.tempat_lahir + ', ' + konstituenDataDetail?.ktp.tanggal_lahir ?? 'Belum Ada Kode Referensi'} />
                                        </ListItemButton>
                                        <ListItemButton>
                                            <ListItemIcon><AlternateEmail /></ListItemIcon>
                                            <ListItemText primary={konstituenDataDetail?.member.email ?? 'Belum Ada Email'} />
                                        </ListItemButton>
                                        <ListItemButton>
                                            <ListItemIcon><Phone /></ListItemIcon>
                                            <ListItemText primary={konstituenDataDetail?.member.no_hp ?? 'Belum Ada Nomor HP'} />
                                        </ListItemButton>
                                        <ListItemButton>
                                            <ListItemIcon><WhatsApp /></ListItemIcon>
                                            <ListItemText primary={konstituenDataDetail?.member.no_wa ?? 'Belum Ada Nomor WhatsApp'} />
                                        </ListItemButton>
                                        <ListItemButton>
                                            <ListItemIcon><VerifiedUser /></ListItemIcon>
                                            <ListItemText primary={konstituenDataDetail?.username ?? 'Belum Ada Username'} />
                                        </ListItemButton>
                                        <ListItemButton>
                                            <ListItemIcon><AssuredWorkload /></ListItemIcon>
                                            <ListItemText primary={konstituenDataDetail?.member.hak_pilih ? 'Ada Hak Pilih di TPS' : 'Tidak ada Hak Pilih'} />
                                        </ListItemButton>
                                        <ListItemButton>
                                            <ListItemIcon><Home /></ListItemIcon>
                                            <ListItemText primary={konstituenDataDetail?.ktp.alamat ?? 'Belum Ada Alamat'} />
                                        </ListItemButton>
                                        <ListItemButton>
                                            <ListItemIcon><ReduceCapacity /></ListItemIcon>
                                            <ListItemText primary={konstituenDataDetail?.referensi.kode_referensi ?? 'Tidak Ada Referensi'} />
                                        </ListItemButton>
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant='h6'>Informasi Lokasi Rumah & TPS Konstituen</Typography>
                                    <Map
                                        defaultZoom={10}
                                        style={{ width: '100%', height: '420px' }}
                                        defaultCenter={ { lat: process.env.REACT_APP_MAPS_LATTITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LATTITUDE) : -4.55858490, lng: process.env.REACT_APP_MAPS_LONGITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LONGITUDE) : 105.40680790 } }
                                    >
                                        {
                                            konstituenDataDetail.area.length > 0 ? konstituenDataDetail.area.map(a => <Marker position={{lat: parseFloat(a.lattitude), lng: parseFloat(a.longitude)}} icon={{
                                                url: 'http://maps.google.com/mapfiles/kml/pal3/icon56.png',
                                                scaledSize: new google.maps.Size(32, 32)
                                            }} key={a.area_member_id} onClick={() => setSelectedMarker({name: "Rumah", lat: a.lattitude, lng: a.longitude, alamat:a.nama_desa + ', ' + a.nama_kecamatan + ', ' + a.nama_kabupaten + ', ' + a.nama_provinsi})} />) : <></>
                                        }
                                        {
                                            konstituenDataDetail.tps.nama_tps !== null ? <Marker position={{lat: parseFloat(konstituenDataDetail.tps.lattitude), lng: parseFloat(konstituenDataDetail.tps.longitude)}} icon={{
                                                url: 'http://maps.google.com/mapfiles/kml/pal3/icon21.png',
                                                scaledSize: new google.maps.Size(32, 32)
                                            }} onClick={() => setSelectedMarker({name: konstituenDataDetail.tps.nama_tps.split(" ").includes('TPS') ? konstituenDataDetail.tps.nama_tps : "TPS " + konstituenDataDetail.tps.nama_tps, lat: konstituenDataDetail.tps.lattitude, lng: konstituenDataDetail.tps.longitude, alamat: konstituenDataDetail.tps.alamat})} /> : <></>
                                        }
                                        {selectedMarker && (
                                            <InfoWindow
                                                position={{ lat: parseFloat(selectedMarker.lat), lng: parseFloat(selectedMarker.lng) }}
                                                onCloseClick={() => setSelectedMarker(null)}
                                            >
                                                <div>
                                                    <h4>{selectedMarker.name}</h4>
                                                    <p>Alamat: {selectedMarker.alamat}</p>
                                                </div>
                                            </InfoWindow>
                                        )}
                                    </Map>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant='h6'>Role</Typography>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nama Role</TableCell>
                                                <TableCell>Keterangan</TableCell>
                                                <TableCell>Aktif</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                konstituenDataDetail.role.length > 0 ? konstituenDataDetail.role.map(k => <TableRow key={k.role_id}>
                                                    <TableCell>{k.nama_role}</TableCell>
                                                    <TableCell>{k.keterangan}</TableCell>
                                                    <TableCell>{k.aktif ? <Chip label={'Aktif'} variant="outlined" color="primary" /> : <Chip label={'Tidak'} variant="outlined" color="error" />}</TableCell>
                                                </TableRow>) : <TableRow><TableCell colSpan={8}><Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography></TableCell></TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                    <Typography variant='h6' sx={{ marginTop: '12px' }}>Informasi Relawan</Typography>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Id Relawan</TableCell>
                                                <TableCell>Nama Relawan</TableCell>
                                                <TableCell>Aksi</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                konstituenDataDetail.relawan_list.length > 0 ? konstituenDataDetail.relawan_list.map(k => <TableRow key={k.relasi_id}>
                                                    <TableCell>{k.relasi_id}</TableCell>
                                                    <TableCell>{k.nama_relasi}</TableCell>
                                                    <TableCell><Button variant='contained' color='error' title='hapus' startIcon={<Delete />} onClick={() => removeRelasi(konstituenDataDetail.konstituen.konstituen_id, k.relasi_id)}>Hapus</Button></TableCell>
                                                </TableRow>) : <TableRow><TableCell colSpan={8} align='center'><Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Relawan</Typography><Button onClick={() => {setOpenModalRelasi(true); setDataTempTambahRelasi(ListMemberField); setFormNik('')}} sx={{ marginTop: '10px' }} startIcon={<Add />} title='Tambah' variant='contained' color='success'>Relasikan</Button></TableCell></TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: '50px' }}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='h6'>Informasi Lokasi TPS</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Provinsi</TableCell>
                                        <TableCell>Kabupaten</TableCell>
                                        <TableCell>Kecamatan</TableCell>
                                        <TableCell>Desa</TableCell>
                                        <TableCell>RW</TableCell>
                                        <TableCell>RT</TableCell>
                                        <TableCell>TPS</TableCell>
                                        <TableCell>Alamat</TableCell>
                                    </TableRow>
                                </TableHead>
                                    <TableBody>
                                        {
                                            konstituenDataDetail.area.filter(a => a.alamat_utama).length > 0 ? konstituenDataDetail.area.filter(a => a.alamat_utama).map(k => 
                                            <TableRow key={k.area_member_id}>
                                                <TableCell>{k.nama_provinsi}</TableCell>
                                                <TableCell>{k.nama_kabupaten}</TableCell>
                                                <TableCell>{k.nama_kecamatan}</TableCell>
                                                <TableCell>{k.nama_desa}</TableCell>
                                                <TableCell>{k.nama_rw}</TableCell>
                                                <TableCell>{k.nama_rt}</TableCell>
                                                <TableCell>{k.nama_tps}</TableCell>
                                                <TableCell>{konstituenDataDetail.tps.alamat}</TableCell>
                                            </TableRow>) : <TableRow><TableCell colSpan={8}><Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography></TableCell></TableRow>
                                        }
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='h6'>Informasi Lokasi Rumah</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Provinsi</TableCell>
                                        <TableCell>Kabupaten</TableCell>
                                        <TableCell>Kecamatan</TableCell>
                                        <TableCell>Desa</TableCell>
                                        <TableCell>RW</TableCell>
                                        <TableCell>RT</TableCell>
                                        <TableCell>TPS</TableCell>
                                        <TableCell>Utama</TableCell>
                                    </TableRow>
                                </TableHead>
                                    <TableBody>
                                        {
                                            konstituenDataDetail.area.length > 0 ? konstituenDataDetail.area.map(k => <TableRow key={k.area_member_id}>
                                                <TableCell>{k.nama_provinsi}</TableCell>
                                                <TableCell>{k.nama_kabupaten}</TableCell>
                                                <TableCell>{k.nama_kecamatan}</TableCell>
                                                <TableCell>{k.nama_desa}</TableCell>
                                                <TableCell>{k.nama_rw}</TableCell>
                                                <TableCell>{k.nama_rt}</TableCell>
                                                <TableCell>{k.nama_tps}</TableCell>
                                                <TableCell>{k.alamat_utama ? <Chip label={'Utama'} variant="outlined" color="primary" /> : <Chip label={'Bukan'} variant="outlined" color="error" />}</TableCell>
                                            </TableRow>) : <TableRow><TableCell colSpan={8}><Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography></TableCell></TableRow>
                                        }
                                    </TableBody>
                                    
                            </Table>
                        </Grid>
                    </Grid>
                    <ModalCreateRelasiToRelawan
                        openModalRelasi={openModalRelasi}
                        setOpenModalRelasi={setOpenModalRelasi}
                        addRelasi={addRelasi}
                        checkNikToRelasiRelawan={checkNikToRelasiRelawan}
                        dataTempTambahRelasi={dataTempTambahRelasi}
                        konstituenDataDetail={konstituenDataDetail}
                        formNik={formNik}
                        setFormNik={setFormNik}
                    />
                </Container>
            }
        </Dialog>
    );
}

export default DetailKonstituenMember;
