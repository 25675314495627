import { Close, SaveAs } from '@mui/icons-material';
import { Alert, AppBar, Button, Checkbox, Container, Dialog, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Slide, TextField, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import {Map, Marker} from '@vis.gl/react-google-maps';
import { AreaMemberProps, ErrorAreaMemberProps } from '../../models/Area/AreaMemberProps';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { ProvinsiProps } from '../../models/Area/ProvinsiProps';
import { KabupatenProps } from '../../models/Area/KabupatenProps';
import { KecamatanProps } from '../../models/Area/KecamatanProps';
import { DesaProps } from '../../models/Area/DesaProps';
import { RwProps } from '../../models/Area/RwProps';
import { RtProps } from '../../models/Area/RtProps';
import { TpsProps } from '../../models/Area/TpsProps';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogAreaMemberProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    createAlamat: any;
    titleDialog: string;
    formAreaMember: AreaMemberProps;
    setFormAreaMember: React.Dispatch<React.SetStateAction<AreaMemberProps>>;
    messageSnackbar: string;
    errorForm: ErrorAreaMemberProps;
    setErrorForm: React.Dispatch<React.SetStateAction<ErrorAreaMemberProps>>;
    theme: ThemeStoreProps;
    getKabupaten: any;
    getKecamatan: any;
    getDesa: any;
    getRw: any;
    getRt: any;
    getTps: any;
    provinsiData: ProvinsiProps[];
    kabupatenData: KabupatenProps[];
    kecamatanData: KecamatanProps[];
    desaData: DesaProps[];
    rwData: RwProps[];
    rtData: RtProps[];
    tpsData: TpsProps[];
}

const DialogAreaMember: React.FC<DialogAreaMemberProps> = ({ openModal, setOpenModal, createAlamat, titleDialog, formAreaMember, setFormAreaMember, messageSnackbar, errorForm, setErrorForm, theme, getKabupaten, getKecamatan, getDesa, getRw, getRt, getTps, provinsiData, kabupatenData, kecamatanData, desaData, rwData, rtData, tpsData}) => {
    return (
        <Dialog
            fullScreen
            open={openModal}
            onClose={() => setOpenModal(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setOpenModal(false)}
                    aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {titleDialog}
                    </Typography>
                    {
                        titleDialog.split(" ").includes("Tambah") ? 
                        <Button autoFocus startIcon={<SaveAs />} color="inherit" onClick={() => createAlamat()}>
                            Simpan
                        </Button> :
                        <Button autoFocus startIcon={<Close />} color="inherit" onClick={() => setOpenModal(false)}>
                            Tutup
                        </Button>
                    }
                </Toolbar>
            </AppBar>
            <Container>
                <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}><Typography variant='h5'>Form Alamat</Typography></Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                    <InputLabel id="provinsi-select">Provinsi</InputLabel>
                                    <Select
                                        labelId="provinsi-select"
                                        id="provinsi_id"
                                        value={formAreaMember.provinsi_id}
                                        disabled={provinsiData.length === 0}
                                        label="Provinsi"
                                        onChange={(e) => {setFormAreaMember({...formAreaMember, provinsi_id: e.target.value});getKabupaten(e.target.value)}}
                                    >
                                        {
                                            provinsiData.map(p => <MenuItem key={p.provinsi_id} value={p.provinsi_id}>{p.nama_provinsi}</MenuItem>)
                                        }
                                    </Select>
                                    {(errorForm.provinsi_id != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.provinsi_id}</Typography> : <></>}
                                </FormControl>
                                <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                    <InputLabel id="kabupaten-select">Kabupaten</InputLabel>
                                    <Select
                                        labelId="kabupaten-select"
                                        id="kabupaten_id"
                                        value={formAreaMember.kabupaten_id}
                                        disabled={kabupatenData.length === 0}
                                        label="Kabupaten"
                                        onChange={(e) => {setFormAreaMember({...formAreaMember, kabupaten_id: e.target.value});getKecamatan(e.target.value)}}
                                    >
                                        {
                                            kabupatenData.map(p => <MenuItem key={p.kabupaten_id} value={p.kabupaten_id}>{p.nama_kabupaten}</MenuItem>)
                                        }
                                    </Select>
                                    {(errorForm.kabupaten_id != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.kabupaten_id}</Typography> : <></>}
                                </FormControl>
                                <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                    <InputLabel id="kecamatan-select">Kecamatan</InputLabel>
                                    <Select
                                        labelId="kecamatan-select"
                                        id="kecamatan_id"
                                        value={formAreaMember.kecamatan_id}
                                        disabled={kecamatanData.length === 0}
                                        label="Kecamatan"
                                        onChange={(e) => {setFormAreaMember({...formAreaMember, kecamatan_id: e.target.value});getDesa(e.target.value)}}
                                    >
                                        {
                                            kecamatanData.map(p => <MenuItem key={p.kecamatan_id} value={p.kecamatan_id}>{p.nama_kecamatan}</MenuItem>)
                                        }
                                    </Select>
                                    {(errorForm.kecamatan_id != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.kecamatan_id}</Typography> : <></>}
                                </FormControl>
                                <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                    <InputLabel id="desa-select">Desa</InputLabel>
                                    <Select
                                        labelId="desa-select"
                                        id="desa_id"
                                        disabled={desaData.length === 0}
                                        value={formAreaMember.desa_id}
                                        label="Desa"
                                        onChange={(e) => {setFormAreaMember({...formAreaMember, desa_id: e.target.value});getRw(e.target.value)}}
                                    >
                                        {
                                            desaData.map(p => <MenuItem key={p.desa_id} value={p.desa_id}>{p.nama_desa}</MenuItem>)
                                        }
                                    </Select>
                                    {(errorForm.desa_id != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.desa_id}</Typography> : <></>}
                                </FormControl>
                                <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                    <InputLabel id="rw-select">RW</InputLabel>
                                    <Select
                                        labelId="rw-select"
                                        id="rw_id"
                                        disabled={rwData.length === 0}
                                        value={formAreaMember.rw_id}
                                        label="RW"
                                        onChange={(e) => {setFormAreaMember({...formAreaMember, rw_id: e.target.value});getRt(e.target.value); getTps(e.target.value, null);}}
                                    >
                                        {
                                            rwData.map(p => <MenuItem key={p.rw_id} value={p.rw_id}>{p.nama_rw}</MenuItem>)
                                        }
                                    </Select>
                                    {(errorForm.rw_id != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.rw_id}</Typography> : <></>}
                                </FormControl>
                                <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                    <InputLabel id="rt-select">RT</InputLabel>
                                    <Select
                                        labelId="rt-select"
                                        disabled={rtData.length === 0}
                                        id="rt_id"
                                        value={formAreaMember.rt_id}
                                        label="RT"
                                        onChange={(e) => {setFormAreaMember({...formAreaMember, rt_id: e.target.value}); getTps(formAreaMember.rw_id, e.target.value);}}
                                    >
                                        {
                                            rtData.map(p => <MenuItem key={p.rt_id} value={p.rt_id}>{p.nama_rt}</MenuItem>)
                                        }
                                    </Select>
                                    {(errorForm.rt_id != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.rt_id}</Typography> : <></>}
                                </FormControl>
                                <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                    <InputLabel id="tps-select">TPS</InputLabel>
                                    <Select
                                        labelId="tps-select"
                                        disabled={tpsData.length === 0}
                                        id="rt_id"
                                        value={formAreaMember.tps_id}
                                        label="RT"
                                        onChange={(e) => {setFormAreaMember({...formAreaMember, tps_id: e.target.value});}}
                                    >
                                        {
                                            tpsData.map(p => <MenuItem key={p.tps_id} value={p.tps_id}>{p.nama_tps}</MenuItem>)
                                        }
                                    </Select>
                                    {(errorForm.tps_id != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.tps_id}</Typography> : <></>}
                                </FormControl>
                                <FormControlLabel control={<Checkbox checked={formAreaMember.alamat_utama} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFormAreaMember({...formAreaMember, alamat_utama: event.target.checked})} />} label="Jadikan Alamat Utama" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}><Typography variant='h5'>Titik Maps</Typography></Grid>
                            <Grid item xs={12}>
                                <Alert severity='info'>Ketuk Peta untuk menentukan titik Alamat Anda.</Alert>
                            </Grid>
                            <Grid item xs={12}>
                                <Map
                                    defaultZoom={8}
                                    style={{ width: '100%', height: '350px' }}
                                    defaultCenter={ { lat: process.env.REACT_APP_MAPS_LATTITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LATTITUDE) : -4.55858490, lng: process.env.REACT_APP_MAPS_LONGITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LONGITUDE) : 105.40680790 } }
                                    onClick={(e) => {
                                        setFormAreaMember({...formAreaMember, lattitude: e.detail.latLng !== null ? e.detail.latLng.lat.toString() : '', longitude: e.detail.latLng !== null ? e.detail.latLng.lng.toString() : ''});
                                    }}
                                    >
                                        {(
                                            <Marker position={{lat: parseFloat(formAreaMember.lattitude), lng: parseFloat(formAreaMember.longitude)}} />
                                        )}
                                </Map>
                                <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                    <TextField
                                        required
                                        sx={{ marginTop: '15px' }}
                                        fullWidth
                                        id="longitude"
                                        label="Longitude"
                                        name="longitude"
                                        autoFocus
                                        disabled
                                        value={formAreaMember.longitude}
                                    />
                                    {(errorForm.longitude != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.longitude}</Typography> : <></>}
                                </FormControl>
                                <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="lattitude"
                                        label="Lattitude"
                                        name="lattitude"
                                        autoFocus
                                        disabled
                                        value={formAreaMember.lattitude}
                                    />
                                    {(errorForm.lattitude != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.lattitude}</Typography> : <></>}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    );
}

export default DialogAreaMember;
