import React, { ChangeEvent } from 'react';
import { CalonPemimpinField, CalonPemimpinProps, ErrorCalonPemimpinField, ErrorCalonPemimpinProps } from '../../models/QuickCount/Leader/CalonPemimpinProps';
import { useRecoilValue } from 'recoil';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { ThemeStoreState } from '../../store';
import Swal from 'sweetalert2';
import CalonPemimpinService from '../../services/PerhitunganSuara/CalonPemimpinService';
import { replaceItemAtIndex } from '../../Utils/helper';
import { CalonPemimpinValidateForm } from '../../validation/ForumValidateForm';
import { Box, Button, Container, Grid, Paper, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { Add, AssuredWorkload, Delete, Edit } from '@mui/icons-material';
import ImageDefault from "../../asset/images/cat.jpg";
import DialogPengelolaanCalonPemimpin from '../../pages/Superadmin/DialogPengelolaanCalonPemimpin';
import { PartaiProps } from '../../models/Institution/PartaiProps';
import { PeriodeProps } from '../../models/Forums/PeriodeProps';
import { KategoriProps } from '../../models/Forums/KategoriProps';
import { JenisPemilihanProps } from '../../models/Forums/JenisPemilihanProps';
import PeriodeService from '../../services/Forum/PeriodeService';
import KategoriService from '../../services/Forum/KategoriService';
import JenisPemilihanService from '../../services/Forum/JenisPemilihanService';
import JenisTingkatPemilihanService from '../../services/Forum/JenisTingkatPemilihanService';
import { JenisTingkatPemilihanProps } from '../../models/Forums/JenisTingkatPemilihanProps';
import { ProvinsiProps } from '../../models/Area/ProvinsiProps';
import { KabupatenProps } from '../../models/Area/KabupatenProps';
import ProvinsiService from '../../services/Area/ProvinsiService';
import KabupatenService from '../../services/Area/KabupatenService';

const SuperadminPengelolaanCalonPemimpin = () => {
    // Partai 
    const [openModalPartai, setOpenModalPartai] = React.useState<boolean>(false);
    const [partaiData, setPartaiData] = React.useState<PartaiProps[]>([]);
    const [loadingSkeletonTablePartai, setLoadingSkeletonTablePartai] = React.useState<boolean>(false);
    const [settingTablePartai, setSettingTablePartai] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'partaiId',
        search: '',
        totalElements: 0,
        totalPages: 0,
    });
    const openAddDukungan = async () => {
        setOpenModalPartai(true);
        openAddDukunganAxios();

    }
    const openAddDukunganAxios = async () => {
        setLoadingSkeletonTablePartai(true);
        await CalonPemimpinService.getAllPartaiByCalonPemimpin(settingTablePartai.page, settingTablePartai.size, settingTablePartai.sort, settingTablePartai.search, calonPemimpinDataDetail.calon_pemimpin_id, true).then(res => {
            setPartaiData(res.data.content);
            setSettingTablePartai({...settingTablePartai, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak ada jaringan");
            setOpenSnackbar(true);
        }).finally(() => setLoadingSkeletonTablePartai(false));
    }
    const addDukungan = async(p: PartaiProps) => {
        setLoadingSkeletonTablePartai(true);
        setLoadingSkeletonTablePartaiAdd(true);
        await CalonPemimpinService.addRelation(calonPemimpinDataDetail.calon_pemimpin_id, p.partai_id).then(res => {
            setPartaiData([...partaiData, p]);
            setPartaiAddData(partaiAddData.filter(x => x.partai_id !== p.partai_id));
            setSettingTablePartai({...settingTablePartai, totalElements: settingTablePartai.totalElements + 1});
            setSettingTablePartaiAdd({...settingTablePartaiAdd, totalElements: settingTablePartaiAdd.totalElements - 1});
            setMessageSnackbar("Berhasil Menambahkan dukungan dari Partai " + p.nama_partai);
            setOpenSnackbar(true);
            setOpenModalPartai(false);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak ada Jaringan");
            setOpenSnackbar(true);
        }).finally(() => {
            setLoadingSkeletonTablePartai(false);
            setLoadingSkeletonTablePartaiAdd(false);
        });
    }
    const deleteDukungan = async(p: PartaiProps) => {
        setLoadingSkeletonTablePartai(true);
        setLoadingSkeletonTablePartaiAdd(true);
        await CalonPemimpinService.removeRelation(calonPemimpinDataDetail.calon_pemimpin_id, p.partai_id).then(res => {
            setPartaiData(partaiData.filter(x => x.partai_id !== p.partai_id));
            setPartaiAddData([...partaiAddData, p]);
            setSettingTablePartai({...settingTablePartai, totalElements: settingTablePartai.totalElements - 1});
            setSettingTablePartaiAdd({...settingTablePartaiAdd, totalElements: settingTablePartaiAdd.totalElements + 1});
            setMessageSnackbar("Berhasil Melepaskan dukungan dari Partai " + p.nama_partai);
            setOpenSnackbar(true);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak ada Jaringan");
            setOpenSnackbar(true);
        }).finally(() => {
            setLoadingSkeletonTablePartai(false);
            setLoadingSkeletonTablePartaiAdd(false);
        });
    }
    const handleChangePagePartai = (event: unknown, newPage: number) => {
        setLoadingSkeletonTablePartai(true);
        setSettingTablePartai({...settingTablePartai,  page: newPage});
        setLoadingSkeletonTablePartai(false);
    };
    const handleChangeRowsPerPagePartai = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTablePartai({...settingTablePartai, page: 0, size: +event.target.value});
    };
    const openToPartai = async (cp: CalonPemimpinProps) => {
        setCalonPemimpinDataDetail(cp);
        await CalonPemimpinService.getAllPartaiByCalonPemimpin(settingTablePartai.page, settingTablePartai.size, settingTablePartai.sort, settingTablePartai.search, cp.calon_pemimpin_id, true).then(res => {
            setPartaiData(res.data.content);
            setSettingTablePartai({...settingTablePartai, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
            setTitleDialog("Dukungan Partai Politik");
            setOpenModal(true);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak ada jaringan");
            setOpenSnackbar(true);
        });
    }
    const getProvinsi = async() => {
        await ProvinsiService.getAllArray().then(res => setProvinsiData(res.data)).catch(err => {});
    }
    const getKabupaten = async (provinsiId?: string) => {
        await KabupatenService.getAllArray(provinsiId !== undefined ? provinsiId : calonPemimpinDataDetail.provinsi_id).then(res => setKabupatenData(res.data)).catch(err => {});
    }

    // Add Partai 
    const [loadingSkeletonTablePartaiAdd, setLoadingSkeletonTablePartaiAdd] = React.useState<boolean>(false);
    const [partaiAddData, setPartaiAddData] = React.useState<PartaiProps[]>([]);
    const [settingTablePartaiAdd, setSettingTablePartaiAdd] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'partaiId',
        search: '',
        totalElements: 0,
        totalPages: 0,
    });
    const openAddDukunganPartai = async () => {
        setOpenModalPartai(true);
        openAddDukunganPartaiAxios();

    }
    const openAddDukunganPartaiAxios = async () => {
        setLoadingSkeletonTablePartaiAdd(true);
        await CalonPemimpinService.getAllPartaiByCalonPemimpin(settingTablePartaiAdd.page, settingTablePartaiAdd.size, settingTablePartaiAdd.sort, settingTablePartaiAdd.search, calonPemimpinDataDetail.calon_pemimpin_id, false).then(res => {
            setPartaiAddData(res.data.content);
            setSettingTablePartaiAdd({...settingTablePartaiAdd, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak ada jaringan");
            setOpenSnackbar(true);
        }).finally(() => setLoadingSkeletonTablePartaiAdd(false));
    }
    const handleChangePagePartaiAdd = (event: unknown, newPage: number) => {
        setLoadingSkeletonTablePartaiAdd(true);
        setSettingTablePartaiAdd({...settingTablePartaiAdd,  page: newPage});
        setLoadingSkeletonTablePartaiAdd(false);
    };
    const handleChangeRowsPerPagePartaiAdd = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTablePartaiAdd({...settingTablePartaiAdd, page: 0, size: +event.target.value});
    };

    // Calon Pemimpin 
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState);
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const [loadingSkeletonTable, setLoadingSkeletonTable] = React.useState<boolean>(false);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [provinsiData, setProvinsiData] = React.useState<ProvinsiProps[]>([]);
    const [kabupatenData, setKabupatenData] = React.useState<KabupatenProps[]>([]);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [titleDialog, setTitleDialog] = React.useState<string>('');
    const [calonPemimpinData, setCalonPemimpinData] = React.useState<CalonPemimpinProps[]>([]);
    const [calonPemimpinDataDetail, setCalonPemimpinDataDetail] = React.useState<CalonPemimpinProps>(CalonPemimpinField);
    const [errors, setErrors] = React.useState<ErrorCalonPemimpinProps>(ErrorCalonPemimpinField);
    const [image, setImage] = React.useState<File | null>(null);
    const [imagePreview, setImagePreview] = React.useState<string | null>(null);
    const [settingTable, setSettingTable] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'calonPemimpinId',
        search: '',
        totalElements: 0,
        totalPages: 0,
    });

    const [periodeData, setPeriodeData] = React.useState<PeriodeProps[]>([]);
    const [kategoriData, setKategoriData] = React.useState<KategoriProps[]>([]);
    const [jenisPemilihanData, setJenisPemilihanData] = React.useState<JenisPemilihanProps[]>([]);
    const [jenisTingkatPemilihanData, setJenisTingkatPemilihanData] = React.useState<JenisTingkatPemilihanProps[]>([]);
    const getPeriode = async () => {
        if(periodeData.length === 0) {
            await PeriodeService.getAllArr().then(res=> {
                setPeriodeData(res.data);
            }).catch(err=>{setMessageSnackbar(err.response.data.message ?? "Tidak ada Jaringan");setOpenSnackbar(true);});
        }
    }
    const getKategori = async (periodeId:string) => {
        await KategoriService.getAllArr(periodeId).then(res=> {
            setKategoriData(res.data);
        }).catch(err=>{setMessageSnackbar(err.response.data.message ?? "Tidak ada Jaringan");setOpenSnackbar(true);});
    }
    const getJenisPemilihan = async (kategoriId:string) => {
        await JenisPemilihanService.getAllArr(kategoriId).then(res=> {
            setJenisPemilihanData(res.data);
        }).catch(err=>{setMessageSnackbar(err.response.data.message ?? "Tidak ada Jaringan");setOpenSnackbar(true);});
    }
    const getJenisTingkatPemilihan = async(jenisPemilihanId: string) => {
        await JenisTingkatPemilihanService.getAllArr(jenisPemilihanId).then(res=> {
            setJenisTingkatPemilihanData(res.data);
        }).catch(err=>{setMessageSnackbar(err.response.data.message ?? "Tidak ada Jaringan");setOpenSnackbar(true);});
    }
    const getAll = async () => {
        await CalonPemimpinService.getAll(settingTable.page, settingTable.size, settingTable.sort, settingTable.search).then(res => {
            setCalonPemimpinData(res.data.content);
            setSettingTable({...settingTable, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak ada jaringan");
            setOpenSnackbar(true);
        }).finally(() => {
            setLoadingSkeleton(false);
            setLoadingSkeletonTable(false);
        });
    }
    const openAdd = async () => {
        setCalonPemimpinDataDetail(CalonPemimpinField);
        setImage(null);
        setImagePreview(null);
        setErrors(ErrorCalonPemimpinField);
        setTitleDialog("Tambah Calon Pemimpin");
        setOpenModal(true);
    }
    const openById = async (cp: CalonPemimpinProps) => {
        await getKategori(cp.periode_id);
        await getJenisPemilihan(cp.kategori_id);
        await getJenisTingkatPemilihan(cp.jenis_pemilihan_id);
        setCalonPemimpinDataDetail(cp);
        setImage(null);
        setImagePreview(null);
        setErrors(ErrorCalonPemimpinField);
        setTitleDialog("Ubah Calon Pemimpin");
        setOpenModal(true);
    }
    function uploadImage(event: ChangeEvent<HTMLInputElement>) {
        const selectedFile = event.target.files?.[0] || null;
        setImage(selectedFile);

        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        }
    }
    const createCalonPemimpin = async () => {
        const newErrors: any = {};
        try {
            await CalonPemimpinValidateForm.validate(calonPemimpinDataDetail, {abortEarly:false});
            setErrors(ErrorCalonPemimpinField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            const data = new FormData();
            if(image) {
                data.append('image', image)
                await CalonPemimpinService.create(data, calonPemimpinDataDetail).then(res => {
                    setCalonPemimpinData([...calonPemimpinData, res.data]);
                    setSettingTable({...settingTable, totalElements: settingTable.totalElements + 1})
                    setMessageSnackbar("Calon Pemimpin berhasil di tambah");
                    setOpenSnackbar(true);
                    setOpenModal(false);
                }).catch(err => {
                    setMessageSnackbar(err.response.data.message ?? "Calon Pemimpin gagal di tambah");
                    setOpenSnackbar(true);
                })
            } else {
                setMessageSnackbar("Gambar Calon Perlu di Upload");
                setOpenSnackbar(true);
            }
        }
    }
    const updateCalonPemimpin = async () => {
        const newErrors: any = {};
        try {
            await CalonPemimpinValidateForm.validate(calonPemimpinDataDetail, {abortEarly:false});
            setErrors(ErrorCalonPemimpinField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            const data = new FormData();
            if(image) {
                data.append('image', image)
            } 
            await CalonPemimpinService.put(data, calonPemimpinDataDetail).then(res => {
                let index = calonPemimpinData.findIndex(p => p.calon_pemimpin_id === calonPemimpinDataDetail.calon_pemimpin_id);
                setCalonPemimpinData(replaceItemAtIndex(calonPemimpinData, index, res.data));
                setMessageSnackbar("Calon Pemimpin berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Calon Pemimpin gagal di ubah");
                setOpenSnackbar(true);
            })
        }
    }
    const deleteCalonPemimpin = async (cp: CalonPemimpinProps) => {
        Swal.fire({
            title: 'Hapus Calon Pemimpin',
            text: 'Apakah anda ingin menghapus Calon Pemimpin ' + cp.nama_calon + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                CalonPemimpinService.delete(cp.calon_pemimpin_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Calon Pemimpin "+ cp.nama_calon +" sudah dihapus.",
                        icon: "success"
                    });
                    setCalonPemimpinData(calonPemimpinData.filter(p => p.calon_pemimpin_id !== cp.calon_pemimpin_id));
                    setSettingTable({...settingTable, totalElements: settingTable.totalElements - 1})
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: err.response.data.message ?? "Calon Pemimpin "+ cp.nama_calon +" gagal dihapus.",
                        icon: "error"
                    });
                })
            }
        });
    }
    const handleChangePage = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable,  page: newPage});
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTable({...settingTable, page: 0, size: +event.target.value});
    };

    React.useEffect(() => {
        if(openModal && openModalPartai) {
            openAddDukunganPartaiAxios();
        }

        if(openModal && !openModalPartai) {
            openAddDukunganAxios();
        }

        if(!openModal && !openModalPartai) {
            getPeriode();
            getProvinsi();
            getAll();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        settingTable.page,
        settingTable.size,
        settingTable.sort,
        settingTable.search,
        settingTablePartai.page,
        settingTablePartai.size,
        settingTablePartai.sort,
        settingTablePartai.search,
        settingTablePartaiAdd.page,
        settingTablePartaiAdd.size,
        settingTablePartaiAdd.sort,
        settingTablePartaiAdd.search,

    ]);

    return loadingSkeleton ? (<Container>
        <Skeleton animation="wave" width={'100%'} height={'576px'} variant='rounded'></Skeleton>
    </Container>) : (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: '10px' }}>
                    <Grid container spacing={2} sx={{ paddingBottom: '5px' }}>
                        <Grid item xs={8} sm={8}>
                            <Typography variant='h3' fontSize={'24px'}>Calon Pemimpin</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} display={'flex'} justifyContent={'flex-end'}>
                            <Button startIcon={<Add />} onClick={() => openAdd()} title='tambah' color='info' variant='contained'>Tambah</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {
                loadingSkeletonTable ? <Skeleton animation={'wave'} width={'100%'} height={'500px'} /> :
                <Grid item xs={12} sm={12} md={12}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 540 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Gambar</TableCell>
                                        <TableCell>Nama Calon</TableCell>
                                        <TableCell>Nama Wakil</TableCell>
                                        <TableCell>Kabinet</TableCell>
                                        <TableCell>Aksi</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    calonPemimpinData.length !== 0 ? calonPemimpinData.map(p => 
                                    <TableRow key={p.calon_pemimpin_id}>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <img src={p.gambar !== '' ? p.gambar : ImageDefault} alt={p.nama_calon} width={'100%'} height={'100vh'} />
                                            </Box>
                                        </TableCell>
                                        <TableCell>{p.nama_calon}</TableCell>
                                        <TableCell>{p.nama_wakil_calon}</TableCell>
                                        <TableCell>{p.kabinet}</TableCell>
                                        <TableCell>
                                            <Button sx={{ marginLeft: '5px' }} onClick={() => openToPartai(p)} size='small' variant='contained' startIcon={<AssuredWorkload />} color='info'>Partai</Button>
                                            <Button sx={{ marginLeft: '5px' }} onClick={() => openById(p)} size='small' variant='contained' startIcon={<Edit />} color='success'>Ubah</Button>
                                            <Button sx={{ marginLeft: '5px' }} onClick={() => deleteCalonPemimpin(p)} size='small' variant='contained' startIcon={<Delete />} color='error'>Hapus</Button>
                                        </TableCell>
                                    </TableRow> 
                                    )
                                    : <TableRow>
                                        <TableCell colSpan={6}>
                                            <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                        component="div"
                        count={settingTable.totalElements}
                        rowsPerPage={settingTable.size}
                        page={settingTable.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Paper>
                </Grid>
                }
                <Snackbar
                    open={openSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={3000}
                    onClose={() => setOpenSnackbar(false)}
                    message={messageSnackbar}
                />
                <DialogPengelolaanCalonPemimpin
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    theme={theme}
                    titleDialog={titleDialog}
                    errors={errors}
                    setErrors={setErrors}
                    calonPemimpinDataDetail={calonPemimpinDataDetail}
                    setCalonPemimpinDataDetail={setCalonPemimpinDataDetail}
                    createCalonPemimpin={createCalonPemimpin}
                    updateCalonPemimpin={updateCalonPemimpin}
                    image={image}
                    setImage={setImage}
                    imagePreview={imagePreview}
                    setImagePreview={setImagePreview}
                    uploadImage={uploadImage}
                    ImageDefault={ImageDefault}
                    settingTablePartai={settingTablePartai}
                    setSettingTablePartai={setSettingTablePartai}
                    loadingSkeletonTablePartai={loadingSkeletonTablePartai}
                    partaiData={partaiData}
                    handleChangePagePartai={handleChangePagePartai}
                    handleChangeRowsPerPagePartai={handleChangeRowsPerPagePartai}
                    openModalPartai={openModalPartai}
                    setOpenModalPartai={setOpenModalPartai}
                    openAddDukungan={openAddDukungan}
                    addDukungan={addDukungan}
                    deleteDukungan={deleteDukungan}
                    loadingSkeletonTablePartaiAdd={loadingSkeletonTablePartaiAdd}
                    setLoadingSkeletonTablePartaiAdd={setLoadingSkeletonTablePartaiAdd}
                    partaiAddData={partaiAddData}
                    setPartaiAddData={setPartaiAddData}
                    settingTablePartaiAdd={settingTablePartaiAdd}
                    setSettingTablePartaiAdd={setSettingTablePartaiAdd}
                    handleChangePagePartaiAdd={handleChangePagePartaiAdd}
                    handleChangeRowsPerPagePartaiAdd={handleChangeRowsPerPagePartaiAdd}
                    openAddDukunganPartai={openAddDukunganPartai}
                    periodeData={periodeData}
                    kategoriData={kategoriData}
                    jenisPemilihanData={jenisPemilihanData}
                    jenisTingkatPemilihanData={jenisTingkatPemilihanData}
                    getPeriode={getPeriode}
                    getKategori={getKategori}
                    getJenisPemilihan={getJenisPemilihan}
                    getJenisTingkatPemilihan={getJenisTingkatPemilihan}
                    provinsiData={provinsiData}
                    kabupatenData={kabupatenData}
                    getKabupaten={getKabupaten}
                    setKategori={setKategoriData}
                    setJenisPemilihan={setJenisPemilihanData}
                    setJenisTingkatPemilihan={setJenisTingkatPemilihanData}
                />
            </Grid>
    );
}

export default SuperadminPengelolaanCalonPemimpin;
