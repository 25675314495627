const { atom } = require("recoil");

const ThemeStoreState = atom({
    key: "ThemeStore",
    default: {
        backgroundColor: 'red',
        color: 'white'
    },
});

export default ThemeStoreState;
