import { Grid, Typography } from '@mui/material';
import React from 'react';
import CardPortal from '../../components/CardPortal';
import BlogPostProps from '../../models/Blog/BlogPostProps';

const beritaList: BlogPostProps[] = [
    {
        blog_post_id: "1",
        member_id: "",
        nama_member: "",
        title: "Jogetin Aja",
        slug: "",
        summary: "",
        content: "",
        published_at: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        published: true,
        visited: 100,
        meta: [],
        category: [],
        tag: [],
    },
    {
        blog_post_id: "2",
        member_id: "",
        nama_member: "",
        title: "Haha",
        slug: "",
        summary: "",
        content: "",
        published_at: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        published: true,
        visited: 100,
        meta: [],
        category: [],
        tag: [],
    },
];

const Berita: React.FC = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h5' sx={{ paddingY: '10px' }}>Berita Terbaru</Typography>
            </Grid>
            {
                beritaList.map(berita => 
                    <Grid item xs={12} key={berita.blog_post_id}>
                        <CardPortal
                            blog_post_id={berita.blog_post_id}
                            member_id={berita.member_id}
                            nama_member={berita.nama_member}
                            title={berita.title}
                            slug={berita.slug}
                            summary={berita.summary}
                            content={berita.content}
                            published_at={berita.published_at}
                            created_at={berita.created_at}
                            updated_at={berita.updated_at}
                            deleted_at={berita.deleted_at}
                            published={berita.published}
                            visited={berita.visited}
                            meta={berita.meta}
                            category={berita.category}
                            tag={berita.tag}
                        />
                    </Grid>
                )
            }
        </Grid>
    );
}

export default Berita;
