import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import { BerandaRelawanResponse } from '../../models/Relawan/BerandaRelawanResponse';
import RelawanDetailKonstituen from '../../models/Relawan/RelawanDetailKonstituen';

class RelawanServices {
    async findAllKonstituen(page: number, size: number, sort: string, search: string, relawanId?: string | null, memberId?: string | null, provinsiId?: string | null, kabupatenId?: string | null, kecamatanId?: string | null, desaId?: string | null, rwId?: string | null, rtId?: string | null, tpsId?: string | null, hakPilih?: string | null): Promise<AxiosResponse<PaginationResponseProps>> {
        hakPilih = (hakPilih === null) ? 'null' : hakPilih;
        return axios.get(`/api/v1/relawan/konstituen?page=${page}&size=${size}&sort=${sort}&search=${search}${relawanId!== null ? '&relawanId=' + relawanId : ''}${memberId!== null ? '&memberId=' + memberId : ''}${provinsiId!== null ? '&provinsiId=' + provinsiId : ''}${kabupatenId!== null ? '&kabupatenId=' + kabupatenId : ''}${kecamatanId !== null ? '&kecamatanId=' + kecamatanId : ''}${desaId!== null ? '&desaId=' + desaId : ''}${rwId!== null ? '&rwId=' + rwId : ''}${rtId!== null ? '&rtId=' + rtId : ''}${tpsId!== null ? '&tpsId=' + tpsId : ''}${hakPilih!== 'null' ? '&hakPilih=' + hakPilih : ''}`,{headers: {'Content-Type': 'application/json',}});
    }

    async beranda(memberId: string): Promise<AxiosResponse<BerandaRelawanResponse>> {
        return axios.get(`/api/v1/relawan?memberId=${memberId}`,{headers: {'Content-Type': 'application/json',}});
    }

    async pendaftaran(): Promise<AxiosResponse<void>> {
        return axios.get(`/api/v1/relawan/pendaftaran`, {headers: {'Content-Type': 'application/json',}});
    }

    async detailKonstituen(memberId: string): Promise<AxiosResponse<RelawanDetailKonstituen>> {
        return axios.get(`/api/v1/relawan/detail-konstituen?memberId=${memberId}`, {headers: {'Content-Type': 'application/json'}});
    }

    async aktivitas(): Promise<AxiosResponse<void>> {
        return axios.get(`/api/v1/relawan/aktivitas`, {headers: {'Content-Type': 'application/json'}});
    }

    async bantuan(): Promise<AxiosResponse<void>> {
        return axios.get(`/api/v1/relawan/bantuan`, {headers: {'Content-Type': 'application/json',}});
    }

    async getKodeReferensi(member_id: string): Promise<AxiosResponse<string>> {
        return axios.get(`/api/v1/relawan/kode-referensi?memberId=${member_id}`, {headers: {'Content-Type': 'application/json',}});
    }

    async cekNik(nik: string): Promise<AxiosResponse<boolean>> {
        return axios.get(`/api/v1/relawan/cek-nik?nik=${nik}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RelawanServices();