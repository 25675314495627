import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ThemeStoreProps from '../../../models/Store/ThemeStoreProps';
import { ThemeStoreState } from '../../../store';

const pages = [
    {
        path: '/berita',
        title: 'Berita'
    }, 
    {
        path: '/tag',
        title: 'Tag'
    }, 
    {
        path: '/kategori',
        title: 'Kategori'
    }
];
const authMenu = [
    {
        path: '/login',
        title: 'Login'
    }, 
    {
        path: '/register',
        title: 'Register'
    }, 
];

const Navbar: React.FC = () => {
    const navigate = useNavigate();
    const colorTheme = useRecoilValue<ThemeStoreProps>(ThemeStoreState);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    return (
    <AppBar position="static" sx={{ backgroundColor: colorTheme.backgroundColor, color: colorTheme.color }}>
        <Container>
            <Toolbar disableGutters>
                <LeaderboardIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    onClick={() => navigate('/')}
                    sx={{
                        mr: 2,
                        display: { xs: 'none', md: 'flex' },
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }}
                >
                    SIPT
                </Typography>

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(event) => setAnchorElNav(event.currentTarget)}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={() => setAnchorElNav(null)}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                    {pages.map((page) => (
                        <MenuItem key={page.title} onClick={() => {navigate(page.path); setAnchorElNav(null);}}>
                            <Typography textAlign="center" sx={{color: colorTheme.color}}>{page.title}</Typography>
                        </MenuItem>
                    ))}
                    </Menu>
                </Box>
                <LeaderboardIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                <Typography
                    variant="h5"
                    noWrap
                    component="a"
                    onClick={() => navigate('/')}
                    sx={{
                        mr: 2,
                        display: { xs: 'flex', md: 'none' },
                        flexGrow: 1,
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }}
                >
                    SIPT
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {pages.map((page) => (
                    <Button
                        key={page.title}
                        onClick={() => {navigate(page.path); setAnchorElNav(null);}}
                        sx={{ my: 2, color: colorTheme.color, display: 'block' }}
                    >
                        {page.title}
                    </Button>
                    ))}
                </Box>
                <Box sx={{ flexGrow: 0 ,display: 'flex'}}>
                {authMenu.map((page) => (
                    <Button
                        key={page.title}
                        onClick={() => {navigate(page.path); setAnchorElNav(null);}}
                        sx={{ my: 2, color: colorTheme.color, display: 'block' }}
                    >
                        {page.title}
                    </Button>
                ))}
                </Box>
            </Toolbar>
        </Container>
    </AppBar>
    );
}

export default Navbar;
