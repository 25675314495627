export interface CalonPemimpinProps {
    calon_pemimpin_id: string;
    periode_id: string;
    kategori_id: string;
    jenis_pemilihan_id: string;
    jenis_tingkat_pemilihan_id: string;
    provinsi_id: string;
    kabupaten_id: string;
    nama_calon: string;
    nama_wakil_calon: string;
    kabinet: string;
    gambar: string;
}

export const CalonPemimpinField: CalonPemimpinProps = {
    calon_pemimpin_id: '',
    periode_id: '',
    kategori_id: '',
    jenis_pemilihan_id: '',
    jenis_tingkat_pemilihan_id: '',
    provinsi_id: '',
    kabupaten_id: '',
    nama_calon: '',
    nama_wakil_calon: '',
    kabinet: '',
    gambar: '',
}

export interface ErrorCalonPemimpinProps {
    calon_pemimpin_id: string | null;
    periode_id: string | null;
    kategori_id: string | null;
    jenis_pemilihan_id: string | null;
    jenis_tingkat_pemilihan_id: string | null;
    provinsi_id: string | null;
    kabupaten_id: string | null;
    nama_calon: string | null;
    nama_wakil_calon: string | null;
    kabinet: string | null;
    gambar: string | null;
}

export const ErrorCalonPemimpinField: ErrorCalonPemimpinProps = {
    calon_pemimpin_id: null,
    periode_id: null,
    kategori_id: null,
    jenis_pemilihan_id: null,
    jenis_tingkat_pemilihan_id: null,
    provinsi_id: null,
    kabupaten_id: null,
    nama_calon: null,
    nama_wakil_calon: null,
    kabinet: null,
    gambar: null,
}
