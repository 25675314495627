import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import { DetailMemberProps } from '../../models/Member/DetailMemberProps';
import ListMemberProps from '../../models/Member/ListMemberProps';
import RegisterResponseProps from '../../models/Auth/RegisterResponseProps';
import { RegisterRequestProps } from '../../models/Auth/RegisterRequestProps';

class AdminServices {
    async getAllMember(page: number, size: number, sort: string, search: string, roleId?: string, provinsiId?: string | null, kabupatenId?: string | null, kecamatanId?:string | null, desaId?: string | null, rwId?:string | null, rtId?: string | null, tpsId?: string | null, hakPilih?: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/admin/member?page=${page}&size=${size}&sort=${sort}&search=${search}${roleId!== 'null' ? '&roleId=' + roleId : ''}${provinsiId!== null ? '&provinsiId=' + provinsiId : ''}${kabupatenId!== null ? '&kabupatenId=' + kabupatenId : ''}${kecamatanId !== null ? '&kecamatanId=' + kecamatanId : ''}${desaId!== null ? '&desaId=' + desaId : ''}${rwId!== null ? '&rwId=' + rwId : ''}${rtId!== null ? '&rtId=' + rtId : ''}${tpsId!== null ? '&tpsId=' + tpsId : ''}${hakPilih!== 'null' ? '&hakPilih=' + hakPilih : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllMemberWithoutRoleId(page: number, size: number, sort: string, search: string, roleId: string | null, provinsiId?: string | null, kabupatenId?: string | null, kecamatanId?:string | null, desaId?: string | null, rwId?:string | null, rtId?: string | null, tpsId?: string | null, hakPilih?: boolean | null): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/admin/get-member-without-roleId?page=${page}&size=${size}&sort=${sort}&search=${search}${roleId!== null ? '&roleId=' + roleId : ''}${provinsiId!== null ? '&provinsiId=' + provinsiId : ''}${kabupatenId!== null ? '&kabupatenId=' + kabupatenId : ''}${kecamatanId !== null ? '&kecamatanId=' + kecamatanId : ''}${desaId!== null ? '&desaId=' + desaId : ''}${rwId!== null ? '&rwId=' + rwId : ''}${rtId!== null ? '&rtId=' + rtId : ''}${tpsId!== null ? '&tpsId=' + tpsId : ''}${hakPilih!== null ? '&hakPilih=' + hakPilih : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllMemberWithoutRoleRelawan(page: number, size: number, sort: string, search: string, provinsiId?: string | null, kabupatenId?: string | null, kecamatanId?:string | null, desaId?: string | null, rwId?:string | null, rtId?: string | null, tpsId?: string | null, hakPilih?: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/admin/get-member-without-role-relawan?page=${page}&size=${size}&sort=${sort}&search=${search}${provinsiId!== null ? '&provinsiId=' + provinsiId : ''}${kabupatenId!== null ? '&kabupatenId=' + kabupatenId : ''}${kecamatanId !== null ? '&kecamatanId=' + kecamatanId : ''}${desaId!== null ? '&desaId=' + desaId : ''}${rwId!== null ? '&rwId=' + rwId : ''}${rtId!== null ? '&rtId=' + rtId : ''}${tpsId!== null ? '&tpsId=' + tpsId : ''}${hakPilih!== 'null' ? '&hakPilih=' + hakPilih : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllMemberWithoutRoleSaksi(page: number, size: number, sort: string, search: string, provinsiId?: string | null, kabupatenId?: string | null, kecamatanId?:string | null, desaId?: string | null, rwId?:string | null, rtId?: string | null, tpsId?: string | null, hakPilih?: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/admin/get-member-without-role-saksi?page=${page}&size=${size}&sort=${sort}&search=${search}${provinsiId!== null ? '&provinsiId=' + provinsiId : ''}${kabupatenId!== null ? '&kabupatenId=' + kabupatenId : ''}${kecamatanId !== null ? '&kecamatanId=' + kecamatanId : ''}${desaId!== null ? '&desaId=' + desaId : ''}${rwId!== null ? '&rwId=' + rwId : ''}${rtId!== null ? '&rtId=' + rtId : ''}${tpsId!== null ? '&tpsId=' + tpsId : ''}${hakPilih!== 'null' ? '&hakPilih=' + hakPilih : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllMemberWithoutRoleKonstituen(page: number, size: number, sort: string, search: string, provinsiId?: string | null, kabupatenId?: string | null, kecamatanId?:string | null, desaId?: string | null, rwId?:string | null, rtId?: string | null, tpsId?: string | null, hakPilih?: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/admin/get-member-without-role-konstituen?page=${page}&size=${size}&sort=${sort}&search=${search}${provinsiId!== null ? '&provinsiId=' + provinsiId : ''}${kabupatenId!== null ? '&kabupatenId=' + kabupatenId : ''}${kecamatanId !== null ? '&kecamatanId=' + kecamatanId : ''}${desaId!== null ? '&desaId=' + desaId : ''}${rwId!== 'null' ? '&rwId=' + rwId : ''}${rtId!== null ? '&rtId=' + rtId : ''}${tpsId!== null ? '&tpsId=' + tpsId : ''}${hakPilih!== 'null' ? '&hakPilih=' + hakPilih : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllKonstituen(page: number, size: number, sort: string, search: string, provinsiId?: string | null, kabupatenId?: string | null, kecamatanId?:string | null, desaId?: string | null, rwId?:string | null, rtId?: string | null, tpsId?: string | null, hakPilih?: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/admin/konstituen?page=${page}&size=${size}&sort=${sort}&search=${search}${provinsiId!== null ? '&provinsiId=' + provinsiId : ''}${kabupatenId!== null ? '&kabupatenId=' + kabupatenId : ''}${kecamatanId !== null ? '&kecamatanId=' + kecamatanId : ''}${desaId!== null ? '&desaId=' + desaId : ''}${rwId!== null ? '&rwId=' + rwId : ''}${rtId!== null ? '&rtId=' + rtId : ''}${tpsId!== null ? '&tpsId=' + tpsId : ''}${hakPilih!== 'null' ? '&hakPilih=' + hakPilih : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllRelawan(page: number, size: number, sort: string, search: string, provinsiId?: string | null, kabupatenId?: string | null, kecamatanId?:string | null, desaId?: string | null, rwId?:string | null, rtId?: string | null, tpsId?: string | null, hakPilih?: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/admin/relawan?page=${page}&size=${size}&sort=${sort}&search=${search}${provinsiId!== null ? '&provinsiId=' + provinsiId : ''}${kabupatenId!== null ? '&kabupatenId=' + kabupatenId : ''}${kecamatanId !== null ? '&kecamatanId=' + kecamatanId : ''}${desaId!== null ? '&desaId=' + desaId : ''}${rwId!== null ? '&rwId=' + rwId : ''}${rtId!== null ? '&rtId=' + rtId : ''}${tpsId!== null ? '&tpsId=' + tpsId : ''}${hakPilih!== 'null' ? '&hakPilih=' + hakPilih : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllSaksi(page: number, size: number, sort: string, search: string, provinsiId?: string | null, kabupatenId?: string | null, kecamatanId?:string | null, desaId?: string | null, rwId?:string | null, rtId?: string | null, tpsId?: string | null, hakPilih?: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/admin/saksi?page=${page}&size=${size}&sort=${sort}&search=${search}${provinsiId!== null ? '&provinsiId=' + provinsiId : ''}${kabupatenId!== null ? '&kabupatenId=' + kabupatenId : ''}${kecamatanId !== null ? '&kecamatanId=' + kecamatanId : ''}${desaId!== null ? '&desaId=' + desaId : ''}${rwId!== null ? '&rwId=' + rwId : ''}${rtId!== null ? '&rtId=' + rtId : ''}${tpsId!== null ? '&tpsId=' + tpsId : ''}${hakPilih!== 'null' ? '&hakPilih=' + hakPilih : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getDetailMember(memberId: string): Promise<AxiosResponse<DetailMemberProps>> {
        return axios.get(`/api/v1/admin/detail?memberId=`+ memberId,{headers: {'Content-Type': 'application/json',}});
    }
    
    async addMemberKonstituen(memberId: string): Promise<AxiosResponse<void>> {
        return axios.post(`/api/v1/admin/add-member-konstituen?memberId=`+ memberId, null,{headers: {'Content-Type': 'application/json',}});
    }
    
    async addMemberSaksi(memberId: string, tpsId: string): Promise<AxiosResponse<void>> {
        return axios.post(`/api/v1/admin/add-member-saksi?memberId=${memberId}&tpsId=${tpsId}`, null,{headers: {'Content-Type': 'application/json'}});
    }
    
    async addMemberRelawan(memberId: string): Promise<AxiosResponse<void>> {
        return axios.post(`/api/v1/admin/add-member-relawan?memberId=`+ memberId, null,{headers: {'Content-Type': 'application/json'}});
    }

    async removeMemberKonstituen(memberId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/admin/remove-member-konstituen?memberId=`+ memberId,{headers: {'Content-Type': 'application/json',}});
    }

    async removeMemberSaksi(memberId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/admin/remove-member-saksi?memberId=`+ memberId,{headers: {'Content-Type': 'application/json',}});
    }

    async removeMemberRelawan(memberId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/admin/remove-member-relawan?memberId=`+ memberId,{headers: {'Content-Type': 'application/json',}});
    }
    
    async checkNik(nik: string): Promise<AxiosResponse<ListMemberProps>> {
        return axios.get(`/api/v1/admin/cek-member-by-nik?nik=${nik}`,{headers: {'Content-Type': 'application/json',}});
    }

    async addRelasi(konstituenId: string, relawanId: string): Promise<AxiosResponse<ListMemberProps>> {
        return axios.put(`/api/v1/admin/add-relasi-relawan?konstituenId=${konstituenId}&relawanId=${relawanId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async removeRelasi(konstituenId: string, relawanId: string): Promise<AxiosResponse<void>> {
        return axios.put(`/api/v1/admin/remove-relasi-relawan?konstituenId=${konstituenId}&relawanId=${relawanId}`,{headers: {'Content-Type': 'application/json',}});
    }

    async addRelasiKonstituen(konstituenId: string, relawanId: string): Promise<AxiosResponse<ListMemberProps>> {
        return axios.put(`/api/v1/admin/add-relasi-konstituen?konstituenId=${konstituenId}&relawanId=${relawanId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async removeRelasiKonstituen(konstituenId: string, relawanId: string): Promise<AxiosResponse<void>> {
        return axios.put(`/api/v1/admin/remove-relasi-konstituen?konstituenId=${konstituenId}&relawanId=${relawanId}`,{headers: {'Content-Type': 'application/json',}});
    }

    async addMember(registData: RegisterRequestProps): Promise<AxiosResponse<RegisterResponseProps>>{
        return axios.post(`/api/v1/auth/register`, registData, {headers: {'Content-Type': 'application/json',},});
    }

    async removeMember(memberId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/admin/remove-member?memberId=${memberId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AdminServices();