import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useRecoilValue } from 'recoil';
import ThemeStoreProps from '../../../models/Store/ThemeStoreProps';
import { ThemeStoreState } from '../../../store';

const Footer: React.FC = () => {
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState);
    return (
        <Box sx={{
            backgroundColor: theme.backgroundColor,
            color: theme.color
        }}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box>
                            <LeaderboardIcon sx={{ width: '70px', height: '70px' }} />
                            <Typography
                                variant="h6"
                                noWrap
                                sx={{
                                    mr: 2,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    cursor: 'pointer'
                                }}
                            >
                                SIPT
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography variant='h5' sx={{mb: '20px'}}>Sistem Informasi Pemilu Terpadu</Typography>
                        <Typography textAlign={'justify'}>Sistem Informasi Pemilu Terpadu (SIPT) adalah Ekosistem Aplikasi terpadu yang memiliki layanan Sistem Informasi Relawan, Sistem Informasi Saksi, Sistem Informasi Konstituen dan Perhitungan Cepat.</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                        <Typography>{new Date().getFullYear()} &copy; Made with <span><FavoriteIcon /></span> by Akbar</Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Footer;
