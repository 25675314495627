export default interface ListMemberProps {
    member_id: string;
    relawan_id: string;
    konstituen_id: string;
    nama: string;
    jenis_kelamin: string;
    tempat_lahir: string;
    tanggal_lahir: string;
    username: string;
    avatar: string;
    no_hp: string;
    no_wa: string;
    longitude: string;
    lattitude: string;
    alamat: string;
    hak_pilih: boolean;
    punya_relawan: boolean;
}

export const ListMemberField : ListMemberProps = {
    member_id: '',
    relawan_id: '',
    konstituen_id: '',
    nama: '',
    jenis_kelamin: '',
    tempat_lahir: '',
    tanggal_lahir: '',
    username: '',
    avatar: '',
    no_hp: '',
    no_wa: '',
    longitude: '',
    lattitude: '',
    alamat: '',
    hak_pilih: false,
    punya_relawan: false
}