export interface KecamatanProps {
    kecamatan_id: string;
    kabupaten_id: string;
    nama_kecamatan: string;
}

export const KecamatanField: KecamatanProps = {
    kecamatan_id: '',
    kabupaten_id: '',
    nama_kecamatan: ''
}