import { Grid, Typography } from '@mui/material';
import NotFoundIconSvg from '../../asset/icon/undraw_page_not_found_re_e9o6.svg';

const NotFound = () => {
    return (
        <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
        >
            <Grid item md={6} style={{ textAlign: 'center' }}>
                <img
                    src={NotFoundIconSvg}
                    style={{ width: '100%' }}
                    alt={'notfound'}
                />
                <Typography variant='h5'>Halaman Tidak ditemukan</Typography>
            </Grid>
        </Grid>
    );
}

export default NotFound;
