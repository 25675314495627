import axios, { AxiosResponse } from 'axios';
import { PelaporanPemungutanSuaraResponseDTO } from '../../models/Saksi/PelaporanPemungutanSuaraResponseDTO';
import { GetTpsBySaksiProps } from '../../models/Saksi/GetTpsBySaksiResponseDTO';

class PelaporanPemungutanSuaraServices {
    async getAll(tpsId?: string, memberId?:string, periodeId?: string): Promise<AxiosResponse<PelaporanPemungutanSuaraResponseDTO>> {
        return axios.get(`/api/v1/pelaporan?${tpsId !== undefined ? '&tpsId=' + tpsId : ''}${memberId !== undefined ? '&memberId=' + memberId : ''}${periodeId !== undefined ? '&periodeId=' + periodeId : ''}`,{headers: {'Content-Type': 'application/json'}});
    }
    
    async getTpsBySaksiId(tpsId?: number, saksiId?: number, memberId?: string): Promise<AxiosResponse<GetTpsBySaksiProps>> {
        return axios.get(`/api/v1/pelaporan/getTpsBySaksiId?${tpsId !== undefined ? 'tpsId=' + tpsId : ''}${saksiId !== undefined ? 'saksiId=' + saksiId : ''}${memberId !== undefined ? 'memberId=' + memberId : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: PelaporanPemungutanSuaraResponseDTO): Promise<AxiosResponse<PelaporanPemungutanSuaraResponseDTO>> {
        return axios.post(`/api/v1/pelaporan`, data, {headers: {'Content-Type': 'application/json',}});
    }
    
    async createCalonPemimpin(tpsId: string, calonPemimpinId: string, totalSuara: number): Promise<AxiosResponse<void>> {
        return axios.post(`/api/v1/pelaporan/calon-pemimpin?tpsId=${tpsId}&calonPemimpinId=${calonPemimpinId}&totalSuara=${totalSuara}`, null, {headers: {'Content-Type': 'application/json',}});
    }
    
    async createCalonLegislatif(tpsId: string, calonLegislatifId: string, totalSuara: number): Promise<AxiosResponse<void>> {
        return axios.post(`/api/v1/pelaporan/calon-legislatif?tpsId=${tpsId}&calonLegislatifId=${calonLegislatifId}&totalSuara=${totalSuara}`, null, {headers: {'Content-Type': 'application/json',}});
    }
    
    async createPartai(tpsId: string, partaiId: string, totalSuara: number): Promise<AxiosResponse<void>> {
        return axios.post(`/api/v1/pelaporan/partai?tpsId=${tpsId}&partaiId=${partaiId}&totalSuara=${totalSuara}`, null, {headers: {'Content-Type': 'application/json',}});
    }

    async reset(tpsId: string): Promise<AxiosResponse<void>> {
        return axios.put(`/api/v1/pelaporan/reset?tpsId=${tpsId}`, null, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }
    
    async verify(perhitunganId: string, verified: string): Promise<AxiosResponse<void>> {
        return axios.put(`/api/v1/pelaporan/verified?perhitunganId=${perhitunganId}&verified=${verified}`, null, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PelaporanPemungutanSuaraServices();