export interface DppProps {
    dpp_id: string;
    partai_id: string;
    nama_dpp: string;
    ketua_dpp: string;
    gambar: string;
    alamat: string;
    no_telp: string;
    created_at: string;
}

export const DppField :DppProps = {
    dpp_id: '',
    partai_id: '',
    nama_dpp: '',
    ketua_dpp: '',
    gambar: '',
    alamat: '',
    no_telp: '',
    created_at: '',
}

export interface errorDppProps {
    dpp_id: string;
    partai_id: string;
    nama_dpp: string;
    ketua_dpp: string;
    gambar: string;
    alamat: string;
    no_telp: string;
    created_at: string;
}

export const errorDppField :errorDppProps = {
    dpp_id: '',
    partai_id: '',
    nama_dpp: '',
    ketua_dpp: '',
    gambar: '',
    alamat: '',
    no_telp: '',
    created_at: '',
}