import React from 'react';

const PemungutanPenerimaanDataSuara = () => {
    return (
        <div>
            PemungutanPenerimaanDataSuara
        </div>
    );
}

export default PemungutanPenerimaanDataSuara;
