import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import {DesaProps} from '../../models/Area/DesaProps';

class DesaServices {
    async getAll(page: number, size: number, sort: string, search: string, kecamatanId: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/area/desa?page=${page}&size=${size}&sort=${sort}&search=${search}&kecamatanId=${kecamatanId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllArray(kecamatanId: string): Promise<AxiosResponse<DesaProps[]>> {
        return axios.get(`/api/v1/area/desa/arr?kecamatanId=${kecamatanId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(desaId: string): Promise<AxiosResponse<DesaProps>> {
        return axios.get(`/api/v1/area/desa/${desaId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: DesaProps): Promise<AxiosResponse<DesaProps>> {
        return axios.post(`/api/v1/area/desa`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(desaId: string, data : DesaProps): Promise<AxiosResponse<DesaProps>> {
        return axios.put(`/api/v1/area/desa/${desaId}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async delete(desaId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/area/desa/${desaId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DesaServices();