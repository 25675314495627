import LoginRoleProps from "../Role/LoginRoleProps";

export interface LoginResponseProps {
    member_id: string;
    nama: string;
    jenis_kelamin: string;
    tempat_lahir: string;
    tanggal_lahir: string;
    avatar: string;
    alamat: string;
    kode_pos: string;
    email: string;
    no_hp: string;
    no_wa: string;
    hak_pilih: boolean;
    username: string;
    access: string;
    access_token: string;
    refresh_token: string;
    role: LoginRoleProps[];
}

export const LoginResponseField: LoginResponseProps = {
    member_id: '',
    nama: '',
    jenis_kelamin: '',
    tempat_lahir: '',
    tanggal_lahir: '',
    avatar: '',
    alamat: '',
    kode_pos: '',
    email: '',
    no_hp: '',
    no_wa: '',
    hak_pilih: false,
    username: '',
    access: '',
    access_token: '',
    refresh_token: '',
    role: []
}

export interface ErrorLoginResponseProps {
    member_id: string | null;
    nama: string | null;
    jenis_kelamin: string | null;
    tempat_lahir: string | null;
    tanggal_lahir: string | null;
    avatar: string | null;
    alamat: string | null;
    kode_pos: string | null;
    email: string | null;
    no_hp: string | null;
    no_wa: string | null;
    hak_pilih: boolean | null;
    username: string | null;
    access: string | null;
    access_token: string | null;
    refresh_token: string | null;
    role: LoginRoleProps[] | null;
}

export const ErrorLoginResponseField: ErrorLoginResponseProps = {
    member_id: null,
    nama: null,
    jenis_kelamin: null,
    tempat_lahir: null,
    tanggal_lahir: null,
    alamat: null,
    avatar: null,
    kode_pos: null,
    email: null,
    no_hp: null,
    no_wa: null,
    hak_pilih: null,
    username: null,
    access: null,
    access_token: null,
    refresh_token: null,
    role: null
}