import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import {PartaiProps} from '../../models/Institution/PartaiProps';

class PartaiServices {
    async getAllArr(): Promise<AxiosResponse<PartaiProps[]>> {
        return axios.get(`/api/v1/institution/partai/arr/partai`,{headers: {'Content-Type': 'application/json',}});
    }

    async getAll(page: number, size: number, sort: string, search: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/institution/partai?page=${page}&size=${size}&sort=${sort}&search=${search}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(partaiId: string): Promise<AxiosResponse<PartaiProps>> {
        return axios.get(`/api/v1/institution/partai/${partaiId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: FormData): Promise<AxiosResponse<PartaiProps>> {
        return axios.post(`/api/v1/institution/partai`, data, {headers: {'Content-Type': 'multipart/form-data',}});
    }

    async put(partaiId: string, data : FormData): Promise<AxiosResponse<PartaiProps>> {
        return axios.put(`/api/v1/institution/partai/${partaiId}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    async delete(partaiId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/institution/partai/${partaiId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PartaiServices();