import * as yup from 'yup';

export const ProfilValidateForm = yup.object().shape({
    email: yup.string().email('Email tidak valid').required('Email tidak boleh kosong').max(128, "Email tidak boleh melebihi 128 Karakter"),
    nik: yup.string().required("NIK harus diisi").matches(/^[0-9]+$/, "NIK harus berupa Angka").min(16, 'Panjang NIK harus 16 Karakter').max(16, 'Panjang NIK harus 16 Karakter'),
    nama: yup.string().required('Nama Tidak boleh kosong').max(128, 'Nama tidak boleh melebihi 128 Karakter'),
    jenis_kelamin: yup.mixed().oneOf(['PRIA', 'WANITA'] as const).defined(),
    tempat_lahir: yup.string().required('Tempat Lahir Tidak boleh kosong').max(128, 'Tempat Lahir tidak boleh melebihi 128 Karakter'),
    tanggal_lahir: yup.string().required('Tanggal Lahir Tidak boleh kosong'),
    alamat: yup.string().required('Alamat Tidak boleh kosong'),
    kode_pos: yup.string().required("Kode Pos harus diisi").matches(/^[0-9]+$/, "Kode Pos harus berupa Angka").min(5, 'Kode Pos harus Memiliki Panjang 5 Angka').max(5, 'Kode Pos harus Memiliki Panjang 5 Angka'),
    no_hp: yup.string().required("Nomor HP harus diisi").matches(/^[0-9]+$/, "Nomor HP harus berupa Angka").min(8, 'Panjang Nomor HP minimal 8 Karakter').max(16, 'Panjang Nomor HP Maksimal 16 Karakter'),
    no_wa: yup.string().required("Nomor WA harus diisi").matches(/^[0-9]+$/, "Nomor WA harus berupa Angka").min(8, 'Panjang Nomor WA minimal 8 Karakter').max(16, 'Panjang Nomor WA maksimal 16 Karakter'),
    hak_pilih: yup.boolean().required('Hak Pilih Tidak boleh kosong')
});