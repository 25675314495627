import React, { ChangeEvent, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { AuthStoreState, RoleStoreState, ThemeStoreState } from '../../store';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { ErrorPertanyaanField, ErrorPertanyaanProps, PertanyaanField, PertanyaanProps } from '../../models/Bantuan/PertanyaanProps';
import { PertanyaanKategoriProps } from '../../models/Bantuan/PertanyaanKategoriProps';
import { Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { Add, ChecklistRounded, Clear, CommentRounded, Delete, Edit } from '@mui/icons-material';
import PertanyaanKategoriService from '../../services/Bantuan/PertanyaanKategoriService';
import PertanyaanService from '../../services/Bantuan/PertanyaanService';
import RoleService from '../../services/Role/RoleService';
import RoleProps from '../../models/Role/RoleProps';
import { Priority } from '../../enum/Priority';
import { StatusTiket } from '../../enum/StatusTicket';
import { JawabanValidateForm, PertanyaanValidateForm } from '../../validation/BantuanValidateForm';
import Swal from 'sweetalert2';
import { replaceItemAtIndex } from '../../Utils/helper';
import { PertanyaanFileField, PertanyaanFileProps } from '../../models/Bantuan/PertanyaanFileProps';
import { ErrorJawabanField, ErrorJawabanProps, JawabanField, JawabanProps } from '../../models/Bantuan/JawabanProps';
import PertanyaanFileService from '../../services/Bantuan/PertanyaanFileService';
import JawabanService from '../../services/Bantuan/JawabanService';
import DialogPengelolaanBantuan from '../../pages/Superadmin/DialogPengelolaanBantuan';
import ImageDefault from "../../asset/images/cat.jpg";
import { LoginResponseProps } from '../../models/Auth/LoginResponseProps';
import LoginRoleProps from '../../models/Role/LoginRoleProps';
import { RichTextEditorRef } from 'mui-tiptap';

const SuperadminBantuanKontak = () => {
    // Utility 
    const rteRef = useRef<RichTextEditorRef>(null);
    const rteRefJawaban = useRef<RichTextEditorRef>(null);
    const profil = useRecoilValue<LoginResponseProps>(AuthStoreState);
    const role = useRecoilValue<LoginRoleProps>(RoleStoreState);
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState)
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [titleDialog, setTitleDialog] = React.useState<string>('');
    const [kategoriPertanyaanData, setKategoriPertanyaanData] = React.useState<PertanyaanKategoriProps[]>([]);
    const [roleData, setRoleData] = React.useState<RoleProps[]>([]);
    const [openModalLihatJawaban, setOpenModalLihatJawaban] = React.useState<boolean>(false);
    const getKategoriPertanyaan = async () => {
        if(kategoriPertanyaanData.length === 0) {
            await PertanyaanKategoriService.getAll().then(res => {
                setKategoriPertanyaanData(res.data);
            }).catch(err => {
                setMessageSnackbar(err.response.data.message ?? 'Tidak ada Jaringan');
                setOpenSnackbar(true);
            });
        }
    }
    const getPertanyaan = async() => {
        setLoadingSkeletonTable(true);
        await PertanyaanService.getAll(settingTable.page,settingTable.size,settingTable.sort,settingTable.search,settingTable.memberId,settingTable.roleId,settingTable.kategoriPertanyaanId, settingTable.priority, settingTable.status).then(res => {
            setPertanyaanData(res.data.content);
            setSettingTable({...settingTable, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? 'Tidak ada Jaringan');
            setOpenSnackbar(true);
        }).finally(() => {
            setLoadingSkeleton(false);
            setLoadingSkeletonTable(false);
        });
    }
    const getRoles = async () => {
        if(roleData.length === 0) {
            await RoleService.getAllArr().then(res => {
                setRoleData(res.data);
            }).catch(err => {
                setMessageSnackbar(err.response.data.message ?? 'Tidak ada Jaringan');
                setOpenSnackbar(true);
            });
        }
    }

    // Pertanyaan 
    const [pertanyaanData, setPertanyaanData] = React.useState<PertanyaanProps[]>([]);
    const [pertanyaanDataDetail, setPertanyaanDataDetail] = React.useState<PertanyaanProps>(PertanyaanField);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [loadingSkeletonTable, setLoadingSkeletonTable] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<ErrorPertanyaanProps>(ErrorPertanyaanField);
    const [settingTable, setSettingTable] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        memberId: string;
        roleId: string;
        kategoriPertanyaanId: string;
        priority: string;
        status: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'pertanyaanId',
        search: '',
        memberId: '',
        roleId: '',
        kategoriPertanyaanId: '',
        priority: '',
        status: '',
        totalElements: 0,
        totalPages: 0,
    });
    const handleChangePage = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable,  page: newPage});
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTable({...settingTable, page: 0, size: +event.target.value});
    };
    const openAdd = async () => {
        setPertanyaanDataDetail({...PertanyaanField, member_id: profil.member_id, nama: profil.nama, role_id: role.role_id, nama_role: role.nama_role});
        setTitleDialog("Tambah Tiket Bantuan");
        setOpenModal(true);
    }
    const openById = async (p: PertanyaanProps) => {
        setPertanyaanDataDetail(p);
        await getPertanyaanFile(p.pertanyaan_id);
        setTitleDialog("Ubah Pertanyaan " + p.title);
        setOpenModal(true);
    }
    const openByIdJawaban = async (p: PertanyaanProps) => {
        setPertanyaanDataDetail(p);
        setSettingTableJawaban({...settingTableJawaban, page: 0, size: 0});
        await getPertanyaanFile(p.pertanyaan_id);
        await getAllJawaban(p.pertanyaan_id);
        setTitleDialog("Jawaban dari Pertanyaan " + p.title);
        setJawabanDataDetail({...jawabanDataDetail, pertanyaan_id: p.pertanyaan_id, member_id: profil.member_id, role_id: role.role_id});
        setOpenModal(true);
    }
    const createPertanyaan = async () => {
        const newErrors: any = {};
        try {
            await PertanyaanValidateForm.validate({...pertanyaanDataDetail, subtitle: rteRef.current?.editor?.getHTML() ?? ""}, {abortEarly:false});
            setErrors(ErrorPertanyaanField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }
        if(Object.keys(newErrors).length === 0) {
            await PertanyaanService.create({...pertanyaanDataDetail, subtitle: rteRef.current?.editor?.getHTML() ?? ""}).then(res => {
                setPertanyaanData([...pertanyaanData, res.data]);
                setSettingTable({...settingTable, totalElements: settingTable.totalElements + 1})
                setMessageSnackbar("Pertanyaan berhasil di tambah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Pertanyaan gagal di tambah");
                setOpenSnackbar(true);
            });
        }
    }
    const updatePertanyaan = async () => {
        const newErrors: any = {};
        try {
            await PertanyaanValidateForm.validate({...pertanyaanDataDetail, subtitle: rteRef.current?.editor?.getHTML() ?? ""}, {abortEarly:false});
            setErrors(ErrorPertanyaanField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            // const data = new FormData();
            // if(image) {
            //     data.append('image', image)
            // } 
            await PertanyaanService.put(pertanyaanDataDetail.pertanyaan_id, {...pertanyaanDataDetail, subtitle: rteRef.current?.editor?.getHTML() ?? ""}).then(res => {
                let index = pertanyaanData.findIndex(p => p.pertanyaan_id === pertanyaanDataDetail.pertanyaan_id);
                setPertanyaanData(replaceItemAtIndex(pertanyaanData, index, res.data));
                setMessageSnackbar("Pertanyaan berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Pertanyaan gagal di ubah");
                setOpenSnackbar(true);
            })
        }
    }
    const deletePertanyaan = async (p: PertanyaanProps) => {
        Swal.fire({
            title: 'Hapus Pertanyaan',
            text: 'Apakah anda ingin menghapus Pertanyaan ' + p.title + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                PertanyaanService.delete(p.pertanyaan_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Pertanyaan "+ p.title +" sudah dihapus.",
                        icon: "success"
                    });
                    setPertanyaanData(pertanyaanData.filter(r => r.pertanyaan_id !== p.pertanyaan_id));
                    setSettingTable({...settingTable, totalElements: settingTable.totalElements - 1})
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: err.response.data.message ?? "Pertanyaan "+ p.title +" gagal dihapus.",
                        icon: "error"
                    });
                    console.error(err);
                })
            }
        });
    }

    // Jawaban 
    const [jawabanData, setJawabanData] = React.useState<JawabanProps[]>([]);
    const [jawabanDataDetail, setJawabanDataDetail] = React.useState<JawabanProps>(JawabanField);
    const [openModalJawaban, setOpenModalJawaban] = React.useState<boolean>(false);
    const [loadingSkeletonTableJawaban, setLoadingSkeletonTableJawaban] = React.useState<boolean>(false);
    const [errorsJawaban, setErrorsJawaban] = React.useState<ErrorJawabanProps>(ErrorJawabanField);
    const [settingTableJawaban, setSettingTableJawaban] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        memberId: string;
        roleId: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'jawabanId',
        search: '',
        memberId: '',
        roleId: '',
        totalElements: 0,
        totalPages: 0,
    });
    
    const handleChangePageJawaban = (event: unknown, newPage: number) => {
        setLoadingSkeletonTableJawaban(true);
        setSettingTableJawaban({...settingTableJawaban,  page: newPage});
        setLoadingSkeletonTableJawaban(false);
    };
    const handleChangeRowsPerPageJawaban = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTableJawaban({...settingTableJawaban, page: 0, size: +event.target.value});
    };

    const getAllJawaban = async (pertanyaanId?: string) => {
        await JawabanService.getAll(settingTableJawaban.page,settingTableJawaban.size,settingTableJawaban.sort,settingTableJawaban.search,settingTableJawaban.memberId,settingTableJawaban.roleId, pertanyaanId !== undefined ? pertanyaanId : pertanyaanDataDetail.pertanyaan_id).then(res => {
            setJawabanData(res.data.content);
            setSettingTableJawaban({...settingTableJawaban, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak ada Jaringan");
            setOpenSnackbar(true);
        });
    }
    const createJawaban = async () => {
        const newErrors: any = {};
        try {
            await JawabanValidateForm.validate({...jawabanDataDetail, member_id: profil.member_id, role_id: role.role_id, subtitle: rteRefJawaban.current?.editor?.getHTML() ?? ""}, {abortEarly:false});
            setErrorsJawaban(ErrorJawabanField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorsJawaban(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await JawabanService.create({...jawabanDataDetail, member_id: profil.member_id, role_id: role.role_id, subtitle: rteRefJawaban.current?.editor?.getHTML() ?? ""}).then(res => {
                setJawabanData([...jawabanData, res.data]);
                setSettingTableJawaban({...settingTableJawaban, totalElements: settingTableJawaban.totalElements + 1});
                setOpenModalJawaban(false);
                setMessageSnackbar("Jawaban berhasil di tambah");
                setOpenSnackbar(true);
            }).catch(err => {
                setMessageSnackbar("Jawaban gagal di tambah");
                setOpenSnackbar(true);
            });
        }
    }
    const updateJawaban = async () => {
        const newErrors: any = {};
        try {
            await JawabanValidateForm.validate({...jawabanDataDetail, subtitle: rteRefJawaban.current?.editor?.getHTML() ?? ""}, {abortEarly:false});
            setErrorsJawaban(ErrorJawabanField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorsJawaban(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await JawabanService.put(jawabanDataDetail.jawaban_id, {...jawabanDataDetail, subtitle: rteRefJawaban.current?.editor?.getHTML() ?? ""}).then(res => {
                let index = jawabanData.findIndex(p => p.jawaban_id === jawabanDataDetail.jawaban_id);
                setJawabanData(replaceItemAtIndex(jawabanData, index, res.data));
                setMessageSnackbar("Jawaban berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModalJawaban(false);
            }).catch(err => {
                setMessageSnackbar("Jawaban gagal di ubah");
                setOpenSnackbar(true);
            })
        }
    }
    const deleteJawaban = async (j: JawabanProps) => {
        if (window.confirm("Jawaban ini akan dihapus? Lanjutkan")) {
            await JawabanService.delete(j.jawaban_id).then(res => {
                setJawabanData(jawabanData.filter(x => x.jawaban_id !== j.jawaban_id));
                setSettingTableJawaban({...settingTableJawaban, totalElements: settingTableJawaban.totalElements - 1});
                setMessageSnackbar("berhasil Menghapus Jawaban");
                setOpenSnackbar(true);
            }).catch(err => {
                setMessageSnackbar(err.response.data.message ?? "Tidak ada Jaringan");
                setOpenSnackbar(true);
            })
        }
    }
    
    // File 
    const [openModalFile, setOpenModalFile] = React.useState<boolean>(false);
    const [pertanyaanFileData, setPertanyaanFileData] = React.useState<PertanyaanFileProps[]>([]);
    const [pertanyaanFileDataDetail, setPertanyaanFileDataDetail] = React.useState<PertanyaanFileProps>(PertanyaanFileField);
    const [image, setImage] = React.useState<File | null>(null);
    const [imagePreview, setImagePreview] = React.useState<string | null>(null);
    function uploadImage(event: ChangeEvent<HTMLInputElement>) {
        const selectedFile = event.target.files?.[0] || null;
        setImage(selectedFile);

        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        }
    }
    const getPertanyaanFile = async(pertanyaanId?: string) => {
        await PertanyaanFileService.getAll(pertanyaanId !== undefined ? pertanyaanId : pertanyaanDataDetail.pertanyaan_id).then(res => {
            setPertanyaanFileData(res.data);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak ada Jaringan");
            setOpenSnackbar(true);
        })
    }
    const createPertanyaanFile = async () => {
        const data = new FormData();
        if(image) {
            data.append('file', image)
            await PertanyaanFileService.create(pertanyaanDataDetail.pertanyaan_id, data).then(res => {
                setPertanyaanFileData([...pertanyaanFileData, res.data]);
                setMessageSnackbar("File berhasil di tambah");
                setOpenSnackbar(true);
                setOpenModalFile(false);
            }).catch(err => {
                setMessageSnackbar(err.response.data.message ?? "File gagal di tambah");
                setOpenSnackbar(true);
            })
        } else {
            setMessageSnackbar("File Perlu di Upload");
            setOpenSnackbar(true);
        }
    }
    const deletePertanyaanFile = async (f: PertanyaanFileProps) => {
        await PertanyaanFileService.delete(f.pertanyaan_file_id).then(res => {
            setPertanyaanFileData(pertanyaanFileData.filter(x => x.pertanyaan_file_id !== f.pertanyaan_file_id));
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak dapat hapus file");
            setOpenSnackbar(true);
        })
    }

    // Status 
    const [openModalStatusBoolean, setOpenModalStatusBoolean] = React.useState<boolean>(false);
    const openModalStatus = (p: PertanyaanProps) => {
        setPertanyaanDataDetail(p);
        setOpenModalStatusBoolean(true);
    }
    const changeStatus = async () => {
        await PertanyaanService.putStatus(pertanyaanDataDetail.pertanyaan_id, pertanyaanDataDetail.status).then(res => {
            let index = pertanyaanData.findIndex(x => x.pertanyaan_id === pertanyaanDataDetail.pertanyaan_id);
            setPertanyaanData(replaceItemAtIndex(pertanyaanData, index, pertanyaanDataDetail));
            setOpenModalStatusBoolean(false);
            setMessageSnackbar('Status Pertanyaan berhasil diubah');
            setOpenSnackbar(true);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? 'Tidak ada jaringan');
            setOpenSnackbar(true);
        });
    }
    
    React.useEffect(() => {
        getKategoriPertanyaan();
        getRoles();
        getPertanyaan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingTable.page,settingTable.size,settingTable.sort,settingTable.search,settingTable.memberId,settingTable.roleId,settingTable.kategoriPertanyaanId,settingTable.priority,settingTable.status]);

    return loadingSkeleton ? (<Container>
        <Skeleton animation="wave" width={'100%'} height={'576px'} variant='rounded'></Skeleton>
    </Container>) : (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: '10px' }}>
                    <Grid container spacing={2} sx={{ paddingBottom: '5px' }}>
                        <Grid item xs={8} sm={8}>
                            <Typography variant='h3' fontSize={'24px'}>Bantuan</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} display={'flex'} justifyContent={'flex-end'}>
                            <Button startIcon={<Add />} onClick={() => openAdd()} title='tambah' color='info' variant='contained'>Tambah</Button>
                            {
                                settingTable.kategoriPertanyaanId !== '' || settingTable.priority !== '' || settingTable.status !== '' || settingTable.roleId !== '' ? 
                                <Button startIcon={<Clear />} sx={{ marginLeft: '10px' }} onClick={() => setSettingTable({...settingTable, memberId: '', kategoriPertanyaanId: '', roleId: '', priority: '', status: ''})} title='clear filter' color='primary' variant='contained'>Filter</Button> : <></>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ paddingBottom: '5px' }}>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                                <InputLabel id="role-select">Role</InputLabel>
                                <Select
                                    labelId="role-select"
                                    id="role_id"
                                    value={settingTable.roleId}
                                    disabled={roleData.length === 0}
                                    label="Role"
                                    onChange={(e) => setSettingTable({...settingTable, roleId: e.target.value})}
                                >
                                    {
                                        roleData.map(p => <MenuItem key={p.role_id} value={p.role_id}>{p.nama_role.replace("_", " ")}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                                <InputLabel id="kategori-pertanyaan-select">Kategori Pertanyaan</InputLabel>
                                <Select
                                    labelId="kategori-pertanyaan-select"
                                    id="kategori-pertanyaan_id"
                                    value={settingTable.kategoriPertanyaanId}
                                    disabled={kategoriPertanyaanData.length === 0}
                                    label="Kategori Pertanyaan"
                                    onChange={(e) => setSettingTable({...settingTable, kategoriPertanyaanId: e.target.value})}
                                >
                                    {
                                        kategoriPertanyaanData.map(p => <MenuItem key={p.pertanyaan_kategori_id} value={p.pertanyaan_kategori_id}>{p.nama_kategori}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                                <InputLabel id="priority-select">Prioritas</InputLabel>
                                <Select
                                    labelId="priority-select"
                                    id="priority_id"
                                    value={settingTable.priority}
                                    label="Prioritas"
                                    onChange={(e) => setSettingTable({...settingTable, priority: e.target.value})}
                                >
                                    {
                                        Priority.map((p: string, index: number) => <MenuItem key={index} value={p}>{p}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                            <FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                                <InputLabel id="status-tiket-select">Status Tiket</InputLabel>
                                <Select
                                    labelId="status-tiket-select"
                                    id="status-tiket_id"
                                    value={settingTable.status}
                                    label="Status Tiket"
                                    onChange={(e) => setSettingTable({...settingTable, status: e.target.value})}
                                >
                                    {
                                        StatusTiket.map((p: string, index: number) => <MenuItem key={index} value={p}>{p}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                {
                loadingSkeletonTable ? <Skeleton animation={'wave'} width={'100%'} height={'500px'} /> :
                <Grid item xs={12} sm={12} md={12}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 540 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Kategori</TableCell>
                                        <TableCell>Nama</TableCell>
                                        <TableCell>Role</TableCell>
                                        <TableCell>Pertanyaan</TableCell>
                                        <TableCell>Priority</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Aksi</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    pertanyaanData.length !== 0 ? pertanyaanData.map(p => 
                                    <TableRow key={p.pertanyaan_id}>
                                        <TableCell>{kategoriPertanyaanData.find(x => x.pertanyaan_kategori_id === p.kategori_pertanyaan_id)?.nama_kategori}</TableCell>
                                        <TableCell>{p.nama}</TableCell>
                                        <TableCell>{p.nama_role.replace("_", " ")}</TableCell>
                                        <TableCell>{p.title}</TableCell>
                                        <TableCell>{p.priority === 'CRITICAL' ? <Chip color='error' label="CRITICAL" /> : p.priority === 'HIGH' ? <Chip color='warning' label="HIGH" /> : p.priority === 'MEDIUM' ? <Chip color='primary' label="MEDIUM" /> : <Chip color='success' label="LOW" /> }</TableCell>
                                        <TableCell>{
                                            p.status === 'OPEN' ? <Chip label="OPEN" color='success' variant='filled' /> : p.status === 'IN_PROGRESS' ? <Chip label="IN_PROGRESS" color='primary' variant='filled' /> : p.status === 'RESOLVED' ? <Chip label="RESOLVED" color='warning' variant='filled' /> : <Chip label="CLOSED" color='error' variant='filled' />
                                        }</TableCell>
                                        <TableCell>
                                            <Button sx={{ marginLeft: '5px' }} onClick={() => openModalStatus(p)} size='small' variant='contained' startIcon={<ChecklistRounded />} color='warning'>Status</Button>
                                            <Button sx={{ marginLeft: '5px' }} onClick={() => openByIdJawaban(p)} size='small' variant='contained' startIcon={<CommentRounded />} color='primary'>Jawaban</Button>
                                            <Button sx={{ marginLeft: '5px' }} onClick={() => openById(p)} size='small' variant='contained' startIcon={<Edit />} color='success'>Ubah</Button>
                                            <Button sx={{ marginLeft: '5px' }} onClick={() => deletePertanyaan(p)} size='small' variant='contained' startIcon={<Delete />} color='error'>Hapus</Button>
                                        </TableCell>
                                    </TableRow>
                                    )
                                    : <TableRow>
                                        <TableCell colSpan={7}>
                                            <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                        component="div"
                        count={settingTable.totalElements}
                        rowsPerPage={settingTable.size}
                        page={settingTable.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Paper>
                </Grid>
                }
                <Snackbar
                    open={openSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={3000}
                    onClose={() => setOpenSnackbar(false)}
                    message={messageSnackbar}
                />
                <DialogPengelolaanBantuan 
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    theme={theme}
                    titleDialog={titleDialog}
                    errors={errors}
                    setErrors={setErrors}
                    image={image}
                    setImage={setImage}
                    imagePreview={imagePreview}
                    setImagePreview={setImagePreview}
                    uploadImage={uploadImage}
                    ImageDefault={ImageDefault}
                    loadingSkeleton={loadingSkeleton}
                    setLoadingSkeleton={setLoadingSkeleton}
                    setOpenSnackbar={setOpenSnackbar}
                    setMessageSnackbar={setMessageSnackbar}
                    setTitleDialog={setTitleDialog}
                    settingTable={settingTable}
                    setSettingTable={setSettingTable}
                    kategoriPertanyaanData={kategoriPertanyaanData}
                    setKategoriPertanyaanData={setKategoriPertanyaanData}
                    roleData={roleData}
                    setRoleData={setRoleData}
                    createPertanyaan={createPertanyaan}
                    updatePertanyaan={updatePertanyaan}
                    jawabanData={jawabanData}
                    setJawabanData={setJawabanData}
                    jawabanDataDetail={jawabanDataDetail}
                    setJawabanDataDetail={setJawabanDataDetail}
                    openModalJawaban={openModalJawaban}
                    setOpenModalJawaban={setOpenModalJawaban}
                    loadingSkeletonTableJawaban={loadingSkeletonTableJawaban}
                    setLoadingSkeletonTableJawaban={setLoadingSkeletonTableJawaban}
                    errorsJawaban={errorsJawaban}
                    setErrorsJawaban={setErrorsJawaban}
                    settingTableJawaban={settingTableJawaban}
                    setSettingTableJawaban={setSettingTableJawaban}
                    handleChangePageJawaban={handleChangePageJawaban}
                    handleChangeRowsPerPageJawaban={handleChangeRowsPerPageJawaban}
                    getAllJawaban={getAllJawaban}
                    createJawaban={createJawaban}
                    updateJawaban={updateJawaban}
                    deleteJawaban={deleteJawaban}
                    openModalFile={openModalFile}
                    setOpenModalFile={setOpenModalFile}
                    pertanyaanFileData={pertanyaanFileData}
                    setPertanyaanFileData={setPertanyaanFileData}
                    pertanyaanFileDataDetail={pertanyaanFileDataDetail}
                    setPertanyaanFileDataDetail={setPertanyaanFileDataDetail}
                    createPertanyaanFile={createPertanyaanFile}
                    deletePertanyaanFile={deletePertanyaanFile}
                    pertanyaanDataDetail={pertanyaanDataDetail}
                    setPertanyaanDataDetail={setPertanyaanDataDetail}
                    profil={profil}
                    role={role}
                    rteRef={rteRef}
                    rteRefJawaban={rteRefJawaban}
                    openModalLihatJawaban={openModalLihatJawaban}
                    setOpenModalLihatJawaban={setOpenModalLihatJawaban}
                />
                <ModalStatus 
                    openModal={openModalStatusBoolean}
                    setOpenModal={setOpenModalStatusBoolean}
                    pertanyaanDataDetail={pertanyaanDataDetail}
                    setPertanyaanDataDetail={setPertanyaanDataDetail}
                    changeStatus={changeStatus}
                />
            </Grid>
    );
}

export default SuperadminBantuanKontak;

interface ModalStatusProps {
    openModal: boolean;
    setOpenModal: any;
    pertanyaanDataDetail: PertanyaanProps;
    setPertanyaanDataDetail: React.Dispatch<React.SetStateAction<PertanyaanProps>>;
    changeStatus: any;
}

const ModalStatus: React.FC<ModalStatusProps> = ({
    openModal, setOpenModal, pertanyaanDataDetail, setPertanyaanDataDetail, changeStatus
}) => {
    return (
        <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Ubah Status Pertanyaan</DialogTitle>
                <DialogContent>
                    <FormControl>
                        <FormLabel id="status-tiket">Status Tiket Pertanyaan</FormLabel>
                        <RadioGroup
                            aria-labelledby="status-tiket"
                            value={pertanyaanDataDetail.status}
                            name="radio-buttons-group"
                            onChange={(e) => setPertanyaanDataDetail({...pertanyaanDataDetail, status: e.target.value})}
                        >
                            {
                                StatusTiket.map((s, index) => 
                                    <FormControlLabel key={index} value={s} control={<Radio />} label={s} />
                                )
                            }
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenModal(false)}>Tutup</Button>
                <Button onClick={() => changeStatus()} color='success' variant='contained'>Simpan</Button>
            </DialogActions>
        </Dialog>
    );
}