import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import {JenisTingkatPemilihanProps} from '../../models/Forums/JenisTingkatPemilihanProps';

class JenisTingkatPemilihanServices {
    async getAllArr(jenisPemilihanId:string): Promise<AxiosResponse<JenisTingkatPemilihanProps[]>> {
        return axios.get(`/api/v1/forum/jenis-tingkat-pemilihan/arr?jenisPemilihanId=${jenisPemilihanId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAll(page: number, size: number, sort: string, search: string, periodeId?: string, kategoriId?:string, jenisPemilihanId?:string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/forum/jenis-tingkat-pemilihan?page=${page}&size=${size}&sort=${sort}&search=${search}${periodeId !== null ? '&periodeId=' + periodeId : ''}${kategoriId !== null ? '&kategoriId=' + kategoriId : ''}${jenisPemilihanId !== null ? '&jenisPemilihanId=' + jenisPemilihanId : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(jenisTingkatPemilihanId: string): Promise<AxiosResponse<JenisTingkatPemilihanProps>> {
        return axios.get(`/api/v1/forum/jenis-tingkat-pemilihan/${jenisTingkatPemilihanId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: JenisTingkatPemilihanProps): Promise<AxiosResponse<JenisTingkatPemilihanProps>> {
        return axios.post(`/api/v1/forum/jenis-tingkat-pemilihan`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(jenisTingkatPemilihanId: string, data : JenisTingkatPemilihanProps): Promise<AxiosResponse<JenisTingkatPemilihanProps>> {
        return axios.put(`/api/v1/forum/jenis-tingkat-pemilihan/${jenisTingkatPemilihanId}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async delete(jenisTingkatPemilihanId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/forum/jenis-tingkat-pemilihan/${jenisTingkatPemilihanId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new JenisTingkatPemilihanServices();