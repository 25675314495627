import axios, { AxiosResponse } from 'axios';
import {MonitoringFilesProps} from '../../models/Monitoring/MonitoringProps';

class MonitoringServices {
    async getAllByMonitoringId(monitoringId: string): Promise<AxiosResponse<MonitoringFilesProps[]>> {
        return axios.get(`/api/v1/monitoring/files?monitoringId=${monitoringId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(monitoringFilesId: string): Promise<AxiosResponse<MonitoringFilesProps>> {
        return axios.get(`/api/v1/monitoring/files/file?monitoringFilesId=${monitoringFilesId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(monitoringId: string, data: FormData): Promise<AxiosResponse<MonitoringFilesProps>> {
        return axios.post(`/api/v1/monitoring/files/${monitoringId}`, data, {headers: {'Content-Type': 'multipart/form-data',}});
    }

    async delete(monitoringFilesId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/monitoring/files/${monitoringFilesId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MonitoringServices();