import { AccountCircle, Add, AssuredWorkload, Close, Delete, FileUpload, GavelRounded, GroupRounded, Person, SaveAs, Search } from '@mui/icons-material';
import { Alert, AppBar, Box, Button, Container, Dialog, FormControl, Grid, IconButton, InputAdornment, InputLabel, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Select, Skeleton, Slide, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { CalonPemimpinProps, ErrorCalonPemimpinProps } from '../../models/QuickCount/Leader/CalonPemimpinProps';
import { PartaiProps } from '../../models/Institution/PartaiProps';
import { PeriodeProps } from '../../models/Forums/PeriodeProps';
import { KategoriProps } from '../../models/Forums/KategoriProps';
import { JenisPemilihanProps } from '../../models/Forums/JenisPemilihanProps';
import { JenisTingkatPemilihanProps } from '../../models/Forums/JenisTingkatPemilihanProps';
import { ProvinsiProps } from '../../models/Area/ProvinsiProps';
import { KabupatenProps } from '../../models/Area/KabupatenProps';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

interface DialogAddDukunganProps {
    openModalPartai: boolean;
    setOpenModalPartai: React.Dispatch<React.SetStateAction<boolean>>;
    theme: ThemeStoreProps;
    loadingSkeletonTablePartaiAdd: boolean;
    setLoadingSkeletonTablePartaiAdd: React.Dispatch<React.SetStateAction<boolean>>;
    partaiAddData: PartaiProps[];
    setPartaiAddData: React.Dispatch<React.SetStateAction<PartaiProps[]>>;
    settingTablePartaiAdd: {page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;}
    setSettingTablePartaiAdd: React.Dispatch<React.SetStateAction<{page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;}>>;
    handleChangePagePartaiAdd: any;
    handleChangeRowsPerPagePartaiAdd: any;
    addDukungan: any;
    ImageDefault: any;
}

const DialogAddDukungan: React.FC<DialogAddDukunganProps> = ({
    openModalPartai,
    setOpenModalPartai, 
    theme, 
    loadingSkeletonTablePartaiAdd,
    setLoadingSkeletonTablePartaiAdd,
    partaiAddData,
    setPartaiAddData,
    settingTablePartaiAdd,
    setSettingTablePartaiAdd,
    handleChangePagePartaiAdd,
    handleChangeRowsPerPagePartaiAdd,
    addDukungan, 
    ImageDefault
}) => {
    return (
        <Dialog
        fullScreen
        open={openModalPartai}
        onClose={() => setOpenModalPartai(false)}
        TransitionComponent={Transition}
    >
        <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setOpenModalPartai(false)}
                    aria-label="close"
                >
                    <Close />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Tambah Dukungan Partai
                </Typography>
                <Button autoFocus startIcon={<Close />} color="inherit" onClick={() => setOpenModalPartai(false)}>
                    Tutup
                </Button>
            </Toolbar>
        </AppBar>
        <Container sx={{ marginTop: '30px' }}>
            {
                loadingSkeletonTablePartaiAdd ? <Skeleton width={'100%'} height={'200px'} variant='rectangular' animation={'wave'} /> : <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                            <Grid item xs={12} sm={12} md={4}>
                                <Typography variant='h3' fontSize={35}>Partai</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} display={'flex'} justifyContent={'flex-end'}>
                                <TextField
                                    label="Cari Nama Partai"
                                    id="search"
                                    placeholder='Cari Nama Partai'
                                    value={settingTablePartaiAdd.search}
                                    onChange={(e) => setSettingTablePartaiAdd({...settingTablePartaiAdd, search: e.target.value})}
                                    sx={{ m: 1, marginBottom: '10px' }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 540 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Gambar</TableCell>
                                            <TableCell>Nama Partai</TableCell>
                                            <TableCell>Ketua Umum</TableCell>
                                            <TableCell>Sekjen</TableCell>
                                            <TableCell>Aksi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        partaiAddData.length !== 0 ? partaiAddData.map(p => 
                                        <TableRow key={p.partai_id}>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={p.gambar !== '' ? p.gambar : ImageDefault} alt={p.gambar} width={'100%'} height={'100vh'} />
                                                </Box>
                                            </TableCell>
                                            <TableCell>{p.nama_partai}</TableCell>
                                            <TableCell>{p.ketua_umum}</TableCell>
                                            <TableCell>{p.sekjen}</TableCell>
                                            <TableCell>
                                                <Button sx={{ marginLeft: '5px' }} onClick={() => addDukungan(p)} size='small' variant='contained' startIcon={<Add />} color='success'>Tambah</Button>
                                            </TableCell>
                                        </TableRow> 
                                        )
                                        : <TableRow>
                                            <TableCell colSpan={6}>
                                                <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                            component="div"
                            count={settingTablePartaiAdd.totalElements}
                            rowsPerPage={settingTablePartaiAdd.size}
                            page={settingTablePartaiAdd.page}
                            onPageChange={handleChangePagePartaiAdd}
                            onRowsPerPageChange={handleChangeRowsPerPagePartaiAdd}
                        />
                        </Paper>
                    </Grid>
                </Grid>
            }
        </Container>
    </Dialog>);
}

interface DialogPengelolaanCalonPemimpinProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    theme: ThemeStoreProps;
    titleDialog: string;
    errors: ErrorCalonPemimpinProps;
    setErrors: React.Dispatch<React.SetStateAction<ErrorCalonPemimpinProps>>;
    calonPemimpinDataDetail: CalonPemimpinProps;
    setCalonPemimpinDataDetail: React.Dispatch<React.SetStateAction<CalonPemimpinProps>>;
    createCalonPemimpin: any;
    updateCalonPemimpin: any;
    image: File | null;
    setImage: React.Dispatch<React.SetStateAction<File | null>>;
    imagePreview: string | null;
    setImagePreview: React.Dispatch<React.SetStateAction<string | null>>;
    uploadImage: any;
    ImageDefault: any;
    settingTablePartai: {page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;};
    setSettingTablePartai: React.Dispatch<React.SetStateAction<{page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;}>>;
    loadingSkeletonTablePartai: boolean;
    partaiData: PartaiProps[];
    handleChangePagePartai: any;
    handleChangeRowsPerPagePartai: any;
    openModalPartai: boolean;
    setOpenModalPartai: React.Dispatch<React.SetStateAction<boolean>>;
    openAddDukungan: any;
    addDukungan: any;
    deleteDukungan: any;
    loadingSkeletonTablePartaiAdd: boolean;
    setLoadingSkeletonTablePartaiAdd: React.Dispatch<React.SetStateAction<boolean>>;
    partaiAddData: PartaiProps[];
    setPartaiAddData: React.Dispatch<React.SetStateAction<PartaiProps[]>>;
    settingTablePartaiAdd: {page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;}
    setSettingTablePartaiAdd: React.Dispatch<React.SetStateAction<{page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;}>>;
    handleChangePagePartaiAdd: any;
    handleChangeRowsPerPagePartaiAdd: any;
    openAddDukunganPartai: any;
    periodeData: PeriodeProps[];
    kategoriData: KategoriProps[];
    jenisPemilihanData: JenisPemilihanProps[];
    jenisTingkatPemilihanData: JenisTingkatPemilihanProps[];
    getPeriode: any;
    getKategori: any;
    getJenisPemilihan: any;
    getJenisTingkatPemilihan: any;
    provinsiData: ProvinsiProps[];
    kabupatenData: KabupatenProps[];
    getKabupaten: any;
    setKategori: React.Dispatch<React.SetStateAction<KategoriProps[]>>;
    setJenisPemilihan: React.Dispatch<React.SetStateAction<JenisPemilihanProps[]>>;
    setJenisTingkatPemilihan: React.Dispatch<React.SetStateAction<JenisTingkatPemilihanProps[]>>;
}

const DialogPengelolaanCalonPemimpin: React.FC<DialogPengelolaanCalonPemimpinProps> = ({
    openModal,
    setOpenModal,
    theme,
    titleDialog,
    errors,
    setErrors,
    calonPemimpinDataDetail,
    setCalonPemimpinDataDetail,
    createCalonPemimpin,
    updateCalonPemimpin,
    image,
    setImage,
    imagePreview,
    setImagePreview,
    uploadImage, 
    ImageDefault,
    settingTablePartai,
    setSettingTablePartai,
    loadingSkeletonTablePartai,
    partaiData,
    handleChangePagePartai,
    handleChangeRowsPerPagePartai, 
    openModalPartai,
    setOpenModalPartai,
    openAddDukungan,
    addDukungan,
    deleteDukungan, 
    loadingSkeletonTablePartaiAdd,
    setLoadingSkeletonTablePartaiAdd,
    partaiAddData,
    setPartaiAddData,
    settingTablePartaiAdd,
    setSettingTablePartaiAdd,
    handleChangePagePartaiAdd,
    handleChangeRowsPerPagePartaiAdd,
    openAddDukunganPartai,
    periodeData,
    kategoriData,
    jenisPemilihanData,
    jenisTingkatPemilihanData,
    getPeriode,
    getKategori,
    getJenisPemilihan,
    getJenisTingkatPemilihan,
    provinsiData,
    kabupatenData, 
    getKabupaten,
    setKategori,
    setJenisPemilihan,
    setJenisTingkatPemilihan
}) => {
    
    return (
        <Dialog
            fullScreen
            open={openModal}
            onClose={() => setOpenModal(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setOpenModal(false)}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {titleDialog}
                    </Typography>
                    <Button autoFocus startIcon={<Close />} color="inherit" onClick={() => setOpenModal(false)}>
                        Tutup
                    </Button>
                </Toolbar>
            </AppBar>
                {
                    titleDialog.split(" ").includes("Partai") ? <Container><Grid container spacing={2} sx={{ marginTop: '20px' }}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ width: '100%', height: '150px', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                <img src={calonPemimpinDataDetail.gambar} alt={calonPemimpinDataDetail.nama_calon} width={'50%'} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <List component="nav" aria-label="main mailbox folders">
                                <ListItemButton>
                                    <ListItemIcon><Person /></ListItemIcon>
                                    <ListItemText primary={calonPemimpinDataDetail.nama_calon ?? 'Belum Ada Nama Calon Pemimpin'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><GroupRounded /></ListItemIcon>
                                    <ListItemText primary={calonPemimpinDataDetail.nama_wakil_calon ?? 'Belum Ada Nama Wakil Calon Pemimpin'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><AssuredWorkload /></ListItemIcon>
                                    <ListItemText primary={calonPemimpinDataDetail.kabinet ?? 'Belum Ada Nama Kabinet'} />
                                </ListItemButton>
                            </List>
                        </Grid>
                    </Grid><Grid container spacing={2} sx={{ marginTop: '20px' }}>
                        <Grid item xs={12} sm={12} md={4}>
                            <Typography variant='h3' fontSize={35}>Partai</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} display={'flex'} justifyContent={'flex-end'}>
                            <TextField
                                label="Cari Nama Partai"
                                id="search"
                                placeholder='Cari Nama Partai'
                                value={settingTablePartai.search}
                                onChange={(e) => setSettingTablePartai({...settingTablePartai, search: e.target.value})}
                                sx={{ m: 1, marginBottom: '10px' }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
                                }}
                            />
                            <Button title='Tambah Dukungan' onClick={() => openAddDukunganPartai()} color='primary' variant='contained' size='small' startIcon={<Add />}>Tambah</Button>
                        </Grid>
                    </Grid><Grid container spacing={2} sx={{ marginTop: '5px' }}>
                        <Grid item xs={12}>
                            {
                                loadingSkeletonTablePartai ? <Skeleton width={'100%'} height={'200px'} animation="wave" /> : 
                                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                    <TableContainer sx={{ maxHeight: 540 }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Gambar</TableCell>
                                                    <TableCell>Nama Partai</TableCell>
                                                    <TableCell>Ketua Umum</TableCell>
                                                    <TableCell>Sekjen</TableCell>
                                                    <TableCell>Aksi</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {
                                                partaiData.length !== 0 ? partaiData.map(p => 
                                                <TableRow key={p.partai_id}>
                                                    <TableCell>
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <img src={p.gambar !== '' ? p.gambar : ImageDefault} alt={p.gambar} width={'100%'} height={'100vh'} />
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>{p.nama_partai}</TableCell>
                                                    <TableCell>{p.ketua_umum}</TableCell>
                                                    <TableCell>{p.sekjen}</TableCell>
                                                    <TableCell>
                                                        <Button sx={{ marginLeft: '5px' }} onClick={() => deleteDukungan(p)} size='small' variant='contained' startIcon={<Delete />} color='error'>Hapus</Button>
                                                    </TableCell>
                                                </TableRow> 
                                                )
                                                : <TableRow>
                                                    <TableCell colSpan={6}>
                                                        <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                    component="div"
                                    count={settingTablePartai.totalElements}
                                    rowsPerPage={settingTablePartai.size}
                                    page={settingTablePartai.page}
                                    onPageChange={handleChangePagePartai}
                                    onRowsPerPageChange={handleChangeRowsPerPagePartai}
                                />
                                </Paper>
                            }
                        </Grid>
                    </Grid></Container> : <Container>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                        <Grid item xs={12} sm={12} md={4}>
                            <Grid container spacing={1}>
                                <Box sx={{ width: '100%', marginBottom: '20px', height: '300px' }}>
                                {
                                    imagePreview !== null ? 
                                    <img src={imagePreview} alt={'preview'} width={'100%'} height={'100%'} />
                                    : (calonPemimpinDataDetail.gambar !== '' ?
                                    <img src={calonPemimpinDataDetail.gambar} alt={calonPemimpinDataDetail.kabinet} width={'100%'} height={'100%'} /> :
                                    <img src={ImageDefault} alt={calonPemimpinDataDetail.kabinet} width={'100%'} height={'100%'} />)
                                }
                                </Box>
                            </Grid>
                            <Grid container spacing={1}>
                            {
                                imagePreview !== null ?
                                <Grid item xs={12}>
                                    {
                                        image == null ? <Alert severity="info">File Perlu dibawah 1 MB.</Alert> : image.size / 1000 > 1000 ? 
                                        <Alert severity="error">File anda terlalu besar! {image == null ? 0 : image.size / 1000} Kb.</Alert> :
                                        <Alert severity="info">File yang anda Upload berada di {image == null ? 0 : image.size / 1000} Kb.</Alert>
                                    }
                                    <Button variant='contained' sx={{ marginTop: '5px' }} fullWidth onClick={() => {
                                        setImagePreview(null);
                                        setImage(null);
                                    }} startIcon={<Delete />} color='error'>Hapus</Button>
                                </Grid> : 
                                <Grid item xs={12}>
                                    <Alert severity="info">File Perlu dibawah 1 MB.</Alert>
                                    <Button component="label" sx={{ marginTop: '5px' }} tabIndex={-1} variant='contained' fullWidth startIcon={<FileUpload />} color='primary'>Upload<VisuallyHiddenInput type="file" onChange={uploadImage} /></Button>
                                </Grid>
                            }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <Box>
                                <Typography variant='h5' sx={{ marginBottom: '5px' }}>Data Calon Pemimpin</Typography>
                                <FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                                    <InputLabel id="periode-select">Periode</InputLabel>
                                    <Select
                                        labelId="periode-select"
                                        id="periode_id"
                                        value={calonPemimpinDataDetail.periode_id}
                                        disabled={periodeData.length === 0}
                                        label="Periode"
                                        onChange={(e) => {setCalonPemimpinDataDetail({...calonPemimpinDataDetail, periode_id: e.target.value, kategori_id: '', jenis_pemilihan_id: '', jenis_tingkat_pemilihan_id: ''}); setJenisPemilihan([]); setJenisTingkatPemilihan([]); getKategori(e.target.value);}}
                                    >
                                        {
                                            periodeData.map(p => <MenuItem key={p.periode_id} value={p.periode_id}>{p.tahun}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                                    <InputLabel id="kategori-select">Kategori</InputLabel>
                                    <Select
                                        labelId="kategori-select"
                                        id="kategori_id"
                                        value={calonPemimpinDataDetail.kategori_id}
                                        disabled={kategoriData.length === 0}
                                        label="Kategori"
                                        onChange={(e) => {setCalonPemimpinDataDetail({...calonPemimpinDataDetail, kategori_id: e.target.value, jenis_pemilihan_id: '', jenis_tingkat_pemilihan_id: ''}); setJenisTingkatPemilihan([]); getJenisPemilihan(e.target.value);}}
                                    >
                                        {
                                            kategoriData.map(p => <MenuItem key={p.kategori_id} value={p.kategori_id}>{p.nama_kategori}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                                    <InputLabel id="jenis_pemilihan-select">Jenis Pemilihan</InputLabel>
                                    <Select
                                        labelId="jenis_pemilihan-select"
                                        id="jenis_pemilihan_id"
                                        value={calonPemimpinDataDetail.jenis_pemilihan_id}
                                        disabled={jenisPemilihanData.length === 0}
                                        label="Jenis Pemilihan"
                                        onChange={(e) => {setCalonPemimpinDataDetail({...calonPemimpinDataDetail, jenis_pemilihan_id: e.target.value, jenis_tingkat_pemilihan_id: ''}); getJenisTingkatPemilihan(e.target.value);}}
                                    >
                                        {
                                            jenisPemilihanData.map(p => <MenuItem key={p.jenis_pemilihan_id} value={p.jenis_pemilihan_id}>{p.nama_pemilihan}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                                    <InputLabel id="jenis_tingkat_pemilihan-select">Jenis Tingkat Pemilihan</InputLabel>
                                    <Select
                                        labelId="jenis_tingkat_pemilihan-select"
                                        id="jenis_tingkat_pemilihan_id"
                                        value={calonPemimpinDataDetail.jenis_tingkat_pemilihan_id}
                                        disabled={jenisTingkatPemilihanData.length === 0}
                                        label="Jenis Tingkat Pemilihan"
                                        onChange={(e) => {setCalonPemimpinDataDetail({...calonPemimpinDataDetail, jenis_tingkat_pemilihan_id: e.target.value});}}
                                    >
                                        {
                                            jenisTingkatPemilihanData.map(p => <MenuItem key={p.jenis_tingkat_pemilihan_id} value={p.jenis_tingkat_pemilihan_id}>{p.nama_tingkat}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Nama Calon Pemimpin"
                                    id="Nama_Calon_Pemimpin"
                                    fullWidth
                                    placeholder='Nama Calon Pemimpin'
                                    value={calonPemimpinDataDetail.nama_calon}
                                    onChange={(e) => setCalonPemimpinDataDetail({...calonPemimpinDataDetail, nama_calon: e.target.value})}
                                    sx={{ m: 1, marginBottom: '10px' }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><AccountCircle /></InputAdornment>,
                                    }}
                                />
                                {(errors.nama_calon != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.nama_calon}</Typography> : <></>}
                                <TextField
                                    label="Nama Wakil Calon Pemimpin"
                                    id="Nama_Wakil_Calon_Pemimpin"
                                    fullWidth
                                    placeholder='Nama Wakil Calon Pemimpin'
                                    value={calonPemimpinDataDetail.nama_wakil_calon}
                                    onChange={(e) => setCalonPemimpinDataDetail({...calonPemimpinDataDetail, nama_wakil_calon: e.target.value})}
                                    sx={{ m: 1, marginBottom: '10px' }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><GroupRounded /></InputAdornment>,
                                    }}
                                />
                                {(errors.nama_wakil_calon != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.nama_wakil_calon}</Typography> : <></>}
                                <TextField
                                    label="Nama Kabinet"
                                    id="Nama_Kabinet"
                                    fullWidth
                                    placeholder='Nama Kabinet'
                                    value={calonPemimpinDataDetail.kabinet}
                                    onChange={(e) => setCalonPemimpinDataDetail({...calonPemimpinDataDetail, kabinet: e.target.value})}
                                    sx={{ m: 1, marginBottom: '10px' }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><GavelRounded /></InputAdornment>,
                                    }}
                                />
                                {(errors.kabinet != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.kabinet}</Typography> : <></>}

                                {
                                    (jenisTingkatPemilihanData.find(x => x.nama_tingkat === 'DPRD') || jenisTingkatPemilihanData.find(x => x.nama_tingkat === 'DPD') || jenisTingkatPemilihanData.find(x => x.nama_tingkat === 'Pemilihan Walikota') || jenisTingkatPemilihanData.find(x => x.nama_tingkat === 'Pemilihan Bupati') || jenisTingkatPemilihanData.find(x => x.nama_tingkat === 'Pemilihan Gubernur')) ? 
                                    <React.Fragment><FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                                        <InputLabel id="provinsi-select">Provinsi</InputLabel>
                                        <Select
                                            labelId="provinsi-select"
                                            id="provinsi_id"
                                            value={calonPemimpinDataDetail.provinsi_id}
                                            disabled={provinsiData.length === 0}
                                            label="Provinsi"
                                            onChange={(e) => {getKabupaten(e.target.value); setCalonPemimpinDataDetail({...calonPemimpinDataDetail, provinsi_id: e.target.value});}}
                                        >
                                            {
                                                provinsiData.map(p => <MenuItem key={p.provinsi_id} value={p.provinsi_id}>{p.nama_provinsi}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl><FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                                        <InputLabel id="kabupaten-select">Kabupaten</InputLabel>
                                        <Select
                                            labelId="kabupaten-select"
                                            id="kabupaten_id"
                                            value={calonPemimpinDataDetail.kabupaten_id}
                                            disabled={kabupatenData.length === 0}
                                            label="Kabupaten"
                                            onChange={(e) => {setCalonPemimpinDataDetail({...calonPemimpinDataDetail, kabupaten_id: e.target.value});}}
                                        >
                                            {
                                                kabupatenData.map(p => <MenuItem key={p.kabupaten_id} value={p.kabupaten_id}>{p.nama_kabupaten}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl></React.Fragment> : <></>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                            <Button startIcon={<SaveAs />} variant='contained' color='success' title='simpan' onClick={() => titleDialog.split(' ').includes('Tambah') ? createCalonPemimpin() : updateCalonPemimpin()}>Simpan</Button>
                        </Grid>
                    </Grid>
                </Container>
                }
            <DialogAddDukungan 
                openModalPartai={openModalPartai}
                setOpenModalPartai={setOpenModalPartai}
                theme={theme}
                loadingSkeletonTablePartaiAdd={loadingSkeletonTablePartaiAdd}
                setLoadingSkeletonTablePartaiAdd={setLoadingSkeletonTablePartaiAdd}
                partaiAddData={partaiAddData}
                setPartaiAddData={setPartaiAddData}
                settingTablePartaiAdd={settingTablePartaiAdd}
                setSettingTablePartaiAdd={setSettingTablePartaiAdd}
                handleChangePagePartaiAdd={handleChangePagePartaiAdd}
                handleChangeRowsPerPagePartaiAdd={handleChangeRowsPerPagePartaiAdd}
                addDukungan={addDukungan}
                ImageDefault={ImageDefault}
            />
        </Dialog>
    );
}

export default DialogPengelolaanCalonPemimpin;
