import React from 'react';

const PerhitunganBantuanKontak = () => {
    return (
        <div>
            PerhitunganBantuanKontak
        </div>
    );
}

export default PerhitunganBantuanKontak;
