import React from 'react';

const SaksiPendaftaranSaksi = () => {
    return (
        <div>
            SaksiPendaftaranSaksi
        </div>
    );
}

export default SaksiPendaftaranSaksi;
