export default interface TpsKordinatProps {
    tps_id: string;
    rw_id: string;
    rt_id: string;
    nama_tps: string;
    alamat: string;
    kordinat_id: string;
    longitude: string;
    lattitude: string;
}

export const TpsKordinatField: TpsKordinatProps = {
    tps_id: '',
    rw_id: '',
    rt_id: '',
    nama_tps: '',
    alamat: '',
    kordinat_id: '',
    longitude: '',
    lattitude: ''
}