import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import CardPortal from '../../components/CardPortal';
import BlogPostProps from '../../models/Blog/BlogPostProps';

const tagList = [
    {
        id: 1, 
        nama: 'Tag A'
    },
    {
        id: 2, 
        nama: 'Tag B'
    },
    {
        id: 3, 
        nama: 'Tag C'
    },
    {
        id: 4, 
        nama: 'Tag D'
    },
    {
        id: 5, 
        nama: 'Tag E'
    }
];

const beritaList: BlogPostProps[] = [
    {
        blog_post_id: "1",
        member_id: "",
        nama_member: "",
        title: "Jogetin Aja",
        slug: "",
        summary: "",
        content: "",
        published_at: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        published: true,
        visited: 100,
        meta: [],
        category: [],
        tag: [],
    },
    {
        blog_post_id: "2",
        member_id: "",
        nama_member: "",
        title: "Haha",
        slug: "",
        summary: "",
        content: "",
        published_at: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        published: true,
        visited: 100,
        meta: [],
        category: [],
        tag: [],
    },
];
const Tag: React.FC = () => {
    const [tagChoose, setTagChoose] = React.useState<number>(1)
    return <Box>
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant='h5'>Tag yang tersedia:</Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container spacing={3}>
                {
                    tagList.map(k => 
                        <Grid item xs={4} key={k.id}>
                            <Paper onClick={() => setTagChoose(k.id)} elevation={1} sx={{ padding: '10px', cursor: 'pointer'}}>
                                {k.nama}
                            </Paper>
                        </Grid>
                    )
                }
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Typography>Berita / Artikel {tagList.find(k => k.id === tagChoose)?.nama} </Typography>
        </Grid>
        {
            beritaList.map(berita => 
                <Grid item xs={12} key={berita.blog_post_id}>
                    <CardPortal
                        blog_post_id={berita.blog_post_id}
                        member_id={berita.member_id}
                        nama_member={berita.nama_member}
                        title={berita.title}
                        slug={berita.slug}
                        summary={berita.summary}
                        content={berita.content}
                        published_at={berita.published_at}
                        created_at={berita.created_at}
                        updated_at={berita.updated_at}
                        deleted_at={berita.deleted_at}
                        published={berita.published}
                        visited={berita.visited}
                        meta={berita.meta}
                        category={berita.category}
                        tag={berita.tag}
                    />
                </Grid>
            )
        }
    </Grid>
</Box>
}

export default Tag
