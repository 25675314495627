import React from 'react';
import { Button, Grid, Paper, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { ThemeStoreState } from '../../store';
import { useRecoilValue } from 'recoil';
import Swal from 'sweetalert2'
import { replaceItemAtIndex } from '../../Utils/helper';
import { Add, Delete, Diversity2Outlined, Edit } from '@mui/icons-material';
import { errorPartaiField, errorPartaiProps } from '../../models/Institution/PartaiProps';
import { PartaiField, PartaiProps } from '../../models/Institution/PartaiProps';
import PartaiService from '../../services/Institution/PartaiService';
import ModalCreateUpdatePartai from '../../pages/Institution/ModalCreateUpdatePartai';
import { PartaiValidateForm } from '../../validation/PartaiValidateForm';
import Image from "../../asset/images/default.png";
import { DppField, DppProps, errorDppField, errorDppProps } from '../../models/Institution/DppProps';
import DppService from '../../services/Institution/DppService';
import { DppValidateForm } from '../../validation/DppValidateForm';

const PengelolaanDataPartai: React.FC = () => {
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const [loadingSkeletonTable, setLoadingSkeletonTable] = React.useState<boolean>(false);

    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');

    const [titleDialog, setTitleDialog] = React.useState<string>('');
    const [errorForm, setErrorForm] = React.useState<errorPartaiProps>(errorPartaiField);
    const [partaiData, setPartaiData] = React.useState<PartaiProps[]>([]);
    
    const [image, setImage] = React.useState<File | null>(null);
    const [imagePreview, setImagePreview] = React.useState<string | null>(null);

    const [formPartai, setFormPartai] = React.useState<PartaiProps>(PartaiField);
    const [settingTable, setSettingTable] = React.useState({
        page: 0,
        size: 10,
        sort: 'partaiId',
        search: '',
        totalElements: 0,
        totalPages: 0,
        pilihan: '',
        namaPilihan: ''
    });

    const [openConfirmationDelete, setOpenConfirmationDelete] = React.useState<boolean>(false);
    const [dppData, setDppData] = React.useState<DppProps[]>([]);
    const [formDpp, setFormDpp] = React.useState<DppProps>(DppField);
    const [errorFormDpp, setErrorFormDpp] = React.useState<errorDppProps>(errorDppField);

    const [settingTableDpp, setSettingTableDpp] = React.useState({
        page: 0,
        size: 10,
        sort: 'dppId',
        search: '',
        totalElements: 0,
        totalPages: 0,
        pilihan: '',
        namaPilihan: ''
    });

    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState)

    const handleChangePage = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable,  page: newPage});
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTable({...settingTable, page: 0, size: +event.target.value});
    };
    const handleChangePageDpp = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable,  page: newPage});
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPageDpp = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTable({...settingTable, page: 0, size: +event.target.value});
    };
    function getAllPartai() {
        PartaiService.getAll(settingTable.page, settingTable.size, settingTable.sort, settingTable.search).then(res => {
            setPartaiData(res.data.content);
            setSettingTable({...settingTable, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
        }).catch(err => {
            setMessageSnackbar("Terdapat Error");
            setOpenSnackbar(true)
        })
    }
    async function aturDpp(partai: PartaiProps) {
        setTitleDialog("Atur DPP " + partai.nama_partai);
        setFormPartai(partai);
        await getAllDpp(partai.partai_id);
        setFormDpp(DppField);
        setErrorFormDpp(errorDppField)
        setErrorForm(errorPartaiField);
        setOpenModal(true);
    }
    function addPartai() {
        setTitleDialog("Tambah Data Partai");
        setFormPartai(PartaiField);
        setErrorForm(errorPartaiField);
        setOpenModal(true);
        setImage(null);
        setImagePreview(null);
    }
    async function createPartai() {
        const newErrors: any = {};
        try {
            await PartaiValidateForm.validate(formPartai, {abortEarly:false});
            setErrorForm(errorPartaiField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            const data = new FormData();
            data.append('data', JSON.stringify(formPartai));
            if(image) {
                data.append('image', image)
            }
            await PartaiService.create(data).then(res => {
                setPartaiData([...partaiData, res.data]);
                setMessageSnackbar("Partai berhasil di tambah");
                setOpenSnackbar(true);
                setOpenModal(false);
                setSettingTable({...settingTable, totalElements: settingTable.totalElements + 1})
            }).catch(err => {
                setMessageSnackbar("Partai gagal di tambah");
                setOpenSnackbar(true);
            })
        }
    }
    function editPartai(partai: PartaiProps) {
        setTitleDialog("Ubah Data Partai");
        setFormPartai(partai);
        setErrorForm(errorPartaiField);
        setOpenModal(true);
        setImage(null);
        setImagePreview(null);
    }
    async function updatePartai() {
        const newErrors: any = {};
        try {
            await PartaiValidateForm.validate(formPartai, {abortEarly:false});
            setErrorForm(errorPartaiField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            const data = new FormData();
            data.append('data', JSON.stringify(formPartai));
            if(image) {
                data.append('image', image)
            }
            await PartaiService.put(formPartai.partai_id, data).then(res => {
                let index = partaiData.findIndex(p => p.partai_id === formPartai.partai_id);
                setPartaiData(replaceItemAtIndex(partaiData, index, {...formPartai, gambar: res.data.gambar}));
                setMessageSnackbar("Partai berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Partai gagal di ubah");
                setOpenSnackbar(true);
            })
        }
    }
    function deletePartai(partai: PartaiProps) {
        Swal.fire({
            title: 'Hapus Partai',
            text: 'Apakah anda ingin menghapus Partai ' + partai.nama_partai + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                PartaiService.delete(partai.partai_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Partai "+ partai.nama_partai +" sudah dihapus.",
                        icon: "success"
                    });
                    setPartaiData(partaiData.filter(r => r.partai_id !== partai.partai_id));
                    setSettingTable({...settingTable, totalElements: settingTable.totalElements - 1})
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: "Partai "+ partai.nama_partai +" gagal dihapus.",
                        icon: "error"
                    });
                    console.error(err);
                })
            }
        });
    }

    function getAllDpp(partaiId: string) {
        if(titleDialog.split(" ").includes("DPP")) {
            DppService.getAll(settingTableDpp.page, settingTableDpp.size, settingTableDpp.sort, settingTableDpp.search, partaiId !== null ? partaiId : formPartai.partai_id).then(res => {
                setDppData(res.data.content);
                setSettingTableDpp({...settingTableDpp, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
            }).catch(err => {
                setMessageSnackbar("Terdapat Error");
                setOpenSnackbar(true)
            })
        }
    }
    async function createDpp() {
        const newErrors: any = {};
        try {
            await DppValidateForm.validate(formDpp, {abortEarly:false});
            setErrorFormDpp(errorDppField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorFormDpp(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            const data = new FormData();
            data.append('data', JSON.stringify({...formDpp, partai_id: formPartai.partai_id}));
            if(image) {
                data.append('image', image)
            }
            await DppService.create(data).then(res => {
                setDppData([...dppData, res.data]);
                setMessageSnackbar("DPP berhasil di tambah");
                setOpenSnackbar(true);
                setSettingTableDpp({...settingTableDpp, totalElements: settingTableDpp.totalElements + 1});
                setFormDpp(DppField);
                setImage(null);
                setImagePreview(null);
            }).catch(err => {
                setMessageSnackbar("DPP gagal di tambah");
                setOpenSnackbar(true);
            })
        }
    }
    async function deleteDpp(dpp: DppProps) {
        DppService.delete(dpp.dpp_id).then(res => {
            setDppData(dppData.filter(r => r.dpp_id !== dpp.dpp_id));
            setMessageSnackbar("DPP berhasil di hapus");
            setOpenSnackbar(true);
            setOpenConfirmationDelete(false);
            setSettingTableDpp({...settingTableDpp, totalElements: settingTableDpp.totalElements - 1})
        }).catch(err => {
            setMessageSnackbar("DPP "+ dpp.nama_dpp +" gagal dihapus");
            setOpenSnackbar(true);
        })
    }

    React.useEffect(() => {
        setTimeout(() => {
            setLoadingSkeleton(false);
            getAllPartai();
            getAllDpp(formPartai.partai_id);
            setLoadingSkeletonTable(false);
        }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingTable.page, settingTable.size, settingTable.sort, settingTable.search, settingTableDpp.page, settingTableDpp.size, settingTableDpp.sort, settingTableDpp.search])

    return loadingSkeleton ? (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                        <Skeleton width={'100%'} height={'50px'} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Skeleton width={'100%'} height={'50px'} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Skeleton width={'100%'} height={'500px'} />
            </Grid>
        </Grid>
    ) : (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <Typography variant='h5' sx={{ justifyContent: 'center', alignItem: 'center' }}>Partai</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button variant='contained' sx={{ backgroundColor: theme.backgroundColor, color: theme.color }}  onClick={() => addPartai()} startIcon={<Add />} color='inherit'>Tambah Partai</Button>
                    </Grid>
                </Grid>
            </Grid>
            {
                loadingSkeletonTable ? <Skeleton width={'100%'} height={'500px'} /> :
                <Grid item xs={12} sm={12} md={12}>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 540 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Gambar</TableCell>
                                            <TableCell>Nama Partai</TableCell>
                                            <TableCell>Singkatan</TableCell>
                                            <TableCell>No. Telpon</TableCell>
                                            <TableCell>Aksi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                        partaiData.length !== 0 ? partaiData.map(p => 
                                            <TableRow key={p.partai_id}>
                                                <TableCell>
                                                    {
                                                        p.gambar == null ? 
                                                        <img style={{ width: '150px', height: '150px' }} alt={p.nama_partai} src={Image} /> :
                                                        <img style={{ width: '150px', height: '150px' }} alt={p.nama_partai} src={p.gambar} />
                                                    }
                                                </TableCell>
                                                <TableCell>{p.nama_partai}</TableCell>
                                                <TableCell>{p.singkatan}</TableCell>
                                                <TableCell>{p.no_telp}</TableCell>
                                                <TableCell><Button size='small' sx={{ backgroundColor: theme.backgroundColor, color: theme.color, marginLeft: '5px' }} startIcon={<Diversity2Outlined />} variant='contained' color='inherit' onClick={() => aturDpp(p)}>Atur DPP</Button><Button size='small' variant='contained' color='warning' onClick={() => editPartai(p)} sx={{ marginLeft: '5px' }} startIcon={<Edit />}>Ubah</Button><Button sx={{ marginLeft: '5px' }} onClick={() => deletePartai(p)} size='small' variant='contained' startIcon={<Delete />} color='error'>Hapus</Button></TableCell>
                                        </TableRow> 
                                            )
                                        : <></>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                component="div"
                                count={settingTable.totalElements}
                                rowsPerPage={settingTable.size}
                                page={settingTable.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                </Grid>
            }
            <ModalCreateUpdatePartai 
                openModal={openModal}
                setOpenModal={setOpenModal}
                titleDialog={titleDialog}
                formPartai={formPartai}
                setFormPartai={setFormPartai}
                errorForm={errorForm}
                messageSnackbar={messageSnackbar}
                theme={theme}
                createPartai={createPartai}
                updatePartai={updatePartai}
                image={image}
                setImage={setImage}
                imagePreview={imagePreview}
                setImagePreview={setImagePreview}
                formDpp={formDpp}
                setFormDpp={setFormDpp}
                errorFormDpp={errorFormDpp}
                setErrorFormDpp={setErrorFormDpp}
                dppData={dppData}
                setDppData={setDppData}
                settingTableDpp={settingTableDpp}
                setSettingTableDpp={setSettingTableDpp}
                createDpp={createDpp}
                deleteDpp={deleteDpp}
                handleChangePageDpp={handleChangePageDpp}
                handleChangeRowsPerPageDpp={handleChangeRowsPerPageDpp}
                openConfirmationDelete={openConfirmationDelete} 
                setOpenConfirmationDelete={setOpenConfirmationDelete}
            />
            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                message={messageSnackbar}
            />
        </Grid>
    );
}

export default PengelolaanDataPartai;
