import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Cancel, SaveAs } from '@mui/icons-material';
import { errorFormTpsProps, formTpsKordinatProps } from '../../models/Area/ErrorFormTps';
import {Map, Marker} from '@vis.gl/react-google-maps';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

interface ModalTpsProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    formTpsKordinat: formTpsKordinatProps;
    setFormTpsKordinat: React.Dispatch<React.SetStateAction<formTpsKordinatProps>>;
    titleDialog: string;
    errorForm: errorFormTpsProps;
    createTps: any;
    updateTps: any;
    helperName: {rw_id: string, nama_rw: string | undefined, rt_id:string, nama_rt: string | undefined}
}

const ModalTps: React.FC<ModalTpsProps> = ({openModal, setOpenModal, formTpsKordinat, setFormTpsKordinat, titleDialog, errorForm, createTps, updateTps, helperName}) => {
    const [showMaps, setShowMaps] = React.useState<boolean>(true);

    return (
        <BootstrapDialog
            onClose={() => {setOpenModal(false); setShowMaps(false)}}
            aria-labelledby="customized-dialog-title"
            open={openModal}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {titleDialog}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => {setOpenModal(false); setShowMaps(false)}}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox checked={showMaps} onChange={() => setShowMaps(!showMaps)} />} label="Tampilkan Peta" />
                    </Grid>
                    {
                        showMaps ? (<Grid item xs={12}>
                        <Map
                            defaultZoom={7}
                            style={{ width: '100%', height: '250px' }}
                            defaultCenter={ { lat: process.env.REACT_APP_MAPS_LATTITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LATTITUDE) : -4.55858490, lng: process.env.REACT_APP_MAPS_LONGITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LONGITUDE) : 105.40680790 } }
                            // onCameraChanged={ (ev: MapCameraChangedEvent) =>
                            //     console.log('camera changed:', ev.detail.center, 'zoom:', ev.detail.zoom)
                            // }
                            onClick={(e) => {
                                setFormTpsKordinat({...formTpsKordinat, lattitude: e.detail.latLng !== null ? e.detail.latLng.lat.toString() : '', longitude: e.detail.latLng !== null ? e.detail.latLng.lng.toString() : ''});
                                setShowMaps(false);
                            }}
                            >
                                <Marker position={{lat: parseFloat(formTpsKordinat.lattitude), lng: parseFloat(formTpsKordinat.longitude)}} />
                        </Map>
                        </Grid>) : (<>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="longitude"
                                label="Longitude"
                                disabled
                                name="longitude"
                                autoFocus
                                onChange={(e) => setFormTpsKordinat({...formTpsKordinat, longitude: e.target.value})}
                                value={formTpsKordinat.longitude}
                            />
                            {(errorForm.longitude != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.longitude}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="lattitude"
                                label="Lattitude"
                                name="lattitude"
                                autoFocus
                                disabled
                                onChange={(e) => setFormTpsKordinat({...formTpsKordinat, lattitude: e.target.value})}
                                value={formTpsKordinat.lattitude}
                            />
                            {(errorForm.lattitude != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.lattitude}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="rw"
                                disabled
                                label="Nama RW"
                                name="rw"
                                autoFocus
                                value={helperName.nama_rw}
                            />
                            {(errorForm.nama_tps != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.nama_tps}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                disabled
                                fullWidth
                                id="rt"
                                label="Nama RT"
                                name="rt"
                                autoFocus
                                value={helperName.nama_rt}
                            />
                            {(errorForm.nama_tps != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.nama_tps}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="nama_tps"
                                label="Nama TPS"
                                name="nama_tps"
                                autoFocus
                                onChange={(e) => setFormTpsKordinat({...formTpsKordinat, nama_tps: e.target.value})}
                                value={formTpsKordinat.nama_tps}
                            />
                            {(errorForm.nama_tps != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.nama_tps}</Typography> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="alamat"
                                label="Alamat TPS"
                                name="alamat"
                                multiline
                                rows={3}
                                onChange={(e) => setFormTpsKordinat({...formTpsKordinat, alamat: e.target.value})}
                                value={formTpsKordinat.alamat}
                            />
                            {(errorForm.alamat != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.alamat}</Typography> : <></>}
                        </Grid>
                        </>)
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant='contained' color='error' startIcon={<Cancel />} onClick={() => {setOpenModal(false); setShowMaps(false);}}>
                    Batalkan
                </Button>
                <Button autoFocus variant='contained' color='success' startIcon={<SaveAs />} onClick={() => {
                    if(titleDialog.split(" ").includes("Tambah")) {
                        createTps();
                    } else {
                        updateTps({
                            tps_id: formTpsKordinat.tps_id,
                            rw_id: formTpsKordinat.rw_id,
                            rt_id: formTpsKordinat.rt_id,
                            nama_tps: formTpsKordinat.nama_tps,
                            alamat: formTpsKordinat.alamat
                        });
                    }
                }}>
                    Simpan
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

export default ModalTps;
