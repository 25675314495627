import axios, { AxiosResponse } from 'axios';
import { BerandaSaksiResponseDTO } from '../../models/Saksi/BerandaSaksiResponseDTO';

class SaksiServices {
    async beranda(memberId: string): Promise<AxiosResponse<BerandaSaksiResponseDTO>> {
        return axios.get(`/api/v1/saksi?memberId=${memberId}`,{headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SaksiServices();