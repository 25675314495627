export default interface KtpProps {
    ktp_id: string;
    nik: string;
    nama: string;
    jenis_kelamin: string;
    tempat_lahir: string;
    tanggal_lahir: string;
    alamat: string;
    kode_pos: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    verified: boolean;
}

export const KtpField: KtpProps = {
    ktp_id: '',
    nik: '',
    nama: '',
    jenis_kelamin: '',
    tempat_lahir: '',
    tanggal_lahir: '',
    alamat: '',
    kode_pos: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
    verified: false
}