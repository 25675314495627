export default interface ReferenceProps {
    referensi_id: string;
    member_id: string;
    kode_referensi: string;
    created_at: string;
    updated_at: string;
}

export const ReferenceField: ReferenceProps = { 
    referensi_id: '',
    member_id: '',
    kode_referensi: '',
    created_at: '',
    updated_at: ''
}