import { AccountCircle, Cancel, Close, Create, Delete, Email, FileUpload, House, LocationCity, Phone, SaveAs, Signpost, VerifiedUser, WhatsApp } from '@mui/icons-material';
import { Alert, AppBar, Badge, Box, Button, Checkbox, Container, Dialog, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, Radio, RadioGroup, Skeleton, Slide, styled, TextField, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ImageDefault from "../../asset/images/default.png";
import { ErrorRegisterRequestProps, RegisterRequestProps } from '../../models/Auth/RegisterRequestProps';
import { ErrorProfileRequestProps, ProfileRequestProps } from '../../models/Profile/ProfileRequestProps';
import { ProfileResponseProps } from '../../models/Profile/ProfileResponseProps';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface DetailMemberProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    titleDialog: string;
    theme: ThemeStoreProps;
    memberDataDetail: ProfileResponseProps;
    handleSubmit: any;
    register: RegisterRequestProps;
    setRegister: React.Dispatch<React.SetStateAction<RegisterRequestProps>>;
    errors: ErrorRegisterRequestProps;
    checkBoxHelper: boolean;
    helperCheckbox: any;
    setCheckBoxHelper: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    imagePreview: string | null;
    setImagePreview: React.Dispatch<React.SetStateAction<string | null>>;
    image: File | null;
    setImage: React.Dispatch<React.SetStateAction<File | null>>;
    editForm: boolean;
    setEditForm: React.Dispatch<React.SetStateAction<boolean>>;
    uploadImage: any;
    form: ProfileRequestProps;
    setForm: React.Dispatch<React.SetStateAction<ProfileRequestProps>>;
    cancelResetForm: any;
    setTempDataProfil: React.Dispatch<React.SetStateAction<ProfileResponseProps>>;
    handleSubmitProfil: any;
    avatar: string;
    setAvatar: React.Dispatch<React.SetStateAction<string>>;
    errorsProfil: ErrorProfileRequestProps;
    setErrorsProfil: React.Dispatch<React.SetStateAction<ErrorProfileRequestProps>>;
}

const DetailMember: React.FC<DetailMemberProps> = ({ openModal, setOpenModal, titleDialog, theme, memberDataDetail, handleSubmit, register, setRegister, errors, checkBoxHelper, helperCheckbox, setCheckBoxHelper, loading, imagePreview,setImagePreview, image, setImage, editForm, setEditForm, uploadImage, form, setForm, cancelResetForm, setTempDataProfil, handleSubmitProfil, avatar, setAvatar, errorsProfil, setErrorsProfil}) => {
    const today = dayjs();
    return (
        <Dialog
            fullScreen
            open={openModal}
            onClose={() => setOpenModal(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setOpenModal(false)}
                    aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {titleDialog}
                    </Typography>
                    <Button startIcon={<Close />} color="inherit" onClick={() => setOpenModal(false)}>Tutup</Button>
                </Toolbar>
            </AppBar>
            {
                titleDialog.split(' ').includes('Detail') ? <Container> {
                    loading ? (
                    <Box sx={{ marginY: '50px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                                <Skeleton animation='wave' sx={{width: '100%', height: '300px'}} variant='rectangular' />
                                <Grid container spacing={1} sx={{ marginY: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Grid item xs={6}>
                                        <Skeleton animation='wave' sx={{width: '100%', height: '70px'}} variant='rectangular' />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} lg={8}>
                                <Box component="form" noValidate onSubmit={handleSubmit} >
                                    <Skeleton animation='wave' sx={{width: '100%', height: '50px', m: 1}} variant='text' />
                                    <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                                    <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                                    <Grid spacing={1} container>
                                        <Grid item xs={6}>
                                        <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                                        </Grid>
                                        <Grid item xs={6}>
                                        <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                                        </Grid>
                                    </Grid>
                                    <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                                    <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                                    <Skeleton animation='wave' sx={{width: '100%', height: '50px', m: 1}} variant='text' />
                                    <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                                    <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                                    <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <Box sx={{ marginY: '10px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                                <Box sx={{ width: '100%', marginBottom: '20px', height: '300px' }}>
                                    {
                                        imagePreview !== null ? 
                                        <img src={imagePreview} alt={'preview'} width={'100%'} height={'100%'} />
                                        : (avatar !== '' ?
                                        <img src={avatar} alt={avatar} width={'100%'} height={'100%'} /> :
                                        <img src={ImageDefault} alt={form.nama} width={'100%'} height={'100%'} />)
                                    }
                                </Box>
                                <Grid container spacing={3}>
                                {
                                    imagePreview !== null ?
                                    <Grid item xs={12}>
                                        {
                                            image == null ? <Alert severity="info">File Perlu dibawah 1 MB.</Alert> : image.size / 1000 > 1000 ? 
                                            <Alert severity="error">File anda terlalu besar! {image == null ? 0 : image.size / 1000} Kb.</Alert> :
                                            <Alert severity="info">File yang anda Upload berada di {image == null ? 0 : image.size / 1000} Kb.</Alert>
                                        }
                                        <Button variant='contained' sx={{ marginTop: '5px' }} fullWidth onClick={() => {
                                            setImagePreview(null);
                                            setImage(null);
                                        }} startIcon={<Delete />} color='error'>Hapus</Button>
                                    </Grid> : 
                                    <Grid item xs={12}>
                                        <Alert severity="info">File Perlu dibawah 1 MB.</Alert>
                                        <Button component="label" sx={{ marginTop: '5px' }} tabIndex={-1} variant='contained' fullWidth startIcon={<FileUpload />} disabled={!editForm} color='primary'>Upload<VisuallyHiddenInput type="file" onChange={uploadImage} /></Button>
                                    </Grid>
                                }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} lg={8}>
                                <Box>
                                    <Typography variant='h5' sx={{ marginBottom: '5px' }}>Data Diri</Typography>
                                    <TextField
                                        label="NIK"
                                        id="nik"
                                        disabled={!editForm}
                                        fullWidth
                                        placeholder='NIK'
                                        value={form.nik}
                                        onChange={(e) => setForm({...form, nik: e.target.value})}
                                        sx={{ m: 1 }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><AccountCircle /></InputAdornment>,
                                        }}
                                    />
                                    {(errorsProfil.nik != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorsProfil.nik}</Typography> : <></>}
                                    <TextField
                                        label="Nama"
                                        id="nama"
                                        disabled={!editForm}
                                        fullWidth
                                        placeholder='Nama'
                                        value={form.nama}
                                        onChange={(e) => setForm({...form, nama: e.target.value})}
                                        sx={{ m: 1 }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><Badge /></InputAdornment>,
                                        }}
                                    />
                                    {(errorsProfil.nama != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorsProfil.nama}</Typography> : <></>}
                                    <Grid spacing={1} container>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Tempat Lahir"
                                                id="tempat_lahir"
                                                fullWidth
                                                value={form.tempat_lahir}
                                                onChange={(e) => setForm({...form, tempat_lahir: e.target.value})}
                                                placeholder='Tempat Lahir'
                                                disabled={!editForm}
                                                sx={{ m: 1 }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><LocationCity /></InputAdornment>,
                                                }}
                                            />
                                            {(errorsProfil.tempat_lahir != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorsProfil.tempat_lahir}</Typography> : <></>}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    defaultValue={dayjs(form.tanggal_lahir) ?? today}
                                                    value={dayjs(form.tanggal_lahir)}
                                                    label="Tanggal Lahir"
                                                    disabled={!editForm}
                                                    name='tanggal_lahir'
                                                    disableFuture
                                                    sx={{ m: 1, width: '100%' }}
                                                    views={['year', 'month', 'day']}
                                                    onChange={(e) => setForm({...form, tanggal_lahir: (e?.format('YYYY-MM-DD') ?? today.format('YYYY-MM-DD'))})}
                                                />
                                            </LocalizationProvider>
                                            {(errorsProfil.tanggal_lahir != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorsProfil.tanggal_lahir}</Typography> : <></>}
                                        </Grid>
                                    </Grid>
                                    <FormControl sx={{m: 1}}>
                                            <FormLabel id="gender">Jenis Kelamin</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="gender"
                                                defaultValue={form.jenis_kelamin}
                                                name="radio-buttons-group"
                                                onChange={(e) => setForm({...form, jenis_kelamin: e.target.value})}
                                                value={form.jenis_kelamin}
                                            >
                                                <FormControlLabel value="WANITA" control={<Radio disabled={!editForm} />} label="Perempuan" />
                                                <FormControlLabel value="PRIA" control={<Radio disabled={!editForm} />} label="Laki-Laki" />
                                            </RadioGroup>
                                            {(errorsProfil.jenis_kelamin != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorsProfil.jenis_kelamin}</Typography> : <></>}
                                        </FormControl>
                                    <TextField
                                        label="Alamat"
                                        id="alamat"
                                        disabled={!editForm}
                                        fullWidth
                                        value={form.alamat}
                                        onChange={(e) => setForm({...form, alamat: e.target.value})}
                                        placeholder='Alamat'
                                        sx={{ m: 1 }}
                                        multiline
                                        rows={3}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><House /></InputAdornment>,
                                        }}
                                    />
                                    {(errorsProfil.alamat != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorsProfil.alamat}</Typography> : <></>}
                                    <TextField
                                        label="Kode Pos"
                                        id="kode_pos"
                                        disabled={!editForm}
                                        fullWidth
                                        value={form.kode_pos}
                                        onChange={(e) => setForm({...form, kode_pos: e.target.value})}
                                        placeholder='Kode Pos'
                                        sx={{ m: 1 }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><Signpost /></InputAdornment>,
                                        }}
                                    />
                                    {(errorsProfil.kode_pos != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorsProfil.kode_pos}</Typography> : <></>}
                                    <Typography variant='h5' sx={{ marginY: '5px' }}>Informasi Login</Typography>
                                    <TextField
                                        label="Username"
                                        id="username"
                                        disabled
                                        fullWidth
                                        value={form.username}
                                        onChange={(e) => setForm({...form, username: e.target.value})}
                                        placeholder='Username'
                                        sx={{ m: 1 }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><VerifiedUser /></InputAdornment>,
                                        }}
                                    />
                                    {(errorsProfil.username != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorsProfil.username}</Typography> : <></>}
                                    <Typography variant='h5' sx={{ marginY: '5px' }}>Informasi Kontak</Typography>
                                    <TextField
                                        label="Email"
                                        id="email"
                                        disabled={!editForm}
                                        fullWidth
                                        value={form.email}
                                        onChange={(e) => setForm({...form, email: e.target.value})}
                                        placeholder='Email'
                                        sx={{ m: 1 }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><Email /></InputAdornment>,
                                        }}
                                    />
                                    {(errorsProfil.email != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorsProfil.email}</Typography> : <></>}
                                    <TextField
                                        label="Nomor HP"
                                        id="nomor_hp"
                                        disabled={!editForm}
                                        fullWidth
                                        value={form.no_hp}
                                        onChange={(e) => setForm({...form, no_hp: e.target.value})}
                                        placeholder='Nomor HP'
                                        sx={{ m: 1 }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><Phone /></InputAdornment>,
                                        }}
                                    />
                                    {(errorsProfil.no_hp != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorsProfil.no_hp}</Typography> : <></>}
                                    <TextField
                                        label="Nomor Whatsapp"
                                        id="no_wa"
                                        disabled={!editForm}
                                        fullWidth
                                        value={form.no_wa}
                                        onChange={(e) => setForm({...form, no_wa: e.target.value})}
                                        placeholder='Nomor Whatsapp'
                                        sx={{ m: 1 }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><WhatsApp /></InputAdornment>,
                                        }}
                                    />
                                    {(errorsProfil.no_wa != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorsProfil.no_wa}</Typography> : <></>}
                                    <FormControlLabel sx={{m: 1}}
                                        control={<Checkbox 
                                            disabled={!editForm}
                                            checked={form.hak_pilih} 
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setForm({...form, hak_pilih: event.target.checked})} 
                                            />} 
                                        label="Saya Memiliki Hak Pilih di TPS Alamat Tinggal" 
                                    />
                                    <Grid container spacing={2}>
                                        {
                                            !editForm ? 
                                            <Grid item xs={12}>
                                                <Button startIcon={<Create />} onClick={() => {setEditForm(true); setTempDataProfil({
                                                    member_id: form.member_id,
                                                    nik: form.nik,
                                                    nama: form.nama,
                                                    jenis_kelamin: form.jenis_kelamin,
                                                    tempat_lahir: form.tempat_lahir,
                                                    tanggal_lahir: form.tanggal_lahir,
                                                    alamat: form.alamat,
                                                    kode_pos: form.kode_pos,
                                                    avatar: '',
                                                    email: form.email,
                                                    no_hp: form.no_hp,
                                                    no_wa: form.no_wa,
                                                    hak_pilih: form.hak_pilih,
                                                    username: form.username
                                                })}} variant='contained' color="inherit" fullWidth sx={{m:1, backgroundColor: theme.backgroundColor, color: theme.color}}>Ubah</Button>
                                            </Grid> : 
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Button color="error" onClick={() => {cancelResetForm();setImagePreview(null);setImage(null); setEditForm(false);}} startIcon={<Cancel />} variant='contained' fullWidth sx={{m:1}}>Batalkan</Button>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button color="success" variant='contained' startIcon={<SaveAs />} fullWidth sx={{m:1 }} onClick={() => handleSubmitProfil()}>Simpan</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                </Container> : 
                <Container>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="nik"
                                    label="Nomor Induk Kepegawaian (NIK)"
                                    name="nik"
                                    autoFocus
                                    helperText={register.nik.length + '/16 Karakter'}
                                    onChange={(e) => setRegister({...register, nik: e.target.value})}
                                    value={register.nik}
                                />
                                {(errors.nik != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.nik}</Typography> : <></>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="nama"
                                    label="Nama"
                                    name="nama"
                                    autoComplete="nama"
                                    onChange={(e) => setRegister({...register, nama: e.target.value})}
                                    value={register.nama}
                                />
                                {(errors.nama != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.nama}</Typography> : <></>}
                            </Grid>
                            <Grid item xs={12}>
                            <FormControl>
                                <FormLabel id="gender">Jenis Kelamin</FormLabel>
                                <RadioGroup
                                    aria-labelledby="gender"
                                    defaultValue="WANITA"
                                    name="radio-buttons-group"
                                    onChange={(e) => setRegister({...register, jenis_kelamin: e.target.value})}
                                    value={register.jenis_kelamin}
                                >
                                    <FormControlLabel value="WANITA" control={<Radio />} label="Perempuan" />
                                    <FormControlLabel value="PRIA" control={<Radio />} label="Laki-Laki" />
                                </RadioGroup>
                                {(errors.jenis_kelamin != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.jenis_kelamin}</Typography> : <></>}
                            </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="tempat_lahir"
                                    name="tempat_lahir"
                                    required
                                    fullWidth
                                    id="tempat_lahir"
                                    label="Tempat Lahir"
                                    onChange={(e) => setRegister({...register, tempat_lahir: e.target.value})}
                                    value={register.tempat_lahir}
                                />
                                {(errors.tempat_lahir != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.tempat_lahir}</Typography> : <></>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        defaultValue={today}
                                        label="Tanggal Lahir"
                                        name='tanggal_lahir'
                                        disableFuture
                                        views={['year', 'month', 'day']}
                                        onChange={(e) => setRegister({...register, tanggal_lahir: (e?.format('YYYY-MM-DD') ?? today.format('YYYY-MM-DD'))})}
                                    />
                                </LocalizationProvider>
                                {(errors.tanggal_lahir != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.tanggal_lahir}</Typography> : <></>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="alamat"
                                    label="Alamat"
                                    name='alamat'
                                    required
                                    fullWidth
                                    multiline
                                    maxRows={6}
                                    onChange={(e) => setRegister({...register, alamat: e.target.value})}
                                    value={register.alamat}
                                />
                                {(errors.alamat != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.alamat}</Typography> : <></>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="kode_pos"
                                    fullWidth
                                    required
                                    label="Kode Pos"
                                    name='kode_pos'
                                    onChange={(e) => setRegister({...register, kode_pos: e.target.value})}
                                    value={register.kode_pos}
                                />
                                {(errors.kode_pos != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.kode_pos}</Typography> : <></>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="no_hp"
                                    label="Nomor HP"
                                    name="no_hp"
                                    autoComplete="no_hp"
                                    onChange={(e) => 
                                        (checkBoxHelper) ? setRegister({...register, no_wa: e.target.value, no_hp: e.target.value}) : setRegister({...register, no_hp: e.target.value})   
                                    }
                                    value={register.no_hp}
                                />
                                {(errors.no_hp != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.no_hp}</Typography> : <></>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="no_wa"
                                    label="Nomor Whatsapp"
                                    name="no_wa"
                                    autoComplete="no_wa"
                                    value={register.no_wa}
                                    onChange={(e) => {setRegister({...register, no_wa: e.target.value}); setCheckBoxHelper(false);}}
                                />
                                {(errors.no_wa != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.no_wa}</Typography> : <></>}
                                <FormControlLabel control={<Checkbox checked={checkBoxHelper} onChange={helperCheckbox} />} label="Nomor Wa saya sama dengan Nomor HP" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    onChange={(e) => setRegister({...register, email: e.target.value})}
                                    value={register.email}
                                />
                                {(errors.email != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.email}</Typography> : <></>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    name="username"
                                    autoComplete="username"
                                    onChange={(e) => setRegister({...register, username: e.target.value})}
                                    value={register.username}
                                />
                                {(errors.username != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.username}</Typography> : <></>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    onChange={(e) => setRegister({...register, password: e.target.value})}
                                    value={register.password}
                                />
                                {(errors.password != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.password}</Typography> : <></>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="reference"
                                    label="Kode Referensi (Opsional)"
                                    id="reference"
                                    onChange={(e) => setRegister({...register, referensi_kode: e.target.value})}
                                    value={register.referensi_kode}
                                />
                                <Typography sx={{fontSize: '10px'}}>Kode Referensi didapatkan dari Relawan</Typography>
                                {(errors.referensi_kode != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.referensi_kode}</Typography> : <></>}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox checked={register.hak_pilih} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRegister({...register, hak_pilih: event.target.checked})} />} label="Saya Memiliki Hak Pilih di TPS Alamat Tinggal" />
                                {(errors.hak_pilih != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.hak_pilih}</Typography> : <></>}
                            </Grid>
                        </Grid>
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} startIcon={<SaveAs />} title='Simpan'>Simpan</Button>
                    </Box>
                </Container>
            }
        </Dialog>
    );
}

export default DetailMember;
