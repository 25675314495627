import React from 'react';
import { ProvinsiProps } from '../../models/Area/ProvinsiProps';
import { KabupatenProps } from '../../models/Area/KabupatenProps';
import { KecamatanProps } from '../../models/Area/KecamatanProps';
import { DesaProps } from '../../models/Area/DesaProps';
import { RwProps } from '../../models/Area/RwProps';
import { RtProps } from '../../models/Area/RtProps';
import { TpsProps } from '../../models/Area/TpsProps';
import ListMemberProps from '../../models/Member/ListMemberProps';
import ProvinsiService from '../../services/Area/ProvinsiService';
import KabupatenService from '../../services/Area/KabupatenService';
import TpsService from '../../services/Area/TpsService';
import RtService from '../../services/Area/RtService';
import RwService from '../../services/Area/RwService';
import DesaService from '../../services/Area/DesaService';
import KecamatanService from '../../services/Area/KecamatanService';
import AdminService from '../../services/Member/AdminService';
import { useRecoilValue } from 'recoil';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { ThemeStoreState } from '../../store';
import Swal from 'sweetalert2';
import { ListSaksiField, ListSaksiProps } from '../../models/Member/ListSaksiProps';
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { Add, Cancel, Delete, Diversity2Outlined, ExpandMore } from '@mui/icons-material';
import DetailSaksiMember from '../../pages/Admin/DetailSaksiMember';
import { replaceItemAtIndex } from '../../Utils/helper';
import { DetailMemberField, DetailMemberProps } from '../../models/Member/DetailMemberProps';

const AdminPengelolaanDataSaksi = () => {
    // Tambah 
    const [konstituenDataDetail, setKonstituenDataDetail] = React.useState<DetailMemberProps>(DetailMemberField);
    const [loadingSkeletonTambah, setLoadingSkeletonTambah] = React.useState<boolean>(true);
    const [loadingSkeletonTableTambah, setLoadingSkeletonTableTambah] = React.useState<boolean>(false);
    const [settingTableTambah, setSettingTableTambah] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        provinsiId: string;
        kabupatenId: string;
        kecamatanId: string;
        desaId: string;
        rwId: string;
        rtId: string;
        tpsId: string;
        hakPilih: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'member_id',
        search: '',
        provinsiId: '',
        kabupatenId: '',
        kecamatanId: '',
        desaId: '',
        rwId: '',
        rtId: '',
        tpsId: '',
        hakPilih: 'null',
        totalElements: 0,
        totalPages: 0,
    });
    const [saksiDataTambah, setSaksiDataTambah] = React.useState<ListMemberProps[]>([]);
    const [provinsiDataTambah, setProvinsiDataTambah] = React.useState<ProvinsiProps[]>([]);
    const [kabupatenDataTambah, setKabupatenDataTambah] = React.useState<KabupatenProps[]>([]);
    const [kecamatanDataTambah, setKecamatanDataTambah] = React.useState<KecamatanProps[]>([]);
    const [desaDataTambah, setDesaDataTambah] = React.useState<DesaProps[]>([]);
    const [rwDataTambah, setRwDataTambah] = React.useState<RwProps[]>([]);
    const [rtDataTambah, setRtDataTambah] = React.useState<RtProps[]>([]);
    const [tpsDataTambah, setTpsDataTambah] = React.useState<TpsProps[]>([]);
    const getAllDataTambah = () => {
        setLoadingSkeletonTableTambah(true);
        AdminService.getAllMemberWithoutRoleSaksi(settingTableTambah.page, settingTableTambah.size, settingTableTambah.sort, settingTableTambah.search, settingTableTambah.provinsiId, settingTableTambah.kabupatenId, settingTableTambah.kecamatanId, settingTableTambah.desaId, settingTableTambah.rwId, settingTableTambah.rtId, settingTableTambah.tpsId, settingTableTambah.hakPilih).then(res => {
            setSaksiDataTambah(res.data.content);
            setSettingTableTambah({...settingTableTambah, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
            setLoadingSkeletonTableTambah(false);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak Ada Jaringan");
            setOpenSnackbar(true);
            setLoadingSkeletonTableTambah(false);
        })
    }
    function getProvinsiTambah() {
        if(provinsiDataTambah.length === 0) {
            setLoadingSkeletonTambah(true);
            ProvinsiService.getAllArray().then(res => setProvinsiDataTambah(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
            setLoadingSkeletonTambah(false);
        }
    }
    function getKabupatenTambah(id?: string | null) {
        setLoadingSkeletonTambah(true);
        KabupatenService.getAllArray(id == null ? settingTableTambah.provinsiId : id).then(res => setKabupatenDataTambah(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonTambah(false);
    }
    function getKecamatanTambah(id?: string | null) {
        setLoadingSkeletonTambah(true);
        KecamatanService.getAllArray(id == null ? settingTableTambah.kabupatenId : id).then(res => setKecamatanDataTambah(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonTambah(false);
    }
    function getDesaTambah(id?: string | null) {
        setLoadingSkeletonTambah(true);
        DesaService.getAllArray(id == null ? settingTableTambah.kecamatanId : id).then(res => setDesaDataTambah(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonTambah(false);
    }
    function getRwTambah(id?: string | null) {
        setLoadingSkeletonTambah(true);
        RwService.getAllArr(id == null ? settingTableTambah.desaId : id).then(res => setRwDataTambah(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonTambah(false);
    }
    function getRtTambah(id?: string | null) {
        setLoadingSkeletonTambah(true);
        RtService.getAllArr(id == null ? settingTableTambah.rwId : id).then(res => setRtDataTambah(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonTambah(false);
    }
    function getTpsTambah(rwid?: string | null, rtid?: string | null) {
        setLoadingSkeletonTambah(true);
        TpsService.getAllArr(rwid == null ? settingTable.rwId : rwid, rtid == null ? settingTable.rtId : rtid).then(res => setTpsDataTambah(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonTambah(false);
    }
    const handleChangePageTambah = (event: unknown, newPage: number) => {
        setLoadingSkeletonTableTambah(true);
        setSettingTableTambah({...settingTableTambah,  page: newPage});
        setLoadingSkeletonTableTambah(false);
    };
    const handleChangeRowsPerPageTambah = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTableTambah({...settingTableTambah, page: 0, size: +event.target.value});
    };
    const clearFilterTambah = () => {
        setSettingTableTambah({
            ...settingTableTambah, 
            provinsiId: '',
            kabupatenId: '',
            kecamatanId: '',
            desaId: '',
            rwId: '',
            rtId: '',
            tpsId: '',
            hakPilih: 'null',
        });
        setKabupatenDataTambah([]);
        setKecamatanDataTambah([]);
        setDesaDataTambah([]);
        setRwDataTambah([]);
        setRtDataTambah([]);
        setTpsDataTambah([]);
    }

    // Saksi 
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const [loadingSkeletonTable, setLoadingSkeletonTable] = React.useState<boolean>(false);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [titleDialog, setTitleDialog] = React.useState<string>('');
    const [settingTable, setSettingTable] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        provinsiId: string;
        kabupatenId: string;
        kecamatanId: string;
        desaId: string;
        rwId: string;
        rtId: string;
        tpsId: string;
        hakPilih: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'tpsId',
        search: '',
        provinsiId: '',
        kabupatenId: '',
        kecamatanId: '',
        desaId: '',
        rwId: '',
        rtId: '',
        tpsId: '',
        hakPilih: 'null',
        totalElements: 0,
        totalPages: 0,
    });
    const [saksiData, setSaksiData] = React.useState<ListSaksiProps[]>([]);
    const [saksiDataDetail, setSaksiDataDetail] = React.useState<ListSaksiProps>(ListSaksiField);
    const [provinsiData, setProvinsiData] = React.useState<ProvinsiProps[]>([]);
    const [kabupatenData, setKabupatenData] = React.useState<KabupatenProps[]>([]);
    const [kecamatanData, setKecamatanData] = React.useState<KecamatanProps[]>([]);
    const [desaData, setDesaData] = React.useState<DesaProps[]>([]);
    const [rwData, setRwData] = React.useState<RwProps[]>([]);
    const [rtData, setRtData] = React.useState<RtProps[]>([]);
    const [tpsData, setTpsData] = React.useState<TpsProps[]>([]);
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState);
    
    const getAllData = () => {
        setLoadingSkeletonTable(true);
        AdminService.getAllSaksi(settingTable.page, settingTable.size, settingTable.sort, settingTable.search, settingTable.provinsiId, settingTable.kabupatenId, settingTable.kecamatanId, settingTable.desaId, settingTable.rwId, settingTable.rtId, settingTable.tpsId, settingTable.hakPilih).then(res => {
            setSaksiData(res.data.content);
            setSettingTable({...settingTable, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
            setLoadingSkeletonTable(false);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak Ada Jaringan");
            setOpenSnackbar(true);
            setLoadingSkeletonTable(false);
        })
    }
    function getProvinsi() {
        if(provinsiData.length === 0) {
            setLoadingSkeleton(true);
            ProvinsiService.getAllArray().then(res => {setProvinsiData(res.data); setProvinsiDataTambah(res.data)}).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
            setLoadingSkeleton(false);
        }
    }
    function getKabupaten(id?: string | null) {
        setLoadingSkeleton(true);
        KabupatenService.getAllArray(id == null ? settingTable.provinsiId : id).then(res => setKabupatenData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getKecamatan(id?: string | null) {
        setLoadingSkeleton(true);
        KecamatanService.getAllArray(id == null ? settingTable.kabupatenId : id).then(res => setKecamatanData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getDesa(id?: string | null) {
        setLoadingSkeleton(true);
        DesaService.getAllArray(id == null ? settingTable.kecamatanId : id).then(res => setDesaData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getRw(id?: string | null) {
        setLoadingSkeleton(true);
        RwService.getAllArr(id == null ? settingTable.desaId : id).then(res => setRwData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getRt(id?: string | null) {
        setLoadingSkeleton(true);
        RtService.getAllArr(id == null ? settingTable.rwId : id).then(res => setRtData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getTps(rwid?: string | null, rtid?: string | null) {
        setLoadingSkeleton(true);
        TpsService.getAllArr(rwid == null ? settingTable.rwId : rwid, rtid == null ? settingTable.rtId : rtid).then(res => setTpsData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    const openAdd = (member: ListSaksiProps) => {
        AdminService.getAllMemberWithoutRoleSaksi(settingTableTambah.page, settingTableTambah.size, settingTableTambah.sort, settingTableTambah.search, settingTableTambah.provinsiId, settingTableTambah.kabupatenId, settingTableTambah.kecamatanId, settingTableTambah.desaId, settingTableTambah.rwId, settingTableTambah.rtId, settingTableTambah.tpsId, settingTableTambah.hakPilih).then(res=> {
            setSaksiDataTambah(res.data.content);
            setSettingTableTambah({...settingTableTambah, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
            setLoadingSkeletonTableTambah(false);
            setSaksiDataDetail(member);
            setTitleDialog("Atur Saksi TPS " + member.nama_tps);
            setOpenModal(true);
            setLoadingSkeletonTambah(false);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? 'Tidak dapat Mengambil data Anggota'); 
            setOpenSnackbar(true);
        })
    }
    const openDetail = async (member: ListSaksiProps) => {
        AdminService.getDetailMember(member.member_id).then(res => {
            setKonstituenDataDetail(res.data);
            setSaksiDataDetail(member);
            setTitleDialog('Detail Saksi ' + member.nama);
            setOpenModal(true);
            setLoadingSkeletonTambah(false);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak Ada Jaringan");
            setOpenSnackbar(true);
        });
    }
    const createPerson = async (member: ListMemberProps, tps: ListSaksiProps) => {
        await AdminService.addMemberSaksi(member.member_id, tps.tps_id).then(res => {
            let index = saksiData.findIndex(x => x.tps_id === tps.tps_id);
            let temp = saksiData.find(x => x.tps_id === tps.tps_id);
            setSaksiData(replaceItemAtIndex(saksiData, index, {
                longitude: temp?.longitude,
                lattitude: temp?.lattitude,
                tps_id : temp?.tps_id,
                nama_provinsi: temp?.nama_provinsi,
                nama_kabupaten: temp?.nama_kabupaten,
                nama_kecamatan: temp?.nama_kecamatan,
                nama_desa: temp?.nama_desa,
                member_id: member.member_id,
                saksi_id: null,
                nama: member.nama,
                username: member.username,
                avatar: member.avatar,
                no_hp: member.no_hp,
                no_wa: member.no_wa,
                hak_pilih: member.hak_pilih,
                nama_rw: temp?.nama_rw,
                nama_rt: temp?.nama_rt,
                nama_tps: temp?.nama_tps,
            }));
            setMessageSnackbar("Berhasil Menambah Data Saksi");
            setOpenSnackbar(true);
            setOpenModal(false);
        }).catch(err => {
            setMessageSnackbar("Gagal Menambah Data Saksi");
            setOpenSnackbar(true);
        });
    }
    const deletePerson = async (member: ListSaksiProps) => {
        Swal.fire({
            title: 'Hapus Saksi',
            text: 'Apakah anda ingin menghapus Saksi dengan Nama ' + member.nama + " di TPS "+ member.nama_tps +" dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                AdminService.removeMemberSaksi(member.member_id).then(res => {
                    let index = saksiData.findIndex(x => x.member_id === member.member_id);
                    let temp = saksiData.find(x => x.member_id === member.member_id);
                    setSaksiData(replaceItemAtIndex(saksiData, index, {
                        longitude: temp?.longitude,
                        lattitude: temp?.lattitude,
                        tps_id : temp?.tps_id,
                        nama_provinsi: temp?.nama_provinsi,
                        nama_kabupaten: temp?.nama_kabupaten,
                        nama_kecamatan: temp?.nama_kecamatan,
                        nama_desa: temp?.nama_desa,
                        nama_rw: temp?.nama_rw,
                        nama_rt: temp?.nama_rt,
                        nama_tps: temp?.nama_tps,
                        member_id: null,
                        saksi_id: null,
                        nama: null,
                        username: null,
                        avatar: null,
                        no_hp: null,
                        no_wa: null,
                        hak_pilih: null,
                    }));
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Saksi dengan Nama "+ member.nama +" sudah dihapus.",
                        icon: "success"
                    });
                    setMessageSnackbar('Saksi telah dihapus'); 
                    setOpenSnackbar(true);
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: err.response.data.message ?? "Saksi "+ member.nama +" gagal dihapus.",
                        icon: "error"
                    });
                    setMessageSnackbar('Tidak dapat Hapus Saksi'); 
                    setOpenSnackbar(true);
                })
            }
        });
    }
    const handleChangePage = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable,  page: newPage});
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTable({...settingTable, page: 0, size: +event.target.value});
    };
    const clearFilter = () => {
        setSettingTable({
            ...settingTable, 
            provinsiId: '',
            kabupatenId: '',
            kecamatanId: '',
            desaId: '',
            rwId: '',
            rtId: '',
            tpsId: '',
            hakPilih: 'null',
        });
        setKabupatenData([]);
        setKecamatanData([]);
        setDesaData([]);
        setRwData([]);
        setRtData([]);
        setTpsData([]);
    }

    React.useEffect(() => {
        if (openModal) {
            if(titleDialog.split(' ').includes('Atur')) {
                setLoadingSkeletonTableTambah(true);
                getAllDataTambah();
                setLoadingSkeletonTableTambah(false);
            }
        } else {
            getAllData()
            getProvinsi();
            setLoadingSkeleton(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingTable.page, settingTable.size, settingTable.sort, settingTable.search, settingTable.provinsiId, settingTable.kabupatenId, settingTable.kecamatanId, settingTable.desaId, settingTable.rwId, settingTable.rtId, settingTable.tpsId, settingTable.hakPilih, settingTableTambah.page, settingTableTambah.size, settingTableTambah.sort, settingTableTambah.search, settingTableTambah.provinsiId, settingTableTambah.kabupatenId, settingTableTambah.kecamatanId, settingTableTambah.desaId, settingTableTambah.rwId, settingTableTambah.rtId, settingTableTambah.tpsId, settingTableTambah.hakPilih]);

    return loadingSkeleton ? (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                        <Skeleton width={'100%'} height={'50px'} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Skeleton width={'100%'} height={'50px'} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Skeleton width={'100%'} height={'500px'} />
            </Grid>
        </Grid>
    ) : (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                    <Grid item xs={12} sm={12}>
                        <Accordion sx={{ marginY: '20px' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                Filter
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2} sx={{ paddingX: '20px' }}>
                                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="provinsi-select">Provinsi</InputLabel>
                                            <Select
                                                labelId="provinsi-select"
                                                id="provinsi_id"
                                                value={settingTable.provinsiId}
                                                disabled={provinsiData.length === 0}
                                                label="Provinsi"
                                                onChange={(e) => {setSettingTable({...settingTable, provinsiId: e.target.value, kecamatanId: '', kabupatenId: '', desaId: '', rwId: '', rtId: '', tpsId: ''}); getKabupaten(e.target.value);}}
                                            >
                                                {
                                                    provinsiData.map(p => <MenuItem key={p.provinsi_id} value={p.provinsi_id}>{p.nama_provinsi}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="kabupaten-select">Kabupaten</InputLabel>
                                            <Select
                                                labelId="kabupaten-select"
                                                id="kabupaten_id"
                                                value={settingTable.kabupatenId}
                                                disabled={kabupatenData.length === 0}
                                                label="Kabupaten"
                                                onChange={(e) => {setSettingTable({...settingTable, kabupatenId: e.target.value, kecamatanId: '', desaId: '', rwId: '', rtId: '', tpsId: ''}); getKecamatan(e.target.value)}}
                                            >
                                                {
                                                    kabupatenData.map(p => <MenuItem key={p.kabupaten_id} value={p.kabupaten_id}>{p.nama_kabupaten}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="kecamatan-select">Kecamatan</InputLabel>
                                            <Select
                                                labelId="kecamatan-select"
                                                id="kecamatan_id"
                                                value={settingTable.kecamatanId}
                                                disabled={kecamatanData.length === 0}
                                                label="Kecamatan"
                                                onChange={(e) => {setSettingTable({...settingTable, kecamatanId: e.target.value, desaId: '', rwId: '', rtId: '', tpsId: ''}); getDesa(e.target.value)}}
                                            >
                                                {
                                                    kecamatanData.map(p => <MenuItem key={p.kecamatan_id} value={p.kecamatan_id}>{p.nama_kecamatan}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="desa-select">Desa</InputLabel>
                                            <Select
                                                labelId="desa-select"
                                                id="desa_id"
                                                value={settingTable.desaId}
                                                disabled={desaData.length === 0}
                                                label="Desa"
                                                onChange={(e) => {setSettingTable({...settingTable, desaId: e.target.value, rwId: '', rtId: '', tpsId: ''}); getRw(e.target.value);}}
                                            >
                                                {
                                                    desaData.map(p => <MenuItem key={p.desa_id} value={p.desa_id}>{p.nama_desa}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="rw-select">RW</InputLabel>
                                            <Select
                                                labelId="rw-select"
                                                id="rw_id"
                                                value={settingTable.rwId}
                                                disabled={rwData.length === 0}
                                                label="RW"
                                                onChange={(e) => {setSettingTable({...settingTable, rwId: e.target.value, rtId: '', tpsId: ''}); getRt(e.target.value); getTps(e.target.value, null)}}
                                            >
                                                {
                                                    rwData.map(p => <MenuItem key={p.rw_id} value={p.rw_id}>{p.nama_rw}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="rt-select">RT</InputLabel>
                                            <Select
                                                labelId="rt-select"
                                                id="rt_id"
                                                value={settingTable.rtId}
                                                disabled={rtData.length === 0}
                                                label="RT"
                                                onChange={(e) => {setSettingTable({...settingTable, rtId: e.target.value, tpsId: ''}); getTps(settingTable.rwId, e.target.value)}}
                                            >
                                                {
                                                    rtData.map(p => <MenuItem key={p.rt_id} value={p.rt_id}>{p.nama_rt}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="tps-select">TPS</InputLabel>
                                            <Select
                                                labelId="tps-select"
                                                id="tps_id"
                                                value={settingTable.tpsId}
                                                disabled={tpsData.length === 0}
                                                label="TPS"
                                                onChange={(e) => setSettingTable({...settingTable, tpsId: e.target.value})}
                                            >
                                                {
                                                    tpsData.map(p => <MenuItem key={p.tps_id} value={p.tps_id}>{p.nama_tps}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="hak_pilih-select">Hak Pilih</InputLabel>
                                            <Select
                                                labelId="hak_pilih-select"
                                                id="hak_pilih_id"
                                                value={settingTable.hakPilih}
                                                label="Hak Pilih Member"
                                                onChange={(e) => setSettingTable({...settingTable, hakPilih: e.target.value})}
                                            >
                                                <MenuItem value={'null'}>{'Semua Hak Pilih'}</MenuItem>
                                                <MenuItem value={'true'}>{'Punya Hak Milik'}</MenuItem>
                                                <MenuItem value={'false'}>{'Tidak ada Hak Pilih'}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {
                                        settingTable.provinsiId !== '' ? 
                                    <Grid item xs={12} sm={12} md={4} lg={3} justifyContent={'center'} alignItems={'center'}>
                                        <Button color='error' variant='contained' title='Bersihkan Filter' startIcon={<Cancel />} onClick={() => clearFilter()}>Clear Filter</Button>
                                    </Grid> : <></>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant='h5' sx={{ justifyContent: 'center', alignItem: 'center' }}>Data Saksi</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {
                loadingSkeletonTable ? <Skeleton width={'100%'} height={'500px'} /> :
                <Grid item xs={12} sm={12} md={12}>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 540 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Provinsi</TableCell>
                                            <TableCell>Kabupaten</TableCell>
                                            <TableCell>Kelurahan</TableCell>
                                            <TableCell>Desa</TableCell>
                                            <TableCell>RW</TableCell>
                                            <TableCell>RT</TableCell>
                                            <TableCell>TPS</TableCell>
                                            <TableCell>Nama</TableCell>
                                            <TableCell>Username</TableCell>
                                            <TableCell>Aksi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                        saksiData.length !== 0 ? saksiData.map(p => 
                                            <TableRow key={p.tps_id}>
                                                <TableCell>{p.nama_provinsi}</TableCell>
                                                <TableCell>{p.nama_kabupaten}</TableCell>
                                                <TableCell>{p.nama_kecamatan}</TableCell>
                                                <TableCell>{p.nama_desa}</TableCell>
                                                <TableCell>{p.nama_rw}</TableCell>
                                                <TableCell>{p.nama_rt !== null ? p.nama_rt : <Chip size='small' label={'-'} variant='outlined' color='error' />}</TableCell>
                                                <TableCell>{p.nama_tps}</TableCell>
                                                <TableCell>{p.nama !== null ? p.nama : <Chip size='small' label={'Belum Diatur'} variant='outlined' color='error' />}</TableCell>
                                                <TableCell>{p.username !== null ? p.username : <Chip size='small' label={'Belum Diatur'} variant='outlined' color='error' />}</TableCell>
                                                <TableCell>
                                                {
                                                    p.nama === null ? <Button size='small' sx={{ backgroundColor: theme.backgroundColor, color: theme.color, marginLeft: '5px' }} startIcon={<Add />} title='Atur Saksi' variant='contained' color='inherit' onClick={() => openAdd(p)}>Atur</Button> : <React.Fragment>
                                                        <Button size='small' sx={{ backgroundColor: theme.backgroundColor, color: theme.color, marginLeft: '5px' }} startIcon={<Diversity2Outlined />} variant='contained' color='inherit' onClick={() => openDetail(p)}>Detail</Button><Button sx={{ marginLeft: '5px' }} onClick={() => deletePerson(p)} size='small' variant='contained' startIcon={<Delete />} color='error'>Hapus</Button>
                                                    </React.Fragment>
                                                }
                                                </TableCell>
                                        </TableRow> 
                                            )
                                        : <TableRow>
                                            <TableCell colSpan={10}>
                                                <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                            </TableCell>
                                        </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                component="div"
                                count={settingTable.totalElements}
                                rowsPerPage={settingTable.size}
                                page={settingTable.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                </Grid>
            }
            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                message={messageSnackbar}
            />
            <DetailSaksiMember 
                openModal={openModal}
                setOpenModal={setOpenModal}
                titleDialog={titleDialog}
                settingTableTambah={settingTableTambah}
                setSettingTableTambah={setSettingTableTambah}
                theme={theme}
                provinsiDataTambah={provinsiDataTambah}
                kabupatenDataTambah={kabupatenDataTambah}
                kecamatanDataTambah={kecamatanDataTambah}
                desaDataTambah={desaDataTambah}
                rwDataTambah={rwDataTambah}
                rtDataTambah={rtDataTambah}
                tpsDataTambah={tpsDataTambah}
                getProvinsiTambah={getProvinsiTambah}
                getKabupatenTambah={getKabupatenTambah}
                getKecamatanTambah={getKecamatanTambah}
                getDesaTambah={getDesaTambah}
                getRwTambah={getRwTambah}
                getRtTambah={getRtTambah}
                getTpsTambah={getTpsTambah}
                clearFilterTambah={clearFilterTambah}
                loadingSkeletonTableTambah={loadingSkeletonTableTambah}
                handleChangePageTambah={handleChangePageTambah}
                handleChangeRowsPerPageTambah={handleChangeRowsPerPageTambah}
                saksiDataTambah={saksiDataTambah}
                createPerson={createPerson}
                saksiDataDetail={saksiDataDetail}
                loadingSkeletonTambah={loadingSkeletonTambah}
                konstituenDataDetail={konstituenDataDetail}
                setKonstituenDataDetail={setKonstituenDataDetail}
            />
        </Grid>
    );
}

export default AdminPengelolaanDataSaksi;
