import { Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSetRecoilState } from 'recoil';
import { RoleStoreState } from '../../../store';
import LoginRoleProps from '../../../models/Role/LoginRoleProps';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import { Home } from '@mui/icons-material';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

interface SidebarProps {
    roleName: string;
    roleStore: LoginRoleProps;
    openSidebar: boolean;
    setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

const Sidebar: React.FC<SidebarProps> = ({roleName, roleStore, openSidebar, setOpenSidebar}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const setRoleStore = useSetRecoilState<LoginRoleProps | null>(RoleStoreState);
    
    return (
        <Drawer variant="permanent" open={openSidebar}>
            <DrawerHeader>
                <Typography sx={{ paddingLeft: '10px' }}>{roleName.replace("_", " ")}</Typography>
                <IconButton onClick={() => setOpenSidebar(false)}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                <ListItem disablePadding sx={{ display: 'block' }}  onClick={() => navigate("/home")}>
                    <ListItemButton sx={{
                        minHeight: 48,
                        justifyContent: openSidebar ? 'initial' : 'center',
                        px: 2.5
                    }}>
                        <ListItemIcon sx={{
                            minWidth: 0,
                            mr: openSidebar ? 3 : 'auto',
                            justifyContent: 'center',
                        }}>
                            <Home />
                        </ListItemIcon>
                        <ListItemText primary={"Home"} sx={{ opacity: openSidebar ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            {
                roleStore.layanan.map(l => l.aktif ? (
                    <React.Fragment key={l.layanan_id}>
                        <List>
                    {l.fitur.map((fitur, index) => fitur.aktif ? (
                        <ListItem key={fitur.fitur_id} disablePadding sx={{ display: 'block', cursor: 'pointer' }} onClick={() => navigate(l.url + fitur.route_fitur)}>
                            <ListItemButton
                                sx={{
                                minHeight: 48,
                                justifyContent: openSidebar ? 'initial' : 'center',
                                px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: openSidebar ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                                >
                                <img src={fitur.icon} width={'15px'} height={'15px'} alt={'ikon'} />
                                </ListItemIcon>
                                <ListItemText primary={capitalizeFirstLetter(fitur.nama_fitur.replaceAll("_", " "))} sx={{ opacity: openSidebar ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ) : <></>)}
                    </List><Divider /></React.Fragment>
                ) : <></>)
            }
            <List>
                <ListItem disablePadding sx={{ display: 'block' }}  onClick={() => {setRoleStore(null); setOpenSidebar(false); localStorage.removeItem('role');}}>
                    <ListItemButton sx={{
                        minHeight: 48,
                        justifyContent: openSidebar ? 'initial' : 'center',
                        px: 2.5
                    }}>
                        <ListItemIcon sx={{
                            minWidth: 0,
                            mr: openSidebar ? 3 : 'auto',
                            justifyContent: 'center',
                        }}>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Menu Role"} sx={{ opacity: openSidebar ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
    );
}

export default Sidebar;
