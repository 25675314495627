export interface RtProps {
    rt_id: string;
    rw_id: string;
    nama_rt: string;
}

export const RtField: RtProps = {
    rt_id: '',
    rw_id: '',
    nama_rt: ''
}