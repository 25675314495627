export interface errorFormAreaProps {
    nama_provinsi: string;
    nama_kabupaten: string;
    nama_kecamatan: string;
    nama_desa: string;
    nama_rw: string;
    nama_rt: string;
}

export const errorFormAreaField:errorFormAreaProps  = {
    nama_provinsi: '',
    nama_kabupaten: '',
    nama_kecamatan: '',
    nama_desa: '',
    nama_rw: '',
    nama_rt: ''
};