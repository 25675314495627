import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import { JawabanProps } from '../../models/Bantuan/JawabanProps';

class JawabanServices {
    async getAll(page: number, size: number, sort: string, search: string, memberId?: string, roleId?: string, pertanyaanId?:string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/bantuan/jawaban?page=${page}&size=${size}&sort=${sort}&search=${search}${memberId !== undefined ? '&memberId=' + memberId : ''}${roleId !== undefined ? '&roleId=' + roleId : ''}${pertanyaanId !== undefined ? '&pertanyaanId=' + pertanyaanId : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getByPertanyaanId(pertanyaanId: string): Promise<AxiosResponse<JawabanProps[]>> {
        return axios.get(`/api/v1/blog/bantuan/jawaban/pertanyaanId?pertanyaanId=${pertanyaanId}`,{headers: {'Content-Type': 'application/json'}});
    }
    
    async getById(jawabanId: string): Promise<AxiosResponse<JawabanProps>> {
        return axios.get(`/api/v1/blog/bantuan/jawaban/${jawabanId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: JawabanProps): Promise<AxiosResponse<JawabanProps>> {
        return axios.post(`/api/v1/bantuan/jawaban`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(jawabanId: string, data : JawabanProps): Promise<AxiosResponse<JawabanProps>> {
        return axios.put(`/api/v1/bantuan/jawaban/${jawabanId}`, data, { headers: {'Content-Type': 'application/json', },});
    }

    async delete(jawabanId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/bantuan/jawaban/${jawabanId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new JawabanServices();