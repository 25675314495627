import React from 'react';

const RelawanPenugasanTugas = () => {
    return (
        <div>
            RelawanPenugasanTugas
        </div>
    );
}

export default RelawanPenugasanTugas;
