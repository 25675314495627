import TpsKordinatProps, { TpsKordinatField } from "../Area/TpsKordinatProps";
import { PeriodeResponseDTO } from "./PeriodeResponseDTO"

export interface PelaporanPemungutanSuaraResponseDTO {
    periode: PeriodeResponseDTO[];
    tps: TpsKordinatProps;
}
export const PelaporanPemungutanSuaraFieldResponseDTO: PelaporanPemungutanSuaraResponseDTO = {
    periode: [],
    tps: TpsKordinatField
}