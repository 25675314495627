import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Outlet, useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import { LoginResponseProps } from '../../../models/Auth/LoginResponseProps';
import LoginRoleProps from '../../../models/Role/LoginRoleProps';
import { useSetRecoilState } from 'recoil';
import { AuthStoreState, RoleStoreState } from '../../../store';

const Index: React.FC = () => {
    const setRoleStore = useSetRecoilState<LoginRoleProps>(RoleStoreState);
    const setAuthStore = useSetRecoilState<LoginResponseProps>(AuthStoreState);
    const navigate = useNavigate();
    React.useEffect(() => {
        function getStarted () {
            const storedDataUser: string | null = localStorage.getItem('user');
            
            if (storedDataUser) {
                let tempUser : LoginResponseProps = JSON.parse(storedDataUser);

                const storedDataRole: string | null = localStorage.getItem('role');
                if (storedDataRole) {
                    let tempRole : LoginRoleProps = JSON.parse(storedDataRole);
                    setRoleStore(tempRole);
                }
                setAuthStore(tempUser);
                navigate('/home');
            }
        }
        getStarted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Navbar />
                <Container sx={{ marginY: '30px' }}>
                    <Outlet />
                </Container>
            <Footer />
        </>
    );
}

export default Index;
