export default interface MemberProps {
    member_id: string;
    ktp_id: string;
    email: string;
    no_hp: string;
    no_wa: string;
    avatar: string;
    status: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    hak_pilih: boolean;
}

export const MemberField: MemberProps = {
    member_id: '',
    ktp_id: '',
    email: '',
    no_hp: '',
    no_wa: '',
    avatar: '',
    status: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
    hak_pilih: false
}