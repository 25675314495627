import * as yup from 'yup';

export const PertanyaanValidateForm = yup.object().shape({
    role_id: yup.string().required('Role harus diisi'),
    member_id: yup.string().required('Member harus diisi'),
    title: yup.string().required('judul harus diisi'),
    subtitle: yup.string().required('Konten harus diisi'),
    priority: yup.string().required('Priority harus diisi'),
});

export const KategoriPertanyaanValidateForm = yup.object().shape({
    nama_kategori: yup.string().required('Nama Kategori harus diisi'),
    deskripsi: yup.string().required('Deskripsi harus diisi'),
});

export const JawabanValidateForm = yup.object().shape({
    pertanyaan_id: yup.string().required('Pertanyaan harus diisi'),
    role_id: yup.string().required('Role harus diisi'),
    member_id: yup.string().required('Member harus diisi'),
    title: yup.string().required('judul harus diisi'),
    subtitle: yup.string().required('Konten harus diisi'),
});