import React, { ChangeEvent } from 'react';
import { useRecoilValue } from 'recoil';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { ThemeStoreState } from '../../store';
import Swal from 'sweetalert2';
import { replaceItemAtIndex } from '../../Utils/helper';
import { CalonLegislatifValidateForm } from '../../validation/ForumValidateForm';
import { Box, Button, Container, Grid, Paper, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import ImageDefault from "../../asset/images/cat.jpg";
import { PartaiProps } from '../../models/Institution/PartaiProps';
import { PeriodeProps } from '../../models/Forums/PeriodeProps';
import { KategoriProps } from '../../models/Forums/KategoriProps';
import { JenisPemilihanProps } from '../../models/Forums/JenisPemilihanProps';
import PeriodeService from '../../services/Forum/PeriodeService';
import KategoriService from '../../services/Forum/KategoriService';
import JenisPemilihanService from '../../services/Forum/JenisPemilihanService';
import JenisTingkatPemilihanService from '../../services/Forum/JenisTingkatPemilihanService';
import { JenisTingkatPemilihanProps } from '../../models/Forums/JenisTingkatPemilihanProps';
import DialogPengelolaanCalonLegislatif from '../../pages/Superadmin/DialogPengelolaanCalonLegislatif';
import CalonLegislatifService from '../../services/PerhitunganSuara/CalonLegislatifService';
import { CalonLegislatifField, CalonLegislatifProps, ErrorCalonLegislatifField, ErrorCalonLegislatifProps } from '../../models/QuickCount/Senate/CalonLegislatifProps';
import PartaiService from '../../services/Institution/PartaiService';

const SuperadminPengelolaanCalonLegislatif = () => {
    // Calon Pemimpin 
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState);
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const [loadingSkeletonTable, setLoadingSkeletonTable] = React.useState<boolean>(false);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [titleDialog, setTitleDialog] = React.useState<string>('');
    const [calonLegislatifData, setCalonLegislatifData] = React.useState<CalonLegislatifProps[]>([]);
    const [calonLegislatifDataDetail, setCalonLegislatifDataDetail] = React.useState<CalonLegislatifProps>(CalonLegislatifField);
    const [errors, setErrors] = React.useState<ErrorCalonLegislatifProps>(ErrorCalonLegislatifField);
    const [image, setImage] = React.useState<File | null>(null);
    const [imagePreview, setImagePreview] = React.useState<string | null>(null);
    const [settingTable, setSettingTable] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'calonLegislatifId',
        search: '',
        totalElements: 0,
        totalPages: 0,
    });
    const [partaiData, setPartaiData] = React.useState<PartaiProps[]>([]);
    const [periodeData, setPeriodeData] = React.useState<PeriodeProps[]>([]);
    const [kategoriData, setKategoriData] = React.useState<KategoriProps[]>([]);
    const [jenisPemilihanData, setJenisPemilihanData] = React.useState<JenisPemilihanProps[]>([]);
    const [jenisTingkatPemilihanData, setJenisTingkatPemilihanData] = React.useState<JenisTingkatPemilihanProps[]>([]);
    const getPartai = async () => {
        if(partaiData.length === 0) {
            await PartaiService.getAllArr().then(res=> {
                setPartaiData(res.data);
            }).catch(err=>{setMessageSnackbar(err.response.data.message ?? "Tidak ada Jaringan");setOpenSnackbar(true);});
        }
    }
    const getPeriode = async () => {
        if(periodeData.length === 0) {
            await PeriodeService.getAllArr().then(res=> {
                setPeriodeData(res.data);
            }).catch(err=>{setMessageSnackbar(err.response.data.message ?? "Tidak ada Jaringan");setOpenSnackbar(true);});
        }
    }
    const getKategori = async (periodeId:string) => {
        await KategoriService.getAllArr(periodeId).then(res=> {
            setKategoriData(res.data);
        }).catch(err=>{setMessageSnackbar(err.response.data.message ?? "Tidak ada Jaringan");setOpenSnackbar(true);});
    }
    const getJenisPemilihan = async (kategoriId:string) => {
        await JenisPemilihanService.getAllArr(kategoriId).then(res=> {
            setJenisPemilihanData(res.data);
        }).catch(err=>{setMessageSnackbar(err.response.data.message ?? "Tidak ada Jaringan");setOpenSnackbar(true);});
    }
    const getJenisTingkatPemilihan = async(jenisPemilihanId: string) => {
        await JenisTingkatPemilihanService.getAllArr(jenisPemilihanId).then(res=> {
            setJenisTingkatPemilihanData(res.data);
        }).catch(err=>{setMessageSnackbar(err.response.data.message ?? "Tidak ada Jaringan");setOpenSnackbar(true);});
    }
    const getAll = async () => {
        setLoadingSkeletonTable(true);
        await CalonLegislatifService.getAll(settingTable.page, settingTable.size, settingTable.sort, settingTable.search).then(res => {
            setCalonLegislatifData(res.data.content);
            setSettingTable({...settingTable, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak ada jaringan");
            setOpenSnackbar(true);
        }).finally(() => {
            setLoadingSkeleton(false);
            setLoadingSkeletonTable(false);
        });
    }
    const openAdd = async () => {
        setCalonLegislatifDataDetail(CalonLegislatifField);
        setImage(null);
        setImagePreview(null);
        setErrors(ErrorCalonLegislatifField);
        setTitleDialog("Tambah Calon Legislatif");
        setOpenModal(true);
    }
    const openById = async (cp: CalonLegislatifProps) => {
        await getKategori(cp.periode_id);
        await getJenisPemilihan(cp.kategori_id);
        await getJenisTingkatPemilihan(cp.jenis_pemilihan_id);
        setCalonLegislatifDataDetail(cp);
        setImage(null);
        setImagePreview(null);
        setErrors(ErrorCalonLegislatifField);
        setTitleDialog("Ubah Calon Legislatif");
        setOpenModal(true);
    }
    function uploadImage(event: ChangeEvent<HTMLInputElement>) {
        const selectedFile = event.target.files?.[0] || null;
        setImage(selectedFile);

        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        }
    }
    const createCalonLegislatif = async () => {
        const newErrors: any = {};
        try {
            await CalonLegislatifValidateForm.validate(calonLegislatifDataDetail, {abortEarly:false});
            setErrors(ErrorCalonLegislatifField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            const data = new FormData();
            if(image) {
                data.append('image', image)
                await CalonLegislatifService.create(data, calonLegislatifDataDetail).then(res => {
                    setCalonLegislatifData([...calonLegislatifData, res.data]);
                    setSettingTable({...settingTable, totalElements: settingTable.totalElements + 1})
                    setMessageSnackbar("Calon Legislatif berhasil di tambah");
                    setOpenSnackbar(true);
                    setOpenModal(false);
                }).catch(err => {
                    setMessageSnackbar(err.response.data.message ?? "Calon Legislatif gagal di tambah");
                    setOpenSnackbar(true);
                })
            } else {
                setMessageSnackbar("Gambar Calon Perlu di Upload");
                setOpenSnackbar(true);
            }
        }
    }
    const updateCalonLegislatif = async () => {
        const newErrors: any = {};
        try {
            await CalonLegislatifValidateForm.validate(calonLegislatifDataDetail, {abortEarly:false});
            setErrors(ErrorCalonLegislatifField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            const data = new FormData();
            if(image) {
                data.append('image', image)
            } 
            await CalonLegislatifService.put(data, calonLegislatifDataDetail).then(res => {
                let index = calonLegislatifData.findIndex(p => p.calon_legislatif_id === calonLegislatifDataDetail.calon_legislatif_id);
                setCalonLegislatifData(replaceItemAtIndex(calonLegislatifData, index, res.data));
                setMessageSnackbar("Calon Legislatif berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Calon Legislatif gagal di ubah");
                setOpenSnackbar(true);
            })
        }
    }
    const deleteCalonLegislatif = async (cp: CalonLegislatifProps) => {
        Swal.fire({
            title: 'Hapus Calon Legislatif',
            text: 'Apakah anda ingin menghapus Calon Legislatif ' + cp.nama_calon + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                CalonLegislatifService.delete(cp.calon_legislatif_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Calon Legislatif "+ cp.nama_calon +" sudah dihapus.",
                        icon: "success"
                    });
                    setCalonLegislatifData(calonLegislatifData.filter(p => p.calon_legislatif_id !== cp.calon_legislatif_id));
                    setSettingTable({...settingTable, totalElements: settingTable.totalElements - 1})
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: err.response.data.message ?? "Calon Legislatif "+ cp.nama_calon +" gagal dihapus.",
                        icon: "error"
                    });
                })
            }
        });
    }
    const handleChangePage = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable,  page: newPage});
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTable({...settingTable, page: 0, size: +event.target.value});
    };

    React.useEffect(() => {
        getPeriode();
        getPartai();
        getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingTable.page,settingTable.size,settingTable.sort,settingTable.search]);

    return loadingSkeleton ? (<Container>
        <Skeleton animation="wave" width={'100%'} height={'576px'} variant='rounded'></Skeleton>
    </Container>) : (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: '10px' }}>
                    <Grid container spacing={2} sx={{ paddingBottom: '5px' }}>
                        <Grid item xs={8} sm={8}>
                            <Typography variant='h3' fontSize={'24px'}>Calon Legislatif</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} display={'flex'} justifyContent={'flex-end'}>
                            <Button startIcon={<Add />} onClick={() => openAdd()} title='tambah' color='info' variant='contained'>Tambah</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {
                loadingSkeletonTable ? <Skeleton animation={'wave'} width={'100%'} height={'500px'} /> :
                <Grid item xs={12} sm={12} md={12}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 540 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Gambar</TableCell>
                                        <TableCell>Nama Partai</TableCell>
                                        <TableCell>Nama Calon</TableCell>
                                        <TableCell>Aksi</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    calonLegislatifData.length !== 0 ? calonLegislatifData.map(p => 
                                    <TableRow key={p.calon_legislatif_id}>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <img src={p.gambar !== '' ? p.gambar : ImageDefault} alt={p.nama_calon} width={'100%'} height={'100vh'} />
                                            </Box>
                                        </TableCell>
                                        <TableCell>{partaiData.find(x => x.partai_id === p.partai_id)?.nama_partai}</TableCell>
                                        <TableCell>{p.nama_calon}</TableCell>
                                        <TableCell>
                                            <Button sx={{ marginLeft: '5px' }} onClick={() => openById(p)} size='small' variant='contained' startIcon={<Edit />} color='success'>Ubah</Button>
                                            <Button sx={{ marginLeft: '5px' }} onClick={() => deleteCalonLegislatif(p)} size='small' variant='contained' startIcon={<Delete />} color='error'>Hapus</Button>
                                        </TableCell>
                                    </TableRow> 
                                    )
                                    : <TableRow>
                                        <TableCell colSpan={4}>
                                            <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                        component="div"
                        count={settingTable.totalElements}
                        rowsPerPage={settingTable.size}
                        page={settingTable.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Paper>
                </Grid>
                }
                <Snackbar
                    open={openSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={3000}
                    onClose={() => setOpenSnackbar(false)}
                    message={messageSnackbar}
                />
                <DialogPengelolaanCalonLegislatif
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    theme={theme}
                    titleDialog={titleDialog}
                    errors={errors}
                    setErrors={setErrors}
                    calonLegislatifDataDetail={calonLegislatifDataDetail}
                    setCalonLegislatifDataDetail={setCalonLegislatifDataDetail}
                    createCalonLegislatif={createCalonLegislatif}
                    updateCalonLegislatif={updateCalonLegislatif}
                    image={image}
                    setImage={setImage}
                    imagePreview={imagePreview}
                    setImagePreview={setImagePreview}
                    uploadImage={uploadImage}
                    ImageDefault={ImageDefault}
                    partaiData={partaiData}
                    periodeData={periodeData}
                    kategoriData={kategoriData}
                    jenisPemilihanData={jenisPemilihanData}
                    jenisTingkatPemilihanData={jenisTingkatPemilihanData}
                    getPeriode={getPeriode}
                    getKategori={getKategori}
                    getJenisPemilihan={getJenisPemilihan}
                    getJenisTingkatPemilihan={getJenisTingkatPemilihan}
                />
            </Grid>
    );
}

export default SuperadminPengelolaanCalonLegislatif;
