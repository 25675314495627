import axios, { AxiosResponse } from 'axios';
import {ProvinsiProps} from '../../models/Area/ProvinsiProps';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';

class ProvinsiServices {
    async getAll(page: number, size: number, sort: string, search: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/area/provinsi?page=${page}&size=${size}&sort=${sort}&search=${search}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllArray(): Promise<AxiosResponse<ProvinsiProps[]>> {
        return axios.get(`/api/v1/area/provinsi/arr`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(provinsiId: string): Promise<AxiosResponse<ProvinsiProps>> {
        return axios.get(`/api/v1/area/provinsi/${provinsiId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: ProvinsiProps): Promise<AxiosResponse<ProvinsiProps>> {
        return axios.post(`/api/v1/area/provinsi`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(provinsiId: string, data : ProvinsiProps): Promise<AxiosResponse<ProvinsiProps>> {
        return axios.put(`/api/v1/area/provinsi/${provinsiId}`, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async delete(provinsiId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/area/provinsi/${provinsiId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ProvinsiServices();