export interface ProfileResponseProps {
    member_id: string;
    nik: string;
    nama: string;
    jenis_kelamin: string;
    tempat_lahir: string;
    tanggal_lahir: string;
    alamat: string;
    kode_pos: string;
    avatar: string;
    email: string;
    no_hp: string;
    no_wa: string;
    hak_pilih: boolean;
    username: string;
}

export const ProfileResponseField : ProfileResponseProps = {
    member_id: "",
    nik: "",
    nama: "",
    jenis_kelamin: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    alamat: "",
    kode_pos: "",
    avatar: "",
    email: "",
    no_hp: "",
    no_wa: "",
    hak_pilih: false,
    username: ""
}

export interface ErrorProfileResponseProps {
    member_id: string | null;
    nik: string | null;
    nama: string | null;
    jenis_kelamin: string | null;
    tempat_lahir: string | null;
    tanggal_lahir: string | null;
    alamat: string | null;
    kode_pos: string | null;
    email: string | null;
    no_hp: string | null;
    no_wa: string | null;
    avatar: string | null;
    hak_pilih: string | null;
    username: string | null;
}

export const ErrorProfileResponseField: ErrorProfileResponseProps = {
    member_id: null,
    nik: null,
    nama: null,
    jenis_kelamin: null,
    tempat_lahir: null,
    tanggal_lahir: null,
    alamat: null,
    kode_pos: null,
    email: null,
    avatar: null,
    no_hp: null,
    no_wa: null,
    hak_pilih: null,
    username: null,
}