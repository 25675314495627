import React from 'react';

const SuperadminPengaturanSistem = () => {
    return (
        <div>
            SuperadminPengaturanSistem
        </div>
    );
}

export default SuperadminPengaturanSistem;
