import { AlertColor } from "@mui/material";

export interface AlertProps {
    open: boolean, 
    message: string,
    severity: AlertColor,
}

export const AlertField: AlertProps = {
    open: false,
    message: "",
    severity: 'error'
}