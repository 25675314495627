import { Box, Grid, Paper, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import ThemeStoreProps from "../../models/Store/ThemeStoreProps";
import { ThemeStoreState } from "../../store";

const Admin = () => {
    const data = {
        'dashboard': [
            {
                field: 'Relawan', 
                value: 12123
            },
            {
                field: 'Saksi', 
                value: 432
            },
            {
                field: 'Konstituen', 
                value: 12345
            },
            {
                field: 'Admin', 
                value: 543
            },
        ]
    }

    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState);
    return (
        <Grid container spacing={2}>
            {
                data.dashboard.map(d => 
                    <Grid item xs={12} sm={12} md={3} key={d.field}>
                        <Box component={Paper} elevation={2} sx={{ backgroundColor: theme.backgroundColor, color: theme.color, paddingY: '5px', paddingX: '20px' }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{d.field}</Typography>
                                    <Typography>{d.value}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                )
            }
        </Grid>
    );
}

export default Admin;
