import * as yup from 'yup';

export const PeriodeValidateForm = yup.object().shape({
    tahun: yup.string().required("Tahun harus diisi").matches(/^\d{4}$/, 'Tahun harus terdiri dari 4 Angka'),
    deskripsi: yup.string(),
});

export const KategoriValidateForm = yup.object().shape({
    nama_kategori: yup.string().required("Nama Kategori harus diisi").max(128, "Nama Kategori Maksimal 128 Karakter"),
    deskripsi: yup.string(),
});

export const JenisPemilihanValidateForm = yup.object().shape({
    nama_pemilihan: yup.string().required("Nama Pemilihan harus diisi").max(128, "Nama Pemilihan Maksimal 128 Karakter"),
    deskripsi: yup.string(),
});

export const JenisTingkatPemilihanValidateForm = yup.object().shape({
    nama_tingkat: yup.string().required("Nama Tingkat harus diisi").max(128, "Nama Tingkat Maksimal 128 Karakter"),
    deskripsi: yup.string(),
});

export const CalonPemimpinValidateForm = yup.object().shape({
    nama_calon: yup.string().required("Nama Calon Pemimpin harus diisi").max(255, "Nama Calon Pemimpin Maksimal 255 Karakter"),
    nama_wakil_calon: yup.string().required("Nama Wakil Calon Pemimpin harus diisi").max(255, "Nama Wakil Calon Pemimpin Maksimal 255 Karakter"),
    kabinet: yup.string().required("Nama Kabinet harus diisi").max(255, "Nama Kabinet Maksimal 255 Karakter"),
});

export const CalonLegislatifValidateForm = yup.object().shape({
    nama_calon: yup.string().required("Nama Calon Legislatif harus diisi").max(255, "Nama Calon Legislatif Maksimal 255 Karakter"),
});