import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Skeleton, Snackbar, Step, StepContent, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, styled } from '@mui/material';
import React from 'react';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { AuthStoreState, ThemeStoreState } from '../../store';
import { useRecoilValue } from 'recoil';
import { Add, Cancel, Close, ContentCopy, Person2Outlined, QrCode2Outlined } from '@mui/icons-material';
import KonstituenAnda from '../../models/Relawan/KonstituenAnda';
import RelawanService from '../../services/Member/RelawanService';
import { LoginResponseProps } from '../../models/Auth/LoginResponseProps';
import DialogDetailKonstituen from '../../pages/Relawan/DialogDetailKonstituen';
import RelawanDetailKonstituen, { RelawanDetailKonstituenField } from '../../models/Relawan/RelawanDetailKonstituen';
import { ProvinsiProps } from '../../models/Area/ProvinsiProps';
import { KabupatenProps } from '../../models/Area/KabupatenProps';
import { KecamatanProps } from '../../models/Area/KecamatanProps';
import { DesaProps } from '../../models/Area/DesaProps';
import { RwProps } from '../../models/Area/RwProps';
import { RtProps } from '../../models/Area/RtProps';
import { TpsProps } from '../../models/Area/TpsProps';
import ProvinsiService from '../../services/Area/ProvinsiService';
import KabupatenService from '../../services/Area/KabupatenService';
import KecamatanService from '../../services/Area/KecamatanService';
import DesaService from '../../services/Area/DesaService';
import RwService from '../../services/Area/RwService';
import RtService from '../../services/Area/RtService';
import TpsService from '../../services/Area/TpsService';
import { ErrorRegisterRequestField, ErrorRegisterRequestProps, RegisterRequestField, RegisterRequestProps } from '../../models/Auth/RegisterRequestProps';
import { RegistrationValidateForm } from '../../validation/RegistrationValidateForm';
import AuthServices from '../../services/Auth/AuthServices';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const PopupTambahKonstituen:React.FC<{kodeReferensiSaya: string; openModal: boolean; theme: ThemeStoreProps, setOpenModal: React.Dispatch<React.SetStateAction<boolean>>, copyTextToClipboard: any}> = ({kodeReferensiSaya, openModal, setOpenModal, theme, copyTextToClipboard}) => {
    return (
        <BootstrapDialog
            onClose={() => setOpenModal(false)}
            aria-labelledby="customized-dialog-title"
            open={openModal}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">Kode Referensi</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpenModal(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close />
            </IconButton>
            <DialogContent dividers>
                <Typography variant='h6'>Kode Referensi Anda</Typography>
                <Box sx={{ width:'100%', padding: '10px',backgroundColor: theme.backgroundColor, color: theme.color }}>
                    <Typography variant='h3' sx={{ fontSize: '20px', textAlign: 'center', fontWeight: 'bold' }}>
                        {kodeReferensiSaya}<Button disableRipple disableElevation disableFocusRipple disableTouchRipple onClick={() => copyTextToClipboard(kodeReferensiSaya)}><ContentCopy /></Button>
                    </Typography>
                </Box>
                <Typography variant='h6' sx={{ marginTop:'10px' }}>Menambah Konstituen menggunakan kode Referensi</Typography>
                <Stepper orientation="vertical">
                    {[
                        {
                            label: 'Ajak Masyarakat',
                            description: `Saudara, Kerabat, Teman atau Kenalan diajak untuk bergabung dan mendaftar ke sistem informasi pemilu terpadu yang sangat aman, di mana semua data Anda terjaga di pusat data kami.`,
                        },
                        {
                            label: 'Gunakan Kode Referensi',
                            description: 'Referensi kode '+ kodeReferensiSaya +' digunakan oleh relawan saat konstituen mendaftar dengan mengisi kolom Kode Referensi di halaman pendaftaran.',
                        },
                        {
                            label: 'Selesai',
                            description: `Selamat, Anda telah berhasil mendapatkan anggota konstituen. Perbanyak jumlah anggota agar aktivitas Anda semakin meningkat.`,
                        },
                    ].map((step) => (
                    <Step expanded key={step.label}>
                        <StepLabel>{step.label}</StepLabel>
                        <StepContent>
                            <Typography variant='h6' sx={{ fontSize: '16px', textAlign: 'justify' }}>{step.description}</Typography>
                        </StepContent>
                    </Step>
                    ))}
                </Stepper>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant='contained' color='error' startIcon={<Cancel />} onClick={() => setOpenModal(false)}>
                    Tutup
                </Button>
            </DialogActions>
        </BootstrapDialog>
    )
}

const RelawanPendaftaranRelawan = () => {
    const [verifikasiNik, setVerifikasiNik] = React.useState<{nik: string; verified: boolean}>({nik: '', verified: false});
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const [loadingSkeletonTable, setLoadingSkeletonTable] = React.useState<boolean>(true);
    const [openModalAdd, setOpenModalAdd] = React.useState<boolean>(false);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [detailKonstituen, setDetailKonstituen] = React.useState<RelawanDetailKonstituen>(RelawanDetailKonstituenField);
    const authStore = useRecoilValue<LoginResponseProps>(AuthStoreState);
    const [kodeReferensiSaya, setKodeReferensiSaya] = React.useState<string>('');
    const [titleDialog, setTitleDialog] = React.useState<string>('');
    const [konstituenData, setKonstituenData] = React.useState<KonstituenAnda[]>([]);

    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState)
    const [settingTable, setSettingTable] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        provinsiId: string;
        kabupatenId: string;
        kecamatanId: string;
        desaId: string;
        rwId: string;
        rtId: string;
        tpsId: string;
        hakPilih: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'konstituenId',
        search: '',
        provinsiId: '',
        kabupatenId: '',
        kecamatanId: '',
        desaId: '',
        rwId: '',
        rtId: '',
        tpsId: '',
        hakPilih: 'null',
        totalElements: 0,
        totalPages: 0,
    });
    const [provinsiData, setProvinsiData] = React.useState<ProvinsiProps[]>([]);
    const [kabupatenData, setKabupatenData] = React.useState<KabupatenProps[]>([]);
    const [kecamatanData, setKecamatanData] = React.useState<KecamatanProps[]>([]);
    const [desaData, setDesaData] = React.useState<DesaProps[]>([]);
    const [rwData, setRwData] = React.useState<RwProps[]>([]);
    const [rtData, setRtData] = React.useState<RtProps[]>([]);
    const [tpsData, setTpsData] = React.useState<TpsProps[]>([]);

    // Tambah Anggota 
    const [register, setRegister] = React.useState<RegisterRequestProps>(RegisterRequestField);
    const [errors, setErrors] = React.useState<ErrorRegisterRequestProps>(ErrorRegisterRequestField);
    const [checkBoxHelper, setCheckBoxHelper] = React.useState<boolean>(false);

    const helperCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckBoxHelper(event.target.checked);
        if(event.target.checked) {
            setRegister({...register, no_wa: register.no_hp});
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await RegistrationValidateForm.validate(register, {abortEarly: false});
            setErrors(ErrorRegisterRequestField);
            JSON.stringify(register);
        } catch(error: any) {
            const newErrors: any = {};
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }

        if(errors.nik === null && errors.nama === null && errors.jenis_kelamin === null && errors.tempat_lahir === null && errors.tanggal_lahir === null && errors.alamat === null && errors.kode_pos === null && errors.email === null && errors.no_hp === null && errors.no_wa === null && errors.hak_pilih === null && errors.username === null && errors.password === null && errors.nama_partai === null) {
            await AuthServices.register({...register, referensi_kode: kodeReferensiSaya}).then(res => {
                setSettingTable({...settingTable, totalElements: settingTable.totalElements + 1});
                setKonstituenData([...konstituenData, {
                    member_id: res.data.member_id,
                    avatar: '',
                    nama: res.data.nama,
                    username: res.data.username,
                    no_hp: res.data.no_hp,
                    no_wa: res.data.no_wa,
                    tempat_lahir: res.data.tempat_lahir,
                    tanggal_lahir: res.data.tanggal_lahir
                }])
                setMessageSnackbar('Konstituen Berhasil di tambah');
                setOpenSnackbar(true);
                setRegister(RegisterRequestField);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar(err.response.data.message ?? 'Konstituen Gagal ditambah');
                setOpenSnackbar(true);
            })
        }
    };

    React.useEffect(() => {
        setTimeout(() => {
            getFindKonstituen();
            getProvinsi();
            getReferensiSaya();
        }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingTable.page, settingTable.search, settingTable.size, settingTable.sort, settingTable.provinsiId, settingTable.kabupatenId, settingTable.kecamatanId, settingTable.desaId, settingTable.rwId, settingTable.rtId, settingTable.tpsId, settingTable.hakPilih]);

    const copyTextToClipboard = async (text: string) => {
        if (navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(text);
                setMessageSnackbar("Kode Referensi Sudah disalin ke clipboard");
                setOpenSnackbar(true);
            } catch (err) {
                setMessageSnackbar("Could not copy text: " + err);
                setOpenSnackbar(true);
            }
        }
    };
    const getFindKonstituen = async () => {
        setLoadingSkeletonTable(true);
        await RelawanService.findAllKonstituen(settingTable.page, settingTable.size, settingTable.sort, settingTable.search, null, authStore.member_id, settingTable.provinsiId, settingTable.kabupatenId, settingTable.kecamatanId, settingTable.desaId, settingTable.rwId, settingTable.rtId, settingTable.tpsId, settingTable.hakPilih).then(res => {
            setKonstituenData(res.data.content)
            setSettingTable({...settingTable, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
        })
        setLoadingSkeletonTable(false);
    }
    const handleChangePage = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable,  page: newPage});
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTable({...settingTable, page: 0, size: +event.target.value});
    };
    const getDetail = async (user: KonstituenAnda) => {
        await RelawanService.detailKonstituen(user.member_id).then(res => {
            setDetailKonstituen(res.data);
            setTitleDialog("Detail Konstituen " + user.nama);
            setOpenModal(true);
        }).catch(err => {
            setMessageSnackbar("Error pada Jaringan Anda");
            setOpenSnackbar(true);
        });
    }
    const getReferensiSaya = async () => {
        if(kodeReferensiSaya === '') {
            await RelawanService.getKodeReferensi(authStore.member_id).then(res => {
                setKodeReferensiSaya(res.data); 
                setRegister({...register, referensi_kode: res.data});
            }).catch(err => {
                setMessageSnackbar("Error pada Jaringan");
                setOpenSnackbar(true);
            });
        }
    }
    function getProvinsi() {
        if(provinsiData.length === 0) {
            setLoadingSkeleton(true);
            ProvinsiService.getAllArray().then(res => setProvinsiData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
            setLoadingSkeleton(false);
        }
    }
    function getKabupaten(id?: string | null) {
        setLoadingSkeleton(true);
        KabupatenService.getAllArray(id == null ? settingTable.provinsiId : id).then(res => setKabupatenData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getKecamatan(id?: string | null) {
        setLoadingSkeleton(true);
        KecamatanService.getAllArray(id == null ? settingTable.kabupatenId : id).then(res => setKecamatanData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getDesa(id?: string | null) {
        setLoadingSkeleton(true);
        DesaService.getAllArray(id == null ? settingTable.kecamatanId : id).then(res => setDesaData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getRw(id?: string | null) {
        setLoadingSkeleton(true);
        RwService.getAllArr(id == null ? settingTable.desaId : id).then(res => setRwData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getRt(id?: string | null) {
        setLoadingSkeleton(true);
        RtService.getAllArr(id == null ? settingTable.rwId : id).then(res => setRtData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getTps(rwid?: string | null, rtid?: string | null) {
        setLoadingSkeleton(true);
        TpsService.getAllArr(rwid == null ? settingTable.rwId : rwid, rtid == null ? settingTable.rtId : rtid).then(res => setTpsData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    const clearFilter = () => {
        setSettingTable({
            ...settingTable, 
            provinsiId: '',
            kabupatenId: '',
            kecamatanId: '',
            desaId: '',
            rwId: '',
            rtId: '',
            tpsId: '',
            search: '',
            hakPilih: 'null'
        });
        setKabupatenData([]);
        setKecamatanData([]);
        setDesaData([]);
        setRwData([]);
        setRtData([]);
        setTpsData([]);
    }
    function openAdd() {
        setTitleDialog('Tambah Konstituen Anda');
        setRegister(RegisterRequestField);
        setVerifikasiNik({nik: '', verified: false});
        setErrors(ErrorRegisterRequestField);
        setOpenModal(true);
    }

    return loadingSkeleton ? (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                        <Skeleton width={'100%'} height={'50px'} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Skeleton width={'100%'} height={'50px'} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Skeleton width={'100%'} height={'500px'} />
            </Grid>
        </Grid>
    ) : (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <Typography variant='h5' sx={{ justifyContent: 'center', alignItem: 'center' }}>Konstituen Anda</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button variant='contained' sx={{ backgroundColor: theme.backgroundColor, color: theme.color }}  onClick={() => setOpenModalAdd(true)} startIcon={<QrCode2Outlined />} color='inherit'>Referensi</Button>
                        <Button variant='contained' sx={{ marginLeft: '5px', backgroundColor: theme.backgroundColor, color: theme.color }}  onClick={() => openAdd()} startIcon={<Add />} color='inherit'>Tambah</Button>
                        <PopupTambahKonstituen kodeReferensiSaya={kodeReferensiSaya} openModal={openModalAdd} setOpenModal={setOpenModalAdd} copyTextToClipboard={copyTextToClipboard} theme={theme} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2} sx={{ paddingTop: '50px', paddingBottom: '20px' }}>
                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="provinsi-select">Provinsi</InputLabel>
                            <Select
                                labelId="provinsi-select"
                                id="provinsi_id"
                                value={settingTable.provinsiId}
                                disabled={provinsiData.length === 0}
                                label="Provinsi"
                                onChange={(e) => {setSettingTable({...settingTable, provinsiId: e.target.value, kecamatanId: '', kabupatenId: '', desaId: '', rwId: '', rtId: '', tpsId: ''}); getKabupaten(e.target.value);}}
                            >
                                {
                                    provinsiData.map(p => <MenuItem key={p.provinsi_id} value={p.provinsi_id}>{p.nama_provinsi}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="kabupaten-select">Kabupaten</InputLabel>
                            <Select
                                labelId="kabupaten-select"
                                id="kabupaten_id"
                                value={settingTable.kabupatenId}
                                disabled={kabupatenData.length === 0}
                                label="Kabupaten"
                                onChange={(e) => {setSettingTable({...settingTable, kabupatenId: e.target.value, kecamatanId: '', desaId: '', rwId: '', rtId: '', tpsId: ''}); getKecamatan(e.target.value)}}
                            >
                                {
                                    kabupatenData.map(p => <MenuItem key={p.kabupaten_id} value={p.kabupaten_id}>{p.nama_kabupaten}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="kecamatan-select">Kecamatan</InputLabel>
                            <Select
                                labelId="kecamatan-select"
                                id="kecamatan_id"
                                value={settingTable.kecamatanId}
                                disabled={kecamatanData.length === 0}
                                label="Kecamatan"
                                onChange={(e) => {setSettingTable({...settingTable, kecamatanId: e.target.value, desaId: '', rwId: '', rtId: '', tpsId: ''}); getDesa(e.target.value)}}
                            >
                                {
                                    kecamatanData.map(p => <MenuItem key={p.kecamatan_id} value={p.kecamatan_id}>{p.nama_kecamatan}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="desa-select">Desa</InputLabel>
                            <Select
                                labelId="desa-select"
                                id="desa_id"
                                value={settingTable.desaId}
                                disabled={desaData.length === 0}
                                label="Desa"
                                onChange={(e) => {setSettingTable({...settingTable, desaId: e.target.value, rwId: '', rtId: '', tpsId: ''}); getRw(e.target.value);}}
                            >
                                {
                                    desaData.map(p => <MenuItem key={p.desa_id} value={p.desa_id}>{p.nama_desa}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="rw-select">RW</InputLabel>
                            <Select
                                labelId="rw-select"
                                id="rw_id"
                                value={settingTable.rwId}
                                disabled={rwData.length === 0}
                                label="RW"
                                onChange={(e) => {setSettingTable({...settingTable, rwId: e.target.value, rtId: '', tpsId: ''}); getRt(e.target.value); getTps(e.target.value, null)}}
                            >
                                {
                                    rwData.map(p => <MenuItem key={p.rw_id} value={p.rw_id}>{p.nama_rw}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="rt-select">RT</InputLabel>
                            <Select
                                labelId="rt-select"
                                id="rt_id"
                                value={settingTable.rtId}
                                disabled={rtData.length === 0}
                                label="RT"
                                onChange={(e) => {setSettingTable({...settingTable, rtId: e.target.value, tpsId: ''}); getTps(settingTable.rwId, e.target.value)}}
                            >
                                {
                                    rtData.map(p => <MenuItem key={p.rt_id} value={p.rt_id}>{p.nama_rt}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="tps-select">TPS</InputLabel>
                            <Select
                                labelId="tps-select"
                                id="tps_id"
                                value={settingTable.tpsId}
                                disabled={tpsData.length === 0}
                                label="TPS"
                                onChange={(e) => setSettingTable({...settingTable, tpsId: e.target.value})}
                            >
                                {
                                    tpsData.map(p => <MenuItem key={p.tps_id} value={p.tps_id}>{p.nama_tps}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="hak_pilih-select">Hak Pilih</InputLabel>
                            <Select
                                labelId="hak_pilih-select"
                                id="hak_pilih_id"
                                value={settingTable.hakPilih}
                                label="Hak Pilih Member"
                                onChange={(e) => setSettingTable({...settingTable, hakPilih: e.target.value})}
                            >
                                <MenuItem value={'null'}>{'Semua Hak Pilih'}</MenuItem>
                                <MenuItem value={'true'}>{'Punya Hak Milik'}</MenuItem>
                                <MenuItem value={'false'}>{'Tidak ada Hak Pilih'}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        settingTable.provinsiId !== '' ? 
                    <Grid item xs={12} sm={12} md={4} lg={3} justifyContent={'center'} alignItems={'center'}>
                        <Button color='error' variant='contained' title='Bersihkan Filter' startIcon={<Cancel />} onClick={() => clearFilter()}>Clear Filter</Button>
                    </Grid> : <></>
                    }
                </Grid>
            </Grid>
            {
                loadingSkeletonTable ? <Skeleton width={'100%'} height={'500px'} /> :
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    {
                        loadingSkeletonTable ? <Skeleton width={'100%'} height={'500px'} /> :
                        <Grid item xs={12} sm={12} md={12}>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 540 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nama</TableCell>
                                                <TableCell>Nomor HP</TableCell>
                                                <TableCell>TTL</TableCell>
                                                <TableCell>Username</TableCell>
                                                <TableCell>Aksi</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                            konstituenData.length !== 0 ? konstituenData.map(p => 
                                                <TableRow key={p.member_id}>
                                                    <TableCell>{p.nama}</TableCell>
                                                    <TableCell>{p.no_hp}</TableCell>
                                                    <TableCell>{p.tempat_lahir + ', ' + p.tanggal_lahir}</TableCell>
                                                    <TableCell>{p.username}</TableCell>
                                                    <TableCell><Button sx={{ marginLeft: '5px' }} onClick={() => getDetail(p)} size='small' variant='contained' startIcon={<Person2Outlined />} color='primary'>Detail</Button></TableCell>
                                                </TableRow> 
                                                )
                                            : <TableRow>
                                                <TableCell colSpan={6}>
                                                    <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                                </TableCell>
                                            </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                    component="div"
                                    count={settingTable.totalElements}
                                    rowsPerPage={settingTable.size}
                                    page={settingTable.page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                        }
                </Grid>
            </Grid>
            }
            <DialogDetailKonstituen
                titleDialog={titleDialog}
                openModal={openModal}
                setOpenModal={setOpenModal}
                theme={theme}
                detailKonstituen={detailKonstituen}
                setDetailKonstituen={setDetailKonstituen} 
                handleSubmit={handleSubmit}
                register={register}
                setRegister={setRegister}
                errors={errors}
                setErrors={setErrors}
                checkBoxHelper={checkBoxHelper}
                helperCheckbox={helperCheckbox}
                setCheckBoxHelper={setCheckBoxHelper}
                setOpenSnackbar={setOpenSnackbar}
                setMessageSnackbar={setMessageSnackbar}
                verifikasiNik={verifikasiNik}
                setVerifikasiNik={setVerifikasiNik}
            />
            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                message={messageSnackbar}
            />
        </Grid>
    );
}

export default RelawanPendaftaranRelawan;
