import { Close, HowToReg, Lock, LockOpen, SaveAs, TextFields } from '@mui/icons-material';
import { Alert, AppBar, Box, Button, Container, Dialog, Grid, IconButton, Slide, Stack, TextField, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { ErrorMonitoringProps, MonitoringFilesProps, MonitoringProps } from '../../models/Monitoring/MonitoringProps';
import {
    LinkBubbleMenu,
    MenuButton,
    MenuButtonAddTable,
    MenuButtonBlockquote,
    MenuButtonBold,
    MenuButtonBulletedList,
    MenuButtonCode,
    MenuButtonCodeBlock,
    MenuButtonEditLink,
    MenuButtonHighlightColor,
    MenuButtonHorizontalRule,
    MenuButtonIndent,
    MenuButtonItalic,
    MenuButtonOrderedList,
    MenuButtonRedo,
    MenuButtonRemoveFormatting,
    MenuButtonStrikethrough,
    MenuButtonSubscript,
    MenuButtonSuperscript,
    MenuButtonTaskList,
    MenuButtonTextColor,
    MenuButtonUnderline,
    MenuButtonUndo,
    MenuButtonUnindent,
    MenuControlsContainer,
    MenuDivider,
    MenuSelectFontFamily,
    MenuSelectFontSize,
    MenuSelectHeading,
    MenuSelectTextAlign,
    RichTextEditor,
    TableBubbleMenu,
    insertImages,
    isTouchDevice,
} from "mui-tiptap";
import type { EditorOptions } from "@tiptap/core";
import useExtensions from '../../Utils/useExtension';

interface DialogDetailActivityProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    theme: ThemeStoreProps;
    titleDialog: string;
    handleSubmit: any;
    updateMonitoring: any;
    registerFormMonitoring: MonitoringProps;
    setRegisterFormMonitoring: React.Dispatch<React.SetStateAction<MonitoringProps>>;
    errorFormMonitoring: ErrorMonitoringProps;
    setErrorFormMonitoring: React.Dispatch<React.SetStateAction<ErrorMonitoringProps>>;
    monitoringFilesData: MonitoringFilesProps[];
    setMonitoringFilesData: React.Dispatch<React.SetStateAction<MonitoringFilesProps[]>>;
    rteRef: any;
    uploadImage: any;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogDetailActivity: React.FC<DialogDetailActivityProps> = ({
    openModal,
    setOpenModal,
    theme, 
    titleDialog,
    handleSubmit,
    updateMonitoring, 
    registerFormMonitoring,
    setRegisterFormMonitoring,
    errorFormMonitoring,
    setErrorFormMonitoring,
    monitoringFilesData,
    setMonitoringFilesData,
    rteRef,
    uploadImage
}) => {
    const today = dayjs();
    const [isEditable, setIsEditable] = React.useState(true);
    const [showMenuBar, setShowMenuBar] = React.useState(true);

    const extensions = useExtensions({
        placeholder: "Isi Aktivitas anda disini...",
    });

    function fileListToImageFiles(fileList: FileList): File[] {
        return Array.from(fileList).filter((file) => {
            const mimeType = (file.type || "").toLowerCase();
            return mimeType.startsWith("image/");
        });
    }

    const handleNewImageFiles = useCallback((files: File[], insertPosition?: number): void => {
        if (!rteRef.current?.editor) { return; }

        const attributesForImageFiles = files.map((file) => ({
            src: URL.createObjectURL(file),
            alt: file.name,
        }));
    
        insertImages({
            images: attributesForImageFiles,
            editor: rteRef.current.editor,
            // insertPosition
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDrop: NonNullable<EditorOptions["editorProps"]["handleDrop"]> = useCallback((view, event, _slice, _moved) => {
        if (!(event instanceof DragEvent) || !event.dataTransfer) {
            return false;
        }

        const imageFiles = fileListToImageFiles(event.dataTransfer.files);
        if (imageFiles.length > 0) {
            const insertPosition = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
            })?.pos;

            handleNewImageFiles(imageFiles, insertPosition);

          // Return true to treat the event as handled. We call preventDefault
          // ourselves for good measure.
            event.preventDefault();
            return true;
        }

        return false;
    },[handleNewImageFiles]);

    const handlePaste: NonNullable<EditorOptions["editorProps"]["handlePaste"]> = useCallback((_view, event, _slice) => {
        if (!event.clipboardData) {
            return false;
        }

        const pastedImageFiles = fileListToImageFiles(
            event.clipboardData.files,
        );
        if (pastedImageFiles.length > 0) {
            handleNewImageFiles(pastedImageFiles);
            return true;
        }
        return false;
    },[handleNewImageFiles]);
    
    return (
        <Dialog
            fullScreen
            open={openModal}
            onClose={() => setOpenModal(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setOpenModal(false)}
                    aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {titleDialog}
                    </Typography>
                    <Button autoFocus startIcon={<Close />} color="inherit" onClick={() => setOpenModal(false)}>
                        Tutup
                    </Button>
                </Toolbar>
            </AppBar>
            {
                titleDialog.split(' ').includes('Detail') ? 
            <Container>
                <Grid container spacing={3} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={12} md={10} lg={8}>
                        <Typography variant='h6' sx={{ marginTop: '20px' }}>Informasi Referensi</Typography>
                    </Grid>
                </Grid>
            </Container> : <Box sx={{ paddingTop: '10px' }}>
                <Grid container spacing={2} sx={{ marginTop: '10px', marginBottom: '20px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={12} md={12} lg={8}>
                        <Box component="form" noValidate onSubmit={titleDialog.split(' ').includes('Tambah') ? handleSubmit : updateMonitoring} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="nama"
                                        label="Nama"
                                        name="nama"
                                        autoComplete="nama"
                                        onChange={(e) => setRegisterFormMonitoring({...registerFormMonitoring, nama_aktivitas: e.target.value})}
                                        value={registerFormMonitoring.nama_aktivitas}
                                    />
                                    {(errorFormMonitoring.nama_aktivitas != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormMonitoring.nama_aktivitas}</Typography> : <></>}
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            defaultValue={registerFormMonitoring.tanggal !== '' ? dayjs(registerFormMonitoring.tanggal) : today}
                                            label="Tanggal"
                                            name='tanggal'
                                            sx={{ width: '100%' }}
                                            disableFuture
                                            views={['year', 'month', 'day']}
                                            onChange={(e) => setRegisterFormMonitoring({...registerFormMonitoring, tanggal: (e?.format('YYYY-MM-DD') ?? today.format('YYYY-MM-DD'))})}
                                        />
                                    </LocalizationProvider>
                                    {(errorFormMonitoring.tanggal != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormMonitoring.tanggal}</Typography> : <></>}
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            defaultValue={registerFormMonitoring.jam !== '' ? dayjs(registerFormMonitoring.tanggal + ' ' + registerFormMonitoring.jam) : today}
                                            label="Jam"
                                            sx={{ width: '100%' }}
                                            name='jam'
                                            views={['hours', 'minutes', 'seconds']}
                                            onChange={(e) => setRegisterFormMonitoring({...registerFormMonitoring, jam: (e?.format('H:m:s') ?? today.format('H:m:s'))})}
                                        />
                                    </LocalizationProvider>
                                    {(errorFormMonitoring.jam != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormMonitoring.jam}</Typography> : <></>}
                                </Grid>
                                <Grid item xs={12}>
                                    <RichTextEditor
                                        ref={rteRef}
                                        content={registerFormMonitoring.keterangan}
                                        extensions={extensions}
                                        editable={isEditable}
                                        editorProps={{
                                            handleDrop: handleDrop,
                                            handlePaste: handlePaste,
                                        }}
                                        renderControls={() => 
                                            <MenuControlsContainer>
                                                <MenuSelectFontFamily
                                                    options={[
                                                    { label: "Comic Sans", value: "Comic Sans MS, Comic Sans" },
                                                    { label: "Cursive", value: "cursive" },
                                                    { label: "Monospace", value: "monospace" },
                                                    { label: "Serif", value: "serif" },
                                                    ]}
                                                />
                                                <MenuDivider />
                                                <MenuSelectHeading />
                                                <MenuDivider />
                                                <MenuSelectFontSize />
                                                <MenuDivider />
                                                <MenuButtonBold />
                                                <MenuButtonItalic />
                                                <MenuButtonUnderline />
                                                <MenuButtonStrikethrough />
                                                <MenuButtonSubscript />
                                                <MenuButtonSuperscript />
                                                <MenuDivider />
                                                <MenuButtonTextColor
                                                    defaultTextColor={theme.backgroundColor}
                                                    swatchColors={[
                                                    { value: "#000000", label: "Black" },
                                                    { value: "#ffffff", label: "White" },
                                                    { value: "#888888", label: "Grey" },
                                                    { value: "#ff0000", label: "Red" },
                                                    { value: "#ff9900", label: "Orange" },
                                                    { value: "#ffff00", label: "Yellow" },
                                                    { value: "#00d000", label: "Green" },
                                                    { value: "#0000ff", label: "Blue" },
                                                    ]}
                                                />

                                                <MenuButtonHighlightColor
                                                    swatchColors={[
                                                    { value: "#595959", label: "Dark grey" },
                                                    { value: "#dddddd", label: "Light grey" },
                                                    { value: "#ffa6a6", label: "Light red" },
                                                    { value: "#ffd699", label: "Light orange" },
                                                    { value: "#ffff00", label: "Yellow" },
                                                    { value: "#99cc99", label: "Light green" },
                                                    { value: "#90c6ff", label: "Light blue" },
                                                    { value: "#8085e9", label: "Light purple" },
                                                    ]}
                                                />
                                                <MenuDivider />
                                                <MenuButtonEditLink />
                                                <MenuDivider />
                                                <MenuSelectTextAlign />
                                                <MenuDivider />
                                                <MenuButtonOrderedList />
                                                <MenuButtonBulletedList />
                                                <MenuButtonTaskList />
                                                {isTouchDevice() && (<><MenuButtonIndent /><MenuButtonUnindent /></>)}
                                                <MenuDivider />
                                                <MenuButtonBlockquote />
                                                <MenuDivider />
                                                <MenuButtonCode />
                                                <MenuButtonCodeBlock />
                                                <MenuDivider />
                                                {/* <MenuButtonImageUpload onUploadFiles={handleUploadFiles}/> */}
                                                <MenuDivider />
                                                <MenuButtonHorizontalRule />
                                                <MenuButtonAddTable />
                                                <MenuDivider />
                                                <MenuButtonRemoveFormatting />
                                                <MenuDivider />
                                                <MenuButtonUndo />
                                                <MenuButtonRedo />
                                            </MenuControlsContainer>
                                        }
                                        RichTextFieldProps={{
                                            variant: "outlined",
                                            MenuBarProps: {
                                                hide: !showMenuBar,
                                            },
                                            footer: (
                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                sx={{
                                                    borderTopStyle: "solid",
                                                    borderTopWidth: 1,
                                                    borderTopColor: (theme) => theme.palette.divider,
                                                    py: 1,
                                                    px: 1.5,
                                                }}
                                            >
                                                <MenuButton
                                                value="formatting"
                                                tooltipLabel={
                                                    showMenuBar ? "Hide formatting" : "Show formatting"
                                                }
                                                size="small"
                                                onClick={() =>
                                                    setShowMenuBar((currentState) => !currentState)
                                                }
                                                selected={showMenuBar}
                                                IconComponent={TextFields}
                                                />
                                                <MenuButton
                                                    value="formatting"
                                                    tooltipLabel={
                                                        isEditable
                                                        ? "Prevent edits (use read-only mode)"
                                                        : "Allow edits"
                                                    }
                                                    size="small"
                                                    onClick={() => setIsEditable((currentState) => !currentState)}
                                                    selected={!isEditable}
                                                    IconComponent={isEditable ? Lock : LockOpen }
                                                />
                                            </Stack>
                                            ),
                                        }}>
                                    {() => (
                                        <>
                                        <LinkBubbleMenu />
                                        <TableBubbleMenu />
                                        </>
                                    )}
                                    </RichTextEditor>
                                </Grid>
                                <Grid item xs={12}>
                                    <Alert severity='error' icon={<HowToReg />} variant='filled'>Aktivitas anda akan direview dan diverifikasi oleh admin kami.</Alert>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} startIcon={<SaveAs />} title='Simpan'>Simpan</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            }
        </Dialog>
    );
}

export default DialogDetailActivity;
