import { KordinatAreaMemberField, KordinatAreaMemberProps } from "../Area/KordinatAreaMember";
import BlogPostProps from "../Blog/BlogPostProps";
import { DetailMemberField, DetailMemberProps } from "../Member/DetailMemberProps";
import KtpProps, { KtpField } from "../Member/KtpProps";
import MemberProps, { MemberField } from "../Member/MemberProps";

export interface BerandaRelawanResponse {
    relawan: RelawanProfilResponseDTO;
    detail: DetailMemberProps;
    konstituen_map: KonstituenMapProps[];
    pertanyaan_latest: PertanyaanLatestProps[];
    activity_latest: ActivityLatestProps[];
    blog: BlogPostProps[];
    summary_calendar_activity: SummaryCalendarActivityProps[];
}

export const BerandaRelawanResponseField: BerandaRelawanResponse = {
    relawan: {
        ktp: KtpField,
        member: MemberField,
        username: '',
        kordinat: KordinatAreaMemberField
    },
    detail: DetailMemberField,
    konstituen_map: [],
    pertanyaan_latest: [],
    activity_latest: [],
    blog: [],
    summary_calendar_activity: []
}

export interface RelawanProfilResponseDTO {
    ktp: KtpProps;
    member: MemberProps;
    username: string;
    kordinat: KordinatAreaMemberProps;
}

export const RelawanProfilResponseDTOField: RelawanProfilResponseDTO =  {
    ktp: KtpField,
    member: MemberField,
    username: '',
    kordinat: KordinatAreaMemberField
}

export interface KonstituenMapProps {
    area_member_id: string;
    nama: string;
    nama_tps: string;
    longitude: string;
    lattitude: string;
}

export interface PertanyaanLatestProps {
    pertanyaan_id: string;
    role_id: string;
    member_id: string;
    title: string;
    subtitle: string;
    done: boolean;
    created_at: string;
    updated_at: string;
}

export interface ActivityLatestProps {
    monitoringId: string;
    memberId: string;
    namaAktivitas: string;
    keterangan: string;
    tanggal: string;
    jam: string;
    accepted: boolean;
    acceptedBy: string;
    createdAt: string;
    updatedAt: string;
}

export interface SummaryCalendarActivityProps {
    date: string;
    activity_count: number;
}