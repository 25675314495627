import { AreaMemberProps } from "../Area/AreaMemberProps";
import TpsKordinatProps, { TpsKordinatField } from "../Area/TpsKordinatProps";
import ReferenceProps, { ReferenceField } from "../Reference/ReferenceProps";
import RoleProps from "../Role/RoleProps";
import KonstituenProps, { KonstituenField } from "./KonstituenProps";
import KtpProps, { KtpField } from "./KtpProps";
import MemberProps, { MemberField } from "./MemberProps";
import RelawanProps, { RelawanField } from "./RelawanProps";

export interface DetailMemberProps {
    ktp: KtpProps;
    member: MemberProps;
    username: string;
    role: RoleProps[];
    area: AreaMemberProps[];
    tps: TpsKordinatProps;
    referensi: ReferenceProps;
    relawan: RelawanProps;
    konstituen: KonstituenProps;
    relawan_count: number;
    relawan_list: RelasiMemberProps[];
    konstituen_list: KonstituenForRelawanMapProps[];
    konstituen_count: number;
}

export const DetailMemberField: DetailMemberProps = {
    ktp: KtpField,
    member: MemberField,
    username: '',
    role: [],
    area: [],
    tps: TpsKordinatField,
    referensi: ReferenceField,
    relawan: RelawanField,
    konstituen: KonstituenField,
    relawan_count: 0,
    relawan_list: [],
    konstituen_list: [],
    konstituen_count: 0
}

export interface RelasiMemberProps {
    relasi_id: string;
    member_id: string;
    nama_relasi: string;
}

export const RelasiMemberField: RelasiMemberProps = {
    relasi_id: '',
    member_id: '',
    nama_relasi: ''
}

export interface KonstituenForRelawanMapProps {
    konstituen_id: string;
    member_id: string;
    nama: string;
    username: string;
    avatar: string;
    hak_pilih: boolean;
    alamat: string;
    longitude: string;
    lattitude: string;
}

export const KonstituenForRelawanMapField: KonstituenForRelawanMapProps = {
    konstituen_id: '',
    member_id: '',
    nama: '',
    username: '',
    avatar: '',
    hak_pilih: false,
    alamat: '',
    longitude: '',
    lattitude: '',
}