import axios, { AxiosResponse } from 'axios';
import { RegisterRequestProps } from '../../models/Auth/RegisterRequestProps';
import {LoginResponseProps} from '../../models/Auth/LoginResponseProps';
import RegisterResponseProps from '../../models/Auth/RegisterResponseProps';

const baseUrl = process.env.REACT_APP_BACK_END_URL;

class AuthServices {
    async login(username: string, password: string): Promise<AxiosResponse<LoginResponseProps>> {
        axios.defaults.baseURL = baseUrl;
        return axios.post(`/api/v1/auth/login`,{username: username,password: password,},{headers: {'Content-Type': 'application/json',}});
    }
        
    async register(registData : RegisterRequestProps): Promise<AxiosResponse<RegisterResponseProps>> {
        axios.defaults.baseURL = baseUrl;
        return axios.post(`/api/v1/auth/register`, registData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }
    
    logout(memberId: string) : Promise<AxiosResponse<void>> {
        return axios.post(`/api/v1/auth/logout`,{member_id: memberId}, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthServices();