export interface TpsSaksiResponseDTO {
    provinsi_id: string;
    nama_provinsi: string;
    kabupaten_id: string;
    nama_kabupaten: string;
    kecamatan_id: string;
    nama_kecamatan: string;
    desa_id: string;
    nama_desa: string;
    rw_id: string;
    nama_rw: string;
    rt_id: string;
    nama_rt: string;
    tps_id: string;
    nama_tps: string;
    kordinat_id: string;
    longitude: string;
    lattitude: string;
    alamat: string;
}

export const TpsSaksiResponseDTOField : TpsSaksiResponseDTO = {
    provinsi_id: '',
    nama_provinsi: '',
    kabupaten_id: '',
    nama_kabupaten: '',
    kecamatan_id: '',
    nama_kecamatan: '',
    desa_id: '',
    nama_desa: '',
    rw_id: '',
    nama_rw: '',
    rt_id: '',
    nama_rt: '',
    tps_id: '',
    nama_tps: '',
    kordinat_id: '',
    longitude: '',
    lattitude: '',
    alamat: '',
}