import React from 'react';
import { useParams } from 'react-router-dom';

// interface BeritaDetailProps extends RouteComponentProps<{berita: number}>{}

const BeritaDetail: React.FC = (props) => {
    const {berita} = useParams();
    return (
        <div>
            {berita}
        </div>
    );
}

export default BeritaDetail;
