import React from 'react';
import {AreaMemberField, AreaMemberProps, ErrorAreaMemberField, ErrorAreaMemberProps} from '../models/Area/AreaMemberProps';
import ThemeStoreProps from '../models/Store/ThemeStoreProps';
import { AuthStoreState, ThemeStoreState } from '../store';
import { useRecoilValue } from 'recoil';
import Swal from 'sweetalert2';
import AreaMemberService from '../services/Area/AreaMemberService';
import { LoginResponseProps } from '../models/Auth/LoginResponseProps';
import { Button, Grid, Paper, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import DialogAreaMember from '../pages/Area/DialogAreaMember';
import { ProvinsiProps } from '../models/Area/ProvinsiProps';
import { KabupatenProps } from '../models/Area/KabupatenProps';
import { KecamatanProps } from '../models/Area/KecamatanProps';
import { DesaProps } from '../models/Area/DesaProps';
import { RwProps } from '../models/Area/RwProps';
import { RtProps } from '../models/Area/RtProps';
import { TpsProps } from '../models/Area/TpsProps';
import ProvinsiService from '../services/Area/ProvinsiService';
import KabupatenService from '../services/Area/KabupatenService';
import KecamatanService from '../services/Area/KecamatanService';
import DesaService from '../services/Area/DesaService';
import RwService from '../services/Area/RwService';
import RtService from '../services/Area/RtService';
import TpsService from '../services/Area/TpsService';
import { AreaMemberValidateForm } from '../validation/AreaMemberValidation';

const AreaMember: React.FC = () => {
    const auth = useRecoilValue<LoginResponseProps>(AuthStoreState);
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const [loadingSkeletonTable, setLoadingSkeletonTable] = React.useState<boolean>(false);
    const [provinsiData, setProvinsiData] = React.useState<ProvinsiProps[]>([]);
    const [kabupatenData, setKabupatenData] = React.useState<KabupatenProps[]>([]);
    const [kecamatanData, setKecamatanData] = React.useState<KecamatanProps[]>([]);
    const [desaData, setDesaData] = React.useState<DesaProps[]>([]);
    const [rwData, setRwData] = React.useState<RwProps[]>([]);
    const [rtData, setRtData] = React.useState<RtProps[]>([]);
    const [tpsData, setTpsData] = React.useState<TpsProps[]>([]);

    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');

    const [titleDialog, setTitleDialog] = React.useState<string>('');
    const [errorForm, setErrorForm] = React.useState<ErrorAreaMemberProps>(ErrorAreaMemberField);
    const [areaMemberData, setAreaMemberData] = React.useState<AreaMemberProps[]>([]);

    const [formAreaMember, setFormAreaMember] = React.useState<AreaMemberProps>(AreaMemberField);
    const [settingTable, setSettingTable] = React.useState({
        page: 0,
        size: 10,
        sort: 'areaMemberId',
        search: '',
        totalElements: 0,
        totalPages: 0,
        pilihan: '',
        namaPilihan: ''
    });
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState)
    const handleChangePage = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable,  page: newPage});
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTable({...settingTable, page: 0, size: +event.target.value});
    };
    const getAllAreaMember = async () => {
        AreaMemberService.getAll(settingTable.page, settingTable.size, settingTable.sort, settingTable.search, auth.member_id).then(res => {
            setAreaMemberData(res.data.content);
            setSettingTable({...settingTable, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
        }).catch(err => {
            setMessageSnackbar("Terdapat Error");
            setOpenSnackbar(true)
        })
    }
    const jadikanAlamatUtama = async (areaMember: AreaMemberProps, status: boolean) => {
        Swal.fire({
            title: 'Alamat Anda Dijadikan Utama',
            text: 'Apakah anda ingin menjadikan Alamat di Desa '+ areaMember.nama_desa +' dan RT ' + areaMember.nama_rt + " menjadi Alamat Utama Anda ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Jadikan Utama",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                AreaMemberService.put(areaMember.area_member_id, status).then(res => {
                    let temp: AreaMemberProps[] = [];
                    areaMemberData.forEach(am => {
                        temp.push({
                            ...am, 
                            alamat_utama: am.area_member_id === areaMember.area_member_id ? true : false
                        });}
                    );
                    setAreaMemberData(temp);
                    Swal.fire({
                        title: "Selesai!",
                        text: "Alamat sudah dijadikan Utama.",
                        icon: "success"
                    });
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: "Alamat gagal dijadikan alamat utama.",
                        icon: "error"
                    });
                    console.error(err);
                })
            }
        });
    }
    async function getProvinsi () {
        await ProvinsiService.getAllArray().then(res => {
            setProvinsiData(res.data);
            setKabupatenData([]);
            setKecamatanData([]);
            setDesaData([]);
            setRwData([]);
            setRtData([]);
            setTpsData([]);
        }).catch(err => {});
    }
    async function getKabupaten (provinsiId: string) {
        setFormAreaMember({...formAreaMember, provinsi_id: provinsiId, kabupaten_id: '', kecamatan_id: '', desa_id: '', rw_id: '', rt_id: ''});
        setKabupatenData([]);
        setKecamatanData([]);
        setDesaData([]);
        setRwData([]);
        setRtData([]);
        setTpsData([]);
        await KabupatenService.getAllArray(provinsiId).then(res => {
            setKabupatenData(res.data);
        }).catch(err => {});
    }
    async function getKecamatan (kabupatenId: string) {
        setFormAreaMember({...formAreaMember, kabupaten_id: kabupatenId, kecamatan_id: '', desa_id: '', rw_id: '', rt_id: ''});
        setKecamatanData([]);
        setDesaData([]);
        setRwData([]);
        setRtData([]);
        setTpsData([]);
        await KecamatanService.getAllArray(kabupatenId).then(res => {
            setKecamatanData(res.data);
        }).catch(err => {});
    }
    async function getDesa (kecamatanId: string) {
        setFormAreaMember({...formAreaMember, kecamatan_id: kecamatanId, desa_id: '', rw_id: '', rt_id: ''});
        setDesaData([]);
        setRwData([]);
        setRtData([]);
        setTpsData([]);
        await DesaService.getAllArray(kecamatanId).then(res => {
            setDesaData(res.data);
        }).catch(err => {});
    }
    async function getRw (desaId: string) {
        setFormAreaMember({...formAreaMember, desa_id: desaId, rw_id: '', rt_id: ''});
        setRwData([]);
        setRtData([]);
        setTpsData([]);
        await RwService.getAllArr(desaId).then(res => {
            setRwData(res.data);
            setRtData([]);
        }).catch(err => {});
    }
    async function getRt (rwId: string) {
        setFormAreaMember({...formAreaMember, rw_id: rwId, rt_id: ''});
        await RtService.getAllArr(rwId).then(res => {
            setRtData(res.data);
        }).catch(err => {});
    }
    async function getTps (rwId: string, rtId: string | null) {
        await TpsService.getAllArr(rwId, rtId !== null ? rtId : undefined).then(res => {
            setTpsData(res.data);
        }).catch(err => {});
    }
    const addAlamatDialog = () => {
        getProvinsi();
        setFormAreaMember({
            ...AreaMemberField,
            member_id: auth.member_id,
        });
        setErrorForm(ErrorAreaMemberField);
        setTitleDialog("Tambah Alamat");
        setOpenModal(true);
    }
    const createAlamat = async () => {
        const newErrors: any = {};
        try {
            await AreaMemberValidateForm.validate(formAreaMember, {abortEarly:false});
            setErrorForm(ErrorAreaMemberField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await AreaMemberService.create(formAreaMember).then(res => {
                if(formAreaMember.alamat_utama) {
                    let temp: AreaMemberProps[] = [];
                    areaMemberData.forEach(am => temp.push({...am, alamat_utama: false}));
                    setAreaMemberData([...temp, res.data]);
                } else {
                    setAreaMemberData([...areaMemberData, res.data]);
                }
                setSettingTable({...settingTable, totalElements: settingTable.totalElements + 1})
                setTitleDialog("Detail Area " + titleDialog.split(' ').pop());
                setMessageSnackbar("Alamat berhasil di tambah");
                setOpenSnackbar(true);
            }).catch(err => {
                setMessageSnackbar("Alamat gagal di tambah");
                setOpenSnackbar(true);
            })
        }
    }
    const deleteAreaMember = async (areaMember: AreaMemberProps) => {
        Swal.fire({
            title: 'Hapus Alamat Anda',
            text: 'Apakah anda ingin menghapus Alamat di Desa '+ areaMember.nama_desa +' dan RT ' + areaMember.nama_rt + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                AreaMemberService.delete(areaMember.area_member_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Alamat sudah dihapus.",
                        icon: "success"
                    });
                    setAreaMemberData(areaMemberData.filter(r => r.area_member_id !== areaMember.area_member_id));
                    setSettingTable({...settingTable, totalElements: settingTable.totalElements - 1});
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: "Alamat gagal dihapus.",
                        icon: "error"
                    });
                    console.error(err);
                });
            }
        });
    }

    React.useEffect(() => {
        setTimeout(() => {
            setLoadingSkeleton(false);
            getAllAreaMember();
            setLoadingSkeletonTable(false);
        }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingTable.page, settingTable.size, settingTable.sort, settingTable.search, settingTable.totalElements])

    return loadingSkeleton ? (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                        <Skeleton width={'100%'} height={'50px'} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Skeleton width={'100%'} height={'50px'} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Skeleton width={'100%'} height={'500px'} />
            </Grid>
        </Grid>
    ) : (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <Typography variant='h5' sx={{ justifyContent: 'center', alignItem: 'center' }}>Alamat Anda</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button variant='contained' sx={{ backgroundColor: theme.backgroundColor, color: theme.color }}  onClick={() => addAlamatDialog()} startIcon={<Add />} color='inherit'>Tambah Alamat</Button>
                    </Grid>
                </Grid>
            </Grid>
            {
                loadingSkeletonTable ? <Skeleton width={'100%'} height={'500px'} /> :
                <Grid item xs={12} sm={12} md={12}>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 540 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Provinsi</TableCell>
                                            <TableCell>Kabupaten</TableCell>
                                            <TableCell>Kecamatan</TableCell>
                                            <TableCell>Desa</TableCell>
                                            <TableCell>RW</TableCell>
                                            <TableCell>RT</TableCell>
                                            <TableCell>TPS</TableCell>
                                            <TableCell>Utama</TableCell>
                                            <TableCell>Aksi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                        areaMemberData.length !== 0 ? areaMemberData.map(p => 
                                            <TableRow key={p.area_member_id}>
                                                <TableCell>{p.nama_provinsi}</TableCell>
                                                <TableCell>{p.nama_kabupaten}</TableCell>
                                                <TableCell>{p.nama_kecamatan}</TableCell>
                                                <TableCell>{p.nama_desa}</TableCell>
                                                <TableCell>{p.nama_rw}</TableCell>
                                                <TableCell>{p.nama_rt}</TableCell>
                                                <TableCell>{p.nama_tps}</TableCell>
                                                <TableCell>{p.alamat_utama ? 'Utama' : 'Tidak'}</TableCell>
                                                <TableCell>
                                                    {
                                                        !p.alamat_utama ? 
                                                        <Button size='small' variant='contained' color='warning' onClick={() => jadikanAlamatUtama(p, true)} sx={{ marginLeft: '5px' }} startIcon={<Edit />}>Utama</Button> : <></>
                                                    }
                                                    <Button sx={{ marginLeft: '5px' }} onClick={() => deleteAreaMember(p)} size='small' variant='contained' startIcon={<Delete />} color='error'>Hapus</Button>
                                                    </TableCell>
                                        </TableRow> 
                                            )
                                        : <TableRow>
                                            <TableCell colSpan={9}>
                                                <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                            </TableCell>
                                        </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                component="div"
                                count={settingTable.totalElements}
                                rowsPerPage={settingTable.size}
                                page={settingTable.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                </Grid>
            }
            <DialogAreaMember 
                openModal={openModal}
                setOpenModal={setOpenModal}
                createAlamat={createAlamat}
                titleDialog={titleDialog}
                formAreaMember={formAreaMember}
                setFormAreaMember={setFormAreaMember}
                messageSnackbar={messageSnackbar}
                errorForm={errorForm}
                setErrorForm={setErrorForm}
                theme={theme}
                getKabupaten={getKabupaten}
                getKecamatan={getKecamatan}
                getDesa={getDesa}
                getRw={getRw}
                getRt={getRt}
                getTps={getTps}
                provinsiData={provinsiData}
                kabupatenData={kabupatenData}
                kecamatanData={kecamatanData}
                desaData={desaData}
                rwData={rwData}
                rtData={rtData}
                tpsData={tpsData}
            />
            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                message={messageSnackbar}
            />
        </Grid>
    );
}

export default AreaMember;
