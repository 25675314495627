export interface PartaiProps {
    partai_id: string;
    nama_partai: string;
    singkatan: string;
    ketua_umum: string;
    sekjen: string;
    gambar: string;
    alamat: string;
    no_telp: string;
    alamat_web: string;
    created_at: string;
}

export const PartaiField: PartaiProps = {
    partai_id: '',
    nama_partai: '',
    singkatan: '',
    ketua_umum: '',
    sekjen: '',
    gambar: '',
    alamat: '',
    no_telp: '',
    alamat_web: '',
    created_at: '',
}

export interface errorPartaiProps {
    partai_id: string;
    nama_partai: string;
    singkatan: string;
    ketua_umum: string;
    sekjen: string;
    gambar: string;
    alamat: string;
    no_telp: string;
    alamat_web: string;
    created_at: string;
}
export const errorPartaiField: errorPartaiProps = {
    partai_id: '',
    nama_partai: '',
    singkatan: '',
    ketua_umum: '',
    sekjen: '',
    gambar: '',
    alamat: '',
    no_telp: '',
    alamat_web: '',
    created_at: '',
}