import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import AppbarLayout from './AppbarLayout';
import { useRecoilState } from 'recoil';
import { AuthStoreState, RoleStoreState } from '../../../store';
import { LoginResponseProps } from '../../../models/Auth/LoginResponseProps';
import LoginRoleProps from '../../../models/Role/LoginRoleProps';
import { Button, Container, Grid, Paper, Skeleton, Typography, capitalize } from '@mui/material';
import { VerifiedUserSharp } from '@mui/icons-material';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import Breadcrumb from '../../../components/Breadcrumb';
import BreadcrumbModelProps from '../../../models/Component/BreadcrumbModelProps';
import axios from 'axios';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 1),
    ...theme.mixins.toolbar,
}));

const Index = () => {
    const [openSidebar, setOpenSidebar] = React.useState<boolean>(false);
    const [openProfileMenu, setOpenProfileMenu] = React.useState<null | HTMLElement>(null);
    
    const [authStore, setAuthStore] = useRecoilState<LoginResponseProps>(AuthStoreState)
    const [roleStore, setRoleStore] = useRecoilState<LoginRoleProps | null>(RoleStoreState);
    const [bc, setBc] = React.useState<BreadcrumbModelProps[] | null>(null);
    const [title, setTitle] = React.useState<string | null>(null);

    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        function getStarted () {
            const storedDataUser: string | null = localStorage.getItem('user');
            
            if (storedDataUser) {
                let tempUser : LoginResponseProps = JSON.parse(storedDataUser);
                
                const storedDataRole: string | null = localStorage.getItem('role');
                if (storedDataRole) {
                    let temp : LoginRoleProps = JSON.parse(storedDataRole);
                    setRoleStore(temp);
                }
                setAuthStore(tempUser);
                axios.defaults.headers.common['Authorization'] = `Bearer ${tempUser.access_token}`;
                axios.defaults.baseURL = process.env.REACT_APP_BACK_END_URL;
            } else {
                navigate('/login?msg=userNeedLogin');
            }
        }

        function buildBreadcrumb() {
            let t = location.pathname.split("/");t.shift();
            
            let temp: BreadcrumbModelProps[] = [];
            if (!t.includes('home')) {
                temp.push({
                    path: '/home',
                    title: "Home"
                });
            }
            t.forEach((x, index) => {
                if(index === t.length - 1) {
                    setTitle(capitalize(x.replaceAll("-", " ")));
                }
                temp.push({
                    path: '/' + x,
                    title: capitalize(x.replaceAll("-", " "))
                });
            })
            setBc(temp);
        }

        buildBreadcrumb()
        getStarted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const chooseRole = (roleId: string) => {
        if(authStore !== null) {
            let temp: LoginRoleProps | undefined = authStore.role.find(role => role.role_id === roleId);
            if (temp !== undefined) {
                setRoleStore(temp)
                localStorage.setItem('role', JSON.stringify(temp));
            }
        }
    }

    return (
    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppbarLayout roleStore={Boolean(roleStore)} openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} openProfileMenu={openProfileMenu} setOpenProfileMenu={setOpenProfileMenu} />
        {
            roleStore !== null ? <Sidebar roleName={roleStore.nama_role} roleStore={roleStore} openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} /> : <></>
        }
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            {
                roleStore !== null ?
                <>
                <Container>
                    <Grid container spacing={2} sx={{ mb: '25px' }}>
                        <Grid item xs={12} sm={6}>
                            {
                                title !== null ? <Typography variant='h5'>{title}</Typography> : <Skeleton animation='wave' variant='rectangular' width={120} height={50} />
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                {
                                    bc !== null ? <Breadcrumb menuItem={bc} /> : <Skeleton animation='wave' variant='rectangular' width={120} height={50} />
                                }
                            </Box>
                        </Grid>
                    </Grid>
                    <Outlet />
                </Container>
                </> : <>
                <Typography variant='h5' sx={{ paddingY: '10px' }}>Silakan Pilih Role yang anda miliki</Typography>
                {
                    authStore != null ?
                    <Grid container spacing={3}>
                        {
                            authStore.role.map(r => 
                            <Grid key={r.role_id} item xs={12} sm={12} md={6} xl={3}>
                                <Paper elevation={2} sx={{ overflow: 'hidden' }}>
                                    <Box sx={{ padding: '10px', display: 'flex' }}>
                                        {r.aktif ? <VerifiedUserSharp /> : <SafetyCheckIcon />}
                                        <Typography sx={{paddingLeft: '5px', fontWeight: 'bold', fontSize: '16px', }}>{r.nama_role.replace("_", " ")}</Typography>
                                    </Box>
                                    <Box sx={{ padding: '10px', display: 'flex' }}>
                                        <Button fullWidth variant='outlined' color='success' disabled={!r.aktif} onClick={() => chooseRole(r.role_id)}>Pilih Role</Button>
                                    </Box>
                                </Paper>
                            </Grid>
                            )
                        }
                    </Grid> : <></>
                }
                </>
            }
        </Box>
    </Box>
    );
}

export default Index;
