import axios, { AxiosResponse } from 'axios';
import { PertanyaanKategoriProps } from '../../models/Bantuan/PertanyaanKategoriProps';

class PertanyaanKategoriServices {
    async getAll(): Promise<AxiosResponse<PertanyaanKategoriProps[]>> {
        return axios.get(`/api/v1/bantuan/pertanyaan-kategori`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(kategoriPertanyaanId: string): Promise<AxiosResponse<PertanyaanKategoriProps>> {
        return axios.get(`/api/v1/blog/bantuan/pertanyaan-kategori/${kategoriPertanyaanId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: PertanyaanKategoriProps): Promise<AxiosResponse<PertanyaanKategoriProps>> {
        return axios.post(`/api/v1/bantuan/pertanyaan-kategori`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(kategoriPertanyaanId: string, data : PertanyaanKategoriProps): Promise<AxiosResponse<PertanyaanKategoriProps>> {
        return axios.put(`/api/v1/bantuan/pertanyaan-kategori/${kategoriPertanyaanId}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async delete(kategoriPertanyaanId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/bantuan/pertanyaan-kategori/${kategoriPertanyaanId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PertanyaanKategoriServices();