import { AccountCircle, Add, Close, CommentRounded, Delete, Edit, Lock, LockOpen, RemoveRedEyeOutlined, SaveAs, TextFields } from '@mui/icons-material';
import { AppBar, Avatar, Box, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Paper, Select, Slide, Stack, TablePagination, TextField, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useCallback } from 'react';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { ErrorPertanyaanProps, PertanyaanProps } from '../../models/Bantuan/PertanyaanProps';
import { PertanyaanKategoriProps } from '../../models/Bantuan/PertanyaanKategoriProps';
import RoleProps from '../../models/Role/RoleProps';
import { ErrorJawabanProps, JawabanProps } from '../../models/Bantuan/JawabanProps';
import { PertanyaanFileProps } from '../../models/Bantuan/PertanyaanFileProps';
import { Priority } from '../../enum/Priority';
import { LoginResponseProps } from '../../models/Auth/LoginResponseProps';
import LoginRoleProps from '../../models/Role/LoginRoleProps';
import {
    LinkBubbleMenu,
    MenuButton,
    MenuButtonAddTable,
    MenuButtonBlockquote,
    MenuButtonBold,
    MenuButtonBulletedList,
    MenuButtonCode,
    MenuButtonCodeBlock,
    MenuButtonEditLink,
    MenuButtonHighlightColor,
    MenuButtonHorizontalRule,
    MenuButtonIndent,
    MenuButtonItalic,
    MenuButtonOrderedList,
    MenuButtonRedo,
    MenuButtonRemoveFormatting,
    MenuButtonStrikethrough,
    MenuButtonSubscript,
    MenuButtonSuperscript,
    MenuButtonTaskList,
    MenuButtonTextColor,
    MenuButtonUnderline,
    MenuButtonUndo,
    MenuButtonUnindent,
    MenuControlsContainer,
    MenuDivider,
    MenuSelectFontFamily,
    MenuSelectFontSize,
    MenuSelectHeading,
    MenuSelectTextAlign,
    RichTextEditor,
    TableBubbleMenu,
    insertImages,
    isTouchDevice,
} from "mui-tiptap";
import type { EditorOptions } from "@tiptap/core";
import useExtensions from '../../Utils/useExtension';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogPengelolaanBantuanProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    theme: ThemeStoreProps;
    titleDialog: string;
    errors: ErrorPertanyaanProps;
    setErrors: React.Dispatch<React.SetStateAction<ErrorPertanyaanProps>>;
    image: File | null;
    setImage: React.Dispatch<React.SetStateAction<File | null>>;
    imagePreview: string | null;
    setImagePreview: React.Dispatch<React.SetStateAction<string | null>>;
    uploadImage: any;
    ImageDefault: any;
    loadingSkeleton: boolean;
    setLoadingSkeleton: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
    setMessageSnackbar: React.Dispatch<React.SetStateAction<string>>;
    setTitleDialog: React.Dispatch<React.SetStateAction<string>>;
    settingTable: {page: number;
        size: number;
        sort: string;
        search: string;
        memberId: string;
        roleId: string;
        kategoriPertanyaanId: string;
        priority: string;
        status: string;
        totalElements: number;
        totalPages: number;};
    setSettingTable: React.Dispatch<React.SetStateAction<{page: number;
        size: number;
        sort: string;
        search: string;
        memberId: string;
        roleId: string;
        kategoriPertanyaanId: string;
        priority: string;
        status: string;
        totalElements: number;
        totalPages: number;}>>;
    kategoriPertanyaanData: PertanyaanKategoriProps[];
    setKategoriPertanyaanData: React.Dispatch<React.SetStateAction<PertanyaanKategoriProps[]>>;
    roleData: RoleProps[];
    setRoleData: React.Dispatch<React.SetStateAction<RoleProps[]>>;
    createPertanyaan: any;
    updatePertanyaan: any;
    jawabanData: JawabanProps[];
    setJawabanData: React.Dispatch<React.SetStateAction<JawabanProps[]>>;
    jawabanDataDetail: JawabanProps;
    setJawabanDataDetail: React.Dispatch<React.SetStateAction<JawabanProps>>;
    openModalJawaban: boolean;
    setOpenModalJawaban: React.Dispatch<React.SetStateAction<boolean>>;
    loadingSkeletonTableJawaban: boolean;
    setLoadingSkeletonTableJawaban: React.Dispatch<React.SetStateAction<boolean>>;
    errorsJawaban: ErrorJawabanProps;
    setErrorsJawaban: React.Dispatch<React.SetStateAction<ErrorJawabanProps>>;
    settingTableJawaban: {page: number;
        size: number;
        sort: string;
        search: string;
        memberId: string;
        roleId: string;
        totalElements: number;
        totalPages: number;};
    setSettingTableJawaban: React.Dispatch<React.SetStateAction<{page: number;
        size: number;
        sort: string;
        search: string;
        memberId: string;
        roleId: string;
        totalElements: number;
        totalPages: number;}>>;
    handleChangePageJawaban: any;
    handleChangeRowsPerPageJawaban: any;
    getAllJawaban: any;
    createJawaban: any;
    updateJawaban: any;
    deleteJawaban: any;
    openModalFile: boolean;
    setOpenModalFile: React.Dispatch<React.SetStateAction<boolean>>;
    pertanyaanFileData: PertanyaanFileProps[];
    setPertanyaanFileData: React.Dispatch<React.SetStateAction<PertanyaanFileProps[]>>;
    pertanyaanFileDataDetail: PertanyaanFileProps;
    setPertanyaanFileDataDetail: React.Dispatch<React.SetStateAction<PertanyaanFileProps>>;
    createPertanyaanFile: any;
    deletePertanyaanFile: any;
    pertanyaanDataDetail: PertanyaanProps;
    setPertanyaanDataDetail: React.Dispatch<React.SetStateAction<PertanyaanProps>>;
    profil: LoginResponseProps;
    role: LoginRoleProps;
    rteRef: any;
    rteRefJawaban: any;
    openModalLihatJawaban: boolean;
    setOpenModalLihatJawaban: React.Dispatch<React.SetStateAction<boolean>>;
}

const DialogPengelolaanBantuan: React.FC<DialogPengelolaanBantuanProps> = ({
    openModal,
    setOpenModal,
    theme,
    titleDialog,
    errors,
    setErrors,
    image,
    setImage,
    imagePreview,
    setImagePreview,
    uploadImage,
    ImageDefault,
    loadingSkeleton,
    setLoadingSkeleton,
    setOpenSnackbar,
    setMessageSnackbar,
    setTitleDialog,
    settingTable,
    setSettingTable,
    kategoriPertanyaanData,
    setKategoriPertanyaanData,
    roleData,
    setRoleData,
    createPertanyaan,
    updatePertanyaan,
    jawabanData,
    setJawabanData,
    jawabanDataDetail,
    setJawabanDataDetail,
    openModalJawaban,
    setOpenModalJawaban,
    loadingSkeletonTableJawaban,
    setLoadingSkeletonTableJawaban,
    errorsJawaban,
    setErrorsJawaban,
    settingTableJawaban,
    setSettingTableJawaban,
    handleChangePageJawaban,
    handleChangeRowsPerPageJawaban,
    getAllJawaban,
    createJawaban,
    updateJawaban,
    deleteJawaban,
    openModalFile,
    setOpenModalFile,
    pertanyaanFileData,
    setPertanyaanFileData,
    pertanyaanFileDataDetail,
    setPertanyaanFileDataDetail,
    createPertanyaanFile,
    deletePertanyaanFile,
    pertanyaanDataDetail,
    setPertanyaanDataDetail,
    profil,
    role, 
    rteRef, 
    rteRefJawaban,
    openModalLihatJawaban,
    setOpenModalLihatJawaban
}) => {
    const [isEditable, setIsEditable] = React.useState<boolean>(true);
    const [showMenuBar, setShowMenuBar] = React.useState<boolean>(true);
    const [titleJawaban, setTitleJawaban] = React.useState<string>('');

    const reformatDate = (time: string): string => {
        const dateStr = time;

        // Parse the date string
        const date = new Date(dateStr);

        // Define month names in Indonesian
        const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

        // Extract components
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        // Format the date
        const formattedDate = `${hours}:${minutes}:${seconds} ${day} ${month} ${year}`;

        return formattedDate;
    }
    const extensions = useExtensions({
        placeholder: "Isi Pertanyaan anda disini...",
    });

    function fileListToImageFiles(fileList: FileList): File[] {
        return Array.from(fileList).filter((file) => {
            const mimeType = (file.type || "").toLowerCase();
            return mimeType.startsWith("image/");
        });
    }

    const handleNewImageFiles = useCallback((files: File[], insertPosition?: number): void => {
        if (!rteRef.current?.editor) { return; }

        const attributesForImageFiles = files.map((file) => ({
            src: URL.createObjectURL(file),
            alt: file.name,
        }));
    
        insertImages({
            images: attributesForImageFiles,
            editor: rteRef.current.editor,
            // insertPosition
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDrop: NonNullable<EditorOptions["editorProps"]["handleDrop"]> = useCallback((view, event, _slice, _moved) => {
        if (!(event instanceof DragEvent) || !event.dataTransfer) {
            return false;
        }

        const imageFiles = fileListToImageFiles(event.dataTransfer.files);
        if (imageFiles.length > 0) {
            const insertPosition = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
            })?.pos;

            handleNewImageFiles(imageFiles, insertPosition);

          // Return true to treat the event as handled. We call preventDefault
          // ourselves for good measure.
            event.preventDefault();
            return true;
        }

        return false;
    },[handleNewImageFiles]);

    const handlePaste: NonNullable<EditorOptions["editorProps"]["handlePaste"]> = useCallback((_view, event, _slice) => {
        if (!event.clipboardData) {
            return false;
        }

        const pastedImageFiles = fileListToImageFiles(
            event.clipboardData.files,
        );
        if (pastedImageFiles.length > 0) {
            handleNewImageFiles(pastedImageFiles);
            return true;
        }
        return false;
    },[handleNewImageFiles]);
    
    return (
        <Dialog
            fullScreen
            open={openModal}
            onClose={() => setOpenModal(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setOpenModal(false)}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {titleDialog}
                    </Typography>
                    <Button autoFocus startIcon={<Close />} color="inherit" onClick={() => setOpenModal(false)}>
                        Tutup
                    </Button>
                </Toolbar>
            </AppBar>
            <Container>
                {
                    titleDialog.split(' ').includes('Jawaban') ? 
                    <Grid container spacing={2} sx={{ marginTop: '20px', marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
                        <Grid item xs={12} sm={12} md={10}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant='h5' sx={{ marginRight: '10px', marginBottom: '5px' }}>Jawaban</Typography>
                                        {
                                            pertanyaanDataDetail.status === 'OPEN' ? <Chip label="OPEN" color='success' variant='filled' /> : pertanyaanDataDetail.status === 'IN_PROGRESS' ? <Chip label="IN_PROGRESS" color='primary' variant='filled' /> : pertanyaanDataDetail.status === 'RESOLVED' ? <Chip label="RESOLVED" color='warning' variant='filled' /> : <Chip label="CLOSED" color='error' variant='filled' />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} display={'flex'} justifyContent={'flex-end'}>
                                    {
                                        pertanyaanDataDetail.status === 'CLOSED' ? <></> : 
                                    <Button startIcon={<Add />} title='Tambah' color='success' onClick={() => {setOpenModalJawaban(true); setTitleJawaban("Tambah Jawaban"); setJawabanDataDetail({...jawabanDataDetail, title: '', subtitle: ''})}} variant='contained'>Tambah</Button>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10}>
                            <TextField
                                label="Judul"
                                id="Judul"
                                disabled
                                fullWidth
                                placeholder='Judul'
                                value={pertanyaanDataDetail.title}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><CommentRounded /></InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={10}>
                            <RichTextEditor
                                ref={rteRef}
                                content={pertanyaanDataDetail.subtitle}
                                extensions={extensions}
                                editable={false}
                                editorProps={{
                                    handleDrop: handleDrop,
                                    handlePaste: handlePaste,
                                }}
                                renderControls={() => 
                                    <MenuControlsContainer>
                                        <MenuSelectFontFamily
                                            options={[
                                            { label: "Comic Sans", value: "Comic Sans MS, Comic Sans" },
                                            { label: "Cursive", value: "cursive" },
                                            { label: "Monospace", value: "monospace" },
                                            { label: "Serif", value: "serif" },
                                            ]}
                                        />
                                        <MenuDivider />
                                        <MenuSelectHeading />
                                        <MenuDivider />
                                        <MenuSelectFontSize />
                                        <MenuDivider />
                                        <MenuButtonBold />
                                        <MenuButtonItalic />
                                        <MenuButtonUnderline />
                                        <MenuButtonStrikethrough />
                                        <MenuButtonSubscript />
                                        <MenuButtonSuperscript />
                                        <MenuDivider />
                                        <MenuButtonTextColor
                                            defaultTextColor={theme.backgroundColor}
                                            swatchColors={[
                                            { value: "#000000", label: "Black" },
                                            { value: "#ffffff", label: "White" },
                                            { value: "#888888", label: "Grey" },
                                            { value: "#ff0000", label: "Red" },
                                            { value: "#ff9900", label: "Orange" },
                                            { value: "#ffff00", label: "Yellow" },
                                            { value: "#00d000", label: "Green" },
                                            { value: "#0000ff", label: "Blue" },
                                            ]}
                                        />

                                        <MenuButtonHighlightColor
                                            swatchColors={[
                                            { value: "#595959", label: "Dark grey" },
                                            { value: "#dddddd", label: "Light grey" },
                                            { value: "#ffa6a6", label: "Light red" },
                                            { value: "#ffd699", label: "Light orange" },
                                            { value: "#ffff00", label: "Yellow" },
                                            { value: "#99cc99", label: "Light green" },
                                            { value: "#90c6ff", label: "Light blue" },
                                            { value: "#8085e9", label: "Light purple" },
                                            ]}
                                        />
                                        <MenuDivider />
                                        <MenuButtonEditLink />
                                        <MenuDivider />
                                        <MenuSelectTextAlign />
                                        <MenuDivider />
                                        <MenuButtonOrderedList />
                                        <MenuButtonBulletedList />
                                        <MenuButtonTaskList />
                                        {isTouchDevice() && (<><MenuButtonIndent /><MenuButtonUnindent /></>)}
                                        <MenuDivider />
                                        <MenuButtonBlockquote />
                                        <MenuDivider />
                                        <MenuButtonCode />
                                        <MenuButtonCodeBlock />
                                        <MenuDivider />
                                        {/* <MenuButtonImageUpload onUploadFiles={handleUploadFiles}/> */}
                                        <MenuDivider />
                                        <MenuButtonHorizontalRule />
                                        <MenuButtonAddTable />
                                        <MenuDivider />
                                        <MenuButtonRemoveFormatting />
                                        <MenuDivider />
                                        <MenuButtonUndo />
                                        <MenuButtonRedo />
                                    </MenuControlsContainer>
                                }
                                RichTextFieldProps={{
                                    variant: "outlined",
                                    MenuBarProps: {
                                        hide: true,
                                    }
                                }}>
                                {() => (
                                    <>
                                    <LinkBubbleMenu />
                                    <TableBubbleMenu />
                                    </>
                                )}
                            </RichTextEditor>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10}>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                {
                                    jawabanData.length === 0 ? <Typography fontSize={'24px'} textAlign={'center'} sx={{ marginY: '20px' }}>Tidak ada Jawaban</Typography> : 
                                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                        {
                                            jawabanData.map(j => 
                                                <ListItem 
                                                    key={j.jawaban_id}
                                                    alignItems="flex-start"
                                                    sx={{ borderBottom: '1px solid gray' }}
                                                    secondaryAction={ pertanyaanDataDetail.status === 'CLOSED' ? <IconButton onClick={() => {setJawabanDataDetail(j); setOpenModalLihatJawaban(true); }} size='small' edge="end" aria-label="edit">
                                                    <RemoveRedEyeOutlined />
                                                </IconButton> :
                                                        <React.Fragment>
                                                            <IconButton onClick={() => {setJawabanDataDetail(j); setOpenModalLihatJawaban(true); }} size='small' edge="end" aria-label="edit">
                                                                <RemoveRedEyeOutlined />
                                                            </IconButton>
                                                            {
                                                                profil.member_id === j.member_id && role.role_id === j.role_id ? <React.Fragment>
                                                                <IconButton onClick={() => {
                                                                    setTitleJawaban("Ubah Jawaban");
                                                                    setJawabanDataDetail({...jawabanDataDetail, jawaban_id: j.jawaban_id, title: j.title, subtitle: j.subtitle})
                                                                    setOpenModalJawaban(true);
                                                                }} size='small' edge="end" aria-label="edit">
                                                                    <Edit />
                                                                </IconButton>
                                                                <IconButton onClick={() => deleteJawaban(j)} size='small' edge="end" aria-label="delete">
                                                                    <Delete />
                                                                </IconButton>
                                                                </React.Fragment> : <></>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar alt={j.nama} src={j.avatar} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                    primary={j.nama}
                                                    secondary={
                                                        <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                        {reformatDate(j.created_at)} - 
                                                        </Typography> {j.title}  
                                                        </React.Fragment>
                                                    }
                                                    />
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                }
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                    component="div"
                                    count={settingTableJawaban.totalElements}
                                    rowsPerPage={settingTableJawaban.size}
                                    page={settingTableJawaban.page}
                                    onPageChange={handleChangePageJawaban}
                                    onRowsPerPageChange={handleChangeRowsPerPageJawaban}
                                />
                            </Paper>
                        </Grid>
                    </Grid> : 
                    <Grid container spacing={2} sx={{ marginTop: '20px', marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
                        <Grid item xs={12} sm={12} md={10}>
                            <Typography variant='h5' sx={{ marginBottom: '5px' }}>Tiket Pertanyaan</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10}>
                            <TextField
                                label="Judul"
                                id="Judul"
                                fullWidth
                                onChange={(e) => setPertanyaanDataDetail({...pertanyaanDataDetail, title: e.target.value})}
                                placeholder='Judul'
                                value={pertanyaanDataDetail.title}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><CommentRounded /></InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={10}>
                            <FormControl fullWidth>
                                <InputLabel id="kategori-pertanyaan-select">Kategori Pertanyaan</InputLabel>
                                <Select
                                    labelId="kategori-pertanyaan-select"
                                    id="kategori-pertanyaan_id"
                                    fullWidth
                                    value={pertanyaanDataDetail.kategori_pertanyaan_id}
                                    disabled={kategoriPertanyaanData.length === 0}
                                    label="Kategori Pertanyaan"
                                    onChange={(e) => setPertanyaanDataDetail({...pertanyaanDataDetail, kategori_pertanyaan_id: e.target.value})}
                                >
                                    {
                                        kategoriPertanyaanData.map(p => <MenuItem key={p.pertanyaan_kategori_id} value={p.pertanyaan_kategori_id}>{p.nama_kategori}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10}>
                            <FormControl fullWidth>
                                <InputLabel id="priority-select">Priority</InputLabel>
                                <Select
                                    labelId="priority-select"
                                    id="priority_id"
                                    fullWidth
                                    value={pertanyaanDataDetail.priority}
                                    disabled={Priority.length === 0}
                                    label="Priority"
                                    onChange={(e) => setPertanyaanDataDetail({...pertanyaanDataDetail, priority: e.target.value})}
                                >
                                    {
                                        Priority.map((p, index) => <MenuItem key={index} value={p}>{p}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10}>
                            <TextField
                                label="Nama"
                                id="nama"
                                fullWidth
                                disabled
                                placeholder='Nama'
                                value={titleDialog.split(" ").includes("Tambah") ? profil.nama : pertanyaanDataDetail.nama}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><AccountCircle /></InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={10}>
                            <TextField
                                label="Role"
                                id="role"
                                fullWidth
                                disabled
                                placeholder='Role'
                                value={titleDialog.split(" ").includes("Tambah") ? role.nama_role.replace('_', ' ') : pertanyaanDataDetail.nama_role.replace('_', ' ')}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><AccountCircle /></InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={10}>
                            <RichTextEditor
                                ref={rteRef}
                                content={pertanyaanDataDetail.subtitle}
                                extensions={extensions}
                                editable={isEditable}
                                editorProps={{
                                    handleDrop: handleDrop,
                                    handlePaste: handlePaste,
                                }}
                                renderControls={() => 
                                    <MenuControlsContainer>
                                        <MenuSelectFontFamily
                                            options={[
                                            { label: "Comic Sans", value: "Comic Sans MS, Comic Sans" },
                                            { label: "Cursive", value: "cursive" },
                                            { label: "Monospace", value: "monospace" },
                                            { label: "Serif", value: "serif" },
                                            ]}
                                        />
                                        <MenuDivider />
                                        <MenuSelectHeading />
                                        <MenuDivider />
                                        <MenuSelectFontSize />
                                        <MenuDivider />
                                        <MenuButtonBold />
                                        <MenuButtonItalic />
                                        <MenuButtonUnderline />
                                        <MenuButtonStrikethrough />
                                        <MenuButtonSubscript />
                                        <MenuButtonSuperscript />
                                        <MenuDivider />
                                        <MenuButtonTextColor
                                            defaultTextColor={theme.backgroundColor}
                                            swatchColors={[
                                            { value: "#000000", label: "Black" },
                                            { value: "#ffffff", label: "White" },
                                            { value: "#888888", label: "Grey" },
                                            { value: "#ff0000", label: "Red" },
                                            { value: "#ff9900", label: "Orange" },
                                            { value: "#ffff00", label: "Yellow" },
                                            { value: "#00d000", label: "Green" },
                                            { value: "#0000ff", label: "Blue" },
                                            ]}
                                        />

                                        <MenuButtonHighlightColor
                                            swatchColors={[
                                            { value: "#595959", label: "Dark grey" },
                                            { value: "#dddddd", label: "Light grey" },
                                            { value: "#ffa6a6", label: "Light red" },
                                            { value: "#ffd699", label: "Light orange" },
                                            { value: "#ffff00", label: "Yellow" },
                                            { value: "#99cc99", label: "Light green" },
                                            { value: "#90c6ff", label: "Light blue" },
                                            { value: "#8085e9", label: "Light purple" },
                                            ]}
                                        />
                                        <MenuDivider />
                                        <MenuButtonEditLink />
                                        <MenuDivider />
                                        <MenuSelectTextAlign />
                                        <MenuDivider />
                                        <MenuButtonOrderedList />
                                        <MenuButtonBulletedList />
                                        <MenuButtonTaskList />
                                        {isTouchDevice() && (<><MenuButtonIndent /><MenuButtonUnindent /></>)}
                                        <MenuDivider />
                                        <MenuButtonBlockquote />
                                        <MenuDivider />
                                        <MenuButtonCode />
                                        <MenuButtonCodeBlock />
                                        <MenuDivider />
                                        {/* <MenuButtonImageUpload onUploadFiles={handleUploadFiles}/> */}
                                        <MenuDivider />
                                        <MenuButtonHorizontalRule />
                                        <MenuButtonAddTable />
                                        <MenuDivider />
                                        <MenuButtonRemoveFormatting />
                                        <MenuDivider />
                                        <MenuButtonUndo />
                                        <MenuButtonRedo />
                                    </MenuControlsContainer>
                                }
                                RichTextFieldProps={{
                                    variant: "outlined",
                                    MenuBarProps: {
                                        hide: !showMenuBar,
                                    },
                                    footer: (
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        sx={{
                                            borderTopStyle: "solid",
                                            borderTopWidth: 1,
                                            borderTopColor: (theme) => theme.palette.divider,
                                            py: 1,
                                            px: 1.5,
                                        }}
                                    >
                                        <MenuButton
                                        value="formatting"
                                        tooltipLabel={
                                            showMenuBar ? "Hide formatting" : "Show formatting"
                                        }
                                        size="small"
                                        onClick={() =>
                                            setShowMenuBar((currentState) => !currentState)
                                        }
                                        selected={showMenuBar}
                                        IconComponent={TextFields}
                                        />
                                        <MenuButton
                                            value="formatting"
                                            tooltipLabel={
                                                isEditable
                                                ? "Prevent edits (use read-only mode)"
                                                : "Allow edits"
                                            }
                                            size="small"
                                            onClick={() => setIsEditable((currentState) => !currentState)}
                                            selected={!isEditable}
                                            IconComponent={isEditable ? Lock : LockOpen }
                                        />
                                    </Stack>
                                    ),
                                }}>
                                {() => (
                                    <>
                                    <LinkBubbleMenu />
                                    <TableBubbleMenu />
                                    </>
                                )}
                            </RichTextEditor>
                        </Grid>
                        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                            <Button startIcon={<SaveAs />} variant='contained' color='success' title='simpan' onClick={() => titleDialog.split(' ').includes('Tambah') ? createPertanyaan() : updatePertanyaan()}>Simpan</Button>
                        </Grid>
                    </Grid>
                }
            </Container>
            <ModalShowJawaban 
                openModalLihatJawaban={openModalLihatJawaban}
                setOpenModalLihatJawaban={setOpenModalLihatJawaban}
                jawabanDataDetail={jawabanDataDetail}
                rteRefJawaban={rteRefJawaban}
                theme={theme}
            />
            <DialogJawaban
                theme={theme}
                jawabanData={jawabanData}
                setJawabanData={setJawabanData}
                jawabanDataDetail={jawabanDataDetail}
                setJawabanDataDetail={setJawabanDataDetail}
                openModalJawaban={openModalJawaban}
                setOpenModalJawaban={setOpenModalJawaban}
                loadingSkeletonTableJawaban={loadingSkeletonTableJawaban}
                setLoadingSkeletonTableJawaban={setLoadingSkeletonTableJawaban}
                errorsJawaban={errorsJawaban}
                setErrorsJawaban={setErrorsJawaban}
                rteRefJawaban={rteRefJawaban}
                titleJawaban={titleJawaban}
                setTitleJawaban={setTitleJawaban}
                createJawaban={createJawaban}
                updateJawaban={updateJawaban}
            />
        </Dialog>
    );
}

export default DialogPengelolaanBantuan;

interface DialogJawabanProps {
    theme: ThemeStoreProps;
    jawabanData: JawabanProps[];
    setJawabanData: React.Dispatch<React.SetStateAction<JawabanProps[]>>;
    jawabanDataDetail: JawabanProps;
    setJawabanDataDetail: React.Dispatch<React.SetStateAction<JawabanProps>>;
    openModalJawaban: boolean;
    setOpenModalJawaban: React.Dispatch<React.SetStateAction<boolean>>;
    loadingSkeletonTableJawaban: boolean;
    setLoadingSkeletonTableJawaban: React.Dispatch<React.SetStateAction<boolean>>;
    errorsJawaban: ErrorJawabanProps;
    setErrorsJawaban: React.Dispatch<React.SetStateAction<ErrorJawabanProps>>;
    rteRefJawaban: any;
    titleJawaban: string;
    setTitleJawaban: React.Dispatch<React.SetStateAction<string>>;
    createJawaban: any;
    updateJawaban: any;
}

const DialogJawaban: React.FC<DialogJawabanProps> = ({
    theme,
    jawabanData,
    setJawabanData,
    jawabanDataDetail,
    setJawabanDataDetail,
    openModalJawaban,
    setOpenModalJawaban,
    loadingSkeletonTableJawaban,
    setLoadingSkeletonTableJawaban,
    errorsJawaban,
    setErrorsJawaban,
    rteRefJawaban,
    titleJawaban,
    setTitleJawaban,
    createJawaban,
    updateJawaban
}) => {
    const [isEditable, setIsEditable] = React.useState(true);
    const [showMenuBar, setShowMenuBar] = React.useState(true);

    const extensions = useExtensions({
        placeholder: "Isi Jawaban anda disini...",
    });

    function fileListToImageFiles(fileList: FileList): File[] {
        return Array.from(fileList).filter((file) => {
            const mimeType = (file.type || "").toLowerCase();
            return mimeType.startsWith("image/");
        });
    }

    const handleNewImageFiles = useCallback((files: File[], insertPosition?: number): void => {
        if (!rteRefJawaban.current?.editor) { return; }

        const attributesForImageFiles = files.map((file) => ({
            src: URL.createObjectURL(file),
            alt: file.name,
        }));
    
        insertImages({
            images: attributesForImageFiles,
            editor: rteRefJawaban.current.editor,
            // insertPosition
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDrop: NonNullable<EditorOptions["editorProps"]["handleDrop"]> = useCallback((view, event, _slice, _moved) => {
        if (!(event instanceof DragEvent) || !event.dataTransfer) {
            return false;
        }

        const imageFiles = fileListToImageFiles(event.dataTransfer.files);
        if (imageFiles.length > 0) {
            const insertPosition = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
            })?.pos;

            handleNewImageFiles(imageFiles, insertPosition);

          // Return true to treat the event as handled. We call preventDefault
          // ourselves for good measure.
            event.preventDefault();
            return true;
        }

        return false;
    },[handleNewImageFiles]);

    const handlePaste: NonNullable<EditorOptions["editorProps"]["handlePaste"]> = useCallback((_view, event, _slice) => {
        if (!event.clipboardData) {
            return false;
        }

        const pastedImageFiles = fileListToImageFiles(
            event.clipboardData.files,
        );
        if (pastedImageFiles.length > 0) {
            handleNewImageFiles(pastedImageFiles);
            return true;
        }
        return false;
    },[handleNewImageFiles]);
    
    return (
        <Dialog
            fullScreen
            open={openModalJawaban}
            onClose={() => setOpenModalJawaban(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setOpenModalJawaban(false)}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {titleJawaban}
                    </Typography>
                    <Button autoFocus startIcon={<Close />} color="inherit" onClick={() => setOpenModalJawaban(false)}>
                        Tutup
                    </Button>
                </Toolbar>
            </AppBar>
            <Container>
                <Grid container spacing={2} sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                    <Grid item xs={12} sm={12} md={10}>
                        <Typography variant='h5' sx={{ marginBottom: '5px' }}>Jawaban Anda</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <TextField
                            label="Judul"
                            id="JudulJawaban"
                            fullWidth
                            onChange={(e) => setJawabanDataDetail({...jawabanDataDetail, title: e.target.value})}
                            placeholder='Judul'
                            value={jawabanDataDetail.title}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><CommentRounded /></InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <RichTextEditor
                            ref={rteRefJawaban}
                            content={jawabanDataDetail.subtitle}
                            extensions={extensions}
                            editable={isEditable}
                            editorProps={{
                                handleDrop: handleDrop,
                                handlePaste: handlePaste,
                            }}
                            renderControls={() => 
                                <MenuControlsContainer>
                                    <MenuSelectFontFamily
                                        options={[
                                        { label: "Comic Sans", value: "Comic Sans MS, Comic Sans" },
                                        { label: "Cursive", value: "cursive" },
                                        { label: "Monospace", value: "monospace" },
                                        { label: "Serif", value: "serif" },
                                        ]}
                                    />
                                    <MenuDivider />
                                    <MenuSelectHeading />
                                    <MenuDivider />
                                    <MenuSelectFontSize />
                                    <MenuDivider />
                                    <MenuButtonBold />
                                    <MenuButtonItalic />
                                    <MenuButtonUnderline />
                                    <MenuButtonStrikethrough />
                                    <MenuButtonSubscript />
                                    <MenuButtonSuperscript />
                                    <MenuDivider />
                                    <MenuButtonTextColor
                                        defaultTextColor={theme.backgroundColor}
                                        swatchColors={[
                                        { value: "#000000", label: "Black" },
                                        { value: "#ffffff", label: "White" },
                                        { value: "#888888", label: "Grey" },
                                        { value: "#ff0000", label: "Red" },
                                        { value: "#ff9900", label: "Orange" },
                                        { value: "#ffff00", label: "Yellow" },
                                        { value: "#00d000", label: "Green" },
                                        { value: "#0000ff", label: "Blue" },
                                        ]}
                                    />

                                    <MenuButtonHighlightColor
                                        swatchColors={[
                                        { value: "#595959", label: "Dark grey" },
                                        { value: "#dddddd", label: "Light grey" },
                                        { value: "#ffa6a6", label: "Light red" },
                                        { value: "#ffd699", label: "Light orange" },
                                        { value: "#ffff00", label: "Yellow" },
                                        { value: "#99cc99", label: "Light green" },
                                        { value: "#90c6ff", label: "Light blue" },
                                        { value: "#8085e9", label: "Light purple" },
                                        ]}
                                    />
                                    <MenuDivider />
                                    <MenuButtonEditLink />
                                    <MenuDivider />
                                    <MenuSelectTextAlign />
                                    <MenuDivider />
                                    <MenuButtonOrderedList />
                                    <MenuButtonBulletedList />
                                    <MenuButtonTaskList />
                                    {isTouchDevice() && (<><MenuButtonIndent /><MenuButtonUnindent /></>)}
                                    <MenuDivider />
                                    <MenuButtonBlockquote />
                                    <MenuDivider />
                                    <MenuButtonCode />
                                    <MenuButtonCodeBlock />
                                    <MenuDivider />
                                    {/* <MenuButtonImageUpload onUploadFiles={handleUploadFiles}/> */}
                                    <MenuDivider />
                                    <MenuButtonHorizontalRule />
                                    <MenuButtonAddTable />
                                    <MenuDivider />
                                    <MenuButtonRemoveFormatting />
                                    <MenuDivider />
                                    <MenuButtonUndo />
                                    <MenuButtonRedo />
                                </MenuControlsContainer>
                            }
                                RichTextFieldProps={{
                                    variant: "outlined",
                                    MenuBarProps: {
                                        hide: !showMenuBar,
                                    },
                                    footer: (
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        sx={{
                                            borderTopStyle: "solid",
                                            borderTopWidth: 1,
                                            borderTopColor: (theme) => theme.palette.divider,
                                            py: 1,
                                            px: 1.5,
                                        }}
                                    >
                                        <MenuButton
                                        value="formatting"
                                        tooltipLabel={
                                            showMenuBar ? "Hide formatting" : "Show formatting"
                                        }
                                        size="small"
                                        onClick={() =>
                                            setShowMenuBar((currentState) => !currentState)
                                        }
                                        selected={showMenuBar}
                                        IconComponent={TextFields}
                                        />
                                        <MenuButton
                                            value="formatting"
                                            tooltipLabel={
                                                isEditable
                                                ? "Prevent edits (use read-only mode)"
                                                : "Allow edits"
                                            }
                                            size="small"
                                            onClick={() => setIsEditable((currentState) => !currentState)}
                                            selected={!isEditable}
                                            IconComponent={isEditable ? Lock : LockOpen }
                                        />
                                    </Stack>
                                    ),
                                }}>
                                {() => (
                                    <>
                                    <LinkBubbleMenu />
                                    <TableBubbleMenu />
                                    </>
                                )}
                        </RichTextEditor>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} justifyContent={'center'} display={'flex'}>
                        <Button color='success' onClick={() => titleJawaban.split(' ').includes('Tambah') ? createJawaban() : updateJawaban()} variant='contained' startIcon={<Edit />}>Simpan</Button>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    );
}

interface ModalShowJawabanProps {
    openModalLihatJawaban: boolean;
    setOpenModalLihatJawaban: React.Dispatch<React.SetStateAction<boolean>>;
    jawabanDataDetail: JawabanProps;
    rteRefJawaban: any;
    theme: ThemeStoreProps;
}

const ModalShowJawaban: React.FC<ModalShowJawabanProps> = ({
    openModalLihatJawaban, setOpenModalLihatJawaban, jawabanDataDetail, rteRefJawaban, theme
}) => {
    const extensions = useExtensions({
        placeholder: "Isi Jawaban anda disini...",
    });
    return (
        <Dialog
            open={openModalLihatJawaban}
            onClose={() => setOpenModalLihatJawaban(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Jawaban {jawabanDataDetail.title}</DialogTitle>
            <DialogContent>
                    <RichTextEditor
                        ref={rteRefJawaban}
                        content={jawabanDataDetail.subtitle}
                        extensions={extensions}
                        editable={false}
                        renderControls={() => 
                        <MenuControlsContainer>
                            <MenuSelectFontFamily
                                options={[
                                { label: "Comic Sans", value: "Comic Sans MS, Comic Sans" },
                                { label: "Cursive", value: "cursive" },
                                { label: "Monospace", value: "monospace" },
                                { label: "Serif", value: "serif" },
                                ]}
                            />
                            <MenuDivider />
                            <MenuSelectHeading />
                            <MenuDivider />
                            <MenuSelectFontSize />
                            <MenuDivider />
                            <MenuButtonBold />
                            <MenuButtonItalic />
                            <MenuButtonUnderline />
                            <MenuButtonStrikethrough />
                            <MenuButtonSubscript />
                            <MenuButtonSuperscript />
                            <MenuDivider />
                            <MenuButtonTextColor
                                defaultTextColor={theme.backgroundColor}
                                swatchColors={[
                                { value: "#000000", label: "Black" },
                                { value: "#ffffff", label: "White" },
                                { value: "#888888", label: "Grey" },
                                { value: "#ff0000", label: "Red" },
                                { value: "#ff9900", label: "Orange" },
                                { value: "#ffff00", label: "Yellow" },
                                { value: "#00d000", label: "Green" },
                                { value: "#0000ff", label: "Blue" },
                                ]}
                            />

                            <MenuButtonHighlightColor
                                swatchColors={[
                                { value: "#595959", label: "Dark grey" },
                                { value: "#dddddd", label: "Light grey" },
                                { value: "#ffa6a6", label: "Light red" },
                                { value: "#ffd699", label: "Light orange" },
                                { value: "#ffff00", label: "Yellow" },
                                { value: "#99cc99", label: "Light green" },
                                { value: "#90c6ff", label: "Light blue" },
                                { value: "#8085e9", label: "Light purple" },
                                ]}
                            />
                            <MenuDivider />
                            <MenuButtonEditLink />
                            <MenuDivider />
                            <MenuSelectTextAlign />
                            <MenuDivider />
                            <MenuButtonOrderedList />
                            <MenuButtonBulletedList />
                            <MenuButtonTaskList />
                            {isTouchDevice() && (<><MenuButtonIndent /><MenuButtonUnindent /></>)}
                            <MenuDivider />
                            <MenuButtonBlockquote />
                            <MenuDivider />
                            <MenuButtonCode />
                            <MenuButtonCodeBlock />
                            <MenuDivider />
                            <MenuDivider />
                            <MenuButtonHorizontalRule />
                            <MenuButtonAddTable />
                            <MenuDivider />
                            <MenuButtonRemoveFormatting />
                            <MenuDivider />
                            <MenuButtonUndo />
                            <MenuButtonRedo />
                        </MenuControlsContainer>
                    }
                        RichTextFieldProps={{
                            variant: "outlined",
                            MenuBarProps: {
                                hide: true,
                            }
                        }}>
                        {() => (
                            <>
                            <LinkBubbleMenu />
                            <TableBubbleMenu />
                            </>
                        )}
                    </RichTextEditor>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenModalLihatJawaban(false)}>Tutup</Button>
            </DialogActions>
        </Dialog>
    );
}