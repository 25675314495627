import React from 'react';
import { ErrorKategoriField, ErrorKategoriProps, KategoriField, KategoriProps } from '../../models/Forums/KategoriProps';
import { useRecoilValue } from 'recoil';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { ThemeStoreState } from '../../store';
import KategoriService from '../../services/Forum/KategoriService';
import { replaceItemAtIndex } from '../../Utils/helper';
import Swal from 'sweetalert2';
import { KategoriValidateForm } from '../../validation/ForumValidateForm';
import { Button, Container, Grid, Paper, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import DialogPengelolaanKategori from '../../pages/Superadmin/DialogPengelolaanKategori';
import { PeriodeProps } from '../../models/Forums/PeriodeProps';
import PeriodeService from '../../services/Forum/PeriodeService';

const SuperadminPengelolaanKategori = () => {
    const [periodeData, setPeriodeData] = React.useState<PeriodeProps[]>([]);
    const [errors, setErrors] = React.useState<ErrorKategoriProps>(ErrorKategoriField);
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const [loadingSkeletonTable, setLoadingSkeletonTable] = React.useState<boolean>(false);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [titleDialog, setTitleDialog] = React.useState<string>('');
    const [settingTable, setSettingTable] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'kategoriId',
        search: '',
        totalElements: 0,
        totalPages: 0,
    });
    
    const [kategoriData, setKategoriData] = React.useState<KategoriProps[]>([]);
    const [kategoriDataDetail, setKategoriDataDetail] = React.useState<KategoriProps>(KategoriField);
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState);

    const getAll = async () => {
        await KategoriService.getAll(settingTable.page, settingTable.size, settingTable.sort, settingTable.search).then(res => {
            setKategoriData(res.data.content);
            setSettingTable({...settingTable, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak ada jaringan");
            setOpenSnackbar(true);
        }).finally(() => {
            setLoadingSkeleton(false);
            setLoadingSkeletonTable(false);
        });
    }
    const getPeriode = async () => {
        if(periodeData.length === 0) {
            await PeriodeService.getAllArr().then(res => {
                setPeriodeData(res.data);
            }).catch(err => {
                setMessageSnackbar(err.response.data.message ?? "Tidak ada jaringan");
                setOpenSnackbar(true);
            });
        }
    }
    const openAdd = async () => {
        setTitleDialog("Tambah Kategori");
        setOpenModal(true);
    }
    const openById = (kategori: KategoriProps) => {
        setKategoriDataDetail(kategori);
        setTitleDialog("Ubah Kategori");
        setOpenModal(true);
    }
    const createKategori = async () => {
        const newErrors: any = {};
        try {
            await KategoriValidateForm.validate(kategoriDataDetail, {abortEarly:false});
            setErrors(ErrorKategoriField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await KategoriService.create(kategoriDataDetail).then(res => {
                setKategoriData([...kategoriData, res.data]);
                setSettingTable({...settingTable, totalElements: settingTable.totalElements + 1})
                setMessageSnackbar("Kategori berhasil di tambah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar(err.response.data.message ?? "Kategori gagal di tambah");
                setOpenSnackbar(true);
            })
        }
    }
    const updateKategori = async () => {
        const newErrors: any = {};
        try {
            await KategoriValidateForm.validate(kategoriDataDetail, {abortEarly:false});
            setErrors(ErrorKategoriField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await KategoriService.put(kategoriDataDetail.kategori_id, kategoriDataDetail).then(res => {
                let index = kategoriData.findIndex(p => p.kategori_id === kategoriDataDetail.kategori_id);
                setKategoriData(replaceItemAtIndex(kategoriData, index, res.data));
                setMessageSnackbar("Kategori berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Kategori gagal di ubah");
                setOpenSnackbar(true);
            })
        }
    }
    const deleteKategori = async (kategori: KategoriProps) => {
        Swal.fire({
            title: 'Hapus Kategori',
            text: 'Apakah anda ingin menghapus Kategori ' + kategori.nama_kategori + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                KategoriService.delete(kategori.kategori_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Kategori "+ kategori.nama_kategori +" sudah dihapus.",
                        icon: "success"
                    });
                    setKategoriData(kategoriData.filter(p => p.kategori_id !== kategori.kategori_id));
                    setSettingTable({...settingTable, totalElements: settingTable.totalElements - 1})
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: err.response.data.message ?? "Kategori "+ kategori.nama_kategori +" gagal dihapus.",
                        icon: "error"
                    });
                })
            }
        });
    }

    React.useEffect(() => {
        getPeriode();
        getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePage = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable,  page: newPage});
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTable({...settingTable, page: 0, size: +event.target.value});
    };

    return loadingSkeleton ? (<Container>
        <Skeleton animation="wave" width={'100%'} height={'576px'} variant='rounded'></Skeleton>
    </Container>) : (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: '10px' }}>
                    <Grid container spacing={2} sx={{ paddingBottom: '20px' }}>
                        <Grid item xs={8} sm={8}>
                            <Typography variant='h3' fontSize={'24px'}>Kategori</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} display={'flex'} justifyContent={'flex-end'}>
                            <Button startIcon={<Add />} onClick={() => openAdd()} title='tambah' color='info' variant='contained'>Tambah</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {
                loadingSkeletonTable ? <Skeleton animation={'wave'} width={'100%'} height={'500px'} /> :
                <Grid item xs={12} sm={12} md={12}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 540 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Periode</TableCell>
                                        <TableCell>Kategori</TableCell>
                                        <TableCell>Deskripsi</TableCell>
                                        <TableCell>Aksi</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    kategoriData.length !== 0 ? kategoriData.map(p => 
                                    <TableRow key={p.kategori_id}>
                                        <TableCell>{p.nama_periode}</TableCell>
                                        <TableCell>{p.nama_kategori}</TableCell>
                                        <TableCell>{p.deksripsi}</TableCell>
                                        <TableCell>
                                            <Button sx={{ marginLeft: '5px' }} onClick={() => openById(p)} size='small' variant='contained' startIcon={<Edit />} color='success'>Ubah</Button>
                                            <Button sx={{ marginLeft: '5px' }} onClick={() => deleteKategori(p)} size='small' variant='contained' startIcon={<Delete />} color='error'>Hapus</Button>
                                        </TableCell>
                                    </TableRow> 
                                    )
                                    : <TableRow>
                                        <TableCell colSpan={6}>
                                            <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                        component="div"
                        count={settingTable.totalElements}
                        rowsPerPage={settingTable.size}
                        page={settingTable.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Paper>
                </Grid>
                }
                <Snackbar
                    open={openSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={3000}
                    onClose={() => setOpenSnackbar(false)}
                    message={messageSnackbar}
                />
                <DialogPengelolaanKategori
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    theme={theme}
                    titleDialog={titleDialog}
                    errors={errors}
                    setErrors={setErrors}
                    kategoriDataDetail={kategoriDataDetail}
                    setKategoriDataDetail={setKategoriDataDetail}
                    createKategori={createKategori}
                    updateKategori={updateKategori}
                    periodeData={periodeData}
                />
            </Grid>
        </Container>
    );
}

export default SuperadminPengelolaanKategori;
