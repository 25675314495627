import React from 'react';

const KonstituenPencarianTps = () => {
    return (
        <div>
            KonstituenPencarianTps
        </div>
    );
}

export default KonstituenPencarianTps;
