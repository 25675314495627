import React from 'react';

const PemantauanHasilPemilu = () => {
    return (
        <div>
            PemantauanHasilPemilu
        </div>
    );
}

export default PemantauanHasilPemilu;
