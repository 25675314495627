import KtpProps, { KtpField } from "../Member/KtpProps";
import MemberProps, { MemberField } from "../Member/MemberProps";
import ReferenceProps, { ReferenceField } from "../Reference/ReferenceProps";
import RoleProps from "../Role/RoleProps";
import { KonstituenMapProps } from "./BerandaRelawanResponse";

export default interface RelawanDetailKonstituen {
    ktp: KtpProps;
    member: MemberProps;
    username: string;
    referensi: ReferenceProps;
    role: RoleProps[];
    map: KonstituenMapProps[];
}

export const RelawanDetailKonstituenField: RelawanDetailKonstituen = {
    ktp: KtpField,
    member: MemberField,
    username: '',
    referensi: ReferenceField,
    role: [],
    map: []
}