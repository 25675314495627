import axios, { AxiosResponse } from 'axios';
import { PertanyaanFileProps } from '../../models/Bantuan/PertanyaanFileProps';

class PertanyaanFilesServices {
    async getAll(pertanyaanId: string): Promise<AxiosResponse<PertanyaanFileProps[]>> {
        return axios.get(`/api/v1/bantuan/pertanyaan-file?pertanyaanId=${pertanyaanId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(pertanyaanId: string, data: FormData): Promise<AxiosResponse<PertanyaanFileProps>> {
        return axios.post(`/api/v1/bantuan/pertanyaan-file?pertanyaanId=${pertanyaanId}`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async delete(pertanyaanFileId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/bantuan/pertanyaan-file?pertanyaanFileId=${pertanyaanFileId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PertanyaanFilesServices();