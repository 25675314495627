import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import {RwProps} from '../../models/Area/RwProps';

class RwServices {
    async getAll(page: number, size: number, sort: string, search: string, desaId: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/area/rw?page=${page}&size=${size}&sort=${sort}&search=${search}&desaId=${desaId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllArr(desaId: string): Promise<AxiosResponse<RwProps[]>> {
        return axios.get(`/api/v1/area/rw/arr?desaId=${desaId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(rwId: string): Promise<AxiosResponse<RwProps>> {
        return axios.get(`/api/v1/area/rw/${rwId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: RwProps): Promise<AxiosResponse<RwProps>> {
        return axios.post(`/api/v1/area/rw`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(rwId: string, data : RwProps): Promise<AxiosResponse<RwProps>> {
        return axios.put(`/api/v1/area/rw/${rwId}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async delete(rwId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/area/rw/${rwId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RwServices();