import React from 'react';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { TransitionProps } from '@mui/material/transitions';
import { AppBar, Box, Button, Container, Dialog, FormControl, Grid, IconButton, InputLabel, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Select, Skeleton, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import { AccountBox, Add, AlternateEmail, AssuredWorkload, CalendarMonth, Cancel, Close, Female, Home, Male, Phone, ReduceCapacity, Search, Transgender, VerifiedUser, WhatsApp } from '@mui/icons-material';
import { InfoWindow, Map, Marker } from '@vis.gl/react-google-maps';
import { DetailMemberProps, KonstituenForRelawanMapField, KonstituenForRelawanMapProps } from '../../models/Member/DetailMemberProps';
import Image from "../../asset/images/default.png";
import { ProvinsiProps } from '../../models/Area/ProvinsiProps';
import { KabupatenProps } from '../../models/Area/KabupatenProps';
import { KecamatanProps } from '../../models/Area/KecamatanProps';
import { DesaProps } from '../../models/Area/DesaProps';
import { RwProps } from '../../models/Area/RwProps';
import { RtProps } from '../../models/Area/RtProps';
import { TpsProps } from '../../models/Area/TpsProps';
import ListMemberProps from '../../models/Member/ListMemberProps';
import { KonstituenRelawanSayaProps } from '../../models/Member/KonstituenRelawanSayaProps';
import ModalCreateRelasiToKonstituen from './ModalCreateRelasiToKonstituen';

interface DetailRelawanMemberProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    titleDialog: string;
    messageSnackbar: string;
    theme: ThemeStoreProps;
    relawanDataDetail: DetailMemberProps;
    createPerson: any;
    loadingSkeletonTableTambah: boolean;
    loadingSkeletonTambah: boolean;
    setLoadingSkeletonTambah: React.Dispatch<React.SetStateAction<boolean>>;
    getProvinsiTambah: any;
    getKabupatenTambah: any;
    getKecamatanTambah: any;
    getDesaTambah: any;
    getRwTambah: any;
    getRtTambah: any;
    getTpsTambah: any;
    relawanDataTambah: ListMemberProps[];
    provinsiDataTambah: ProvinsiProps[];
    kabupatenDataTambah: KabupatenProps[];
    setSettingTableTambah: React.Dispatch<React.SetStateAction<{
        page: number;
        size: number;
        sort: string;
        search: string;
        provinsiId: string;
        kabupatenId: string;
        kecamatanId: string;
        desaId: string;
        rwId: string;
        rtId: string;
        tpsId: string;
        hakPilih: string;
        totalElements: number;
        totalPages: number;
    }>>;
    settingTableTambah: {
        page: number;
        size: number;
        sort: string;
        search: string;
        provinsiId: string;
        kabupatenId: string;
        kecamatanId: string;
        desaId: string;
        rwId: string;
        rtId: string;
        tpsId: string;
        hakPilih: string;
        totalElements: number;
        totalPages: number;
    };
    kecamatanDataTambah: KecamatanProps[];
    desaDataTambah: DesaProps[];
    rwDataTambah: RwProps[];
    rtDataTambah: RtProps[];
    tpsDataTambah: TpsProps[];
    handleChangePageTambah: any;
    handleChangeRowsPerPageTambah: any;
    clearFilterTambah: any;
    loadingSkeletonDetail: boolean;
    setLoadingSkeletonDetail: React.Dispatch<React.SetStateAction<boolean>>;
    loadingSkeletonTableDetail: boolean;
    setLoadingSkeletonTableDetail: React.Dispatch<React.SetStateAction<boolean>>;
    settingTableDetail: {
        page: number;
        size: number;
        sort: string;
        search: string;
        provinsiId: string;
        kabupatenId: string;
        kecamatanId: string;
        desaId: string;
        rwId: string;
        rtId: string;
        tpsId: string;
        hakPilih: string;
        totalElements: number;
        totalPages: number;
    }
    setSettingTableDetail: React.Dispatch<React.SetStateAction<{
        page: number;
        size: number;
        sort: string;
        search: string;
        provinsiId: string;
        kabupatenId: string;
        kecamatanId: string;
        desaId: string;
        rwId: string;
        rtId: string;
        tpsId: string;
        hakPilih: string;
        totalElements: number;
        totalPages: number;
    }>>;
    konstituenRelawanSayaDataDetail: KonstituenRelawanSayaProps[];
    handleChangePageDetail: any;
    handleChangeRowsPerPageDetail: any;
    addRelasiKonstituen: any;
    removeRelasiKonstituen: any;
    openModalRelasi: boolean;
    setOpenModalRelasi: React.Dispatch<React.SetStateAction<boolean>>;
    checkNikToRelasiRelawan: any;
    dataTempTambahRelasi: ListMemberProps;
    formNik: string;
    setFormNik: React.Dispatch<React.SetStateAction<string>>;
    clearFilterDetail: any;
    getProvinsiDetail: any;
    getKabupatenDetail: any;
    getKecamatanDetail: any;
    getDesaDetail: any;
    getRwDetail: any;
    getRtDetail: any;
    getTpsDetail: any;
    provinsiDataDetail: ProvinsiProps[];
    kabupatenDataDetail: KabupatenProps[];
    kecamatanDataDetail: KecamatanProps[];
    desaDataDetail: DesaProps[];
    rwDataDetail: RwProps[];
    rtDataDetail: RtProps[];
    tpsDataDetail: TpsProps[];
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DetailRelawanMember: React.FC<DetailRelawanMemberProps> = ({ openModal, setOpenModal, titleDialog, messageSnackbar, theme, relawanDataDetail, createPerson, loadingSkeletonTableTambah, loadingSkeletonTambah, setLoadingSkeletonTambah, getProvinsiTambah,settingTableTambah,setSettingTableTambah, getKabupatenTambah, getKecamatanTambah, getDesaTambah, getRwTambah, getRtTambah, getTpsTambah, relawanDataTambah, provinsiDataTambah, kabupatenDataTambah, kecamatanDataTambah, desaDataTambah, rwDataTambah, rtDataTambah, tpsDataTambah, handleChangePageTambah, handleChangeRowsPerPageTambah, clearFilterTambah, loadingSkeletonDetail, setLoadingSkeletonDetail, loadingSkeletonTableDetail, setLoadingSkeletonTableDetail, settingTableDetail, setSettingTableDetail, konstituenRelawanSayaDataDetail, handleChangePageDetail, handleChangeRowsPerPageDetail, addRelasiKonstituen, removeRelasiKonstituen, openModalRelasi, setOpenModalRelasi, checkNikToRelasiRelawan, dataTempTambahRelasi, formNik, setFormNik, clearFilterDetail, getProvinsiDetail, getKabupatenDetail, getKecamatanDetail, getDesaDetail, getRwDetail, getRtDetail, getTpsDetail, provinsiDataDetail, kabupatenDataDetail, kecamatanDataDetail, desaDataDetail, rwDataDetail, rtDataDetail, tpsDataDetail}) => {
    const [selectedMarker, setSelectedMarker] = React.useState<KonstituenForRelawanMapProps>(KonstituenForRelawanMapField);

    const [search, setSearch] = React.useState<string>('');
    React.useEffect(() => {
        setSelectedMarker(KonstituenForRelawanMapField)
        getProvinsiTambah();
    }, []);
    return (
        <Dialog
            fullScreen
            open={openModal}
            onClose={() => setOpenModal(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setOpenModal(false)}
                    aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {titleDialog}
                    </Typography>
                    <Button autoFocus startIcon={<Close />} color="inherit" onClick={() => setOpenModal(false)}>
                        Tutup
                    </Button>
                </Toolbar>
            </AppBar>
            {
                titleDialog.split(" ").includes("Tambah") ? <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container spacing={2} sx={{ paddingTop: '50px', paddingBottom: '20px' }}>
                                <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="provinsi-select">Provinsi</InputLabel>
                                        <Select
                                            labelId="provinsi-select"
                                            id="provinsi_id"
                                            value={settingTableTambah.provinsiId}
                                            disabled={provinsiDataTambah.length === 0}
                                            label="Provinsi"
                                            onChange={(e) => {setSettingTableTambah({...settingTableTambah, provinsiId: e.target.value, kecamatanId: '', kabupatenId: '', desaId: '', rwId: '', rtId: '', tpsId: ''}); getKabupatenTambah(e.target.value);}}
                                        >
                                            {
                                                provinsiDataTambah.map(p => <MenuItem key={p.provinsi_id} value={p.provinsi_id}>{p.nama_provinsi}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="kabupaten-select">Kabupaten</InputLabel>
                                        <Select
                                            labelId="kabupaten-select"
                                            id="kabupaten_id"
                                            value={settingTableTambah.kabupatenId}
                                            disabled={kabupatenDataTambah.length === 0}
                                            label="Kabupaten"
                                            onChange={(e) => {setSettingTableTambah({...settingTableTambah, kabupatenId: e.target.value, kecamatanId: '', desaId: '', rwId: '', rtId: '', tpsId: ''}); getKecamatanTambah(e.target.value)}}
                                        >
                                            {
                                                kabupatenDataTambah.map(p => <MenuItem key={p.kabupaten_id} value={p.kabupaten_id}>{p.nama_kabupaten}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="kecamatan-select">Kecamatan</InputLabel>
                                        <Select
                                            labelId="kecamatan-select"
                                            id="kecamatan_id"
                                            value={settingTableTambah.kecamatanId}
                                            disabled={kecamatanDataTambah.length === 0}
                                            label="Kecamatan"
                                            onChange={(e) => {setSettingTableTambah({...settingTableTambah, kecamatanId: e.target.value, desaId: '', rwId: '', rtId: '', tpsId: ''}); getDesaTambah(e.target.value)}}
                                        >
                                            {
                                                kecamatanDataTambah.map(p => <MenuItem key={p.kecamatan_id} value={p.kecamatan_id}>{p.nama_kecamatan}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="desa-select">Desa</InputLabel>
                                        <Select
                                            labelId="desa-select"
                                            id="desa_id"
                                            value={settingTableTambah.desaId}
                                            disabled={desaDataTambah.length === 0}
                                            label="Desa"
                                            onChange={(e) => {setSettingTableTambah({...settingTableTambah, desaId: e.target.value, rwId: '', rtId: '', tpsId: ''}); getRwTambah(e.target.value);}}
                                        >
                                            {
                                                desaDataTambah.map(p => <MenuItem key={p.desa_id} value={p.desa_id}>{p.nama_desa}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="rw-select">RW</InputLabel>
                                        <Select
                                            labelId="rw-select"
                                            id="rw_id"
                                            value={settingTableTambah.rwId}
                                            disabled={rwDataTambah.length === 0}
                                            label="RW"
                                            onChange={(e) => {setSettingTableTambah({...settingTableTambah, rwId: e.target.value, rtId: '', tpsId: ''}); getRtTambah(e.target.value); getTpsTambah(e.target.value, null)}}
                                        >
                                            {
                                                rwDataTambah.map(p => <MenuItem key={p.rw_id} value={p.rw_id}>{p.nama_rw}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="rt-select">RT</InputLabel>
                                        <Select
                                            labelId="rt-select"
                                            id="rt_id"
                                            value={settingTableTambah.rtId}
                                            disabled={rtDataTambah.length === 0}
                                            label="RT"
                                            onChange={(e) => {setSettingTableTambah({...settingTableTambah, rtId: e.target.value, tpsId: ''}); getTpsTambah(settingTableTambah.rwId, e.target.value)}}
                                        >
                                            {
                                                rtDataTambah.map(p => <MenuItem key={p.rt_id} value={p.rt_id}>{p.nama_rt}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="tps-select">TPS</InputLabel>
                                        <Select
                                            labelId="tps-select"
                                            id="tps_id"
                                            value={settingTableTambah.tpsId}
                                            disabled={tpsDataTambah.length === 0}
                                            label="TPS"
                                            onChange={(e) => setSettingTableTambah({...settingTableTambah, tpsId: e.target.value})}
                                        >
                                            {
                                                tpsDataTambah.map(p => <MenuItem key={p.tps_id} value={p.tps_id}>{p.nama_tps}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                        <InputLabel id="hak_pilih-select">Hak Pilih</InputLabel>
                                        <Select
                                            labelId="hak_pilih-select"
                                            id="hak_pilih_id"
                                            value={settingTableTambah.hakPilih}
                                            label="Hak Pilih Member"
                                            onChange={(e) => setSettingTableTambah({...settingTableTambah, hakPilih: e.target.value})}
                                        >
                                            <MenuItem value={'null'}>{'Semua Hak Pilih'}</MenuItem>
                                            <MenuItem value={'true'}>{'Punya Hak Milik'}</MenuItem>
                                            <MenuItem value={'false'}>{'Tidak ada Hak Pilih'}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {
                                    settingTableTambah.provinsiId !== '' ? 
                                <Grid item xs={12} sm={12} md={4} lg={3} justifyContent={'center'} alignItems={'center'}>
                                    <Button color='error' variant='contained' title='Bersihkan Filter' startIcon={<Cancel />} onClick={() => clearFilterTambah()}>Clear Filter</Button>
                                </Grid> : <></>
                                }
                            </Grid>
                        </Grid>
                        {
                            loadingSkeletonTableTambah ? <Skeleton width={'100%'} height={'500px'} /> :
                            <Grid item xs={12} sm={12} md={12}>
                                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                        <TableContainer sx={{ maxHeight: 540 }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nama</TableCell>
                                                        <TableCell>Jenis Kelamin</TableCell>
                                                        <TableCell>TTL</TableCell>
                                                        <TableCell>Username</TableCell>
                                                        <TableCell>Aksi</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                    relawanDataTambah.length !== 0 ? relawanDataTambah.map(p => 
                                                        <TableRow key={p.member_id}>
                                                            <TableCell>{p.nama}</TableCell>
                                                            <TableCell>{p.jenis_kelamin}</TableCell>
                                                            <TableCell>{p.tempat_lahir + ', ' + p.tanggal_lahir}</TableCell>
                                                            <TableCell>{p.username}</TableCell>
                                                            <TableCell><Button sx={{ marginLeft: '5px' }} onClick={() => createPerson(p)} size='small' variant='contained' startIcon={<Add />} color='primary'>Tambah</Button></TableCell>
                                                    </TableRow> 
                                                        )
                                                    : <TableRow>
                                                        <TableCell colSpan={6}>
                                                            <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                            component="div"
                                            count={settingTableTambah.totalElements}
                                            rowsPerPage={settingTableTambah.size}
                                            page={settingTableTambah.page}
                                            onPageChange={handleChangePageTambah}
                                            onRowsPerPageChange={handleChangeRowsPerPageTambah}
                                        />
                                    </Paper>
                            </Grid>
                        }
                    </Grid>
                </Container> : <Container>
                    <Grid container spacing={3} sx={{ marginY: '10px' }}>
                        <Grid item xs={12} sm={12} md={4}>
                            <Typography variant='h6'>Profil</Typography>
                            <Box sx={{ width: '100%' }}>
                                {
                                    relawanDataDetail !== null ?
                                    <img src={relawanDataDetail?.member.avatar !== null ? relawanDataDetail?.member.avatar : Image} alt={relawanDataDetail?.ktp.nama} width={'100%'} /> :
                                    <img src={Image} alt={'default'} width={'100%'} />
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <Typography variant='h6'>Informasi Konstituen</Typography>
                            <List component="nav" aria-label="main mailbox folders">
                                <ListItemButton>
                                    <ListItemIcon><AccountBox /></ListItemIcon>
                                    <ListItemText primary={relawanDataDetail?.ktp.nama ?? 'Belum Ada Nama'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon>{ relawanDataDetail?.ktp.jenis_kelamin === null ? <Transgender /> : relawanDataDetail?.ktp.jenis_kelamin === 'PRIA' ? <Male /> : <Female /> }</ListItemIcon>
                                    <ListItemText primary={relawanDataDetail?.ktp.jenis_kelamin ?? 'Belum Ada Jenis Kelamin'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><CalendarMonth /></ListItemIcon>
                                    <ListItemText primary={relawanDataDetail?.ktp.tempat_lahir + ', ' + relawanDataDetail?.ktp.tanggal_lahir ?? 'Belum Ada Kode Referensi'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><AlternateEmail /></ListItemIcon>
                                    <ListItemText primary={relawanDataDetail?.member.email ?? 'Belum Ada Email'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><Phone /></ListItemIcon>
                                    <ListItemText primary={relawanDataDetail?.member.no_hp ?? 'Belum Ada Nomor HP'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><WhatsApp /></ListItemIcon>
                                    <ListItemText primary={relawanDataDetail?.member.no_wa ?? 'Belum Ada Nomor WhatsApp'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><VerifiedUser /></ListItemIcon>
                                    <ListItemText primary={relawanDataDetail?.username ?? 'Belum Ada Username'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><AssuredWorkload /></ListItemIcon>
                                    <ListItemText primary={relawanDataDetail?.member.hak_pilih ? 'Ada Hak Pilih di TPS' : 'Tidak ada Hak Pilih'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><Home /></ListItemIcon>
                                    <ListItemText primary={relawanDataDetail?.ktp.alamat ?? 'Belum Ada Alamat'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><ReduceCapacity /></ListItemIcon>
                                    <ListItemText primary={relawanDataDetail?.referensi.kode_referensi ?? 'Tidak Ada Referensi'} />
                                </ListItemButton>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} sx={{ marginY: '10px' }}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='h6'>Sebaran Data Konstituen ({relawanDataDetail.konstituen_list.length})</Typography>
                            <Map
                                defaultZoom={8}
                                style={{ width: '100%', height: '520px' }}
                                defaultCenter={ { lat: process.env.REACT_APP_MAPS_LATTITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LATTITUDE) : -4.55858490, lng: process.env.REACT_APP_MAPS_LONGITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LONGITUDE) : 105.40680790 } }
                                onClick={(e) => {}}
                            >
                                {
                                    relawanDataDetail.area.length > 0 ? relawanDataDetail.area.map(a => <Marker position={{lat: parseFloat(a.lattitude), lng: parseFloat(a.longitude)}} icon={{
                                        url: 'http://maps.google.com/mapfiles/kml/pal3/icon56.png',
                                        scaledSize: new google.maps.Size(32, 32)
                                    }} key={a.area_member_id} onClick={() => setSelectedMarker({
                                        konstituen_id: relawanDataDetail.konstituen.konstituen_id,
                                        member_id: relawanDataDetail.member.member_id,
                                        nama: "Rumah Saya", 
                                        lattitude: a.lattitude, 
                                        longitude: a.longitude,
                                        username: relawanDataDetail.username,
                                        alamat: relawanDataDetail.ktp.alamat, 
                                        avatar: relawanDataDetail.member.avatar,
                                        hak_pilih: relawanDataDetail.member.hak_pilih,
                                    })} />) : <></>
                                }
                                {
                                    relawanDataDetail.konstituen_list.length > 0 ? relawanDataDetail.konstituen_list.map(k => <Marker position={{lat: parseFloat(k.lattitude), lng: parseFloat(k.longitude)}} key={k.konstituen_id} onClick={() => setSelectedMarker(k)} />) : <></>
                                }
                                {selectedMarker.lattitude === '' ? <></> : (
                                    <InfoWindow
                                        headerContent={'Konstituen: ' + selectedMarker.nama}
                                        position={{ lat: parseFloat(selectedMarker.lattitude), lng: parseFloat(selectedMarker.longitude) }}
                                        onCloseClick={() => setSelectedMarker(KonstituenForRelawanMapField)}
                                    >
                                        <Box>
                                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                                <img src={selectedMarker.avatar} width={'75px'} height={'75px'} alt={selectedMarker.nama} />
                                            </Box>
                                            <List component="nav" aria-label="main mailbox folders">
                                                <ListItemButton>
                                                    <ListItemIcon><AccountBox /></ListItemIcon>
                                                    <ListItemText primary={selectedMarker.nama ?? 'Belum Ada Nama'} />
                                                </ListItemButton>
                                                <ListItemButton>
                                                    <ListItemIcon><Home /></ListItemIcon>
                                                    <ListItemText primary={selectedMarker.alamat ?? 'Belum Ada Alamat'} />
                                                </ListItemButton>
                                                <ListItemButton>
                                                    <ListItemIcon><VerifiedUser /></ListItemIcon>
                                                    <ListItemText primary={selectedMarker.username ?? 'Belum Ada Username'} />
                                                </ListItemButton>
                                                <ListItemButton>
                                                    <ListItemIcon><AssuredWorkload /></ListItemIcon>
                                                    <ListItemText primary={selectedMarker.hak_pilih ? 'Terdapat Hak Pilih' : 'Tidak Memiliki Hak Pilih' ?? 'Belum Ada Hak Pilih'} />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </InfoWindow>
                                )}
                            </Map>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginTop: '5px', marginBottom: '20px' }}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography variant='h4' fontSize={'20px'} sx={{ marginTop: '10px' }}>Sebaran Data Konstituen</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Grid container spacing={1} alignContent={'center'} alignItems={'center'} justifyItems={'center'}>
                                <Grid item xs={6}>
                                    <TextField fullWidth id="cari" label="Cari" variant="outlined" value={search} onChange={(e) => setSearch(e.target.value)} />
                                </Grid>
                                <Grid item xs={3}>
                                    <Button startIcon={<Search />} fullWidth onClick={() => setSettingTableDetail({...settingTableDetail, search: search})} title='Cari' color='info' variant='contained'>Cari</Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button startIcon={<Add />} fullWidth title='Tambah Konstituen' color='success' variant='contained' onClick={() => {setOpenModalRelasi(true); setFormNik('');}}>Tambah</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ paddingTop: '50px', paddingBottom: '20px' }}>
                        <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                <InputLabel id="provinsi-select">Provinsi</InputLabel>
                                <Select
                                    labelId="provinsi-select"
                                    id="provinsi_id"
                                    value={settingTableDetail.provinsiId}
                                    disabled={provinsiDataDetail.length === 0}
                                    label="Provinsi"
                                    onChange={(e) => {setSettingTableDetail({...settingTableDetail, provinsiId: e.target.value, kecamatanId: '', kabupatenId: '', desaId: '', rwId: '', rtId: '', tpsId: ''}); getKabupatenDetail(e.target.value);}}
                                >
                                    {
                                        provinsiDataDetail.map(p => <MenuItem key={p.provinsi_id} value={p.provinsi_id}>{p.nama_provinsi}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                <InputLabel id="kabupaten-select">Kabupaten</InputLabel>
                                <Select
                                    labelId="kabupaten-select"
                                    id="kabupaten_id"
                                    value={settingTableDetail.kabupatenId}
                                    disabled={kabupatenDataDetail.length === 0}
                                    label="Kabupaten"
                                    onChange={(e) => {setSettingTableDetail({...settingTableDetail, kabupatenId: e.target.value, kecamatanId: '', desaId: '', rwId: '', rtId: '', tpsId: ''}); getKecamatanDetail(e.target.value)}}
                                >
                                    {
                                        kabupatenDataDetail.map(p => <MenuItem key={p.kabupaten_id} value={p.kabupaten_id}>{p.nama_kabupaten}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                <InputLabel id="kecamatan-select">Kecamatan</InputLabel>
                                <Select
                                    labelId="kecamatan-select"
                                    id="kecamatan_id"
                                    value={settingTableDetail.kecamatanId}
                                    disabled={kecamatanDataDetail.length === 0}
                                    label="Kecamatan"
                                    onChange={(e) => {setSettingTableDetail({...settingTableDetail, kecamatanId: e.target.value, desaId: '', rwId: '', rtId: '', tpsId: ''}); getDesaDetail(e.target.value)}}
                                >
                                    {
                                        kecamatanDataDetail.map(p => <MenuItem key={p.kecamatan_id} value={p.kecamatan_id}>{p.nama_kecamatan}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                <InputLabel id="desa-select">Desa</InputLabel>
                                <Select
                                    labelId="desa-select"
                                    id="desa_id"
                                    value={settingTableDetail.desaId}
                                    disabled={desaDataDetail.length === 0}
                                    label="Desa"
                                    onChange={(e) => {setSettingTableDetail({...settingTableDetail, desaId: e.target.value, rwId: '', rtId: '', tpsId: ''}); getRwDetail(e.target.value);}}
                                >
                                    {
                                        desaDataDetail.map(p => <MenuItem key={p.desa_id} value={p.desa_id}>{p.nama_desa}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                <InputLabel id="rw-select">RW</InputLabel>
                                <Select
                                    labelId="rw-select"
                                    id="rw_id"
                                    value={settingTableDetail.rwId}
                                    disabled={rwDataDetail.length === 0}
                                    label="RW"
                                    onChange={(e) => {setSettingTableDetail({...settingTableDetail, rwId: e.target.value, rtId: '', tpsId: ''}); getRtDetail(e.target.value); getTpsDetail(e.target.value, null)}}
                                >
                                    {
                                        rwDataDetail.map(p => <MenuItem key={p.rw_id} value={p.rw_id}>{p.nama_rw}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                <InputLabel id="rt-select">RT</InputLabel>
                                <Select
                                    labelId="rt-select"
                                    id="rt_id"
                                    value={settingTableDetail.rtId}
                                    disabled={rtDataDetail.length === 0}
                                    label="RT"
                                    onChange={(e) => {setSettingTableDetail({...settingTableDetail, rtId: e.target.value, tpsId: ''}); getTpsDetail(settingTableDetail.rwId, e.target.value)}}
                                >
                                    {
                                        rtDataDetail.map(p => <MenuItem key={p.rt_id} value={p.rt_id}>{p.nama_rt}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                <InputLabel id="tps-select">TPS</InputLabel>
                                <Select
                                    labelId="tps-select"
                                    id="tps_id"
                                    value={settingTableDetail.tpsId}
                                    disabled={tpsDataDetail.length === 0}
                                    label="TPS"
                                    onChange={(e) => setSettingTableDetail({...settingTableDetail, tpsId: e.target.value})}
                                >
                                    {
                                        tpsDataDetail.map(p => <MenuItem key={p.tps_id} value={p.tps_id}>{p.nama_tps}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                <InputLabel id="hak_pilih-select">Hak Pilih</InputLabel>
                                <Select
                                    labelId="hak_pilih-select"
                                    id="hak_pilih_id"
                                    value={settingTableDetail.hakPilih}
                                    label="Hak Pilih Member"
                                    onChange={(e) => setSettingTableDetail({...settingTableDetail, hakPilih: e.target.value})}
                                >
                                    <MenuItem value={'null'}>{'Semua Hak Pilih'}</MenuItem>
                                    <MenuItem value={'true'}>{'Punya Hak Milik'}</MenuItem>
                                    <MenuItem value={'false'}>{'Tidak ada Hak Pilih'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            settingTableDetail.provinsiId !== '' ? 
                        <Grid item xs={12} sm={12} md={4} lg={3} justifyContent={'center'} alignItems={'center'}>
                            <Button color='error' variant='contained' title='Bersihkan Filter' startIcon={<Cancel />} onClick={() => clearFilterDetail()}>Clear Filter</Button>
                        </Grid> : <></>
                        }
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: '50px' }}>
                    {
                            loadingSkeletonTableDetail ? <Skeleton width={'100%'} height={'500px'} /> :
                            <Grid item xs={12} sm={12} md={12}>
                                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                        <TableContainer sx={{ maxHeight: 540 }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nama</TableCell>
                                                        <TableCell>Username</TableCell>
                                                        <TableCell>TTL</TableCell>
                                                        <TableCell>No. HP</TableCell>
                                                        <TableCell>Aksi</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                    konstituenRelawanSayaDataDetail.length !== 0 ? konstituenRelawanSayaDataDetail.map(p => 
                                                        <TableRow key={p.member_id}>
                                                            <TableCell>{p.nama}</TableCell>
                                                            <TableCell>{p.username}</TableCell>
                                                            <TableCell>{p.tempat_lahir + ', ' + p.tanggal_lahir}</TableCell>
                                                            <TableCell>{p.no_hp}</TableCell>
                                                            <TableCell><Button sx={{ marginLeft: '5px' }} onClick={() => removeRelasiKonstituen(p.konstituen_id, relawanDataDetail.relawan.relawan_id)} size='small' variant='contained' startIcon={<Add />} color='error'>Hapus</Button></TableCell>
                                                    </TableRow> 
                                                        )
                                                    : <TableRow>
                                                        <TableCell colSpan={6}>
                                                            <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                            component="div"
                                            count={settingTableDetail.totalElements}
                                            rowsPerPage={settingTableDetail.size}
                                            page={settingTableDetail.page}
                                            onPageChange={handleChangePageDetail}
                                            onRowsPerPageChange={handleChangeRowsPerPageDetail}
                                        />
                                    </Paper>
                            </Grid>
                        }
                    </Grid>
                    <ModalCreateRelasiToKonstituen 
                        openModalRelasi={openModalRelasi}
                        setOpenModalRelasi={setOpenModalRelasi}
                        addRelasiKonstituen={addRelasiKonstituen}
                        checkNikToRelasiRelawan={checkNikToRelasiRelawan}
                        dataTempTambahRelasi={dataTempTambahRelasi}
                        relawanDataDetail={relawanDataDetail}
                        formNik={formNik}
                        setFormNik={setFormNik}
                    />
                </Container>
            }
        </Dialog>
    );
}

export default DetailRelawanMember;
