export interface PertanyaanProps {
    pertanyaan_id: string;
    kategori_pertanyaan_id: string;
    role_id: string;
    member_id: string;
    nama: string;
    nama_role: string;
    title: string;
    subtitle: string;
    status: string;
    priority: string;
    created_at: string;
    updated_at: string;
}

export const PertanyaanField: PertanyaanProps = {
    pertanyaan_id: '',
    kategori_pertanyaan_id: '',
    role_id: '',
    member_id: '',
    nama: '',
    nama_role: '',
    title: '',
    subtitle: '',
    status: '',
    priority: '',
    created_at: '',
    updated_at: '',
}

export interface ErrorPertanyaanProps {
    pertanyaan_id: string | null;
    kategori_pertanyaan_id: string | null;
    role_id: string | null;
    member_id: string | null;
    nama: string | null;
    nama_role: string | null;
    title: string | null;
    subtitle: string | null;
    status: string | null;
    priority: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export const ErrorPertanyaanField: ErrorPertanyaanProps = {
    pertanyaan_id: null,
    kategori_pertanyaan_id: null,
    role_id: null,
    member_id: null,
    nama: null,
    nama_role: null,
    title: null,
    subtitle: null,
    status: null,
    priority: null,
    created_at: null,
    updated_at: null,
}