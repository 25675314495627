import React, { ChangeEvent } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { AuthStoreState, ThemeStoreState } from '../../store';
import { LoginResponseProps } from '../../models/Auth/LoginResponseProps';
import { Alert, Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Radio, RadioGroup, Skeleton, styled, TextField, Typography } from '@mui/material';
import { AccountCircle, Badge, Cancel, Create, Delete, Email, FileUpload, House, LocationCity, Phone, SaveAs, Signpost, VerifiedUser, WhatsApp } from '@mui/icons-material';
import { AlertField, AlertProps } from '../../models/Component/AlertProps';
import { ProfilValidateForm } from '../../validation/ProfilValidateForm';
import { ErrorProfileRequestField, ErrorProfileRequestProps, ProfileRequestField, ProfileRequestProps } from '../../models/Profile/ProfileRequestProps';
import ProfileService from '../../services/Auth/ProfileService';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ImageDefault from "../../asset/images/default.png";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Profil = () => {
    const today = dayjs();
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState);
    const [auth, setAuth] = useRecoilState<LoginResponseProps>(AuthStoreState);
    const [form, setForm] = React.useState<ProfileRequestProps>(ProfileRequestField);
    const [editForm, setEditForm] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [errors, setErrors] = React.useState<ErrorProfileRequestProps>(ErrorProfileRequestField);
    const [alertOption, setAlertOption] = React.useState<AlertProps>(AlertField);
    const [nik, setNik] = React.useState<string>('');

    const [image, setImage] = React.useState<File | null>(null);
    const [imagePreview, setImagePreview] = React.useState<string | null>(null);

    function cancelResetForm () {
        setForm({
            member_id: auth.member_id,
            nik: nik,
            nama: auth.nama,
            jenis_kelamin: auth.jenis_kelamin,
            tempat_lahir: auth.tempat_lahir,
            tanggal_lahir: auth.tanggal_lahir,
            alamat: auth.alamat,
            kode_pos: auth.kode_pos,
            email: auth.email,
            no_hp: auth.no_hp,
            no_wa: auth.no_wa,
            username: auth.username,
            hak_pilih: auth.hak_pilih
        });
    }

    async function getStarted () {
        await ProfileService.get(auth.member_id).then(res => {
            setNik(res.data.nik);
            setForm({
                member_id: res.data.member_id,
                nik: res.data.nik,
                nama: res.data.nama,
                jenis_kelamin: res.data.jenis_kelamin,
                tempat_lahir: res.data.tempat_lahir,
                tanggal_lahir: res.data.tanggal_lahir,
                alamat: res.data.alamat,
                kode_pos: res.data.kode_pos,
                email: res.data.email,
                no_hp: res.data.no_hp,
                no_wa: res.data.no_wa,
                username: res.data.username,
                hak_pilih: res.data.hak_pilih
            });
        }).catch(err => {
            setAlertOption({...alertOption, open: true, message: 'Error: ' + err, severity: 'error'})
        });
    }
    React.useEffect(() => {
        getStarted ();
        setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async () => {
        const newErrors: any = {};
        try {
            ProfilValidateForm.validate(form, {abortEarly: false});
            setErrors(ErrorProfileRequestField);
        }catch(error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }
        
        if(Object.keys(newErrors).length === 0) {
            const data = new FormData();
            data.append('data', JSON.stringify(form));
            if(image) {
                data.append('image', image)
            }
            await ProfileService.put(data).then(res => {
                setAuth({
                    ...auth, 
                    member_id: res.data.member_id,
                    nama: res.data.nama,
                    jenis_kelamin: res.data.jenis_kelamin,
                    tempat_lahir: res.data.tempat_lahir,
                    tanggal_lahir: res.data.tanggal_lahir,
                    alamat: res.data.alamat,
                    kode_pos: res.data.kode_pos,
                    avatar: res.data.avatar,
                    email: res.data.email,
                    no_hp: res.data.no_hp,
                    no_wa: res.data.no_wa,
                    username: res.data.username,
                    hak_pilih: res.data.hak_pilih
                });
                setForm({
                    ...form, 
                    member_id: res.data.member_id,
                    nik: res.data.nik,
                    nama: res.data.nama,
                    jenis_kelamin: res.data.jenis_kelamin,
                    tempat_lahir: res.data.tempat_lahir,
                    tanggal_lahir: res.data.tanggal_lahir,
                    alamat: res.data.alamat,
                    kode_pos: res.data.kode_pos,
                    email: res.data.email,
                    no_hp: res.data.no_hp,
                    no_wa: res.data.no_wa,
                    username: res.data.username,
                    hak_pilih: res.data.hak_pilih
                });
                setNik(res.data.nik);
                if(localStorage.getItem('user')) {
                    localStorage.removeItem('user');
                    localStorage.setItem('user', JSON.stringify({
                        ...auth, 
                        member_id: res.data.member_id,
                        nama: res.data.nama,
                        jenis_kelamin: res.data.jenis_kelamin,
                        tempat_lahir: res.data.tempat_lahir,
                        tanggal_lahir: res.data.tanggal_lahir,
                        alamat: res.data.alamat,
                        kode_pos: res.data.kode_pos,
                        avatar: res.data.avatar,
                        email: res.data.email,
                        no_hp: res.data.no_hp,
                        no_wa: res.data.no_wa,
                        username: res.data.username,
                        hak_pilih: res.data.hak_pilih
                    }));
                }
                setEditForm(false);
                setAlertOption({...alertOption, open: true, message: 'Berhasil Menyimpan data', severity: 'success'})
            }).catch(err => {
                setEditForm(false);
                setAlertOption({...alertOption, open: true, message: 'Gagal Menyimpan data', severity: 'error'})
            });
            setImagePreview(null);
            setImage(null);
        }
    }

    function uploadImage(event: ChangeEvent<HTMLInputElement>) {
        const selectedFile = event.target.files?.[0] || null;
        setImage(selectedFile);

        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        }
    }

    return loading ? (
        <Box sx={{ marginY: '10px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <Skeleton animation='wave' sx={{width: '100%', height: '300px'}} variant='rectangular' />
                    <Grid container spacing={1} sx={{ marginY: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={6}>
                            <Skeleton animation='wave' sx={{width: '100%', height: '70px'}} variant='rectangular' />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                    <Box component="form" noValidate onSubmit={handleSubmit} >
                        <Skeleton animation='wave' sx={{width: '100%', height: '50px', m: 1}} variant='text' />
                        <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                        <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                        <Grid spacing={1} container>
                            <Grid item xs={6}>
                            <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                            </Grid>
                            <Grid item xs={6}>
                            <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                            </Grid>
                        </Grid>
                        <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                        <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                        <Skeleton animation='wave' sx={{width: '100%', height: '50px', m: 1}} variant='text' />
                        <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                        <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                        <Skeleton animation='wave' sx={{width: '100%', height: '120px', m: 1}} variant='text' />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    ) : (
        <Box sx={{ marginY: '10px' }}>
            <Grid container spacing={2} sx={{ marginY: '10px' }}>
                <Grid item xs={12}>
                {
                    alertOption.open === true ? <Alert severity={alertOption.severity} children={alertOption.message} onClose={() => setAlertOption({...alertOption, open: false})}></Alert> : <></>
                }
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <Box sx={{ width: '100%', marginBottom: '20px', height: '300px' }}>
                        {
                            imagePreview !== null ? 
                            <img src={imagePreview} alt={'preview'} width={'100%'} height={'100%'} />
                            : (auth.avatar !== '' ?
                            <img src={auth.avatar} alt={auth.avatar} width={'100%'} height={'100%'} /> :
                            <img src={ImageDefault} alt={auth.nama} width={'100%'} height={'100%'} />)
                        }
                    </Box>
                    <Grid container spacing={3}>
                    {
                        imagePreview !== null ?
                        <Grid item xs={12}>
                            {
                                image == null ? <Alert severity="info">File Perlu dibawah 1 MB.</Alert> : image.size / 1000 > 1000 ? 
                                <Alert severity="error">File anda terlalu besar! {image == null ? 0 : image.size / 1000} Kb.</Alert> :
                                <Alert severity="info">File yang anda Upload berada di {image == null ? 0 : image.size / 1000} Kb.</Alert>
                            }
                            <Button variant='contained' sx={{ marginTop: '5px' }} fullWidth onClick={() => {
                                setImagePreview(null);
                                setImage(null);
                            }} startIcon={<Delete />} color='error'>Hapus</Button>
                        </Grid> : 
                        <Grid item xs={12}>
                            <Alert severity="info">File Perlu dibawah 1 MB.</Alert>
                            <Button component="label" sx={{ marginTop: '5px' }} tabIndex={-1} variant='contained' fullWidth startIcon={<FileUpload />} disabled={!editForm} color='primary'>Upload<VisuallyHiddenInput type="file" onChange={uploadImage} /></Button>
                        </Grid>
                    }
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                    <Box>
                        <Typography variant='h5' sx={{ marginBottom: '5px' }}>Data Diri</Typography>
                        <TextField
                            label="NIK"
                            id="nik"
                            disabled={!editForm}
                            fullWidth
                            placeholder='NIK'
                            value={form.nik}
                            onChange={(e) => setForm({...form, nik: e.target.value})}
                            sx={{ m: 1 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><AccountCircle /></InputAdornment>,
                            }}
                        />
                        {(errors.nik != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.nik}</Typography> : <></>}
                        <TextField
                            label="Nama"
                            id="nama"
                            disabled={!editForm}
                            fullWidth
                            placeholder='Nama'
                            value={form.nama}
                            onChange={(e) => setForm({...form, nama: e.target.value})}
                            sx={{ m: 1 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><Badge /></InputAdornment>,
                            }}
                        />
                        {(errors.nama != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.nama}</Typography> : <></>}
                        <Grid spacing={1} container>
                            <Grid item xs={6}>
                                <TextField
                                    label="Tempat Lahir"
                                    id="tempat_lahir"
                                    fullWidth
                                    value={form.tempat_lahir}
                                    onChange={(e) => setForm({...form, tempat_lahir: e.target.value})}
                                    placeholder='Tempat Lahir'
                                    disabled={!editForm}
                                    sx={{ m: 1 }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><LocationCity /></InputAdornment>,
                                    }}
                                />
                                {(errors.tempat_lahir != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.tempat_lahir}</Typography> : <></>}
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        defaultValue={dayjs(form.tanggal_lahir) ?? today}
                                        value={dayjs(form.tanggal_lahir)}
                                        label="Tanggal Lahir"
                                        disabled={!editForm}
                                        name='tanggal_lahir'
                                        disableFuture
                                        sx={{ m: 1, width: '100%' }}
                                        views={['year', 'month', 'day']}
                                        onChange={(e) => setForm({...form, tanggal_lahir: (e?.format('YYYY-MM-DD') ?? today.format('YYYY-MM-DD'))})}
                                    />
                                </LocalizationProvider>
                                {(errors.tanggal_lahir != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.tanggal_lahir}</Typography> : <></>}
                            </Grid>
                        </Grid>
                        <FormControl sx={{m: 1}}>
                                <FormLabel id="gender">Jenis Kelamin</FormLabel>
                                <RadioGroup
                                    aria-labelledby="gender"
                                    defaultValue={form.jenis_kelamin}
                                    name="radio-buttons-group"
                                    onChange={(e) => setForm({...form, jenis_kelamin: e.target.value})}
                                    value={form.jenis_kelamin}
                                >
                                    <FormControlLabel value="WANITA" control={<Radio disabled={!editForm} />} label="Perempuan" />
                                    <FormControlLabel value="PRIA" control={<Radio disabled={!editForm} />} label="Laki-Laki" />
                                </RadioGroup>
                                {(errors.jenis_kelamin != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.jenis_kelamin}</Typography> : <></>}
                            </FormControl>
                        <TextField
                            label="Alamat"
                            id="alamat"
                            disabled={!editForm}
                            fullWidth
                            value={form.alamat}
                            onChange={(e) => setForm({...form, alamat: e.target.value})}
                            placeholder='Alamat'
                            sx={{ m: 1 }}
                            multiline
                            rows={3}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><House /></InputAdornment>,
                            }}
                        />
                        <TextField
                            label="Kode Pos"
                            id="kode_pos"
                            disabled={!editForm}
                            fullWidth
                            value={form.kode_pos}
                            onChange={(e) => setForm({...form, kode_pos: e.target.value})}
                            placeholder='Kode Pos'
                            sx={{ m: 1 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><Signpost /></InputAdornment>,
                            }}
                        />
                        <Typography variant='h5' sx={{ marginY: '5px' }}>Informasi Login</Typography>
                        <TextField
                            label="Username"
                            id="username"
                            disabled
                            fullWidth
                            value={form.username}
                            onChange={(e) => setForm({...form, username: e.target.value})}
                            placeholder='Username'
                            sx={{ m: 1 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><VerifiedUser /></InputAdornment>,
                            }}
                        />
                        <Typography variant='h5' sx={{ marginY: '5px' }}>Informasi Kontak</Typography>
                        <TextField
                            label="Email"
                            id="email"
                            disabled={!editForm}
                            fullWidth
                            value={form.email}
                            onChange={(e) => setForm({...form, email: e.target.value})}
                            placeholder='Email'
                            sx={{ m: 1 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><Email /></InputAdornment>,
                            }}
                        />
                        <TextField
                            label="Nomor HP"
                            id="nomor_hp"
                            disabled={!editForm}
                            fullWidth
                            value={form.no_hp}
                            onChange={(e) => setForm({...form, no_hp: e.target.value})}
                            placeholder='Nomor HP'
                            sx={{ m: 1 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><Phone /></InputAdornment>,
                            }}
                        />
                        <TextField
                            label="Nomor Whatsapp"
                            id="no_wa"
                            disabled={!editForm}
                            fullWidth
                            value={form.no_wa}
                            onChange={(e) => setForm({...form, no_wa: e.target.value})}
                            placeholder='Nomor Whatsapp'
                            sx={{ m: 1 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><WhatsApp /></InputAdornment>,
                            }}
                        />
                        <FormControlLabel sx={{m: 1}}
                            control={<Checkbox 
                                disabled={!editForm}
                                checked={form.hak_pilih} 
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setForm({...form, hak_pilih: event.target.checked})} 
                                />} 
                            label="Saya Memiliki Hak Pilih di TPS Alamat Tinggal" 
                        />
                        <Grid container spacing={2}>
                            {
                                !editForm ? 
                                <Grid item xs={12}>
                                    <Button startIcon={<Create />} onClick={() => setEditForm(true)} variant='contained' color="inherit" fullWidth sx={{m:1, backgroundColor: theme.backgroundColor, color: theme.color}}>Ubah</Button>
                                </Grid> : 
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button color="error" onClick={() => {cancelResetForm();setImagePreview(null);setImage(null); setEditForm(false);}} startIcon={<Cancel />} variant='contained' fullWidth sx={{m:1}}>Batalkan</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button color="success" variant='contained' startIcon={<SaveAs />} fullWidth sx={{m:1 }} onClick={() => handleSubmit()}>Simpan</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Profil;