export function replaceItemAtIndex(arr: Array<Object>, index: number, newValue: Object): any {
    return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}

export function removeItemAtIndex(arr: Array<Object>, index: number): any {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });
};