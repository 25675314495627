import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import RoleProps from '../../models/Role/RoleProps';

class RoleServices {
    async getAll(page: number, size: number, sort: string, search: string, memberId?: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/role/role?page=${page}&size=${size}&sort=${sort}&search=${search}&member=${memberId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllArr(): Promise<AxiosResponse<RoleProps[]>> {
        return axios.get(`/api/v1/role/role/arr`,{headers: {'Content-Type': 'application/json',}});
    }

    async getById(fiturId: string): Promise<AxiosResponse<RoleProps>> {
        return axios.get(`/api/v1/role/role/${fiturId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: RoleProps): Promise<AxiosResponse<RoleProps>> {
        return axios.post(`/api/v1/role/role`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(fiturId: string, data : RoleProps): Promise<AxiosResponse<RoleProps>> {
        return axios.put(`/api/v1/role/role/${fiturId}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async delete(fiturId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/role/role/${fiturId}`, {headers: {'Content-Type': 'application/json',}});
    }

    async addMember(memberId: string, roleId: string): Promise<AxiosResponse<void>> {
        return axios.post(`/api/v1/role/add-member?roleId=${roleId}&memberId=${memberId}`, null, {headers: {'Content-Type': 'application/json',}});
    }
    
    async removeMember(memberId: string, roleId: string): Promise<AxiosResponse<void>> {
        return axios.post(`/api/v1/role/remove-member?roleId=${roleId}&memberId=${memberId}`, null, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RoleServices();