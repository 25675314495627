export interface CalonLegislatifProps {
    calon_legislatif_id: string;
    partai_id: string;
    periode_id: string;
    kategori_id: string;
    jenis_pemilihan_id: string;
    jenis_tingkat_pemilihan_id: string;
    nama_calon: string;
    gambar: string;
}

export const CalonLegislatifField: CalonLegislatifProps = {
    calon_legislatif_id: '',
    partai_id: '',
    periode_id: '',
    kategori_id: '',
    jenis_pemilihan_id: '',
    jenis_tingkat_pemilihan_id: '',
    nama_calon: '',
    gambar: '',
}

export interface ErrorCalonLegislatifProps {
    calon_legislatif_id: string | null;
    partai_id: string | null;
    periode_id: string | null;
    kategori_id: string | null;
    jenis_pemilihan_id: string | null;
    jenis_tingkat_pemilihan_id: string | null;
    nama_calon: string | null;
    gambar: string | null;
}

export const ErrorCalonLegislatifField: ErrorCalonLegislatifProps = {
    calon_legislatif_id: null,
    partai_id: null,
    periode_id: null,
    kategori_id: null,
    jenis_pemilihan_id: null,
    jenis_tingkat_pemilihan_id: null,
    nama_calon: null,
    gambar: null,
}
