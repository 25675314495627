import React from 'react';

const Pengaturan = () => {
    return (
        <div>
            Pengatura
        </div>
    );
}

export default Pengaturan;
