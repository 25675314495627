import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import {MonitoringProps} from '../../models/Monitoring/MonitoringProps';

class MonitoringServices {
    async getAll(page: number, size: number, sort: string, search: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/monitoring?page=${page}&size=${size}&sort=${sort}&search=${search}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllByMemberId(page: number, size: number, sort: string, search: string, memberId: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/monitoring/member/${memberId}?page=${page}&size=${size}&sort=${sort}&search=${search}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(monitoringId: string): Promise<AxiosResponse<MonitoringProps>> {
        return axios.get(`/api/v1/monitoring/${monitoringId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: MonitoringProps): Promise<AxiosResponse<MonitoringProps>> {
        return axios.post(`/api/v1/monitoring`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(monitoringId: string, data : MonitoringProps): Promise<AxiosResponse<MonitoringProps>> {
        return axios.put(`/api/v1/monitoring/${monitoringId}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async delete(monitoringId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/monitoring/${monitoringId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MonitoringServices();