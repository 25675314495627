import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import {KategoriProps} from '../../models/Forums/KategoriProps';

class KategoriServices {
    async getAllArr(periodeId?: string): Promise<AxiosResponse<KategoriProps[]>> {
        return axios.get(`/api/v1/forum/kategori/arr${periodeId !== null ? '?periodeId=' + periodeId : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAll(page: number, size: number, sort: string, search: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/forum/kategori?page=${page}&size=${size}&sort=${sort}&search=${search}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(kategoriId: string): Promise<AxiosResponse<KategoriProps>> {
        return axios.get(`/api/v1/forum/kategori/${kategoriId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: KategoriProps): Promise<AxiosResponse<KategoriProps>> {
        return axios.post(`/api/v1/forum/kategori`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(kategoriId: string, data : KategoriProps): Promise<AxiosResponse<KategoriProps>> {
        return axios.put(`/api/v1/forum/kategori/${kategoriId}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async delete(kategoriId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/forum/kategori/${kategoriId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new KategoriServices();