import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import {JenisPemilihanProps} from '../../models/Forums/JenisPemilihanProps';

class JenisPemilihanServices {
    async getAllArr(kategoriId: string): Promise<AxiosResponse<JenisPemilihanProps[]>> {
        return axios.get(`/api/v1/forum/jenis-pemilihan/arr?kategoriId=${kategoriId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAll(page: number, size: number, sort: string, search: string, periodeId?: string, kategoriId?: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/forum/jenis-pemilihan?page=${page}&size=${size}&sort=${sort}&search=${search}${periodeId !== null ? '&periodeId=' + periodeId : ''}${kategoriId !== null ? '&kategoriId=' + kategoriId : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(jenisPemilihanId: string): Promise<AxiosResponse<JenisPemilihanProps>> {
        return axios.get(`/api/v1/forum/jenis-pemilihan/${jenisPemilihanId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: JenisPemilihanProps): Promise<AxiosResponse<JenisPemilihanProps>> {
        return axios.post(`/api/v1/forum/jenis-pemilihan`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(jenisPemilihanId: string, data : JenisPemilihanProps): Promise<AxiosResponse<JenisPemilihanProps>> {
        return axios.put(`/api/v1/forum/jenis-pemilihan/${jenisPemilihanId}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async delete(jenisPemilihanId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/forum/jenis-pemilihan/${jenisPemilihanId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new JenisPemilihanServices();