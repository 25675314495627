import { Box, Container, Grid, List, ListItemButton, ListItemIcon, ListItemText, Paper, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { LoginResponseField, LoginResponseProps } from '../../models/Auth/LoginResponseProps';
import { AccountBox, AlternateEmail, AssuredWorkload, CalendarMonth, Female, Home, Male, Phone, ReduceCapacity, Transgender, VerifiedUser, WhatsApp } from '@mui/icons-material';
import {InfoWindow, Map, Marker} from '@vis.gl/react-google-maps';
import { KonstituenForRelawanMapField, KonstituenForRelawanMapProps } from '../../models/Member/DetailMemberProps';
import RelawanService from '../../services/Member/RelawanService';
import Image from "../../asset/images/cat.jpg";
import { BerandaRelawanResponse, BerandaRelawanResponseField } from '../../models/Relawan/BerandaRelawanResponse';

const Relawan = () => {
    const [loadingState, setloadingState] = React.useState<boolean>(true);
    const [profil, setProfil] = React.useState<LoginResponseProps | null>(null);
    const [selectedMarker, setSelectedMarker] = React.useState<KonstituenForRelawanMapProps>(KonstituenForRelawanMapField);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [relawan, setRelawan] = React.useState<BerandaRelawanResponse>(BerandaRelawanResponseField);
    
    async function getRelawan (memberId?: string) {
        if(memberId !== undefined) {
            await RelawanService.beranda(memberId).then(res => {
                setRelawan(res.data);
            }).catch(err => {
                setMessageSnackbar(err.response.data.message ?? "Terjadi kesalahan")
                setOpenSnackbar(true);
            }).finally(() => setloadingState(false));
        }
    }

    function getLayanan () {
        const temp: string | null = localStorage.getItem('user');
        let user: LoginResponseProps = LoginResponseField;
        
        if(temp !== null) {
            user = JSON.parse(temp);
            setProfil(user);
            getRelawan(user.member_id);
        }
    }

    React.useEffect(() => {
        getLayanan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loadingState ? (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Skeleton animation={'wave'} sx={{ marginBottom: '10px' }} variant='rectangular' width={'100%'} height={30} />
                    <Skeleton animation={'wave'} sx={{ marginBottom: '10px' }} variant='rounded' width={'100%'} height={200} />
                    <Skeleton animation={'wave'} sx={{ marginBottom: '10px' }} variant='rectangular' width={'100%'} height={20} />
                    <Skeleton animation={'wave'} sx={{ marginBottom: '10px' }} variant='rectangular' width={'100%'} height={20} />
                    <Skeleton animation={'wave'} sx={{ marginBottom: '10px' }} variant='rectangular' width={'100%'} height={20} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={8}>
                    <Skeleton animation={'wave'} variant='rectangular' width={'100%'} height={20} />
                    <Box sx={{ width: '100%'}}>
                        <Skeleton animation={'wave'} variant='rectangular' width={'100%'} height={300} />
                    </Box>
                </Grid>
            </Grid>
            <Skeleton animation={'wave'} sx={{ marginBottom: '10px' }} variant='rectangular' width={'100%'} height={20} />
            <Grid container spacing={1} sx={{ marginBottom: '15px' }}>
                <Grid item xs={12} md={4} lg={3}>
                    <Skeleton animation={'wave'} variant='rectangular' width={'100%'} height={50} />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={8}>
                    <Paper elevation={3} sx={{ padding: '15px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                                <Skeleton sx={{ marginBottom: '5px' }} animation={'wave'} variant='rectangular' width={'100%'} height={50} />
                            </Grid>
                            <Grid item xs={6} sm={6} display={'flex'} justifyContent={'flex-end'}>
                                <Skeleton sx={{ marginBottom: '5px' }} animation={'wave'} variant='rectangular' width={'10%'} height={30} />
                            </Grid>
                        </Grid>
                        <Skeleton animation={'wave'} sx={{ marginBottom: '5px' }} variant='rectangular' width={'100%'} height={60} />
                        <Skeleton animation={'wave'} sx={{ marginBottom: '5px' }} variant='rectangular' width={'100%'} height={60} />
                        <Skeleton animation={'wave'} sx={{ marginBottom: '5px' }} variant='rectangular' width={'100%'} height={60} />
                        <Skeleton animation={'wave'} sx={{ marginBottom: '5px' }} variant='rectangular' width={'100%'} height={60} />
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Skeleton animation={'wave'} variant='rectangular' width={'100%'} height={20} />
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Skeleton animation={'wave'} sx={{  marginBottom: '5px'}} variant='rectangular' width={'100%'} height={70} />
                    <Skeleton animation={'wave'} sx={{  marginBottom: '5px'}} variant='rectangular' width={'100%'} height={70} />
                    <Skeleton animation={'wave'} sx={{  marginBottom: '5px'}} variant='rectangular' width={'100%'} height={70} />
                    <Skeleton animation={'wave'} sx={{  marginBottom: '5px'}} variant='rectangular' width={'100%'} height={70} />
                    <Skeleton animation={'wave'} sx={{  marginBottom: '5px'}} variant='rectangular' width={'100%'} height={70} />
                </Grid>
            </Grid>
            <Skeleton animation={'wave'} variant='rectangular' width={'100%'} height={10} />
            <Grid container spacing={3} >
                <Grid item xs={12} sm={12}>
                    <Box sx={{ width: '100%', height: '270px' }}>
                        <Skeleton animation={'wave'} variant='rectangular' width={'100%'} height={150} />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    ) : (
        <Container>
            <Grid container spacing={3} sx={{ marginY: '10px' }}>
                <Grid item xs={12} sm={12} md={4}>
                    <Typography variant='h6'>Profil</Typography>
                    <Box sx={{ width: '100%' }}>
                        {
                            relawan !== null ?
                            <img src={relawan.detail.member.avatar !== null ? relawan.detail.member.avatar : Image} alt={relawan.detail.ktp.nama} width={'100%'} /> :
                            <img src={Image} alt={'default'} width={'100%'} />
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <Typography variant='h6'>Informasi Relawan</Typography>
                    <List component="nav" aria-label="main mailbox folders">
                        <ListItemButton>
                            <ListItemIcon><AccountBox /></ListItemIcon>
                            <ListItemText primary={relawan.detail.ktp.nama ?? 'Belum Ada Nama'} /></ListItemButton>
                            <ListItemButton>
                                <ListItemIcon>{ relawan.detail.ktp.jenis_kelamin === null ? <Transgender /> : relawan.detail.ktp.jenis_kelamin === 'PRIA' ? <Male /> : <Female /> }</ListItemIcon>
                                <ListItemText primary={relawan.detail.ktp.jenis_kelamin ?? 'Belum Ada Jenis Kelamin'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><CalendarMonth /></ListItemIcon>
                                <ListItemText primary={relawan.detail.ktp.tempat_lahir + ', ' + relawan.detail.ktp.tanggal_lahir ?? 'Belum Ada Kode Referensi'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><AlternateEmail /></ListItemIcon>
                                <ListItemText primary={relawan.detail.member.email ?? 'Belum Ada Email'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><Phone /></ListItemIcon>
                                <ListItemText primary={relawan.detail.member.no_hp ?? 'Belum Ada Nomor HP'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><WhatsApp /></ListItemIcon>
                                <ListItemText primary={relawan.detail.member.no_wa ?? 'Belum Ada Nomor WhatsApp'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><VerifiedUser /></ListItemIcon>
                                <ListItemText primary={relawan.detail.username ?? 'Belum Ada Username'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><AssuredWorkload /></ListItemIcon>
                                <ListItemText primary={relawan.detail.member.hak_pilih ? 'Ada Hak Pilih di TPS' : 'Tidak ada Hak Pilih'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><Home /></ListItemIcon>
                                <ListItemText primary={relawan.detail.ktp.alamat ?? 'Belum Ada Alamat'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><ReduceCapacity /></ListItemIcon>
                                <ListItemText primary={relawan.detail.referensi.kode_referensi ?? 'Tidak Ada Referensi'} />
                            </ListItemButton>
                        </List>
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ marginY: '10px' }}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant='h6'>Sebaran Data Konstituen ({relawan.detail.konstituen_list.length})</Typography>
                        <Map
                            defaultZoom={8}
                            style={{ width: '100%', height: '520px' }}
                            defaultCenter={ { lat: process.env.REACT_APP_MAPS_LATTITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LATTITUDE) : -4.55858490, lng: process.env.REACT_APP_MAPS_LONGITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LONGITUDE) : 105.40680790 } }
                            onClick={(e) => {}}
                        >
                            {
                                relawan.detail.area.length > 0 ? relawan.detail.area.map(a => <Marker position={{lat: parseFloat(a.lattitude), lng: parseFloat(a.longitude)}} icon={{
                                    url: 'http://maps.google.com/mapfiles/kml/pal3/icon56.png',
                                    scaledSize: new google.maps.Size(32, 32)
                                }} key={a.area_member_id} onClick={() => setSelectedMarker({
                                    konstituen_id: relawan.detail.konstituen.konstituen_id,
                                    member_id: relawan.detail.member.member_id,
                                    nama: "Rumah Saya", 
                                    lattitude: a.lattitude, 
                                    longitude: a.longitude,
                                    username: relawan.detail.username,
                                    alamat: relawan.detail.ktp.alamat, 
                                    avatar: relawan.detail.member.avatar,
                                    hak_pilih: relawan.detail.member.hak_pilih,
                                })} />) : <></>
                            }
                            {
                                relawan.detail.konstituen_list.length > 0 ? relawan.detail.konstituen_list.map(k => <Marker position={{lat: parseFloat(k.lattitude), lng: parseFloat(k.longitude)}} key={k.konstituen_id} onClick={() => setSelectedMarker(k)} />) : <></>
                            }
                            {selectedMarker.lattitude === '' ? <></> : (
                                <InfoWindow
                                    headerContent={'Konstituen: ' + selectedMarker.nama}
                                    position={{ lat: parseFloat(selectedMarker.lattitude), lng: parseFloat(selectedMarker.longitude) }}
                                    onCloseClick={() => setSelectedMarker(KonstituenForRelawanMapField)}
                                >
                                    <Box>
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                            <img src={selectedMarker.avatar} width={'75px'} height={'75px'} alt={selectedMarker.nama} />
                                        </Box>
                                        <List component="nav" aria-label="main mailbox folders">
                                            <ListItemButton>
                                                <ListItemIcon><AccountBox /></ListItemIcon>
                                                <ListItemText primary={selectedMarker.nama ?? 'Belum Ada Nama'} />
                                            </ListItemButton>
                                            <ListItemButton>
                                                <ListItemIcon><Home /></ListItemIcon>
                                                <ListItemText primary={selectedMarker.alamat ?? 'Belum Ada Alamat'} />
                                            </ListItemButton>
                                            <ListItemButton>
                                                <ListItemIcon><VerifiedUser /></ListItemIcon>
                                                <ListItemText primary={selectedMarker.username ?? 'Belum Ada Username'} />
                                            </ListItemButton>
                                            <ListItemButton>
                                                <ListItemIcon><AssuredWorkload /></ListItemIcon>
                                                <ListItemText primary={selectedMarker.hak_pilih ? 'Terdapat Hak Pilih' : 'Tidak Memiliki Hak Pilih' ?? 'Belum Ada Hak Pilih'} />
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                </InfoWindow>
                            )}
                        </Map>
                    </Grid>
                </Grid>
            </Container>
    );
}

export default Relawan;
