import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import {KecamatanProps} from '../../models/Area/KecamatanProps';

class KecamatanServices {
    async getAll(page: number, size: number, sort: string, search: string, kabupatenId: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/area/kecamatan?page=${page}&size=${size}&sort=${sort}&search=${search}&kabupatenId=${kabupatenId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllArray(kabupatenId: string): Promise<AxiosResponse<KecamatanProps[]>> {
        return axios.get(`/api/v1/area/kecamatan/arr?kabupatenId=${kabupatenId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(kecamatanId: string): Promise<AxiosResponse<KecamatanProps>> {
        return axios.get(`/api/v1/area/kecamatan/${kecamatanId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: KecamatanProps): Promise<AxiosResponse<KecamatanProps>> {
        return axios.post(`/api/v1/area/kecamatan`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(kecamatanId: string, data : KecamatanProps): Promise<AxiosResponse<KecamatanProps>> {
        return axios.put(`/api/v1/area/kecamatan/${kecamatanId}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async delete(kecamatanId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/area/kecamatan/${kecamatanId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new KecamatanServices();