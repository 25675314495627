import { Box, Grid, Pagination, Typography } from '@mui/material';
import React from 'react';
import Cat from "../../asset/images/cat.jpg";
import CardPortal from '../../components/CardPortal';
import { useRecoilValue } from 'recoil';
import { ThemeStoreState } from '../../store';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import BlogPostProps from '../../models/Blog/BlogPostProps';


const Index: React.FC = () => {
    const themeStore: ThemeStoreProps = useRecoilValue(ThemeStoreState);
    const beritaList: BlogPostProps[] = [
        {
            blog_post_id: "",
            member_id: "",
            nama_member: "",
            title: "",
            slug: "",
            summary: "",
            content: "",
            published_at: "",
            created_at: "",
            updated_at: "",
            deleted_at: "",
            published: true,
            visited: 100,
            meta: [],
            category: [],
            tag: [],
        }
    ];
    return (
        <Box>
            <Grid container spacing={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                <Grid item sm={12}>
                    <Box>
                        <img src={Cat} width={'100%'} height={'350'} alt="splash" />
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginY: '20px' }}>
                <Grid item xs={12} sm={12} md={8}>
                    <Box sx={{ backgroundColor:  themeStore.backgroundColor, color: themeStore.color, paddingY: '3px', paddingX: '5px', marginY: '10px'}}>
                        <Typography sx={{ fontSize: '20px', marginY: '10px' }}>Berita Terbaru</Typography>
                    </Box>
                    <Grid container spacing={2}>
                            {
                                beritaList.map(berita => 
                                    <Grid item xs={12} key={berita.blog_post_id}>
                                        <CardPortal
                                            blog_post_id={berita.blog_post_id}
                                            member_id={berita.member_id}
                                            nama_member={berita.nama_member}
                                            title={berita.title}
                                            slug={berita.slug}
                                            summary={berita.summary}
                                            content={berita.content}
                                            published_at={berita.published_at}
                                            created_at={berita.created_at}
                                            updated_at={berita.updated_at}
                                            deleted_at={berita.deleted_at}
                                            published={berita.published}
                                            visited={berita.visited}
                                            meta={berita.meta}
                                            category={berita.category}
                                            tag={berita.tag}
                                        />
                                    </Grid>
                                )
                            }
                    </Grid>
                    <Grid container spacing={2} sx={{ marginY: '20px' }}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', 'alignContent': 'center' }}>
                            <Pagination count={10} color="primary" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Box sx={{ backgroundColor:  themeStore.backgroundColor, color: themeStore.color, paddingY: '3px', paddingX: '5px', marginY: '10px'}}>
                        <Typography sx={{ fontSize: '20px', marginY: '10px' }}>Berita Terpopular</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        {
                            beritaList.map(popular =>
                            <Grid item xs={12} key={popular.blog_post_id}>
                                <CardPortal
                                    blog_post_id={popular.blog_post_id}
                                    member_id={popular.member_id}
                                    nama_member={popular.nama_member}
                                    title={popular.title}
                                    slug={popular.slug}
                                    summary={popular.summary}
                                    content={popular.content}
                                    published_at={popular.published_at}
                                    created_at={popular.created_at}
                                    updated_at={popular.updated_at}
                                    deleted_at={popular.deleted_at}
                                    published={popular.published}
                                    visited={popular.visited}
                                    meta={popular.meta}
                                    category={popular.category}
                                    tag={popular.tag}
                                />
                            </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Index;
