export interface DesaProps {
    desa_id: string;
    kecamatan_id: string;
    nama_desa: string;
}

export const DesaField: DesaProps = {
    desa_id: '',
    kecamatan_id: '',
    nama_desa: ''
}