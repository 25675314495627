import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import {DppProps} from '../../models/Institution/DppProps';

class DppServices {
    async getAll(page: number, size: number, sort: string, search: string, partaiId?: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/institution/dpp?page=${page}&size=${size}&sort=${sort}&search=${search}${partaiId!== null ? '&partaiId=' + partaiId : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(dppId: string): Promise<AxiosResponse<DppProps>> {
        return axios.get(`/api/v1/institution/dpp/${dppId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: FormData): Promise<AxiosResponse<DppProps>> {
        return axios.post(`/api/v1/institution/dpp`, data, {headers: {'Content-Type': 'multipart/form-data',}});
    }

    async put(dppId: string, data : FormData): Promise<AxiosResponse<DppProps>> {
        return axios.put(`/api/v1/institution/dpp/${dppId}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    async delete(dppId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/institution/dpp/${dppId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DppServices();