import Router from './router';
import {APIProvider} from '@vis.gl/react-google-maps';

function App() {
  return (<APIProvider apiKey={'AIzaSyA7MVdGLHgDIID6WfC-qz-caOyFROek_-E'} onLoad={() => {}}><Router /></APIProvider>);
}

export default App;

// API Key AkbarUinJkt AIzaSyA7MVdGLHgDIID6WfC-qz-caOyFROek_-E
// API Key SyopianJp AIzaSyAKGojSSsoDN8rie1O4-2SV5FgygeOSeLU
// API Key Akbar AIzaSyA9k3Rjb2WbVeUvqPF9u1UiQqOE7vWkjq4
