import React from 'react';

const EksekutifBantuanKontak = () => {
    return (
        <div>
            EksekutifBantuanKontak
        </div>
    );
}

export default EksekutifBantuanKontak;
