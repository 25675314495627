import React from 'react';
import { AccountBox, CalendarMonth, Cancel, Close, Female, InterpreterMode, Male, SaveAs, Search, Transgender, VerifiedUser } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItemButton, ListItemIcon, ListItemText, styled, TextField, Typography } from '@mui/material';
import ListMemberProps from '../../models/Member/ListMemberProps';
import { DetailMemberProps } from '../../models/Member/DetailMemberProps';

interface ModalCreateRelasiToKonstituenProps {
    openModalRelasi: boolean;
    setOpenModalRelasi: React.Dispatch<React.SetStateAction<boolean>>;
    addRelasiKonstituen: any;
    checkNikToRelasiRelawan: any;
    dataTempTambahRelasi: ListMemberProps;
    relawanDataDetail: DetailMemberProps;
    formNik: string;
    setFormNik: React.Dispatch<React.SetStateAction<string>>;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const ModalCreateRelasiToKonstituen: React.FC<ModalCreateRelasiToKonstituenProps> = ({openModalRelasi, setOpenModalRelasi, addRelasiKonstituen, checkNikToRelasiRelawan, dataTempTambahRelasi, relawanDataDetail, formNik, setFormNik}) => {
    
    return (
        <BootstrapDialog
            onClose={() => setOpenModalRelasi(false)}
            aria-labelledby="customized-dialog-title"
            open={openModalRelasi}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Tambah Relasi Konstituen
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpenModalRelasi(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close />
            </IconButton>
            <DialogContent dividers>
                <Typography variant='h6' sx={{ marginBottom: '10px' }} fontSize={'16px'}>Cek NIK Konstituen</Typography>
                <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                    <Grid item sm={8}>
                        <TextField
                            fullWidth
                            id="nik"
                            label="Masukan NIK Relawan"
                            onChange={(e) => setFormNik(e.target.value)}
                            value={formNik}
                            helperText={formNik.length + '/ 16 Karakter'}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <Button fullWidth onClick={() => checkNikToRelasiRelawan(formNik)} startIcon={<Search />} title='Cari' variant='contained' color='success'>Cari</Button>
                    </Grid>
                </Grid>
                {
                    dataTempTambahRelasi.nama === '' ? <></> :
                    <React.Fragment>
                        <Typography variant='h6' sx={{ marginBottom: '10px' }} fontSize={'16px'}>Informasi Konstituen</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                    <img alt={dataTempTambahRelasi.nama} src={dataTempTambahRelasi.avatar} width={'125px'} height={'125px'} />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <List component="nav" aria-label="main mailbox folders">
                                    <ListItemButton>
                                        <ListItemIcon><InterpreterMode /></ListItemIcon>
                                        <ListItemText primary={dataTempTambahRelasi.punya_relawan ? 'Sudah Memiliki Relawan' : 'Belum Memiliki Relawan' ?? 'Belum Ada Relawan'} />
                                    </ListItemButton>
                                    <ListItemButton>
                                        <ListItemIcon><AccountBox /></ListItemIcon>
                                        <ListItemText primary={dataTempTambahRelasi.nama ?? 'Belum Ada Nama'} />
                                    </ListItemButton>
                                    <ListItemButton>
                                        <ListItemIcon>{dataTempTambahRelasi.jenis_kelamin === null ? <Transgender /> : dataTempTambahRelasi.jenis_kelamin === 'PRIA' ? <Male /> : <Female />}</ListItemIcon>
                                        <ListItemText primary={dataTempTambahRelasi.jenis_kelamin ?? 'Belum Ada Jenis Kelamin'} />
                                    </ListItemButton>
                                    <ListItemButton>
                                        <ListItemIcon><CalendarMonth /></ListItemIcon>
                                        <ListItemText primary={dataTempTambahRelasi.tempat_lahir + ", " + dataTempTambahRelasi.tanggal_lahir ?? 'Belum Ada Tempat Lahir dan Tanggal Lahir'} />
                                    </ListItemButton>
                                    <ListItemButton>
                                        <ListItemIcon><VerifiedUser /></ListItemIcon>
                                        <ListItemText primary={dataTempTambahRelasi.username ?? 'Belum Ada Username'} />
                                    </ListItemButton>
                                </List>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant='contained' color='error' startIcon={<Cancel />} onClick={() => setOpenModalRelasi(false)}>
                    Batalkan
                </Button>
                {
                    dataTempTambahRelasi.nama !== '' && !dataTempTambahRelasi.punya_relawan ?
                <Button autoFocus variant='contained' color='success' startIcon={<SaveAs />} onClick={() => addRelasiKonstituen(dataTempTambahRelasi.konstituen_id, relawanDataDetail.relawan.relawan_id)}>
                    Simpan
                </Button> : <></>
                }
            </DialogActions>
        </BootstrapDialog>
    );
}

export default ModalCreateRelasiToKonstituen;
