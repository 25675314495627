import React from 'react';

const KonstituenInformasiPemilih = () => {
    return (
        <div>
            KonstituenInformasiPemilih
        </div>
    );
}

export default KonstituenInformasiPemilih;
