import React from 'react';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { useRecoilValue } from 'recoil';
import { ThemeStoreState } from '../../store';
import { ErrorJenisPemilihanField, ErrorJenisPemilihanProps, JenisPemilihanField, JenisPemilihanProps } from '../../models/Forums/JenisPemilihanProps';
import Swal from 'sweetalert2';
import JenisPemilihanService from '../../services/Forum/JenisPemilihanService';
import { JenisPemilihanValidateForm } from '../../validation/ForumValidateForm';
import { replaceItemAtIndex } from '../../Utils/helper';
import { PeriodeProps } from '../../models/Forums/PeriodeProps';
import { KategoriProps } from '../../models/Forums/KategoriProps';
import PeriodeService from '../../services/Forum/PeriodeService';
import KategoriService from '../../services/Forum/KategoriService';
import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import DialogPengelolaanJenisPemilihan from '../../pages/Superadmin/DialogPengelolaanJenisPemilihan';

const SuperadminPengelolaanJenisPemilihan = () => {
    const [periodeData, setPeriodeData] = React.useState<PeriodeProps[]>([]);
    const [kategoriData, setKategoriData] = React.useState<KategoriProps[]>([]);
    const [errors, setErrors] = React.useState<ErrorJenisPemilihanProps>(ErrorJenisPemilihanField);
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const [loadingSkeletonTable, setLoadingSkeletonTable] = React.useState<boolean>(false);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [titleDialog, setTitleDialog] = React.useState<string>('');
    const [settingTable, setSettingTable] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        periode_id: string;
        kategori_id: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'jenisPemilihanId',
        search: '',
        periode_id: '',
        kategori_id: '',
        totalElements: 0,
        totalPages: 0,
    });
    
    const [jenisPemilihanData, setJenisPemilihanData] = React.useState<JenisPemilihanProps[]>([]);
    const [jenisPemilihanDataDetail, setJenisPemilihanDataDetail] = React.useState<JenisPemilihanProps>(JenisPemilihanField);
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState);

    const getAll = async () => {
        await JenisPemilihanService.getAll(settingTable.page, settingTable.size, settingTable.sort, settingTable.search, settingTable.periode_id, settingTable.kategori_id).then(res => {
            setJenisPemilihanData(res.data.content);
            setSettingTable({...settingTable, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak ada jaringan");
            setOpenSnackbar(true);
        }).finally(() => {
            setLoadingSkeleton(false);
            setLoadingSkeletonTable(false);
        });
    }
    const getPeriode = async () => {
        if(periodeData.length === 0) {
            await PeriodeService.getAllArr().then(res => {
                setPeriodeData(res.data);
            }).catch(err => {
                setMessageSnackbar(err.response.data.message ?? 'Tidak ada Jaringan');
                setOpenSnackbar(true);
            })
        }
    }
    const getKategori = async (periodeId? : string) => {
        await KategoriService.getAllArr(periodeId !== null ? periodeId : settingTable.periode_id).then(res => {
            setKategoriData(res.data);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? 'Tidak ada Jaringan');
            setOpenSnackbar(true);
        })
    }
    const openAdd = async () => {
        // if(periodeData.length === 0) {
        //     await PeriodeService.getAllArr().then(res => {
        //         setJenisPemilihanData(res.data);
        //     }).catch(err => {
        //         setMessageSnackbar(err.response.data.message ?? "Tidak ada jaringan");
        //         setOpenSnackbar(true);
        //     });
        // }
        setTitleDialog("Tambah Jenis Pemilihan");
        setKategoriData([]);
        setJenisPemilihanDataDetail(JenisPemilihanField);
        setOpenModal(true);
    }
    const openById = (JenisPemilihan: JenisPemilihanProps) => {
        getKategori(JenisPemilihan.periode_id);
        setJenisPemilihanDataDetail(JenisPemilihan);
        setTitleDialog("Ubah Jenis Pemilihan");
        setOpenModal(true);
    }
    const createJenisPemilihan = async () => {
        const newErrors: any = {};
        try {
            await JenisPemilihanValidateForm.validate(jenisPemilihanDataDetail, {abortEarly:false});
            setErrors(ErrorJenisPemilihanField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await JenisPemilihanService.create(jenisPemilihanDataDetail).then(res => {
                setJenisPemilihanData([...jenisPemilihanData, res.data]);
                setSettingTable({...settingTable, totalElements: settingTable.totalElements + 1})
                setMessageSnackbar("Jenis Pemilihan berhasil di tambah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar(err.response.data.message ?? "Jenis Pemilihan gagal di tambah");
                setOpenSnackbar(true);
            })
        }
    }
    const updateJenisPemilihan = async () => {
        const newErrors: any = {};
        try {
            await JenisPemilihanValidateForm.validate(jenisPemilihanDataDetail, {abortEarly:false});
            setErrors(ErrorJenisPemilihanField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await JenisPemilihanService.put(jenisPemilihanDataDetail.jenis_pemilihan_id, jenisPemilihanDataDetail).then(res => {
                let index = jenisPemilihanData.findIndex(p => p.jenis_pemilihan_id === jenisPemilihanDataDetail.jenis_pemilihan_id);
                setJenisPemilihanData(replaceItemAtIndex(jenisPemilihanData, index, res.data));
                setMessageSnackbar("Jenis Pemilihan berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Jenis Pemilihan gagal di ubah");
                setOpenSnackbar(true);
            })
        }
    }
    const deleteJenisPemilihan = async (jenisPemilihan: JenisPemilihanProps) => {
        Swal.fire({
            title: 'Hapus Jenis Pemilihan',
            text: 'Apakah anda ingin menghapus Jenis Pemilihan ' + jenisPemilihan.nama_pemilihan + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                JenisPemilihanService.delete(jenisPemilihan.jenis_pemilihan_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Jenis Pemilihan "+ jenisPemilihan.nama_pemilihan +" sudah dihapus.",
                        icon: "success"
                    });
                    setJenisPemilihanData(jenisPemilihanData.filter(p => p.jenis_pemilihan_id !== jenisPemilihan.jenis_pemilihan_id));
                    setSettingTable({...settingTable, totalElements: settingTable.totalElements - 1})
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: err.response.data.message ?? "Jenis Pemilihan "+ jenisPemilihan.nama_pemilihan +" gagal dihapus.",
                        icon: "error"
                    });
                })
            }
        });
    }

    React.useEffect(() => {
        getAll();
        getPeriode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingTable.page, settingTable.size, settingTable.sort, settingTable.search, settingTable.periode_id, settingTable.kategori_id]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable,  page: newPage});
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTable({...settingTable, page: 0, size: +event.target.value});
    };

    return loadingSkeleton ? (<Container>
        <Skeleton animation="wave" width={'100%'} height={'576px'} variant='rounded'></Skeleton>
    </Container>) : (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: '10px' }}>
                    <Grid container spacing={2} sx={{ paddingBottom: '5px' }}>
                        <Grid item xs={8} sm={8}>
                            <Typography variant='h3' fontSize={'24px'}>Jenis Pemilihan</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} display={'flex'} justifyContent={'flex-end'}>
                            <Button startIcon={<Add />} onClick={() => openAdd()} title='tambah' color='info' variant='contained'>Tambah</Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ paddingBottom: '20px' }}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="periode-select">Periode</InputLabel>
                                <Select
                                    labelId="periode-select"
                                    id="periode_id"
                                    value={settingTable.periode_id}
                                    disabled={periodeData.length === 0}
                                    label="Periode"
                                    onChange={(e) => {getKategori(e.target.value); setSettingTable({...settingTable, periode_id: e.target.value, kategori_id: ''});}}
                                >
                                    {
                                        periodeData.map(p => <MenuItem key={p.periode_id} value={p.periode_id}>{p.tahun}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="kategori-select">Kategori</InputLabel>
                                <Select
                                    labelId="kategori-select"
                                    id="kategori_id"
                                    value={settingTable.kategori_id}
                                    disabled={kategoriData.length === 0}
                                    label="Kategori"
                                    onChange={(e) => setSettingTable({...settingTable, kategori_id: e.target.value})}
                                >
                                    {
                                        kategoriData.map(p => <MenuItem key={p.kategori_id} value={p.kategori_id}>{p.nama_kategori}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                {
                loadingSkeletonTable ? <Skeleton animation={'wave'} width={'100%'} height={'500px'} /> :
                <Grid item xs={12} sm={12} md={12}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 540 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Periode</TableCell>
                                        <TableCell>Kategori</TableCell>
                                        <TableCell>Jenis Pemilihan</TableCell>
                                        <TableCell>Deskripsi</TableCell>
                                        <TableCell>Aksi</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    jenisPemilihanData.length !== 0 ? jenisPemilihanData.map(p => 
                                    <TableRow key={p.jenis_pemilihan_id}>
                                        <TableCell>{p.nama_periode}</TableCell>
                                        <TableCell>{p.nama_kategori}</TableCell>
                                        <TableCell>{p.nama_pemilihan}</TableCell>
                                        <TableCell>{p.deskripsi}</TableCell>
                                        <TableCell>
                                            <Button sx={{ marginLeft: '5px' }} onClick={() => openById(p)} size='small' variant='contained' startIcon={<Edit />} color='success'>Ubah</Button>
                                            <Button sx={{ marginLeft: '5px' }} onClick={() => deleteJenisPemilihan(p)} size='small' variant='contained' startIcon={<Delete />} color='error'>Hapus</Button>
                                        </TableCell>
                                    </TableRow> 
                                    )
                                    : <TableRow>
                                        <TableCell colSpan={6}>
                                            <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                        component="div"
                        count={settingTable.totalElements}
                        rowsPerPage={settingTable.size}
                        page={settingTable.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Paper>
                </Grid>
                }
                <Snackbar
                    open={openSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={3000}
                    onClose={() => setOpenSnackbar(false)}
                    message={messageSnackbar}
                />
                <DialogPengelolaanJenisPemilihan
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    theme={theme}
                    titleDialog={titleDialog}
                    errors={errors}
                    setErrors={setErrors}
                    kategoriData={kategoriData}
                    periodeData={periodeData}
                    jenisPemilihanDataDetail={jenisPemilihanDataDetail}
                    setJenisPemilihanDataDetail={setJenisPemilihanDataDetail}
                    createJenisPemilihan={createJenisPemilihan}
                    updateJenisPemilihan={updateJenisPemilihan}
                    getKategori={getKategori}
                />
            </Grid>
    );
}

export default SuperadminPengelolaanJenisPemilihan;
