import * as yup from 'yup';

export const ProvinsiValidateForm = yup.object().shape({
    nama_provinsi: yup.string().required("Nama Provinsi harus diisi").max(255, 'Panjang Nama Provinsi maksimal 255 Karakter'),
});
export const KabupatenValidateForm = yup.object().shape({
    nama_kabupaten: yup.string().required("Nama Kabupaten harus diisi").max(255, 'Panjang Nama Kabupaten maksimal 255 Karakter'),
});
export const KecamatanValidateForm = yup.object().shape({
    nama_kecamatan: yup.string().required("Nama Kecamatan harus diisi").max(255, 'Panjang Nama Kecamatan maksimal 255 Karakter'),
});
export const DesaValidateForm = yup.object().shape({
    nama_desa: yup.string().required("Nama Desa harus diisi").max(255, 'Panjang Nama Desa maksimal 255 Karakter'),
});
export const RwValidateForm = yup.object().shape({
    nama_rw: yup.string().required("Nama RW harus diisi").max(4, 'Panjang Nama RW maksimal 4 Karakter'),
});
export const RtValidateForm = yup.object().shape({
    nama_rt: yup.string().required("Nama RT harus diisi").max(4, 'Panjang Nama RT maksimal 4 Karakter'),
});