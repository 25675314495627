import axios, { AxiosResponse } from 'axios';
import { ProfileResponseProps } from '../../models/Profile/ProfileResponseProps';

class ProfileServices {
    async get(memberId: string): Promise<AxiosResponse<ProfileResponseProps>> {
        return axios.get(`/api/v1/profile/` + memberId,{headers: {'Content-Type': 'application/json',}});
    }

    async put(data : FormData): Promise<AxiosResponse<ProfileResponseProps>> {
        return axios.put(`/api/v1/profile/change-profile`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ProfileServices();