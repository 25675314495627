import React from 'react';
import { Button, Grid, IconButton, Paper, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { ThemeStoreState } from '../../store';
import { ProvinsiField, ProvinsiProps } from '../../models/Area/ProvinsiProps';
import { KabupatenField, KabupatenProps } from '../../models/Area/KabupatenProps';
import { KecamatanField, KecamatanProps } from '../../models/Area/KecamatanProps';
import { DesaField, DesaProps } from '../../models/Area/DesaProps';
import { useRecoilValue } from 'recoil';
import { RwProps, RwField } from '../../models/Area/RwProps';
import {RtField, RtProps} from '../../models/Area/RtProps';
import ProvinsiService from '../../services/Area/ProvinsiService';
import DesaService from '../../services/Area/DesaService';
import KecamatanService from '../../services/Area/KecamatanService';
import KabupatenService from '../../services/Area/KabupatenService';
import RwService from '../../services/Area/RwService';
import RtService from '../../services/Area/RtService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Alert } from '@mui/material';
import { AlertField, AlertProps } from '../../models/Component/AlertProps';
import Swal from 'sweetalert2'
import ModalArea from '../../pages/Area/ModalArea';
import { DesaValidateForm, KabupatenValidateForm, KecamatanValidateForm, ProvinsiValidateForm, RtValidateForm, RwValidateForm } from '../../validation/AreaValidateForm';
import { errorFormAreaField, errorFormAreaProps } from '../../models/Area/ErrorFormArea';
import { replaceItemAtIndex } from '../../Utils/helper';

const AdminPengelolaanArea = () => {
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const [loadingSkeletonTable, setLoadingSkeletonTable] = React.useState<boolean>(false);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [titleDialog, setTitleDialog] = React.useState<string>('');
    const [settingTable, setSettingTable] = React.useState({
        provinsi: {
            page: 0,
            size: 10,
            sort: 'provinsiId',
            search: '',
            totalElements: 0,
            totalPages: 0,
            pilihan: '',
            namaPilihan: ''
        },
        kabupaten: {
            page: 0,
            size: 10,
            sort: 'kabupatenId',
            search: '',
            totalElements: 0,
            pilihan: '',
            totalPages: 0,
            namaPilihan: ''
        },
        kecamatan: {
            page: 0,
            size: 10,
            sort: 'kecamatanId',
            search: '',
            totalElements: 0,
            pilihan: '',
            totalPages: 0,
            namaPilihan: ''
        },
        desa: {
            page: 0,
            size: 10,
            sort: 'desaId',
            search: '',
            totalElements: 0,
            totalPages: 0,
            pilihan: '',
            namaPilihan: ''
        },
        rw: {
            page: 0,
            size: 10,
            sort: 'rwId',
            search: '',
            totalElements: 0,
            totalPages: 0,
            pilihan: '',
            namaPilihan: ''
        },
        rt: {
            page: 0,
            size: 10,
            sort: 'rtId',
            search: '',
            totalElements: 0,
            totalPages: 0,
            pilihan: '',
            namaPilihan: ''
        },
    });
    const [errorForm, setErrorForm] = React.useState<errorFormAreaProps>(errorFormAreaField);
    const [alertOption, setAlertOption] = React.useState<AlertProps>(AlertField);
    const [provinsiData, setProvinsiData] = React.useState<ProvinsiProps[]>([]);
    const [kabupatenData, setKabupatenData] = React.useState<KabupatenProps[]>([]);
    const [kecamatanData, setKecamatanData] = React.useState<KecamatanProps[]>([]);
    const [desaData, setDesaData] = React.useState<DesaProps[]>([]);
    const [rwData, setRwData] = React.useState<RwProps[]>([]);
    const [rtData, setRtData] = React.useState<RtProps[]>([]);

    const [formProvinsi, setFormProvinsi] = React.useState<ProvinsiProps>(ProvinsiField);
    const [formKabupaten, setFormKabupaten] = React.useState<KabupatenProps>(KabupatenField);
    const [formKecamatan, setFormKecamatan] = React.useState<KecamatanProps>(KecamatanField);
    const [formDesa, setFormDesa] = React.useState<DesaProps>(DesaField);
    const [formRw, setFormRw] = React.useState<RwProps>(RwField);
    const [formRt, setFormRt] = React.useState<RtProps>(RtField);

    const [areaSelected, setAreaSelected] = React.useState<number>(1);
    const [areaSelectedTitle, setAreaSelectedTitle] = React.useState<string>("Provinsi");
    
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState)
    
    function getProvinsi() {
        setLoadingSkeleton(true);
        ProvinsiService.getAll(settingTable.provinsi.page, settingTable.provinsi.size, settingTable.provinsi.sort, settingTable.provinsi.search).then(res => {
            setSettingTable({...settingTable, provinsi: {
                ...settingTable.provinsi, totalElements: res.data.totalElements, totalPages: res.data.totalPages
            }});
            setProvinsiData(res.data.content);
        }).catch(err => setAlertOption({open: true, message: 'Error: ' + err, severity: 'error'}));
        setLoadingSkeleton(false);
    }
    
    function getKabupaten() {
        setLoadingSkeleton(true);
        KabupatenService.getAll(settingTable.kabupaten.page, settingTable.kabupaten.size, settingTable.kabupaten.sort, settingTable.kabupaten.search, settingTable.provinsi.pilihan).then(res => {
            setSettingTable({...settingTable, kabupaten: {
                ...settingTable.kabupaten, totalElements: res.data.totalElements, totalPages: res.data.totalPages
            }});
            setKabupatenData(res.data.content)
        }).catch(err => setAlertOption({open: true, message: 'Error: ' + err, severity: 'error'}));
        setLoadingSkeleton(false);
    }

    function getKecamatan() {
        setLoadingSkeleton(true);
        KecamatanService.getAll(settingTable.kecamatan.page, settingTable.kecamatan.size, settingTable.kecamatan.sort, settingTable.kecamatan.search, settingTable.kabupaten.pilihan).then(res => {
            setSettingTable({...settingTable, kecamatan: {
                ...settingTable.kecamatan, totalElements: res.data.totalElements, totalPages: res.data.totalPages
            }});
            setKecamatanData(res.data.content)
        }).catch(err => setAlertOption({open: true, message: 'Error: ' + err, severity: 'error'}));
        setLoadingSkeleton(false);
    }
    
    function getDesa() {
        setLoadingSkeleton(true);
        DesaService.getAll(settingTable.desa.page, settingTable.desa.size, settingTable.desa.sort, settingTable.desa.search, settingTable.kecamatan.pilihan).then(res => {
            setSettingTable({...settingTable, desa: {
                ...settingTable.desa, totalElements: res.data.totalElements, totalPages: res.data.totalPages
            }});
            setDesaData(res.data.content)
        }).catch(err => setAlertOption({open: true, message: 'Error: ' + err, severity: 'error'}));
        setLoadingSkeleton(false);
    }
    
    function getRw() {
        setLoadingSkeleton(true);
        RwService.getAll(settingTable.rw.page, settingTable.rw.size, settingTable.rw.sort, settingTable.rw.search, settingTable.desa.pilihan).then(res => {
            setSettingTable({...settingTable, rw: {
                ...settingTable.rw, totalElements: res.data.totalElements, totalPages: res.data.totalPages
            }});
            setRwData(res.data.content)
        }).catch(err => setAlertOption({open: true, message: 'Error: ' + err, severity: 'error'}));
        setLoadingSkeleton(false);
    }
    
    function getRt() {
        setLoadingSkeleton(true);
        RtService.getAll(settingTable.rt.page, settingTable.rt.size, settingTable.rt.sort, settingTable.rt.search, settingTable.rw.pilihan).then(res => {
            setSettingTable({...settingTable, rt: {
                ...settingTable.rt, totalElements: res.data.totalElements, totalPages: res.data.totalPages
            }});
            setRtData(res.data.content)
        }).catch(err => setAlertOption({open: true, message: 'Error: ' + err, severity: 'error'}));
        setLoadingSkeleton(false);
    }

    React.useEffect(() => {
        setLoadingSkeletonTable(true);
        if(areaSelected === 1) {
            getProvinsi();
        }
        if(areaSelected === 2) {
            getKabupaten();
        }
        if(areaSelected === 3) {
            getKecamatan();
        }
        if(areaSelected === 4) {
            getDesa();
        }
        if(areaSelected === 5) {
            getRw();
        }
        if(areaSelected === 6) {
            getRt();
        }
        setLoadingSkeletonTable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        areaSelected, 
        settingTable.provinsi.page, 
        settingTable.kabupaten.page,
        settingTable.kecamatan.page,
        settingTable.desa.page,
        settingTable.rw.page,
        settingTable.rt.page,
        settingTable.provinsi.size, 
        settingTable.kabupaten.size,
        settingTable.kecamatan.size,
        settingTable.desa.size,
        settingTable.rw.size,
        settingTable.rt.size,
        settingTable.provinsi.sort, 
        settingTable.kabupaten.sort,
        settingTable.kecamatan.sort,
        settingTable.desa.sort,
        settingTable.rw.sort,
        settingTable.rt.sort,
        settingTable.provinsi.search, 
        settingTable.kabupaten.search,
        settingTable.kecamatan.search,
        settingTable.desa.search,
        settingTable.rw.search,
        settingTable.rt.search,
    ])

    const handleChangePage = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        if(areaSelected === 1) {
            setSettingTable({...settingTable, provinsi: {...settingTable.provinsi, page: newPage}});
        }
        
        if(areaSelected === 2) {
            setSettingTable({...settingTable, kabupaten: {...settingTable.kabupaten, page: newPage}});
        }
        
        if(areaSelected === 3) {
            setSettingTable({...settingTable, kecamatan: {...settingTable.kecamatan, page: newPage}});
        }
        
        if(areaSelected === 4) {
            setSettingTable({...settingTable, desa: {...settingTable.desa, page: newPage}});
        }
        
        if(areaSelected === 5) {
            setSettingTable({...settingTable, rw: {...settingTable.rw, page: newPage}});
        }
        
        if(areaSelected === 6) {
            setSettingTable({...settingTable, rt: {...settingTable.rt, page: newPage}});
        }
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(areaSelected === 1) {
            setSettingTable({...settingTable, provinsi: {...settingTable.provinsi, page: 0, size: +event.target.value}});
        }
        
        if(areaSelected === 2) {
            setSettingTable({...settingTable, kabupaten: {...settingTable.kabupaten, page: 0, size: +event.target.value}});
        }
        
        if(areaSelected === 3) {
            setSettingTable({...settingTable, kecamatan: {...settingTable.kecamatan, page: 0, size: +event.target.value}});
        }
        
        if(areaSelected === 4) {
            setSettingTable({...settingTable, desa: {...settingTable.desa, page: 0, size: +event.target.value}});
        }

        if(areaSelected === 5) {
            setSettingTable({...settingTable, rw: {...settingTable.rw, page: 0, size: +event.target.value}});
        }

        if(areaSelected === 6) {
            setSettingTable({...settingTable, rt: {...settingTable.rt, page: 0, size: +event.target.value}});
        }
    };
    const gotoAreaProvinsiToKabupaten = (provinsiData: ProvinsiProps) => {
        setSettingTable({...settingTable, provinsi: {...settingTable.provinsi, pilihan: provinsiData.provinsi_id, namaPilihan: provinsiData.nama_provinsi, page: 0}, kabupaten: {...settingTable.kabupaten, page: 0}});
        setAreaSelectedTitle(provinsiData.nama_provinsi);
        setAreaSelected(2);
    }
    const gotoAreaKabupatenToProvinsi = () => {
        setSettingTable({...settingTable, provinsi: {...settingTable.provinsi, pilihan: '', namaPilihan: '', page: 0}, kabupaten: {...settingTable.kabupaten, page: 0}});
        setAreaSelectedTitle("Provinsi");
        setAreaSelected(1);
    }
    const gotoAreaKabupatenToKecamatan = (kabupatenData: KabupatenProps) => {
        setSettingTable({...settingTable, kabupaten: {...settingTable.kabupaten, pilihan: kabupatenData.kabupaten_id, namaPilihan: kabupatenData.nama_kabupaten, page: 0}, kecamatan: {...settingTable.kecamatan, page: 0}});
        setAreaSelectedTitle(kabupatenData.nama_kabupaten);
        setAreaSelected(3);
    }
    const gotoAreaKecamatanToKabupaten = () => {
        setSettingTable({...settingTable, kabupaten: {...settingTable.kabupaten, pilihan: '', namaPilihan: '', page: 0}, kecamatan: {...settingTable.kecamatan, page: 0}});
        setAreaSelectedTitle(settingTable.provinsi.namaPilihan);
        setAreaSelected(2);
    }
    const gotoAreaKecamatanToDesa = (kecamatanData: KecamatanProps) => {
        setSettingTable({...settingTable, kecamatan: {...settingTable.kecamatan, pilihan: kecamatanData.kecamatan_id, namaPilihan: kecamatanData.nama_kecamatan, page: 0}, desa: {...settingTable.desa, page: 0}});
        setAreaSelectedTitle(kecamatanData.nama_kecamatan);
        setAreaSelected(4);
    }
    const gotoAreaDesaToKecamatan = () => {
        setSettingTable({...settingTable, kecamatan: {...settingTable.kecamatan, pilihan: '', page: 0}, desa: {...settingTable.desa, page: 0}});
        setAreaSelectedTitle(settingTable.kabupaten.namaPilihan);
        setAreaSelected(3);
    }
    const gotoAreaDesaToRw = (desaData: DesaProps) => {
        setSettingTable({...settingTable, desa: {...settingTable.desa, pilihan: desaData.desa_id, namaPilihan: desaData.nama_desa, page: 0}, rw: {...settingTable.rw, page: 0}});
        setAreaSelectedTitle(desaData.nama_desa);
        setAreaSelected(5);
    }
    const gotoAreaRwToDesa = () => {
        setSettingTable({...settingTable, desa: {...settingTable.desa, pilihan: '', namaPilihan: '', page: 0}, rw: {...settingTable.rw, page: 0}});
        setAreaSelectedTitle(settingTable.kecamatan.namaPilihan);
        setAreaSelected(4);
    }
    const gotoAreaRwToRt = (rwData: RwProps) => {
        setSettingTable({...settingTable, rw: {...settingTable.rw, pilihan: rwData.rw_id, namaPilihan: rwData.nama_rw, page: 0}, rt: {...settingTable.rt, page: 0}});
        setAreaSelectedTitle(rwData.nama_rw);
        setAreaSelected(6);
    }
    const gotoAreaRtToRw = () => {
        setSettingTable({...settingTable, rw: {...settingTable.rw, pilihan: '', namaPilihan: '', page: 0}, rt: {...settingTable.rt, page: 0}});
        setAreaSelectedTitle(settingTable.desa.namaPilihan);
        setAreaSelected(5);
    }

    const openAddProvinsi = () => {
        setFormProvinsi(ProvinsiField);
        setTitleDialog("Tambah Provinsi");
        setOpenModal(true);
    }
    const openAddKabupaten = () => {
        setFormKabupaten(KabupatenField);
        setTitleDialog("Tambah Kabupaten");
        setFormKabupaten({...formKabupaten, provinsi_id: settingTable.provinsi.pilihan});
        setOpenModal(true);
    }
    const openAddKecamatan = () => {
        setFormKecamatan(KecamatanField);
        setTitleDialog("Tambah Kecamatan");
        setFormKecamatan({...formKecamatan, kabupaten_id: settingTable.kabupaten.pilihan});
        setOpenModal(true);
    }
    const openAddDesa = () => {
        setFormDesa(DesaField);
        setTitleDialog("Tambah Desa");
        setFormDesa({...formDesa, kecamatan_id: settingTable.kecamatan.pilihan});
        setOpenModal(true);
    }
    const openAddRw = () => {
        setFormRw(RwField);
        setTitleDialog("Tambah RW");
        setFormRw({...formRw, desa_id: settingTable.desa.pilihan});
        setOpenModal(true);
    }
    const openAddRt = () => {
        setFormRt(RtField);
        setFormRt({...formRt, rw_id: settingTable.rw.pilihan});
        setTitleDialog("Tambah RT");
        setOpenModal(true);
    }

    const createProvinsi = async () => {
        const newErrors: any = {};
        try {
            await ProvinsiValidateForm.validate(formProvinsi, {abortEarly:false});
            setErrorForm(errorFormAreaField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await ProvinsiService.create(formProvinsi).then(res => {
                setProvinsiData([...provinsiData, res.data]);
                setMessageSnackbar("Provinsi berhasil di tambah");
                setOpenSnackbar(true);
                setOpenModal(false);
                setSettingTable({...settingTable, provinsi: {...settingTable.provinsi, totalElements: settingTable.provinsi.totalElements + 1}})
            }).catch(err => {
                setMessageSnackbar("Provinsi gagal di tambah");
                setOpenSnackbar(true);
            })
        }

    }
    const createKabupaten = async () => {
        const newErrors: any = {};
        try {
            await KabupatenValidateForm.validate(formKabupaten, {abortEarly:false});
            setErrorForm(errorFormAreaField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await KabupatenService.create(formKabupaten).then(res => {
                setKabupatenData([...kabupatenData, res.data]);
                setMessageSnackbar("Kabupaten berhasil di tambah");
                setSettingTable({...settingTable, kabupaten: {...settingTable.kabupaten, totalElements: settingTable.kabupaten.totalElements + 1}})
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Kabupaten gagal di tambah");
                setOpenSnackbar(true);
            })
        }
    }
    const createKecamatan = async () => {
        const newErrors: any = {};
        try {
            await KecamatanValidateForm.validate(formKecamatan, {abortEarly:false});
            setErrorForm(errorFormAreaField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await KecamatanService.create(formKecamatan).then(res => {
                setKecamatanData([...kecamatanData, res.data]);
                setSettingTable({...settingTable, kecamatan: {...settingTable.kecamatan, totalElements: settingTable.kecamatan.totalElements + 1}})
                setMessageSnackbar("Kecamatan berhasil di tambah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Kecamatan gagal di tambah");
                setOpenSnackbar(true);
            })
        }
    }
    const createDesa = async () => {
        const newErrors: any = {};
        try {
            await DesaValidateForm.validate(formDesa, {abortEarly:false});
            setErrorForm(errorFormAreaField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await DesaService.create(formDesa).then(res => {
                setDesaData([...desaData, res.data]);
                setSettingTable({...settingTable, desa: {...settingTable.desa, totalElements: settingTable.desa.totalElements + 1}})
                setMessageSnackbar("Desa berhasil di tambah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Desa gagal di tambah");
                setOpenSnackbar(true);
            })
        }
    }
    const createRw = async () => {
        const newErrors: any = {};
        try {
            await RwValidateForm.validate(formRw, {abortEarly:false});
            setErrorForm(errorFormAreaField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await RwService.create(formRw).then(res => {
                setRwData([...rwData, res.data]);
                setSettingTable({...settingTable, rw: {...settingTable.rw, totalElements: settingTable.rw.totalElements + 1}})
                setMessageSnackbar("RW berhasil di tambah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("RW gagal di tambah");
                setOpenSnackbar(true);
            })
        }
    }
    const createRt = async () => {
        const newErrors: any = {};
        try {
            await RtValidateForm.validate(formRt, {abortEarly:false});
            setErrorForm(errorFormAreaField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await RtService.create(formRt).then(res => {
                setRtData([...rtData, res.data]);
                setSettingTable({...settingTable, rt: {...settingTable.rt, totalElements: settingTable.rt.totalElements + 1}})
                setMessageSnackbar("RT berhasil di tambah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("RT gagal di tambah");
                setOpenSnackbar(true);
            })
        }
    }

    const editProvinsi = (provinsiData: ProvinsiProps) => {
        setFormProvinsi(provinsiData);
        setTitleDialog("Ubah Provinsi");
        setOpenModal(true);
    }
    const editKabupaten = (kabupatenData: KabupatenProps) => {
        setFormKabupaten({...kabupatenData, provinsi_id: settingTable.provinsi.pilihan});
        setTitleDialog("Ubah Kabupaten");
        setOpenModal(true);
    }
    const editKecamatan = (kecamatanData: KecamatanProps) => {
        setTitleDialog("Ubah Kecamatan");
        setFormKecamatan({...kecamatanData, kabupaten_id: settingTable.kabupaten.pilihan});
        setOpenModal(true);
    }
    const editDesa = (desaData: DesaProps) => {
        setFormDesa({...desaData, kecamatan_id: settingTable.kecamatan.pilihan});
        setTitleDialog("Ubah Desa");
        setOpenModal(true);
    }
    const editRw = (rwData: RwProps) => {
        setFormRw({...rwData, desa_id: settingTable.desa.pilihan});
        setTitleDialog("Ubah RW");
        setOpenModal(true);
    }
    const editRt = (rtData: RtProps) => {
        setFormRt({...rtData, rw_id: settingTable.rw.pilihan});
        setTitleDialog("Ubah RT");
        setOpenModal(true);
    }

    const updateProvinsi = async () => {
        const newErrors: any = {};
        try {
            await ProvinsiValidateForm.validate(formProvinsi, {abortEarly:false});
            setErrorForm(errorFormAreaField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await ProvinsiService.put(formProvinsi.provinsi_id, formProvinsi).then(res => {
                let index = provinsiData.findIndex(p => p.provinsi_id === formProvinsi.provinsi_id);
                setProvinsiData(replaceItemAtIndex(provinsiData, index, formProvinsi));
                setMessageSnackbar("Provinsi berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Provinsi gagal di ubah");
                setOpenSnackbar(true);
            })
        }

    }
    const updateKabupaten = async () => {
        const newErrors: any = {};
        try {
            await KabupatenValidateForm.validate(formKabupaten, {abortEarly:false});
            setErrorForm(errorFormAreaField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await KabupatenService.put(formKabupaten.kabupaten_id, formKabupaten).then(res => {
                let index = kabupatenData.findIndex(p => p.kabupaten_id === formKabupaten.kabupaten_id);
                setKabupatenData(replaceItemAtIndex(kabupatenData, index, formKabupaten));
                setMessageSnackbar("Kabupaten berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Kabupaten gagal di ubah");
                setOpenSnackbar(true);
            })
        }
    }
    const updateKecamatan = async () => {
        const newErrors: any = {};
        try {
            await KecamatanValidateForm.validate(formKecamatan, {abortEarly:false});
            setErrorForm(errorFormAreaField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await KecamatanService.put(formKecamatan.kecamatan_id, formKecamatan).then(res => {
                let index = kecamatanData.findIndex(p => p.kecamatan_id === formKecamatan.kecamatan_id);
                setKecamatanData(replaceItemAtIndex(kecamatanData, index, formKecamatan));
                setMessageSnackbar("Kecamatan berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Kecamatan gagal di ubah");
                setOpenSnackbar(true);
            })
        }
    }
    const updateDesa = async () => {
        const newErrors: any = {};
        try {
            await DesaValidateForm.validate(formDesa, {abortEarly:false});
            setErrorForm(errorFormAreaField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await DesaService.put(formDesa.desa_id, formDesa).then(res => {
                let index = desaData.findIndex(p => p.desa_id === formDesa.desa_id);
                setDesaData(replaceItemAtIndex(desaData, index, formDesa));
                setMessageSnackbar("Desa berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Desa gagal di ubah");
                setOpenSnackbar(true);
            })
        }
    }
    const updateRw = async () => {
        const newErrors: any = {};
        try {
            await RwValidateForm.validate(formRw, {abortEarly:false});
            setErrorForm(errorFormAreaField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await RwService.put(formRw.rw_id, formRw).then(res => {
                let index = rwData.findIndex(p => p.rw_id === formRw.rw_id);
                setRwData(replaceItemAtIndex(rwData, index, formRw));
                setMessageSnackbar("RW berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("RW gagal di ubah");
                setOpenSnackbar(true);
            })
        }
    }
    const updateRt = async () => {
        const newErrors: any = {};
        try {
            await RtValidateForm.validate(formRt, {abortEarly:false});
            setErrorForm(errorFormAreaField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await RtService.put(formRt.rt_id, formRt).then(res => {
                let index = rtData.findIndex(p => p.rt_id === formRt.rt_id);
                setRtData(replaceItemAtIndex(rtData, index, formRt));
                setMessageSnackbar("RT berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("RT gagal di ubah");
                setOpenSnackbar(true);
            })
        }
    }

    const deleteProvinsi =(provinsiDataField: ProvinsiProps) => {
        Swal.fire({
            title: 'Hapus Provinsi',
            text: 'Apakah anda ingin menghapus provinsi ' + provinsiDataField.nama_provinsi + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                ProvinsiService.delete(provinsiDataField.provinsi_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Provinsi "+ provinsiDataField.nama_provinsi +" sudah dihapus.",
                        icon: "success"
                    });
                    setProvinsiData(provinsiData.filter(p => p.provinsi_id !== provinsiDataField.provinsi_id));
                    setSettingTable({...settingTable, provinsi: {...settingTable.provinsi, totalElements: settingTable.provinsi.totalElements - 1}})
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: err.response.data.message ?? "Provinsi "+ provinsiDataField.nama_provinsi +" gagal dihapus.",
                        icon: "error"
                    });
                    console.error(err);
                })
            }
        });
    }
    const deleteKabupaten =(kabupatenDataField: KabupatenProps) => {
        Swal.fire({
            title: 'Hapus Kabupaten',
            text: 'Apakah anda ingin menghapus kabupaten ' + kabupatenDataField.nama_kabupaten + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                KabupatenService.delete(kabupatenDataField.kabupaten_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Kabupaten "+ kabupatenDataField.nama_kabupaten +" sudah dihapus.",
                        icon: "success"
                    });
                    setKabupatenData(kabupatenData.filter(k => k.kabupaten_id !== kabupatenDataField.kabupaten_id))
                    setSettingTable({...settingTable, kabupaten: {...settingTable.kabupaten, totalElements: settingTable.kabupaten.totalElements - 1}})
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: err.response.data.message ?? "Kabupaten "+ kabupatenDataField.nama_kabupaten +" gagal dihapus.",
                        icon: "error"
                    });
                    console.error(err);
                })
            }
        });
    }
    const deleteKecamatan =(kecamatanDataField: KecamatanProps) => {
        Swal.fire({
            title: 'Hapus Kecamatan',
            text: 'Apakah anda ingin menghapus Kecamatan ' + kecamatanDataField.nama_kecamatan + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                KecamatanService.delete(kecamatanDataField.kecamatan_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Kecamatan "+ kecamatanDataField.nama_kecamatan +" sudah dihapus.",
                        icon: "success"
                    });
                    setKecamatanData(kecamatanData.filter(k => k.kecamatan_id !== kecamatanDataField.kecamatan_id));
                    setSettingTable({...settingTable, kecamatan: {...settingTable.kecamatan, totalElements: settingTable.kecamatan.totalElements - 1}})
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: err.response.data.message ?? "Kecamatan "+ kecamatanDataField.nama_kecamatan +" gagal dihapus.",
                        icon: "error"
                    });
                    console.error(err);
                })
            }
        });
    }
    const deleteDesa =(desaDataField: DesaProps) => {
        Swal.fire({
            title: 'Hapus Desa',
            text: 'Apakah anda ingin menghapus Desa ' + desaDataField.nama_desa + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                DesaService.delete(desaDataField.desa_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Desa "+ desaDataField.nama_desa +" sudah dihapus.",
                        icon: "success"
                    });
                    setDesaData(desaData.filter(d => d.desa_id !== desaDataField.desa_id));
                    setSettingTable({...settingTable, desa: {...settingTable.desa, totalElements: settingTable.desa.totalElements - 1}})
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: err.response.data.message ?? "Desa gagal dihapus",
                        icon: "error"
                    });
                    console.error(err);
                })
            }
        });
    }
    const deleteRw =(rwDataField: RwProps) => {
        Swal.fire({
            title: 'Hapus RW',
            text: 'Apakah anda ingin menghapus RW ' + rwDataField.nama_rw + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                RwService.delete(rwDataField.rw_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "RW "+ rwDataField.nama_rw +" sudah dihapus.",
                        icon: "success"
                    });
                    setRwData(rwData.filter(r => r.rw_id !== rwDataField.rw_id))
                    setSettingTable({...settingTable, rw: {...settingTable.rw, totalElements: settingTable.rw.totalElements - 1}})
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: err.response.data.message ?? "RW "+ rwDataField.nama_rw +" gagal dihapus.",
                        icon: "error"
                    });
                    console.error(err);
                })
            }
        });
    }
    const deleteRt =(rtDataField: RtProps) => {
        Swal.fire({
            title: 'Hapus RT',
            text: 'Apakah anda ingin menghapus RT ' + rtDataField.nama_rt + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                RtService.delete(rtDataField.rt_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "RT "+ rtDataField.nama_rt +" sudah dihapus.",
                        icon: "success"
                    });
                    setRtData(rtData.filter(r => r.rt_id !== rtDataField.rt_id));
                    setSettingTable({...settingTable, rt: {...settingTable.rt, totalElements: settingTable.rt.totalElements - 1}})
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: err.response.data.message ?? "RT "+ rtDataField.nama_rt +" gagal dihapus.",
                        icon: "error"
                    });
                    console.error(err);
                })
            }
        });
    }

    return loadingSkeleton ? (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                        <Skeleton width={'100%'} height={'50px'} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Skeleton width={'100%'} height={'50px'} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Skeleton width={'100%'} height={'500px'} />
            </Grid>
        </Grid>
    ) : (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
            {
                alertOption.open === true ? <Alert severity={alertOption.severity} children={alertOption.message} onClose={() => setAlertOption({...alertOption, open: false})}></Alert> : <></>
            }
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <Typography variant='h5' sx={{ justifyContent: 'center', alignItem: 'center' }}>
                            {
                                areaSelected !== 1 ? 
                                <IconButton onClick={() => {
                                    if(areaSelected === 2) {
                                        gotoAreaKabupatenToProvinsi();
                                    }
                                    if(areaSelected === 3) {
                                        gotoAreaKecamatanToKabupaten();
                                    }
                                    if(areaSelected === 4) {
                                        gotoAreaDesaToKecamatan();
                                    }
                                    if(areaSelected === 5) {
                                        gotoAreaRwToDesa();
                                    }
                                    if(areaSelected === 6) {
                                        gotoAreaRtToRw();
                                    }
                                }} aria-label="back">
                                    <ArrowBackIcon />
                                </IconButton> : <></>
                            }
                            {areaSelectedTitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        {
                            areaSelected === 1 ? 
                            <Button variant='contained' sx={{ backgroundColor: theme.backgroundColor, color: theme.color }} onClick={() => openAddProvinsi()}color='inherit'>Tambah Provinsi</Button> : areaSelected === 2 ?
                            <Button variant='contained' sx={{ backgroundColor: theme.backgroundColor, color: theme.color }}  onClick={() => openAddKabupaten()} color='inherit'>Tambah Kabupaten</Button> : areaSelected === 3 ?
                            <Button variant='contained' sx={{ backgroundColor: theme.backgroundColor, color: theme.color }}  onClick={() => openAddKecamatan()} color='inherit'>Tambah Kecamatan</Button> : areaSelected === 4 ?
                            <Button variant='contained' sx={{ backgroundColor: theme.backgroundColor, color: theme.color }}  onClick={() => openAddDesa()} color='inherit'>Tambah Desa</Button> : areaSelected === 5 ?
                            <Button variant='contained' sx={{ backgroundColor: theme.backgroundColor, color: theme.color }}  onClick={() => openAddRw()} color='inherit'>Tambah RW</Button> : areaSelected === 6 ?
                            <Button variant='contained' sx={{ backgroundColor: theme.backgroundColor, color: theme.color }}  onClick={() => openAddRt()} color='inherit'>Tambah RT</Button> : <></>
                        }
                    </Grid>
                </Grid>
            </Grid>
            {
                loadingSkeletonTable ? <Skeleton width={'100%'} height={'500px'} /> :

                <Grid item xs={12} sm={12} md={12}>
                    {
                        areaSelected === 1 ?
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nama Provinsi</TableCell>
                                            <TableCell>Aksi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            provinsiData.length !== 0 ? provinsiData.map(p => 
                                        <TableRow key={p.provinsi_id}>
                                            <TableCell>{p.nama_provinsi}</TableCell>
                                            <TableCell><Button onClick={() => gotoAreaProvinsiToKabupaten(p)} size='small' variant='contained' color='success'>Kabupaten</Button><Button size='small' variant='contained' color='warning' onClick={() => editProvinsi(p)} sx={{ margin: '5px' }}>Ubah</Button><Button onClick={() => deleteProvinsi(p)} size='small' variant='contained' color='error'>Hapus</Button></TableCell>
                                        </TableRow> 
                                            )
                                        : <></>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                component="div"
                                count={settingTable.provinsi.totalElements}
                                rowsPerPage={settingTable.provinsi.size}
                                page={settingTable.provinsi.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper> : areaSelected === 2 ?
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nama Kabupaten</TableCell>
                                            <TableCell>Aksi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            kabupatenData.length !== 0 ? kabupatenData.map(k => 
                                            <TableRow key={k.kabupaten_id}>
                                                <TableCell>{k.nama_kabupaten}</TableCell>
                                                <TableCell><Button onClick={() => gotoAreaKabupatenToKecamatan(k)} size='small' variant='contained' color='success'>Kecamatan</Button><Button size='small' variant='contained' color='warning' onClick={() => editKabupaten(k)} sx={{ margin: '5px' }}>Ubah</Button><Button onClick={() => deleteKabupaten(k)} size='small' variant='contained' color='error'>Hapus</Button></TableCell>
                                            </TableRow> 
                                            )
                                        : <></>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                component="div"
                                count={settingTable.kabupaten.totalElements}
                                rowsPerPage={settingTable.kabupaten.size}
                                page={settingTable.kabupaten.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                        : areaSelected === 3 ?
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nama Kecamatan</TableCell>
                                            <TableCell>Aksi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            kecamatanData.length !== 0 ? kecamatanData.map(k => 
                                            <TableRow key={k.kecamatan_id}>
                                                <TableCell>{k.nama_kecamatan}</TableCell>
                                                <TableCell><Button onClick={() => gotoAreaKecamatanToDesa(k)} size='small' variant='contained' color='success'>Desa</Button><Button size='small' variant='contained' color='warning' onClick={() => editKecamatan(k)} sx={{ margin: '5px' }}>Ubah</Button><Button onClick={() => deleteKecamatan(k)} size='small' variant='contained' color='error'>Hapus</Button></TableCell>
                                            </TableRow> 
                                            )
                                        : <></>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                component="div"
                                count={settingTable.kecamatan.totalElements}
                                rowsPerPage={settingTable.kecamatan.size}
                                page={settingTable.kecamatan.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                        : areaSelected === 4 ?
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nama Desa</TableCell>
                                            <TableCell>Aksi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            desaData.length !== 0 ? desaData.map(d => 
                                            <TableRow key={d.desa_id}>
                                                <TableCell>{d.nama_desa}</TableCell>
                                                <TableCell><Button onClick={() => gotoAreaDesaToRw(d)} size='small' variant='contained' color='success'>RW</Button><Button size='small' variant='contained' color='warning' onClick={() => editDesa(d)} sx={{ margin: '5px' }}>Ubah</Button><Button onClick={() => deleteDesa(d)} size='small' variant='contained' color='error'>Hapus</Button></TableCell>
                                            </TableRow> 
                                            )
                                        : <></>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                component="div"
                                count={settingTable.desa.totalElements}
                                rowsPerPage={settingTable.desa.size}
                                page={settingTable.desa.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                        : areaSelected === 5 ?
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nama RW</TableCell>
                                            <TableCell>Aksi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            rwData.length !== 0 ? rwData.map(r => 
                                            <TableRow key={r.rw_id}>
                                                <TableCell>{r.nama_rw}</TableCell>
                                                <TableCell><Button onClick={() => gotoAreaRwToRt(r)} size='small' variant='contained' color='success'>RT</Button><Button size='small' variant='contained' color='warning' onClick={() => editRw(r)} sx={{ margin: '5px' }}>Ubah</Button><Button onClick={() => deleteRw(r)} size='small' variant='contained' color='error'>Hapus</Button></TableCell>
                                            </TableRow> 
                                            )
                                        : <></>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                component="div"
                                count={settingTable.rw.totalElements}
                                rowsPerPage={settingTable.rw.size}
                                page={settingTable.rw.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                        : areaSelected === 6 ?
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nama RT</TableCell>
                                            <TableCell>Aksi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            rtData.length !== 0 ? rtData.map(r => 
                                            <TableRow key={r.rt_id}>
                                                <TableCell>{r.nama_rt}</TableCell>
                                                <TableCell><Button size='small' variant='contained' color='warning' onClick={() => editRt(r)} sx={{ margin: '5px' }}>Ubah</Button><Button onClick={() => deleteRt(r)} size='small' variant='contained' color='error'>Hapus</Button></TableCell>
                                            </TableRow> 
                                            )
                                        : <></>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                component="div"
                                count={settingTable.rt.totalElements}
                                rowsPerPage={settingTable.rt.size}
                                page={settingTable.rt.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                        : <></>
                    }
                </Grid>
            }
            <ModalArea
                openModal={openModal}
                setOpenModal={setOpenModal}
                formProvinsi={formProvinsi}
                setFormProvinsi={setFormProvinsi}
                formKabupaten={formKabupaten}
                setFormKabupaten={setFormKabupaten}
                formKecamatan={formKecamatan}
                setFormKecamatan={setFormKecamatan}
                formDesa={formDesa}
                setFormDesa={setFormDesa}
                formRw={formRw}
                setFormRw={setFormRw}
                formRt={formRt}
                setFormRt={setFormRt}
                areaSelected={areaSelected}
                titleDialog={titleDialog}
                errorForm={errorForm}
                createProvinsi={createProvinsi}
                createKabupaten={createKabupaten}
                createKecamatan={createKecamatan}
                createDesa={createDesa}
                createRw={createRw}
                createRt={createRt}
                updateProvinsi={updateProvinsi}
                updateKabupaten={updateKabupaten}
                updateKecamatan={updateKecamatan}
                updateDesa={updateDesa}
                updateRw={updateRw}
                updateRt={updateRt}
            />
            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                message={messageSnackbar}
            />
        </Grid>
    );
}

export default AdminPengelolaanArea;
