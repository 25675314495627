import * as React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import ThemeStoreProps from '../../../models/Store/ThemeStoreProps';
import { AuthStoreState, RoleStoreState, ThemeStoreState } from '../../../store';
import { Leaderboard } from '@mui/icons-material';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem, Skeleton, Toolbar, Tooltip } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import AuthServices from '../../../services/Auth/AuthServices';
import { LoginResponseField, LoginResponseProps } from '../../../models/Auth/LoginResponseProps';
import axios from 'axios';
import LoginRoleProps from '../../../models/Role/LoginRoleProps';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}
const AppBar = styled(MuiAppBar, {shouldForwardProp: (prop) => prop !== 'open'})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface AppbarLayoutProps {
    roleStore: boolean;
    openSidebar: boolean; 
    setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>
    openProfileMenu: null | HTMLElement; 
    setOpenProfileMenu: React.Dispatch<React.SetStateAction<null | HTMLElement>>; 
}

const AppbarLayout: React.FC<AppbarLayoutProps> = ({ roleStore, openSidebar, setOpenSidebar, openProfileMenu, setOpenProfileMenu}) => {
    const settings = [
        {
            path: '/profil',
            title: 'Profil'
        }, 
        {
            path: '/area-member',
            title: 'Area Member'
        }, 
        {
            path: '/logout',
            title: 'Logout'
        }
    ];
    const themeBg = useRecoilValue<ThemeStoreProps>(ThemeStoreState);
    const [modalLogout, setModalLogout] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const [authStore, setAuthStore] = useRecoilState<LoginResponseProps>(AuthStoreState);
    const [roleStoreState, setRoleStoreState] = useRecoilState<LoginRoleProps | null>(RoleStoreState);

    const logout = async () => {
        await AuthServices.logout(authStore.member_id).then().catch(err => console.error(err));
        localStorage.removeItem('user');
        localStorage.removeItem('role');
        setAuthStore(LoginResponseField);
        setRoleStoreState(null);
        axios.defaults.headers.common['Authorization'] = null;
        navigate("/login?msg=successLogout")
    }

    return (
        <AppBar sx={{ backgroundColor: themeBg.backgroundColor, color: themeBg.color }} position="fixed" open={openSidebar}>
            <Toolbar>
                {
                    roleStore ? 
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setOpenSidebar(true)}
                    edge="start"
                    sx={{ marginRight: 5, ...(openSidebar && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton> : <></>
                }
                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                    <Leaderboard /> 
                    <Typography variant="h6" noWrap component="div" sx={{ paddingLeft: '10px' }}>
                        SIPT
                    </Typography>
                </Box>

                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title={roleStoreState !== null ? 'Buka Menu Profil' : "Silahkan Pilih Role"}>
                        <IconButton onClick={(e) => setOpenProfileMenu(e.currentTarget)} sx={{ p: 0 }}>
                            {
                                authStore.avatar ? 
                                <Avatar alt={authStore.nama} src={authStore.avatar} /> : <Skeleton variant='rounded' width={20} height={20}></Skeleton>
                            }
                        </IconButton>
                    </Tooltip>
                    {
                        roleStoreState !== null ?
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={openProfileMenu}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(openProfileMenu)}
                            onClose={() => setOpenProfileMenu(null)}
                        >
                        {settings.map((setting) => (
                            <MenuItem key={setting.path} onClick={() => {
                                if(setting.path === '/logout') {
                                    setModalLogout(true);
                                } else {
                                    navigate(setting.path);
                                }
                                setOpenProfileMenu(null); 
                            }}>
                                <Typography textAlign="center" sx={{ cursor: 'pointer' }}>{setting.title}</Typography>
                            </MenuItem>
                        ))}
                        </Menu> : <></>
                    }
                </Box>
                <Dialog open={modalLogout} onClose={() => setModalLogout(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                    {"Keluar dari SIPT?"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Pastikan seluruh kegiatan anda sudah disimpan
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button variant='outlined' onClick={() => setModalLogout(false)}>Batalkan</Button>
                    <Button variant='outlined' color='error' onClick={() => {logout(); setModalLogout(false)}} autoFocus>
                        Ya, Keluar
                    </Button>
                    </DialogActions>
                </Dialog>
            </Toolbar>
        </AppBar>
    );
}

export default AppbarLayout;
