import React from 'react';
import { useRecoilValue } from 'recoil';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { ThemeStoreState } from '../../store';
import ListMemberProps, { ListMemberField } from '../../models/Member/ListMemberProps';
import AdminService from '../../services/Member/AdminService';
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { Add, Cancel, Delete, Diversity2Outlined, ExpandMore } from '@mui/icons-material';
import { ProvinsiProps } from '../../models/Area/ProvinsiProps';
import { KabupatenProps } from '../../models/Area/KabupatenProps';
import { KecamatanProps } from '../../models/Area/KecamatanProps';
import { DesaProps } from '../../models/Area/DesaProps';
import { RwProps } from '../../models/Area/RwProps';
import { RtProps } from '../../models/Area/RtProps';
import { TpsProps } from '../../models/Area/TpsProps';
import ProvinsiService from '../../services/Area/ProvinsiService';
import KabupatenService from '../../services/Area/KabupatenService';
import KecamatanService from '../../services/Area/KecamatanService';
import DesaService from '../../services/Area/DesaService';
import RwService from '../../services/Area/RwService';
import RtService from '../../services/Area/RtService';
import TpsService from '../../services/Area/TpsService';
import { DetailMemberProps, DetailMemberField } from '../../models/Member/DetailMemberProps';
import Swal from 'sweetalert2';
import DetailRelawanMember from '../../pages/Admin/DetailRelawanMember';
import { KonstituenRelawanSayaProps } from '../../models/Member/KonstituenRelawanSayaProps';
import RelawanService from '../../services/Member/RelawanService';

const PengelolaanDataRelawan = () => {
    // Check Nik 
    const [openModalRelasi, setOpenModalRelasi] = React.useState<boolean>(false);
    const [dataTempTambahRelasi, setDataTempTambahRelasi] = React.useState<ListMemberProps>(ListMemberField);
    const [formNik, setFormNik] = React.useState<string>('');
    const checkNikToRelasiRelawan = async (nik: string) => {
        setDataTempTambahRelasi(ListMemberField);
        AdminService.checkNik(nik).then(res => {
            setDataTempTambahRelasi(res.data);
        }).catch(e => {
            setMessageSnackbar(e.response.data.message ?? 'Tidak dapat mengambil data. Tidak ada Jaringan Internet'); 
            setOpenSnackbar(true);
        })
    }

    // Detail konstituen 
    const [loadingSkeletonDetail, setLoadingSkeletonDetail] = React.useState<boolean>(true);
    const [loadingSkeletonTableDetail, setLoadingSkeletonTableDetail] = React.useState<boolean>(false);
    const [settingTableDetail, setSettingTableDetail] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        provinsiId: string;
        kabupatenId: string;
        kecamatanId: string;
        desaId: string;
        rwId: string;
        rtId: string;
        tpsId: string;
        hakPilih: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'konstituenId',
        search: '',
        provinsiId: '',
        kabupatenId: '',
        kecamatanId: '',
        desaId: '',
        rwId: '',
        rtId: '',
        tpsId: '',
        hakPilih: '',
        totalElements: 0,
        totalPages: 0,
    });
    const [provinsiDataDetail, setProvinsiDataDetail] = React.useState<ProvinsiProps[]>([]);
    const [kabupatenDataDetail, setKabupatenDataDetail] = React.useState<KabupatenProps[]>([]);
    const [kecamatanDataDetail, setKecamatanDataDetail] = React.useState<KecamatanProps[]>([]);
    const [desaDataDetail, setDesaDataDetail] = React.useState<DesaProps[]>([]);
    const [rwDataDetail, setRwDataDetail] = React.useState<RwProps[]>([]);
    const [rtDataDetail, setRtDataDetail] = React.useState<RtProps[]>([]);
    const [tpsDataDetail, setTpsDataDetail] = React.useState<TpsProps[]>([]);
    const [konstituenRelawanSayaDataDetail, setKonstituenRelawanSayaDataDetail] = React.useState<KonstituenRelawanSayaProps[]>([]);
    function getProvinsiDetail() {
        if(provinsiDataDetail.length === 0) {
            setLoadingSkeletonDetail(true);
            ProvinsiService.getAllArray().then(res => setProvinsiDataDetail(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
            setLoadingSkeletonDetail(false);
        }
    }
    function getKabupatenDetail(id?: string | null) {
        setLoadingSkeletonDetail(true);
        KabupatenService.getAllArray(id == null ? settingTableDetail.provinsiId : id).then(res => setKabupatenDataDetail(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonDetail(false);
    }
    function getKecamatanDetail(id?: string | null) {
        setLoadingSkeletonDetail(true);
        KecamatanService.getAllArray(id == null ? settingTableDetail.kabupatenId : id).then(res => setKecamatanDataDetail(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonDetail(false);
    }
    function getDesaDetail(id?: string | null) {
        setLoadingSkeletonDetail(true);
        DesaService.getAllArray(id == null ? settingTableDetail.kecamatanId : id).then(res => setDesaDataDetail(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonDetail(false);
    }
    function getRwDetail(id?: string | null) {
        setLoadingSkeletonDetail(true);
        RwService.getAllArr(id == null ? settingTableDetail.desaId : id).then(res => setRwDataDetail(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonDetail(false);
    }
    function getRtDetail(id?: string | null) {
        setLoadingSkeletonDetail(true);
        RtService.getAllArr(id == null ? settingTableDetail.rwId : id).then(res => setRtDataDetail(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonDetail(false);
    }
    function getTpsDetail(rwid?: string | null, rtid?: string | null) {
        setLoadingSkeletonDetail(true);
        TpsService.getAllArr(rwid == null ? settingTableDetail.rwId : rwid, rtid == null ? settingTableDetail.rtId : rtid).then(res => setTpsDataDetail(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonDetail(false);
    }
    const handleChangePageDetail = (event: unknown, newPage: number) => {
        setLoadingSkeletonTableDetail(true);
        setSettingTableDetail({...settingTableDetail,  page: newPage});
        setLoadingSkeletonTableDetail(false);
    };
    const handleChangeRowsPerPageDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTableDetail({...settingTableDetail, page: 0, size: +event.target.value});
    };
    const getAllDataDetail = (relawanId?: string) => {
        setLoadingSkeletonTableDetail(true);
        RelawanService.findAllKonstituen(settingTableDetail.page, settingTableDetail.size, settingTableDetail.sort, settingTableDetail.search, relawanId !== null ? relawanId : relawanDataDetail.relawan.relawan_id, null, settingTableDetail.provinsiId, settingTableDetail.kabupatenId, settingTableDetail.kecamatanId, settingTableDetail.desaId, settingTableDetail.rwId, settingTableDetail.rtId, settingTableDetail.tpsId, settingTableDetail.hakPilih).then(res => {
            setKonstituenRelawanSayaDataDetail(res.data.content);
            setSettingTableDetail({...settingTableDetail, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
            setLoadingSkeletonTableDetail(false);
        }).catch(err => {
            setMessageSnackbar("Tidak Ada Jaringan");
            setOpenSnackbar(true);
            setLoadingSkeletonTableDetail(false);
        })
    }
    const addRelasiKonstituen = async (konstituenId: string, relawanId: string) => {
        await AdminService.addRelasiKonstituen(konstituenId, relawanId).then(res => {
            setKonstituenRelawanSayaDataDetail([...konstituenRelawanSayaDataDetail, {
                member_id: res.data.member_id,
                konstituen_id: res.data.konstituen_id,
                avatar: res.data.avatar,
                nama: res.data.nama,
                username: res.data.username,
                no_hp: res.data.no_hp,
                no_wa: res.data.no_wa,
                tempat_lahir: res.data.tempat_lahir,
                tanggal_lahir: res.data.tanggal_lahir,
            }]);
            setRelawanDataDetail({...relawanDataDetail, konstituen_count: relawanDataDetail.konstituen_count + 1, konstituen_list: [...relawanDataDetail.konstituen_list, {
                alamat: res.data.alamat,
                avatar: res.data.avatar,
                hak_pilih: res.data.hak_pilih,
                konstituen_id: res.data.konstituen_id,
                lattitude: res.data.lattitude,
                longitude: res.data.longitude,
                member_id: res.data.member_id,
                nama: res.data.nama,
                username: res.data.username,
            }]});
            setDataTempTambahRelasi(ListMemberField);
            setFormNik('');
            setMessageSnackbar('Relasi Konstituen berhasil ditambah'); 
            setOpenSnackbar(true);
            setOpenModalRelasi(false);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? 'Tidak dapat Menambah Relasi Konstituen'); 
            setOpenSnackbar(true);
        })
    }
    const removeRelasiKonstituen = async (konstituenId: string, relawanId: string) => {
        await AdminService.removeRelasiKonstituen(konstituenId, relawanId).then(res => {
            setKonstituenRelawanSayaDataDetail([...konstituenRelawanSayaDataDetail.filter(x => x.konstituen_id !== konstituenId)]);
            setRelawanDataDetail({...relawanDataDetail, konstituen_count: relawanDataDetail.konstituen_count - 1, konstituen_list: relawanDataDetail.konstituen_list.filter(x => x.konstituen_id !== konstituenId)});
            setMessageSnackbar('Relasi Konstituen berhasil dihapus'); 
            setOpenSnackbar(true);
        }).catch(err => {
            setMessageSnackbar('Tidak dapat Hapus Relasi Konstituen'); 
            setOpenSnackbar(true);
        });
    }
    const clearFilterDetail = () => {
        setSettingTableDetail({
            ...settingTableDetail, 
            search: '',
            provinsiId: '',
            kabupatenId: '',
            kecamatanId: '',
            desaId: '',
            rwId: '',
            rtId: '',
            tpsId: '',
            hakPilih: ''
        });
        setKabupatenDataDetail([]);
        setKecamatanDataDetail([]);
        setDesaDataDetail([]);
        setRwDataDetail([]);
        setRtDataDetail([]);
        setTpsDataDetail([]);
    }

    // Tambah 
    const [loadingSkeletonTambah, setLoadingSkeletonTambah] = React.useState<boolean>(true);
    const [loadingSkeletonTableTambah, setLoadingSkeletonTableTambah] = React.useState<boolean>(false);
    const [settingTableTambah, setSettingTableTambah] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        provinsiId: string;
        kabupatenId: string;
        kecamatanId: string;
        desaId: string;
        rwId: string;
        rtId: string;
        tpsId: string;
        hakPilih: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'member_id',
        search: '',
        provinsiId: '',
        kabupatenId: '',
        kecamatanId: '',
        desaId: '',
        rwId: '',
        rtId: '',
        tpsId: '',
        hakPilih: 'null',
        totalElements: 0,
        totalPages: 0,
    });
    const [relawanDataTambah, setRelawanDataTambah] = React.useState<ListMemberProps[]>([]);
    const [provinsiDataTambah, setProvinsiDataTambah] = React.useState<ProvinsiProps[]>([]);
    const [kabupatenDataTambah, setKabupatenDataTambah] = React.useState<KabupatenProps[]>([]);
    const [kecamatanDataTambah, setKecamatanDataTambah] = React.useState<KecamatanProps[]>([]);
    const [desaDataTambah, setDesaDataTambah] = React.useState<DesaProps[]>([]);
    const [rwDataTambah, setRwDataTambah] = React.useState<RwProps[]>([]);
    const [rtDataTambah, setRtDataTambah] = React.useState<RtProps[]>([]);
    const [tpsDataTambah, setTpsDataTambah] = React.useState<TpsProps[]>([]);

    function getProvinsiTambah() {
        if(provinsiDataTambah.length === 0) {
            setLoadingSkeletonTambah(true);
            ProvinsiService.getAllArray().then(res => setProvinsiDataTambah(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
            setLoadingSkeletonTambah(false);
        }
    }
    function getKabupatenTambah(id?: string | null) {
        setLoadingSkeletonTambah(true);
        KabupatenService.getAllArray(id == null ? settingTableTambah.provinsiId : id).then(res => setKabupatenDataTambah(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonTambah(false);
    }
    function getKecamatanTambah(id?: string | null) {
        setLoadingSkeletonTambah(true);
        KecamatanService.getAllArray(id == null ? settingTableTambah.kabupatenId : id).then(res => setKecamatanDataTambah(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonTambah(false);
    }
    function getDesaTambah(id?: string | null) {
        setLoadingSkeletonTambah(true);
        DesaService.getAllArray(id == null ? settingTableTambah.kecamatanId : id).then(res => setDesaDataTambah(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonTambah(false);
    }
    function getRwTambah(id?: string | null) {
        setLoadingSkeletonTambah(true);
        RwService.getAllArr(id == null ? settingTableTambah.desaId : id).then(res => setRwDataTambah(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonTambah(false);
    }
    function getRtTambah(id?: string | null) {
        setLoadingSkeletonTambah(true);
        RtService.getAllArr(id == null ? settingTableTambah.rwId : id).then(res => setRtDataTambah(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonTambah(false);
    }
    function getTpsTambah(rwid?: string | null, rtid?: string | null) {
        setLoadingSkeletonTambah(true);
        TpsService.getAllArr(rwid == null ? settingTableTambah.rwId : rwid, rtid == null ? settingTableTambah.rtId : rtid).then(res => setTpsDataTambah(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonTambah(false);
    }
    const handleChangePageTambah = (event: unknown, newPage: number) => {
        setLoadingSkeletonTableTambah(true);
        setSettingTableTambah({...settingTableTambah,  page: newPage});
        setLoadingSkeletonTableTambah(false);
    };
    const handleChangeRowsPerPageTambah = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTableTambah({...settingTableTambah, page: 0, size: +event.target.value});
    };
    const clearFilterTambah = () => {
        setSettingTableTambah({
            ...settingTableTambah, 
            search: '',
            provinsiId: '',
            kabupatenId: '',
            kecamatanId: '',
            desaId: '',
            rwId: '',
            rtId: '',
            tpsId: '',
            hakPilih: 'null',
        });
        setKabupatenDataTambah([]);
        setKecamatanDataTambah([]);
        setDesaDataTambah([]);
        setRwDataTambah([]);
        setRtDataTambah([]);
        setTpsDataTambah([]);
    }
    const getAllDataTambah = () => {
        setLoadingSkeletonTableTambah(true);
        AdminService.getAllMemberWithoutRoleRelawan(settingTableTambah.page, settingTableTambah.size, settingTableTambah.sort, settingTableTambah.search, settingTableTambah.provinsiId, settingTableTambah.kabupatenId, settingTableTambah.kecamatanId, settingTableTambah.desaId, settingTableTambah.rwId, settingTableTambah.rtId, settingTableTambah.tpsId, settingTableTambah.hakPilih).then(res => {
            setRelawanDataTambah(res.data.content);
            setSettingTableTambah({...settingTableTambah, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
            setLoadingSkeletonTableTambah(false);
        }).catch(err => {
            setMessageSnackbar("Tidak Ada Jaringan");
            setOpenSnackbar(true);
            setLoadingSkeletonTableTambah(false);
        })
    }

    // Data Utama 
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const [loadingSkeletonTable, setLoadingSkeletonTable] = React.useState<boolean>(false);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [titleDialog, setTitleDialog] = React.useState<string>('');
    const [settingTable, setSettingTable] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        provinsiId: string;
        kabupatenId: string;
        kecamatanId: string;
        desaId: string;
        rwId: string;
        rtId: string;
        tpsId: string;
        hakPilih: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'memberId',
        search: '',
        provinsiId: '',
        kabupatenId: '',
        kecamatanId: '',
        desaId: '',
        rwId: '',
        rtId: '',
        tpsId: '',
        hakPilih: 'null',
        totalElements: 0,
        totalPages: 0,
    });
    const [relawanData, setRelawanData] = React.useState<ListMemberProps[]>([]);
    const [relawanDataDetail, setRelawanDataDetail] = React.useState<DetailMemberProps>(DetailMemberField);
    const [provinsiData, setProvinsiData] = React.useState<ProvinsiProps[]>([]);
    const [kabupatenData, setKabupatenData] = React.useState<KabupatenProps[]>([]);
    const [kecamatanData, setKecamatanData] = React.useState<KecamatanProps[]>([]);
    const [desaData, setDesaData] = React.useState<DesaProps[]>([]);
    const [rwData, setRwData] = React.useState<RwProps[]>([]);
    const [rtData, setRtData] = React.useState<RtProps[]>([]);
    const [tpsData, setTpsData] = React.useState<TpsProps[]>([]);
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState);
    
    const getAllData = () => {
        setLoadingSkeletonTable(true);
        AdminService.getAllRelawan(settingTable.page, settingTable.size, settingTable.sort, settingTable.search, settingTable.provinsiId, settingTable.kabupatenId, settingTable.kecamatanId, settingTable.desaId, settingTable.rwId, settingTable.rtId, settingTable.tpsId, settingTable.hakPilih).then(res => {
            setRelawanData(res.data.content);
            setSettingTable({...settingTable, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
            setLoadingSkeletonTable(false);
        }).catch(err => {
            setMessageSnackbar("Tidak Ada Jaringan");
            setOpenSnackbar(true);
            setLoadingSkeletonTable(false);
        })
    }
    function getProvinsi() {
        if(provinsiData.length === 0) {
            setLoadingSkeleton(true);
            ProvinsiService.getAllArray().then(res => {setProvinsiData(res.data); setProvinsiDataTambah(res.data); setProvinsiDataDetail(res.data)}).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
            setLoadingSkeleton(false);
        }
    }
    function getKabupaten(id?: string | null) {
        setLoadingSkeleton(true);
        KabupatenService.getAllArray(id == null ? settingTable.provinsiId : id).then(res => setKabupatenData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getKecamatan(id?: string | null) {
        setLoadingSkeleton(true);
        KecamatanService.getAllArray(id == null ? settingTable.kabupatenId : id).then(res => setKecamatanData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getDesa(id?: string | null) {
        setLoadingSkeleton(true);
        DesaService.getAllArray(id == null ? settingTable.kecamatanId : id).then(res => setDesaData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getRw(id?: string | null) {
        setLoadingSkeleton(true);
        RwService.getAllArr(id == null ? settingTable.desaId : id).then(res => setRwData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getRt(id?: string | null) {
        setLoadingSkeleton(true);
        RtService.getAllArr(id == null ? settingTable.rwId : id).then(res => setRtData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getTps(rwid?: string | null, rtid?: string | null) {
        setLoadingSkeleton(true);
        TpsService.getAllArr(rwid == null ? settingTable.rwId : rwid, rtid == null ? settingTable.rtId : rtid).then(res => setTpsData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    const openDetail = async (member: ListMemberProps) => {
        await AdminService.getDetailMember(member.member_id).then(res=> {
            setRelawanDataDetail(res.data);
            getAllDataDetail(res.data.relawan.relawan_id);
            setTitleDialog('Detail ' + member.nama);
            setOpenModal(true);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Terjadi kesalahan")
            setOpenSnackbar(true);
        })
    }
    const openAddPerson = () => {
        getAllDataTambah();
        getProvinsiTambah();
        setTitleDialog('Tambah Konstituen');
        setOpenModal(true);
    }
    const createPerson = async (member: ListMemberProps) => {
        await AdminService.addMemberRelawan(member.member_id).then(res => {
            setRelawanData([...relawanData, member]);
            setMessageSnackbar("Berhasil Menambah Data Relawan");
            setOpenSnackbar(true);
            setOpenModal(false);
        }).catch(err => {
            setMessageSnackbar("Gagal Menambah Data Relawan");
            setOpenSnackbar(true);
        });
    }
    const deletePerson = async (member: ListMemberProps) => {
        Swal.fire({
            title: 'Hapus Relawan',
            text: 'Apakah anda ingin menghapus Relawan dengan Nama ' + member.nama + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                AdminService.removeMemberRelawan(member.member_id).then(res => {
                    setRelawanData(relawanData.filter(x => x.member_id !== member.member_id));
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Relawan dengan Nama "+ member.nama +" sudah dihapus.",
                        icon: "success"
                    });
                    setMessageSnackbar('Relawan telah dihapus'); 
                    setOpenSnackbar(true);
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: err.response.data.message ?? "Relawan "+ member.nama +" gagal dihapus.",
                        icon: "error"
                    });
                    setMessageSnackbar('Tidak dapat Hapus Relawan'); 
                    setOpenSnackbar(true);
                })
            }
        });
    }
    const handleChangePage = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable,  page: newPage});
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTable({...settingTable, page: 0, size: +event.target.value});
    };

    const clearFilter = () => {
        setSettingTable({
            ...settingTable, 
            search: '',
            provinsiId: '',
            kabupatenId: '',
            kecamatanId: '',
            desaId: '',
            rwId: '',
            rtId: '',
            tpsId: '',
            hakPilih: 'null',
        });
        setKabupatenData([]);
        setKecamatanData([]);
        setDesaData([]);
        setRwData([]);
        setRtData([]);
        setTpsData([]);
    }

    React.useEffect(() => {
        if (openModal) {
            if(titleDialog.split(" ").includes("Tambah")) {
                getAllDataTambah();
                setLoadingSkeletonTambah(false);
            } else {
                getAllDataDetail(relawanDataDetail.relawan.relawan_id);
                setLoadingSkeletonDetail(false);
            }
        } else {
            getAllData()
            getProvinsi();
            setLoadingSkeleton(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingTable.page, settingTable.size, settingTable.sort, settingTable.search, settingTable.provinsiId, settingTable.kabupatenId, settingTable.kecamatanId, settingTable.desaId, settingTable.rwId, settingTable.rtId, settingTable.tpsId, settingTable.hakPilih, settingTableTambah.page, settingTableTambah.size, settingTableTambah.sort, settingTableTambah.search, settingTableTambah.provinsiId, settingTableTambah.kabupatenId, settingTableTambah.kecamatanId, settingTableTambah.desaId, settingTableTambah.rwId, settingTableTambah.rtId, settingTableTambah.tpsId, settingTableTambah.hakPilih, settingTableDetail.page, settingTableDetail.search, settingTableDetail.size, settingTableDetail.sort, settingTableDetail.provinsiId, settingTableDetail.kabupatenId, settingTableDetail.kecamatanId, settingTableDetail.desaId, settingTableDetail.rwId, settingTableDetail.rtId, settingTableDetail.tpsId, settingTableDetail.hakPilih]);

    return loadingSkeleton ? (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                        <Skeleton width={'100%'} height={'50px'} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Skeleton width={'100%'} height={'50px'} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Skeleton width={'100%'} height={'500px'} />
            </Grid>
        </Grid>
    ) : (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                    <Grid item xs={12} sm={12}>
                        <Accordion sx={{ marginY: '20px' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                Filter
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2} sx={{ paddingX: '20px' }}>
                                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="provinsi-select">Provinsi</InputLabel>
                                            <Select
                                                labelId="provinsi-select"
                                                id="provinsi_id"
                                                value={settingTable.provinsiId}
                                                disabled={provinsiData.length === 0}
                                                label="Provinsi"
                                                onChange={(e) => {setSettingTable({...settingTable, provinsiId: e.target.value, kecamatanId: '', kabupatenId: '', desaId: '', rwId: '', rtId: '', tpsId: ''}); getKabupaten(e.target.value);}}
                                            >
                                                {
                                                    provinsiData.map(p => <MenuItem key={p.provinsi_id} value={p.provinsi_id}>{p.nama_provinsi}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="kabupaten-select">Kabupaten</InputLabel>
                                            <Select
                                                labelId="kabupaten-select"
                                                id="kabupaten_id"
                                                value={settingTable.kabupatenId}
                                                disabled={kabupatenData.length === 0}
                                                label="Kabupaten"
                                                onChange={(e) => {setSettingTable({...settingTable, kabupatenId: e.target.value, kecamatanId: '', desaId: '', rwId: '', rtId: '', tpsId: ''}); getKecamatan(e.target.value)}}
                                            >
                                                {
                                                    kabupatenData.map(p => <MenuItem key={p.kabupaten_id} value={p.kabupaten_id}>{p.nama_kabupaten}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="kecamatan-select">Kecamatan</InputLabel>
                                            <Select
                                                labelId="kecamatan-select"
                                                id="kecamatan_id"
                                                value={settingTable.kecamatanId}
                                                disabled={kecamatanData.length === 0}
                                                label="Kecamatan"
                                                onChange={(e) => {setSettingTable({...settingTable, kecamatanId: e.target.value, desaId: '', rwId: '', rtId: '', tpsId: ''}); getDesa(e.target.value)}}
                                            >
                                                {
                                                    kecamatanData.map(p => <MenuItem key={p.kecamatan_id} value={p.kecamatan_id}>{p.nama_kecamatan}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="desa-select">Desa</InputLabel>
                                            <Select
                                                labelId="desa-select"
                                                id="desa_id"
                                                value={settingTable.desaId}
                                                disabled={desaData.length === 0}
                                                label="Desa"
                                                onChange={(e) => {setSettingTable({...settingTable, desaId: e.target.value, rwId: '', rtId: '', tpsId: ''}); getRw(e.target.value);}}
                                            >
                                                {
                                                    desaData.map(p => <MenuItem key={p.desa_id} value={p.desa_id}>{p.nama_desa}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="rw-select">RW</InputLabel>
                                            <Select
                                                labelId="rw-select"
                                                id="rw_id"
                                                value={settingTable.rwId}
                                                disabled={rwData.length === 0}
                                                label="RW"
                                                onChange={(e) => {setSettingTable({...settingTable, rwId: e.target.value, rtId: '', tpsId: ''}); getRt(e.target.value); getTps(e.target.value, null)}}
                                            >
                                                {
                                                    rwData.map(p => <MenuItem key={p.rw_id} value={p.rw_id}>{p.nama_rw}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="rt-select">RT</InputLabel>
                                            <Select
                                                labelId="rt-select"
                                                id="rt_id"
                                                value={settingTable.rtId}
                                                disabled={rtData.length === 0}
                                                label="RT"
                                                onChange={(e) => {setSettingTable({...settingTable, rtId: e.target.value, tpsId: ''}); getTps(settingTable.rwId, e.target.value)}}
                                            >
                                                {
                                                    rtData.map(p => <MenuItem key={p.rt_id} value={p.rt_id}>{p.nama_rt}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="tps-select">TPS</InputLabel>
                                            <Select
                                                labelId="tps-select"
                                                id="tps_id"
                                                value={settingTable.tpsId}
                                                disabled={tpsData.length === 0}
                                                label="TPS"
                                                onChange={(e) => setSettingTable({...settingTable, tpsId: e.target.value})}
                                            >
                                                {
                                                    tpsData.map(p => <MenuItem key={p.tps_id} value={p.tps_id}>{p.nama_tps}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={3}>
                                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                            <InputLabel id="hak_pilih-select">Hak Pilih</InputLabel>
                                            <Select
                                                labelId="hak_pilih-select"
                                                id="hak_pilih_id"
                                                value={settingTable.hakPilih}
                                                label="Hak Pilih Member"
                                                onChange={(e) => setSettingTable({...settingTable, hakPilih: e.target.value})}
                                            >
                                                <MenuItem value={'null'}>{'Semua Hak Pilih'}</MenuItem>
                                                <MenuItem value={'true'}>{'Punya Hak Milik'}</MenuItem>
                                                <MenuItem value={'false'}>{'Tidak ada Hak Pilih'}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {
                                        settingTable.provinsiId !== '' ? 
                                    <Grid item xs={12} sm={12} md={4} lg={3} justifyContent={'center'} alignItems={'center'}>
                                        <Button color='error' variant='contained' title='Bersihkan Filter' startIcon={<Cancel />} onClick={() => clearFilter()}>Clear Filter</Button>
                                    </Grid> : <></>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <Typography variant='h5' sx={{ justifyContent: 'center', alignItem: 'center' }}>Data Relawan</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button variant='contained' sx={{ backgroundColor: theme.backgroundColor, color: theme.color }}  onClick={() => openAddPerson()} startIcon={<Add />} color='inherit'>Tambah</Button>
                    </Grid>
                </Grid>
            </Grid>
            {
                loadingSkeletonTable ? <Skeleton width={'100%'} height={'500px'} /> :
                <Grid item xs={12} sm={12} md={12}>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 540 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nama</TableCell>
                                            <TableCell>Jenis Kelamin</TableCell>
                                            <TableCell>TTL</TableCell>
                                            <TableCell>Username</TableCell>
                                            <TableCell>Aksi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                        relawanData.length !== 0 ? relawanData.map(p => 
                                            <TableRow key={p.member_id}>
                                                <TableCell>{p.nama}</TableCell>
                                                <TableCell>{p.jenis_kelamin}</TableCell>
                                                <TableCell>{p.tempat_lahir + ', ' + p.tanggal_lahir}</TableCell>
                                                <TableCell>{p.username}</TableCell>
                                                <TableCell><Button size='small' sx={{ backgroundColor: theme.backgroundColor, color: theme.color, marginLeft: '5px' }} startIcon={<Diversity2Outlined />} variant='contained' color='inherit' onClick={() => openDetail(p)}>Detail</Button><Button sx={{ marginLeft: '5px' }} onClick={() => deletePerson(p)} size='small' variant='contained' startIcon={<Delete />} color='error'>Hapus</Button></TableCell>
                                        </TableRow> 
                                            )
                                        : <TableRow>
                                            <TableCell colSpan={6}>
                                                <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                            </TableCell>
                                        </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                component="div"
                                count={settingTable.totalElements}
                                rowsPerPage={settingTable.size}
                                page={settingTable.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                </Grid>
            }
            <DetailRelawanMember 
                openModal={openModal}
                setOpenModal={setOpenModal}
                titleDialog={titleDialog}
                messageSnackbar={messageSnackbar}
                theme={theme}
                relawanDataDetail={relawanDataDetail}
                createPerson={createPerson}
                loadingSkeletonTableTambah={loadingSkeletonTableTambah}
                loadingSkeletonTambah={loadingSkeletonTambah}
                setLoadingSkeletonTambah={setLoadingSkeletonTambah}
                getProvinsiTambah={getProvinsiTambah}
                getKabupatenTambah={getKabupatenTambah}
                getKecamatanTambah={getKecamatanTambah}
                getDesaTambah={getDesaTambah}
                setSettingTableTambah={setSettingTableTambah}
                getRwTambah={getRwTambah}
                getRtTambah={getRtTambah}
                getTpsTambah={getTpsTambah}
                settingTableTambah={settingTableTambah}
                relawanDataTambah={relawanDataTambah}
                provinsiDataTambah={provinsiDataTambah}
                kabupatenDataTambah={kabupatenDataTambah}
                kecamatanDataTambah={kecamatanDataTambah}
                desaDataTambah={desaDataTambah}
                rwDataTambah={rwDataTambah}
                rtDataTambah={rtDataTambah}
                tpsDataTambah={tpsDataTambah}
                handleChangePageTambah={handleChangePageTambah}
                handleChangeRowsPerPageTambah={handleChangeRowsPerPageTambah}
                clearFilterTambah={clearFilterTambah}
                loadingSkeletonDetail={loadingSkeletonDetail}
                setLoadingSkeletonDetail={setLoadingSkeletonDetail}
                loadingSkeletonTableDetail={loadingSkeletonTableDetail}
                setLoadingSkeletonTableDetail={setLoadingSkeletonTableDetail}
                settingTableDetail={settingTableDetail}
                setSettingTableDetail={setSettingTableDetail}
                konstituenRelawanSayaDataDetail={konstituenRelawanSayaDataDetail}
                handleChangePageDetail={handleChangePageDetail}
                handleChangeRowsPerPageDetail={handleChangeRowsPerPageDetail}
                addRelasiKonstituen={addRelasiKonstituen}
                removeRelasiKonstituen={removeRelasiKonstituen}
                openModalRelasi={openModalRelasi}
                setOpenModalRelasi={setOpenModalRelasi}
                checkNikToRelasiRelawan={checkNikToRelasiRelawan}
                dataTempTambahRelasi={dataTempTambahRelasi}
                formNik={formNik}
                setFormNik={setFormNik}
                clearFilterDetail={clearFilterDetail}
                getProvinsiDetail={getProvinsiDetail}
                getKabupatenDetail={getKabupatenDetail}
                getKecamatanDetail={getKecamatanDetail}
                getDesaDetail={getDesaDetail}
                getRwDetail={getRwDetail}
                getRtDetail={getRtDetail}
                getTpsDetail={getTpsDetail}
                provinsiDataDetail={provinsiDataDetail}
                kabupatenDataDetail={kabupatenDataDetail}
                kecamatanDataDetail={kecamatanDataDetail}
                desaDataDetail={desaDataDetail}
                rwDataDetail={rwDataDetail}
                rtDataDetail={rtDataDetail}
                tpsDataDetail={tpsDataDetail}
            />
            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                message={messageSnackbar}
            />
        </Grid>
    );
}

export default PengelolaanDataRelawan;
