export interface KabupatenProps {
    kabupaten_id: string;
    provinsi_id: string;
    nama_kabupaten: string;
}

export const KabupatenField: KabupatenProps = {
    kabupaten_id: '',
    provinsi_id: '',
    nama_kabupaten: ''
}