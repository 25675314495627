import React from 'react';

const PerhitunganPemantauanHasilPerhitungan = () => {
    return (
        <div>
            PerhitunganPemantauanHasilPerhitungan
        </div>
    );
}

export default PerhitunganPemantauanHasilPerhitungan;
