export interface LoginRequestProps {
    username: string;
    password: string;
}
export const LoginRequestField: LoginRequestProps = {
    username: '',
    password: ''
}

export interface ErrorLoginRequestProps {
    username: string| null;
    password: string| null;
}
export const ErrorLoginRequestField: ErrorLoginRequestProps = {
    username: null,
    password: null
}