import { AccountBox, AlternateEmail, AssuredWorkload, CalendarMonth, Female, Home, Male, Phone, ReduceCapacity, Transgender, VerifiedUser, WhatsApp } from '@mui/icons-material';
import { Box, Chip, Container, Grid, List, ListItemButton, ListItemIcon, ListItemText, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { InfoWindow, Map, Marker } from '@vis.gl/react-google-maps';
import React from 'react';
import { DetailMemberField, DetailMemberProps } from '../../models/Member/DetailMemberProps';
import AdminService from '../../services/Member/AdminService';
import { useRecoilValue } from 'recoil';
import { LoginResponseProps } from '../../models/Auth/LoginResponseProps';
import { AuthStoreState } from '../../store';
import Image from "../../asset/images/cat.jpg";

const Konstituen = () => {
    const [selectedMarker, setSelectedMarker] = React.useState<any>(null);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [konstituenDataDetail, setKonstituenDataDetail] = React.useState<DetailMemberProps>(DetailMemberField);
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const authStore = useRecoilValue<LoginResponseProps>(AuthStoreState);

    const openDetail = async () => {
        await AdminService.getDetailMember(authStore.member_id).then(res=> {
            setKonstituenDataDetail(res.data);
            setLoadingSkeleton(false);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Terjadi kesalahan")
            setOpenSnackbar(true);
            setLoadingSkeleton(false);
        })
    }

    React.useEffect(() => {
        openDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loadingSkeleton ? (
        <Container>
            <Skeleton animation={'wave'} width={'100%'} height={'500px'}></Skeleton>
        </Container>
    ) : (
        <Container>
            <Grid container spacing={3} sx={{ marginY: '20px' }}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='h6'>Profil</Typography>
                            <Box sx={{ width: '100%' }}>
                                {
                                    konstituenDataDetail !== null ?
                                    <img src={konstituenDataDetail?.member.avatar !== null ? konstituenDataDetail?.member.avatar : Image} alt={konstituenDataDetail?.ktp.nama} width={'100%'} /> :
                                    <img src={Image} alt={'default'} width={'100%'} />
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='h6'>Informasi Konstituen</Typography>
                            <List component="nav" aria-label="main mailbox folders">
                                <ListItemButton>
                                    <ListItemIcon><AccountBox /></ListItemIcon>
                                    <ListItemText primary={konstituenDataDetail?.ktp.nama ?? 'Belum Ada Nama'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon>{ konstituenDataDetail?.ktp.jenis_kelamin === null ? <Transgender /> : konstituenDataDetail?.ktp.jenis_kelamin === 'PRIA' ? <Male /> : <Female /> }</ListItemIcon>
                                    <ListItemText primary={konstituenDataDetail?.ktp.jenis_kelamin ?? 'Belum Ada Jenis Kelamin'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><CalendarMonth /></ListItemIcon>
                                    <ListItemText primary={konstituenDataDetail?.ktp.tempat_lahir + ', ' + konstituenDataDetail?.ktp.tanggal_lahir ?? 'Belum Ada Kode Referensi'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><AlternateEmail /></ListItemIcon>
                                    <ListItemText primary={konstituenDataDetail?.member.email ?? 'Belum Ada Email'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><Phone /></ListItemIcon>
                                    <ListItemText primary={konstituenDataDetail?.member.no_hp ?? 'Belum Ada Nomor HP'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><WhatsApp /></ListItemIcon>
                                    <ListItemText primary={konstituenDataDetail?.member.no_wa ?? 'Belum Ada Nomor WhatsApp'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><VerifiedUser /></ListItemIcon>
                                    <ListItemText primary={konstituenDataDetail?.username ?? 'Belum Ada Username'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><AssuredWorkload /></ListItemIcon>
                                    <ListItemText primary={konstituenDataDetail?.member.hak_pilih ? 'Ada Hak Pilih di TPS' : 'Tidak ada Hak Pilih'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><Home /></ListItemIcon>
                                    <ListItemText primary={konstituenDataDetail?.ktp.alamat ?? 'Belum Ada Alamat'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><ReduceCapacity /></ListItemIcon>
                                    <ListItemText primary={konstituenDataDetail?.referensi.kode_referensi ?? 'Tidak Ada Referensi'} />
                                </ListItemButton>
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='h6'>Informasi Lokasi Rumah & TPS Konstituen</Typography>
                            <Map
                                defaultZoom={10}
                                style={{ width: '100%', height: '420px' }}
                                defaultCenter={ { lat: process.env.REACT_APP_MAPS_LATTITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LATTITUDE) : -4.55858490, lng: process.env.REACT_APP_MAPS_LONGITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LONGITUDE) : 105.40680790 } }
                            >
                                {
                                    konstituenDataDetail.area.length > 0 ? konstituenDataDetail.area.map(a => <Marker position={{lat: parseFloat(a.lattitude), lng: parseFloat(a.longitude)}} icon={{
                                    url: 'http://maps.google.com/mapfiles/kml/pal3/icon56.png',
                                    scaledSize: new google.maps.Size(32, 32)
                                    }} key={a.area_member_id} onClick={() => setSelectedMarker({name: "Rumah", lat: a.lattitude, lng: a.longitude, alamat:a.nama_desa + ', ' + a.nama_kecamatan + ', ' + a.nama_kabupaten + ', ' + a.nama_provinsi})} />) : <></>
                                }
                                {
                                    konstituenDataDetail.tps.nama_tps !== null ? <Marker position={{lat: parseFloat(konstituenDataDetail.tps.lattitude), lng: parseFloat(konstituenDataDetail.tps.longitude)}} icon={{
                                    url: 'http://maps.google.com/mapfiles/kml/pal3/icon21.png',
                                    scaledSize: new google.maps.Size(32, 32)
                                    }} onClick={() => setSelectedMarker({name: konstituenDataDetail.tps.nama_tps.split(" ").includes('TPS') ? konstituenDataDetail.tps.nama_tps : "TPS " + konstituenDataDetail.tps.nama_tps, lat: konstituenDataDetail.tps.lattitude, lng: konstituenDataDetail.tps.longitude, alamat: konstituenDataDetail.tps.alamat})} /> : <></>
                                }
                                {selectedMarker && (
                                    <InfoWindow
                                        position={{ lat: parseFloat(selectedMarker.lat), lng: parseFloat(selectedMarker.lng) }}
                                        onCloseClick={() => setSelectedMarker(null)}
                                    >
                                        <div>
                                            <h4>{selectedMarker.name}</h4>
                                            <p>Alamat: {selectedMarker.alamat}</p>
                                        </div>
                                    </InfoWindow>
                                )}
                            </Map>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='h6'>Role</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nama Role</TableCell>
                                        <TableCell>Keterangan</TableCell>
                                        <TableCell>Aktif</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        konstituenDataDetail.role.length > 0 ? konstituenDataDetail.role.map(k => <TableRow key={k.role_id}>
                                            <TableCell>{k.nama_role}</TableCell>
                                            <TableCell>{k.keterangan}</TableCell>
                                            <TableCell>{k.aktif ? <Chip label={'Aktif'} variant="outlined" color="primary" /> : <Chip label={'Tidak'} variant="outlined" color="error" />}</TableCell>
                                        </TableRow>) : <TableRow><TableCell colSpan={8}><Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography></TableCell></TableRow>
                                    }
                                </TableBody>
                            </Table>
                            <Typography variant='h6' sx={{ marginTop: '12px' }}>Informasi Relawan</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Id Relawan</TableCell>
                                        <TableCell>Nama Relawan</TableCell>
                                        <TableCell>Aksi</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        konstituenDataDetail.relawan_list.length > 0 ? konstituenDataDetail.relawan_list.map(k => <TableRow key={k.relasi_id}>
                                            <TableCell>{k.relasi_id}</TableCell>
                                            <TableCell>{k.nama_relasi}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>) : <TableRow><TableCell colSpan={8} align='center'><Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Relawan</Typography></TableCell></TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginBottom: '50px' }}>
                <Grid item xs={12} sm={12}>
                    <Typography variant='h6'>Informasi Lokasi TPS</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Provinsi</TableCell>
                                <TableCell>Kabupaten</TableCell>
                                <TableCell>Kecamatan</TableCell>
                                <TableCell>Desa</TableCell>
                                <TableCell>RW</TableCell>
                                <TableCell>RT</TableCell>
                                <TableCell>TPS</TableCell>
                                <TableCell>Alamat</TableCell>
                            </TableRow>
                        </TableHead>
                            <TableBody>
                                {
                                    konstituenDataDetail.area.filter(a => a.alamat_utama).length > 0 ? konstituenDataDetail.area.filter(a => a.alamat_utama).map(k => 
                                    <TableRow key={k.area_member_id}>
                                        <TableCell>{k.nama_provinsi}</TableCell>
                                        <TableCell>{k.nama_kabupaten}</TableCell>
                                        <TableCell>{k.nama_kecamatan}</TableCell>
                                        <TableCell>{k.nama_desa}</TableCell>
                                        <TableCell>{k.nama_rw}</TableCell>
                                        <TableCell>{k.nama_rt}</TableCell>
                                        <TableCell>{k.nama_tps}</TableCell>
                                        <TableCell>{konstituenDataDetail.tps.alamat}</TableCell>
                                    </TableRow>) : <TableRow><TableCell colSpan={8}><Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography></TableCell></TableRow>
                                }
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant='h6'>Informasi Lokasi Rumah</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Provinsi</TableCell>
                                <TableCell>Kabupaten</TableCell>
                                <TableCell>Kecamatan</TableCell>
                                <TableCell>Desa</TableCell>
                                <TableCell>RW</TableCell>
                                <TableCell>RT</TableCell>
                                <TableCell>TPS</TableCell>
                                <TableCell>Utama</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            konstituenDataDetail.area.length > 0 ? konstituenDataDetail.area.map(k => <TableRow key={k.area_member_id}>
                                <TableCell>{k.nama_provinsi}</TableCell>
                                <TableCell>{k.nama_kabupaten}</TableCell>
                                <TableCell>{k.nama_kecamatan}</TableCell>
                                <TableCell>{k.nama_desa}</TableCell>
                                <TableCell>{k.nama_rw}</TableCell>
                                <TableCell>{k.nama_rt}</TableCell>
                                <TableCell>{k.nama_tps}</TableCell>
                                <TableCell>{k.alamat_utama ? <Chip label={'Utama'} variant="outlined" color="primary" /> : <Chip label={'Bukan'} variant="outlined" color="error" />}</TableCell>
                            </TableRow>) : <TableRow><TableCell colSpan={8}><Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography></TableCell></TableRow>
                        }
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Konstituen;
