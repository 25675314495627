import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import React from 'react';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BlogPostProps from '../models/Blog/BlogPostProps';
import { useNavigate } from 'react-router-dom';

const CardPortal: React.FC<BlogPostProps> = ({
    blog_post_id,
    member_id,
    nama_member,
    title,
    slug,
    summary,
    content,
    published_at,
    created_at,
    updated_at,
    deleted_at,
    published,
    visited,
    meta,
    category,
    tag
}) => {
    const navigate = useNavigate();
    return (
        <Card sx={{ width: '100%' }}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">{title ?? 'Judul'}</Typography>
                <Typography variant="body2" color="text.secondary">{summary ?? 'Summary'}</Typography>
            </CardContent>
            <CardActions sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button onClick={() => navigate('/berita/' + blog_post_id)} endIcon={<AutoStoriesIcon />} variant='contained' size="small">Baca Selengkapnya </Button>
            </CardActions>
        </Card>
    );
}

export default CardPortal;
