export interface JawabanProps {
    jawaban_id: string;
    pertanyaan_id: string;
    member_id: string;
    role_id: string;
    nama: string;
    nama_role: string;
    avatar: string;
    title: string;
    subtitle: string;
    star: number;
    created_at: string;
    updated_at: string;
}

export const JawabanField: JawabanProps = {
    jawaban_id: '',
    pertanyaan_id: '',
    member_id: '',
    role_id: '',
    nama: '',
    nama_role: '',
    avatar: '',
    title: '',
    subtitle: '',
    star: 0,
    created_at: '',
    updated_at: '',
}

export interface ErrorJawabanProps {
    jawaban_id: string | null;
    pertanyaan_id: string | null;
    member_id: string | null;
    role_id: string | null;
    nama: string | null;
    nama_role: string | null;
    avatar: string | null;
    title: string | null;
    subtitle: string | null;
    star: number | null;
    created_at: string | null;
    updated_at: string | null;
}

export const ErrorJawabanField: ErrorJawabanProps = {
    jawaban_id: null,
    pertanyaan_id: null,
    member_id: null,
    role_id: null,
    nama: null,
    nama_role: null,
    avatar: null,
    title: null,
    subtitle: null,
    star: null,
    created_at: null,
    updated_at: null,
}