import { AreaMemberField, AreaMemberProps } from "../Area/AreaMemberProps";
import KtpProps, { KtpField } from "../Member/KtpProps";
import MemberProps, { MemberField } from "../Member/MemberProps";
import { TpsSaksiResponseDTO, TpsSaksiResponseDTOField } from "./TpsSaksiResponseDTO";

export interface BerandaSaksiResponseDTO {
    ktp: KtpProps;
    member: MemberProps;
    username: string;
    alamat_rumah: AreaMemberProps;
    alamat_tps: TpsSaksiResponseDTO;
}

export const BerandaSaksiResponseDTOField: BerandaSaksiResponseDTO={
    ktp: KtpField,
    member: MemberField,
    username: '',
    alamat_rumah: AreaMemberField,
    alamat_tps: TpsSaksiResponseDTOField
}