import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ProvinsiProps } from '../../models/Area/ProvinsiProps';
import { KabupatenProps } from '../../models/Area/KabupatenProps';
import { KecamatanProps } from '../../models/Area/KecamatanProps';
import { DesaProps } from '../../models/Area/DesaProps';
import { RwProps } from '../../models/Area/RwProps';
import { RtProps } from '../../models/Area/RtProps';
import { errorFormAreaProps } from '../../models/Area/ErrorFormArea';
import { Cancel, SaveAs } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

interface ModalAreaProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    formProvinsi: ProvinsiProps;
    setFormProvinsi: React.Dispatch<React.SetStateAction<ProvinsiProps>>;
    formKabupaten: KabupatenProps;
    setFormKabupaten: React.Dispatch<React.SetStateAction<KabupatenProps>>;
    formKecamatan: KecamatanProps;
    setFormKecamatan: React.Dispatch<React.SetStateAction<KecamatanProps>>;
    formDesa: DesaProps;
    setFormDesa: React.Dispatch<React.SetStateAction<DesaProps>>;
    formRw: RwProps;
    setFormRw: React.Dispatch<React.SetStateAction<RwProps>>;
    formRt: RtProps;
    setFormRt: React.Dispatch<React.SetStateAction<RtProps>>;
    areaSelected: number;
    titleDialog: string;
    errorForm: errorFormAreaProps;
    createProvinsi: any;
    createKabupaten: any;
    createKecamatan: any;
    createDesa: any;
    createRw: any;
    createRt: any;
    updateProvinsi: any;
    updateKabupaten: any;
    updateKecamatan: any;
    updateDesa: any;
    updateRw: any;
    updateRt: any;
}

const ModalArea: React.FC<ModalAreaProps> = ({openModal, setOpenModal, formProvinsi, setFormProvinsi, formKabupaten, setFormKabupaten, formKecamatan, setFormKecamatan, formDesa, setFormDesa, formRw, setFormRw, formRt, setFormRt, areaSelected, titleDialog, errorForm, createProvinsi, createKabupaten, createKecamatan, createDesa, createRw, createRt, updateProvinsi, updateKabupaten, updateKecamatan, updateDesa, updateRw, updateRt}) => {
    return (
        <BootstrapDialog
            onClose={() => setOpenModal(false)}
            aria-labelledby="customized-dialog-title"
            open={openModal}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {titleDialog}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpenModal(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid container spacing={2} >
                    {
                        areaSelected === 1 ?
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="nama_provinsi"
                                label="Nama Provinsi"
                                name="nama_provinsi"
                                autoFocus
                                onChange={(e) => setFormProvinsi({...formProvinsi, nama_provinsi: e.target.value})}
                                value={formProvinsi.nama_provinsi}
                            />
                            {(errorForm.nama_provinsi != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.nama_provinsi}</Typography> : <></>}
                        </Grid> : areaSelected === 2 ?
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="nama_kabupaten"
                                label="Nama Kabupaten"
                                name="nama_kabupaten"
                                autoFocus
                                onChange={(e) => setFormKabupaten({...formKabupaten, nama_kabupaten: e.target.value})}
                                value={formKabupaten.nama_kabupaten}
                            />
                            {(errorForm.nama_kabupaten != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.nama_kabupaten}</Typography> : <></>}
                        </Grid> : areaSelected === 3 ?
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="nama_kecamatan"
                                label="Nama Kecamatan"
                                name="nama_kecamatan"
                                autoFocus
                                onChange={(e) => setFormKecamatan({...formKecamatan, nama_kecamatan: e.target.value})}
                                value={formKecamatan.nama_kecamatan}
                            />
                            {(errorForm.nama_kecamatan != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.nama_kecamatan}</Typography> : <></>}
                        </Grid> : areaSelected === 4 ?
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="nama_desa"
                                label="Nama Desa"
                                name="nama_desa"
                                autoFocus
                                onChange={(e) => setFormDesa({...formDesa, nama_desa: e.target.value})}
                                value={formDesa.nama_desa}
                            />
                            {(errorForm.nama_desa != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.nama_desa}</Typography> : <></>}
                        </Grid> : areaSelected === 5 ?
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="nama_rw"
                                label="Nama RW"
                                name="nama_rw"
                                autoFocus
                                onChange={(e) => setFormRw({...formRw, nama_rw: e.target.value})}
                                value={formRw.nama_rw}
                            />
                            {(errorForm.nama_rw != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.nama_rw}</Typography> : <></>}
                        </Grid> : areaSelected === 6 ?
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="nama_rt"
                                label="Nama RT"
                                name="nama_rt"
                                autoFocus
                                onChange={(e) => setFormRt({...formRt, nama_rt: e.target.value})}
                                value={formRt.nama_rt}
                            />
                            {(errorForm.nama_rt != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.nama_rt}</Typography> : <></>}
                        </Grid> : <Typography>Terjadi Kesalahan pada Sistem</Typography>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant='contained' color='error' startIcon={<Cancel />} onClick={() => setOpenModal(false)}>
                    Batalkan
                </Button>
                <Button autoFocus variant='contained' color='success' startIcon={<SaveAs />} onClick={() => {
                    if (areaSelected === 1) {
                        if(titleDialog.split(" ").includes("Tambah")) {
                            createProvinsi();
                        } else {
                            updateProvinsi();
                        }
                    }
                    if (areaSelected === 2) {
                        if(titleDialog.split(" ").includes("Tambah")) {
                            createKabupaten();
                        } else {
                            updateKabupaten();
                        }
                    }
                    if (areaSelected === 3) {
                        if(titleDialog.split(" ").includes("Tambah")) {
                            createKecamatan();
                        } else {
                            updateKecamatan();
                        }
                    }
                    if (areaSelected === 4) {
                        if(titleDialog.split(" ").includes("Tambah")) {
                            createDesa();
                        } else {
                            updateDesa();
                        }
                    }
                    if (areaSelected === 5) {
                        if(titleDialog.split(" ").includes("Tambah")) {
                            createRw();
                        } else {
                            updateRw();
                        }
                    }
                    if (areaSelected === 6) {
                        if(titleDialog.split(" ").includes("Tambah")) {
                            createRt();
                        } else {
                            updateRt();
                        }
                    }
                }}>
                    Simpan
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

export default ModalArea;
