import React from 'react';

const EksekutifPemantauanHasilPemilu = () => {
    return (
        <div>
            EksekutifPemantauanHasilPemilu
        </div>
    );
}

export default EksekutifPemantauanHasilPemilu;
