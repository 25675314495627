export interface KordinatAreaMemberProps {
    kordinat_id: string;
    area_member_id: string;
    longitude: string;
    lattitude: string;
}

export const KordinatAreaMemberField: KordinatAreaMemberProps = {
    kordinat_id: '',
    area_member_id: '',
    longitude: '',
    lattitude: '',
}