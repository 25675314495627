import { AccountCircle, Close, Delete, FileUpload, SaveAs } from '@mui/icons-material';
import { Alert, AppBar, Box, Button, Container, Dialog, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Slide, styled, TextField, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { PartaiProps } from '../../models/Institution/PartaiProps';
import { PeriodeProps } from '../../models/Forums/PeriodeProps';
import { KategoriProps } from '../../models/Forums/KategoriProps';
import { JenisPemilihanProps } from '../../models/Forums/JenisPemilihanProps';
import { JenisTingkatPemilihanProps } from '../../models/Forums/JenisTingkatPemilihanProps';
import { CalonLegislatifProps, ErrorCalonLegislatifProps } from '../../models/QuickCount/Senate/CalonLegislatifProps';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

interface DialogPengelolaanCalonLegislatifProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    theme: ThemeStoreProps;
    titleDialog: string;
    errors: ErrorCalonLegislatifProps;
    setErrors: React.Dispatch<React.SetStateAction<ErrorCalonLegislatifProps>>;
    calonLegislatifDataDetail: CalonLegislatifProps;
    setCalonLegislatifDataDetail: React.Dispatch<React.SetStateAction<CalonLegislatifProps>>;
    createCalonLegislatif: any;
    updateCalonLegislatif: any;
    image: File | null;
    setImage: React.Dispatch<React.SetStateAction<File | null>>;
    imagePreview: string | null;
    setImagePreview: React.Dispatch<React.SetStateAction<string | null>>;
    uploadImage: any;
    ImageDefault: any;
    partaiData: PartaiProps[];
    periodeData: PeriodeProps[];
    kategoriData: KategoriProps[];
    jenisPemilihanData: JenisPemilihanProps[];
    jenisTingkatPemilihanData: JenisTingkatPemilihanProps[];
    getPeriode: any;
    getKategori: any;
    getJenisPemilihan: any;
    getJenisTingkatPemilihan: any;
}

const DialogPengelolaanCalonLegislatif: React.FC<DialogPengelolaanCalonLegislatifProps> = ({
    openModal,
    setOpenModal,
    theme,
    titleDialog,
    errors,
    setErrors,
    calonLegislatifDataDetail,
    setCalonLegislatifDataDetail,
    createCalonLegislatif,
    updateCalonLegislatif,
    image,
    setImage,
    imagePreview,
    setImagePreview,
    uploadImage,
    ImageDefault,
    partaiData,
    periodeData,
    kategoriData,
    jenisPemilihanData,
    jenisTingkatPemilihanData,
    getPeriode,
    getKategori,
    getJenisPemilihan,
    getJenisTingkatPemilihan
}) => {
    
    return (
        <Dialog
            fullScreen
            open={openModal}
            onClose={() => setOpenModal(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setOpenModal(false)}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {titleDialog}
                    </Typography>
                    <Button autoFocus startIcon={<Close />} color="inherit" onClick={() => setOpenModal(false)}>
                        Tutup
                    </Button>
                </Toolbar>
            </AppBar>
                <Container>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                        <Grid item xs={12} sm={12} md={4}>
                            <Grid container spacing={1}>
                                <Box sx={{ width: '100%', marginBottom: '20px', height: '300px' }}>
                                {
                                    imagePreview !== null ? 
                                    <img src={imagePreview} alt={'preview'} width={'100%'} height={'100%'} />
                                    : (calonLegislatifDataDetail.gambar !== '' ?
                                    <img src={calonLegislatifDataDetail.gambar} alt={calonLegislatifDataDetail.nama_calon} width={'100%'} height={'100%'} /> :
                                    <img src={ImageDefault} alt={calonLegislatifDataDetail.nama_calon} width={'100%'} height={'100%'} />)
                                }
                                </Box>
                            </Grid>
                            <Grid container spacing={1}>
                            {
                                imagePreview !== null ?
                                <Grid item xs={12}>
                                    {
                                        image == null ? <Alert severity="info">File Perlu dibawah 1 MB.</Alert> : image.size / 1000 > 1000 ? 
                                        <Alert severity="error">File anda terlalu besar! {image == null ? 0 : image.size / 1000} Kb.</Alert> :
                                        <Alert severity="info">File yang anda Upload berada di {image == null ? 0 : image.size / 1000} Kb.</Alert>
                                    }
                                    <Button variant='contained' sx={{ marginTop: '5px' }} fullWidth onClick={() => {
                                        setImagePreview(null);
                                        setImage(null);
                                    }} startIcon={<Delete />} color='error'>Hapus</Button>
                                </Grid> : 
                                <Grid item xs={12}>
                                    <Alert severity="info">File Perlu dibawah 1 MB.</Alert>
                                    <Button component="label" sx={{ marginTop: '5px' }} tabIndex={-1} variant='contained' fullWidth startIcon={<FileUpload />} color='primary'>Upload<VisuallyHiddenInput type="file" onChange={uploadImage} /></Button>
                                </Grid>
                            }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <Box>
                                <Typography variant='h5' sx={{ marginBottom: '5px' }}>Data Calon Pemimpin</Typography>
                                <FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                                    <InputLabel id="periode-select">Periode</InputLabel>
                                    <Select
                                        labelId="periode-select"
                                        id="periode_id"
                                        value={calonLegislatifDataDetail.periode_id}
                                        disabled={periodeData.length === 0}
                                        label="Periode"
                                        onChange={(e) => {setCalonLegislatifDataDetail({...calonLegislatifDataDetail, periode_id: e.target.value, kategori_id: '', jenis_pemilihan_id: '', jenis_tingkat_pemilihan_id: ''}); getKategori(e.target.value);}}
                                    >
                                        {
                                            periodeData.map(p => <MenuItem key={p.periode_id} value={p.periode_id}>{p.tahun}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                                    <InputLabel id="kategori-select">Kategori</InputLabel>
                                    <Select
                                        labelId="kategori-select"
                                        id="kategori_id"
                                        value={calonLegislatifDataDetail.kategori_id}
                                        disabled={kategoriData.length === 0}
                                        label="Kategori"
                                        onChange={(e) => {setCalonLegislatifDataDetail({...calonLegislatifDataDetail, kategori_id: e.target.value, jenis_pemilihan_id: '', jenis_tingkat_pemilihan_id: ''}); getJenisPemilihan(e.target.value);}}
                                    >
                                        {
                                            kategoriData.map(p => <MenuItem key={p.kategori_id} value={p.kategori_id}>{p.nama_kategori}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                                    <InputLabel id="jenis_pemilihan-select">Jenis Pemilihan</InputLabel>
                                    <Select
                                        labelId="jenis_pemilihan-select"
                                        id="jenis_pemilihan_id"
                                        value={calonLegislatifDataDetail.jenis_pemilihan_id}
                                        disabled={jenisPemilihanData.length === 0}
                                        label="Jenis Pemilihan"
                                        onChange={(e) => {setCalonLegislatifDataDetail({...calonLegislatifDataDetail, jenis_pemilihan_id: e.target.value, jenis_tingkat_pemilihan_id: ''}); getJenisTingkatPemilihan(e.target.value);}}
                                    >
                                        {
                                            jenisPemilihanData.map(p => <MenuItem key={p.jenis_pemilihan_id} value={p.jenis_pemilihan_id}>{p.nama_pemilihan}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                                    <InputLabel id="jenis_tingkat_pemilihan-select">Jenis Tingkat Pemilihan</InputLabel>
                                    <Select
                                        labelId="jenis_tingkat_pemilihan-select"
                                        id="jenis_tingkat_pemilihan_id"
                                        value={calonLegislatifDataDetail.jenis_tingkat_pemilihan_id}
                                        disabled={jenisTingkatPemilihanData.length === 0}
                                        label="Jenis Tingkat Pemilihan"
                                        onChange={(e) => {setCalonLegislatifDataDetail({...calonLegislatifDataDetail, jenis_tingkat_pemilihan_id: e.target.value});}}
                                    >
                                        {
                                            jenisTingkatPemilihanData.map(p => <MenuItem key={p.jenis_tingkat_pemilihan_id} value={p.jenis_tingkat_pemilihan_id}>{p.nama_tingkat}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                                    <InputLabel id="partai-select">Partai</InputLabel>
                                    <Select
                                        labelId="partai-select"
                                        id="partai_id"
                                        value={calonLegislatifDataDetail.partai_id}
                                        disabled={partaiData.length === 0}
                                        label="Partai"
                                        onChange={(e) => {setCalonLegislatifDataDetail({...calonLegislatifDataDetail, partai_id: e.target.value});}}
                                    >
                                        {
                                            partaiData.map(p => <MenuItem key={p.partai_id} value={p.partai_id}>{p.nama_partai}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Nama Calon Legislatif"
                                    id="Nama_Calon_Legislatif"
                                    fullWidth
                                    placeholder='Nama Calon Legislatif'
                                    value={calonLegislatifDataDetail.nama_calon}
                                    onChange={(e) => setCalonLegislatifDataDetail({...calonLegislatifDataDetail, nama_calon: e.target.value})}
                                    sx={{ m: 1, marginBottom: '10px' }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><AccountCircle /></InputAdornment>,
                                    }}
                                />
                                {(errors.nama_calon != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.nama_calon}</Typography> : <></>}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                            <Button startIcon={<SaveAs />} variant='contained' color='success' title='simpan' onClick={() => titleDialog.split(' ').includes('Tambah') ? createCalonLegislatif() : updateCalonLegislatif()}>Simpan</Button>
                        </Grid>
                    </Grid>
                </Container>
        </Dialog>
    );
}

export default DialogPengelolaanCalonLegislatif;
