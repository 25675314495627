import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import {TpsProps} from '../../models/Area/TpsProps';
import { formTpsKordinatProps } from '../../models/Area/ErrorFormTps';
import { KordinatProps } from '../../models/Area/KordinatProps';

class TpsServices {
    async getAll(page: number, size: number, sort: string, search: string, rwId: string, rtId: string | null): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/area/tps?page=${page}&size=${size}&sort=${sort}&search=${search}&rwId=${rwId}${rtId != null ? '&rtId=' + rtId : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllArr(rwId: string, rtId?: string): Promise<AxiosResponse<TpsProps[]>> {
        return axios.get(`/api/v1/area/tps/arr?rwId=${rwId}${rtId !== undefined ? '&rtId='+rtId : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(tpsId: string): Promise<AxiosResponse<TpsProps>> {
        return axios.get(`/api/v1/area/tps/${tpsId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: TpsProps): Promise<AxiosResponse<TpsProps>> {
        return axios.post(`/api/v1/area/tps`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(tpsId: string, data : TpsProps): Promise<AxiosResponse<TpsProps>> {
        return axios.put(`/api/v1/area/tps/${tpsId}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async delete(tpsId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/area/tps/${tpsId}`, {headers: {'Content-Type': 'application/json',}});
    }

    async getKordinatByTpsId(tpsId: string): Promise<AxiosResponse<KordinatProps>> {
        return axios.get(`/api/v1/area/kordinat/tps/${tpsId}`,{headers: {'Content-Type': 'application/json',}});
    }

    async createWithKordinat(data: formTpsKordinatProps): Promise<AxiosResponse<formTpsKordinatProps>> {
        return axios.post(`/api/v1/area/tps/kordinat`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async putWithKordinat(tpsId: string, data : formTpsKordinatProps): Promise<AxiosResponse<formTpsKordinatProps>> {
        return axios.put(`/api/v1/area/tps/kordinat/${tpsId}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TpsServices();