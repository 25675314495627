import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Container, Divider, FormControl, Grid, InputLabel, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Skeleton, Tab, Typography } from '@mui/material';
import React from 'react';
import PelaporanPemungutanSuaraService from '../../services/Pelaporan/PelaporanPemungutanSuaraService';
import { useRecoilValue } from 'recoil';
import { LoginResponseProps } from '../../models/Auth/LoginResponseProps';
import { AuthStoreState } from '../../store';
import { PeriodeProps } from '../../models/Forums/PeriodeProps';
import PeriodeService from '../../services/Forum/PeriodeService';
import { PelaporanPemungutanSuaraFieldResponseDTO, PelaporanPemungutanSuaraResponseDTO } from '../../models/Saksi/PelaporanPemungutanSuaraResponseDTO';
import { InfoWindow, Map, Marker } from '@vis.gl/react-google-maps';
import { Badge, ExpandMore, Place } from '@mui/icons-material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const SaksiPelaporanPemungutanSuara = () => {
    const profil = useRecoilValue<LoginResponseProps>(AuthStoreState);
    const [selectedMarker, setSelectedMarker] = React.useState<any>(null);
    const [accordionValue, setAccordionValue] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(true);
    const [periodeData, setPeriodeData] = React.useState<PeriodeProps[]>([]);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [expandedAccordion, setExpandedAccordion] = React.useState<string | false>(false);
    const [pelaporan, setPelaporan] = React.useState<PelaporanPemungutanSuaraResponseDTO>(PelaporanPemungutanSuaraFieldResponseDTO);
    const [settingTable, setSettingTable] = React.useState<{
        periodeId: string;
    }>({
        periodeId: ''
    });

    const handleChangeAccordion =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        let temp = panel.split("||");
        setAccordionValue(panel.replace("||", "|"))
        setExpandedAccordion(isExpanded ? temp[0] : false);
    };


    const getPeriode = async() => {
        await PeriodeService.getAllArr().then(res => {
            setPeriodeData(res.data);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? 'Tidak ada Jaringan');
            setOpenSnackbar(true);
        })
    }

    const getPelaporanPemungutanSuara = async () => {
        await PelaporanPemungutanSuaraService.getAll(undefined, profil.member_id, undefined).then(res => setPelaporan(res.data)).catch(err => {}).finally(() => {setLoading(false);});
    }

    React.useEffect(() => {
        getPeriode();
        getPelaporanPemungutanSuara();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return loading ? (<Container>
        <Skeleton animation="wave" width={'100%'} height={'576px'} variant='rounded'></Skeleton>
    </Container>) : (<React.Fragment>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <Typography variant='h4'>Informasi TPS</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
                <List component={'nav'} sx={{ width: '100%' }}>
                    <ListItemButton>
                        <ListItemIcon>
                            <Avatar>
                                <Badge />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText primary={pelaporan.tps.nama_tps} secondary="Nama TPS" />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <Avatar>
                                <Place />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText primary={pelaporan.tps.alamat} secondary="Alamat" />
                    </ListItemButton>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={8}>
                <Map
                    defaultZoom={10}
                    style={{ width: '100%', height: '420px' }}
                    defaultCenter={ { lat: pelaporan.tps.lattitude !== null ? parseFloat(pelaporan.tps.lattitude) : process.env.REACT_APP_MAPS_LATTITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LATTITUDE) : -4.55858490, lng: pelaporan.tps.longitude !== null ? parseFloat(pelaporan.tps.longitude) : process.env.REACT_APP_MAPS_LONGITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LONGITUDE) : 105.40680790 } }
                >
                    {
                        <Marker position={{lat: parseFloat(pelaporan.tps.lattitude), lng: parseFloat(pelaporan.tps.longitude)}} icon={{
                        url: 'http://maps.google.com/mapfiles/kml/pal3/icon21.png',
                        scaledSize: new google.maps.Size(32, 32)
                        }} onClick={() => setSelectedMarker({name: pelaporan.tps.nama_tps.split(" ").includes('TPS') ? pelaporan.tps.nama_tps : "TPS " + pelaporan.tps.nama_tps, lat: pelaporan.tps.lattitude, lng: pelaporan.tps.longitude, alamat: pelaporan.tps.alamat})} />
                    }
                    {selectedMarker && (
                        <InfoWindow
                            headerContent={'Informasi TPS'}
                            position={{ lat: parseFloat(selectedMarker.lat), lng: parseFloat(selectedMarker.lng) }}
                            onCloseClick={() => setSelectedMarker(null)}
                        >
                            <div>
                                <h4>{selectedMarker.name}</h4>
                                <p>Alamat: {selectedMarker.alamat}</p>
                            </div>
                        </InfoWindow>
                    )}
                </Map>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <FormControl fullWidth sx={{ m: 1, marginBottom: '10px' }}>
                    <InputLabel id="periode-select">Periode</InputLabel>
                    <Select
                        labelId="periode-select"
                        id="periode_id"
                        value={settingTable.periodeId}
                        disabled={periodeData.length === 0}
                        label="Periode"
                        onChange={(e) => setSettingTable({...settingTable, periodeId: e.target.value})}
                    >
                        {
                            periodeData.map(p => <MenuItem key={p.periode_id} value={p.periode_id}>{p.tahun}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                {
                    pelaporan.periode.map(p => <Grid key={p.periode_id} sx={{ marginBottom: '20px' }} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5' fontSize={'32px'}>{p.tahun}</Typography>
                            <Divider />
                            {
                                p.kategori.map(k => 
                                <Accordion expanded={expandedAccordion === p.tahun + '|'+k.kategori_id} onChange={handleChangeAccordion(p.tahun + '|'+k.kategori_id + "||" + (k.jenis_pemilihan.length === 0 ? "" : k.jenis_pemilihan[0].jenis_pemilihan_id))} key={k.kategori_id}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls={`${p.tahun}-content`}
                                        id={`${p.tahun}-header`}
                                    >
                                        {k.nama_kategori}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {k.deksripsi}
                                        <Divider />
                                        {
                                            k.jenis_pemilihan.length === 0 ? <Typography variant='h5' sx={{ marginY: '5px' }} fontSize={'20px'} textAlign={'center'}>Jenis Pemilihan Umum Belum di atur Admin</Typography> : 
                                            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                <TabContext value={accordionValue}>
                                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                        <TabList centered onChange={(event: React.SyntheticEvent, newValue: string) => setAccordionValue(newValue)} aria-label="Kategori">
                                                            {
                                                                k.jenis_pemilihan.map(jp => <Tab label={jp.nama_pemilihan} value={p.tahun+'|'+k.kategori_id+"|"+jp.jenis_pemilihan_id} key={jp.jenis_pemilihan_id} />)
                                                            }
                                                        </TabList>
                                                    </Box>
                                                    {
                                                        k.jenis_pemilihan.map(jp => <TabPanel value={p.tahun + "|"+ k.kategori_id + "|"+jp.jenis_pemilihan_id} key={jp.jenis_pemilihan_id}>
                                                            {jp.deskripsi}
                                                        </TabPanel>)
                                                    }
                                                </TabContext>
                                            </Box>
                                        }
                                    </AccordionDetails>
                                </Accordion>)
                            }
                        </Grid>
                    </Grid>)
                }
            </Grid>
        </Grid>
    </React.Fragment>);
}

export default SaksiPelaporanPemungutanSuara;
