import { Breadcrumbs, Typography } from '@mui/material';
import { Link as LinkDom } from 'react-router-dom';
import BreadcrumbModelProps from '../models/Component/BreadcrumbModelProps';
import { grey } from '@mui/material/colors';

interface BreadcrumbComponentProps {
    menuItem: BreadcrumbModelProps[]
}

const Breadcrumb: React.FC<BreadcrumbComponentProps> = ({menuItem}) => {
    return (
        <Breadcrumbs>
            {
                menuItem.map((b, index) => menuItem.length - 1 !== index ? <LinkDom to={b.path} key={b.path} style={{ color: grey[800] }} color='inherit'>{b.title}</LinkDom> : <Typography key={b.path} color="text.primary">{b.title}</Typography>)
            }
        </Breadcrumbs>
    );
}

export default Breadcrumb;
