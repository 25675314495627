import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import {RtProps} from '../../models/Area/RtProps';

class RtServices {
    async getAll(page: number, size: number, sort: string, search: string, rwId: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/area/rt?page=${page}&size=${size}&sort=${sort}&search=${search}&rwId=${rwId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllArr(rwId: string): Promise<AxiosResponse<RtProps[]>> {
        return axios.get(`/api/v1/area/rt/arr?rwId=${rwId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(rtId: string): Promise<AxiosResponse<RtProps>> {
        return axios.get(`/api/v1/area/rt/${rtId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: RtProps): Promise<AxiosResponse<RtProps>> {
        return axios.post(`/api/v1/area/rt`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(rtId: string, data : RtProps): Promise<AxiosResponse<RtProps>> {
        return axios.put(`/api/v1/area/rt/${rtId}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async delete(rtId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/area/rt/${rtId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RtServices();