export interface AreaMemberProps {
    area_member_id: string;
    member_id: string;
    alamat_utama: boolean;
    provinsi_id: string;
    nama_provinsi: string;
    kabupaten_id: string;
    nama_kabupaten: string;
    kecamatan_id: string;
    nama_kecamatan: string;
    desa_id: string;
    nama_desa: string;
    rw_id: string;
    nama_rw: string;
    rt_id: string;
    nama_rt: string;
    tps_id : string;
    nama_tps: string;
    longitude: string;
    lattitude: string;
}

export const AreaMemberField: AreaMemberProps = {
    member_id: '',
    area_member_id: '',
    alamat_utama: false,
    provinsi_id: '',
    nama_provinsi: '',
    kabupaten_id: '',
    nama_kabupaten: '',
    kecamatan_id: '',
    nama_kecamatan: '',
    desa_id: '',
    nama_desa: '',
    rw_id: '',
    nama_rw: '',
    rt_id: '',
    nama_rt: '',
    tps_id : '',
    nama_tps: '',
    longitude: '',
    lattitude: '',
}

export interface ErrorAreaMemberProps {
    area_member_id: string;
    member_id: string;
    alamat_utama: string;
    provinsi_id: string;
    nama_provinsi: string;
    kabupaten_id: string;
    nama_kabupaten: string;
    kecamatan_id: string;
    nama_kecamatan: string;
    desa_id: string;
    nama_desa: string;
    rw_id: string;
    nama_rw: string;
    rt_id: string;
    nama_rt: string;
    tps_id : string;
    nama_tps: string;
    longitude: string;
    lattitude: string;
}

export const ErrorAreaMemberField: ErrorAreaMemberProps = {
    area_member_id: '',
    member_id: '',
    alamat_utama: '',
    provinsi_id: '',
    nama_provinsi: '',
    kabupaten_id: '',
    nama_kabupaten: '',
    kecamatan_id: '',
    nama_kecamatan: '',
    desa_id: '',
    nama_desa: '',
    rw_id: '',
    nama_rw: '',
    rt_id: '',
    nama_rt: '',
    tps_id : '',
    nama_tps: '',
    longitude: '',
    lattitude: '',
}