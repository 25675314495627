import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { Chart } from 'react-google-charts';

const Eksekutif = () => {
    const calonPemimpin = [
        {
            id: 1, 
            cagub: 'Hj. Eva Dwiana, S.E',
            cawagub: '-',
            image: 'https://upload.wikimedia.org/wikipedia/commons/8/82/Eva_dwiyana_mayor.png',
            total_suara: 130534,
            persentase: 45.7
        },
        {
            id: 2, 
            cagub: 'Dr. dr. Hj. Reihana, M. Kes',
            cawagub: '-',
            image: 'https://dinkes.lampungprov.go.id/wp-content/uploads/2021/06/6-7-1024x683.jpg',
            total_suara: 133744,
            persentase: 54.3
        },
    ];

    const kecamatan = [
        "Teluk Betung Barat",
        "Telukbetung Timur",
        "Teluk Betung Selatan",
        "Bumi Waras",
        "Panjang",
        "Tanjung Karang Timur",
        "Kedamaian",
        "Teluk Betung Utara",
        "Tanjung Karang Pusat",
        "Enggal",
        "Tanjung Karang Barat",
        "Kemiling",
        "Langkapura",
        "Kedaton",
        "Rajabasa",
        "Tanjung Senang",
        "Labuhan Ratu",
        "Sukarame",
        "Sukabumi",
        "Way Halim",
    ]
    
    const [dataChartArea, setDataChartArea] = React.useState<any>();
    const [dataChartDonut, setDataChartDonut] = React.useState<any>();
    const [dataChartGeo, setDataChartGeo] = React.useState<any>();

    function createDataChartArea () {
        let temp: any = [];
        let tempHeader = ['Kecamatan'];
        calonPemimpin.forEach(cp => {
            tempHeader.push(cp.cagub);
        });

        kecamatan.forEach(kec => {
            let kcp: number[] = [];
            calonPemimpin.forEach(cp => {
                kcp.push(Math.random() * 300);
            })
            temp.push([kec, ...kcp]);
        })

        setDataChartArea([tempHeader, ...temp]);
    }

    function createDataChartDonut () {
        let temp: any = [['Paslon', 'Perolehan Suara']];

        calonPemimpin.forEach(cp => {
            temp.push([cp.cagub, cp.total_suara]);
        });

        setDataChartDonut(temp);
    }

    function createDataChartGeo () {
        let temp: any = [['Kecamatan', 'Suara']];

        kecamatan.forEach(kec => {
            temp.push([kec, 200]);
        });

        setDataChartGeo(temp);
    }

    React.useEffect(() => {createDataChartArea(); createDataChartDonut(); createDataChartGeo();}, []);
    
    const optionsChartArea = {
        title: 'Perolehan Suara Per Kecamatan',
        vAxis: { title: 'Kecamatan', titleTextStyle: { color: '#333' } },
        hAxis: { minValue: 0 },
        chartArea: { width: '70%', height: '70%' },
    };
    const optionsChartDonut = {
        title: 'Perolehan Suara Kota Bandar Lampung',
        pieHole: 0.3,
        is3D: false,
    };
    const optionsChartGeo = {
        region: 'ID',
        displayMode: 'markers',
        resolution: 'provinces',
        colorAxis: { colors: ['#e7711c', '#4374e0'] },
        backgroundColor: '#81d4fa',
        datalessRegionColor: '#f8bbd0',
        defaultColor: '#f5f5f5',
        // mapsApiKey: 'AIzaSyA7MVdGLHgDIID6WfC-qz-caOyFROek_-E'
    };
    
    return (
        <Container>
            <Grid container spacing={2} sx={{ marginBottom: '5px' }}>
                <Grid item xs={12}>
                    <Typography variant='h5'>Calon Walikota Kota Bandar Lampung</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {
                    calonPemimpin.map(cp => 
                        <Grid key={cp.id} item xs={12} sm={6} lg={3}>
                            <Box sx={{ width: '100%', height: '140px', marginBottom: '2px' , display: 'flex', justifyContent: 'center' }}>
                                <img style={{ width: '120px', height: '140px' }} src={cp.image} alt={cp.cagub} />
                            </Box>
                            <Box>
                                <Typography variant='h6' textAlign={'center'} fontSize={'20px'} fontWeight={'bold'}>{cp.cagub}</Typography>
                                <Typography variant='h6' textAlign={'center'} fontSize={'20px'} fontWeight={'semibold'}>{cp.cawagub}</Typography>
                                <Typography variant='h6' textAlign={'center'} fontSize={'20px'} fontWeight={'semibold'}>{Intl.NumberFormat().format(cp.total_suara)} Suara ({cp.persentase}%)</Typography>
                            </Box>
                        </Grid>
                    )
                }
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Chart
                        chartType="GeoChart"
                        width="100%"
                        height="500px"
                        data={dataChartGeo}
                        options={optionsChartGeo}
                    />
                    <Chart
                        chartType="BarChart"
                        width="100%"
                        height="500px"
                        data={dataChartArea}
                        options={optionsChartArea}
                    />
                    <Chart
                        chartType="PieChart"
                        data={dataChartDonut}
                        options={optionsChartDonut}
                        width={'100%'}
                        height={'500px'}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default Eksekutif;
