import {  Box, Button, Grid, Paper, Skeleton, Typography } from '@mui/material';
import React from 'react';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { RoleStoreState, ThemeStoreState } from '../../store';
import { useRecoilValue } from 'recoil';
import ImageHome from "../../asset/images/cat.jpg";
import LoginRoleProps from '../../models/Role/LoginRoleProps';
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const [skeleton, setSkeleton] = React.useState<boolean>(true);
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState);
    const role = useRecoilValue<LoginRoleProps>(RoleStoreState);
    const navigate = useNavigate();


    React.useEffect(() => {
        setTimeout(() => {setSkeleton(false)}, 500);
    }, []);

    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
    }

    return !skeleton ? (<>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper elevation={2} sx={{backgroundColor: theme.backgroundColor, color: theme.color, height: '350px'}}>
                    <img src={ImageHome} style={{width: '100%', height: '100%'}} alt={'home'} />
                </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h5' sx={{ paddingTop: '10px' }}>Menu</Typography>
            </Grid>
            <Grid item xs={12}>
                {
                    role.layanan.map(l => l.aktif ? (
                    <Grid container key={l.layanan_id} spacing={3} sx={{ paddingY: '10px' }}>
                        <Grid item xs={12}><Typography variant='h6' textAlign={'center'}>{capitalizeFirstLetter(l.nama_layanan.replaceAll("_", " "))}</Typography></Grid>
                        {
                            l.fitur.map(f => f.aktif ? (
                                <Grid item xs={12} md={6} lg={3} key={f.fitur_id}>
                                    <Paper sx={{ backgroundColor: theme.backgroundColor, color: theme.color, paddingY: '10px',  }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4}>
                                                <Box sx={{ marginX: '10px' }}>
                                                    <img src={f.icon} alt={f.nama_fitur} width={'100%'} />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography sx={{ paddingBottom: '10px' }}>{capitalizeFirstLetter(f.nama_fitur.replaceAll("_", " ").toLowerCase())}</Typography>
                                                <Button variant='outlined' onClick={() => navigate(l.url + f.route_fitur)} size='small'>Buka Fitur</Button>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ) : (<></>))
                        }
                    </Grid>
                    ) : (<></>))
                }
            </Grid>
        </Grid>
    </>
    ) : (<>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Skeleton width={'100%'} height={'350px'} />
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Skeleton variant='text' animation="wave" width={'30%'} height={'50px'} />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1} justifyContent={'center'}>
                    <Grid item xs={6}>
                        <Skeleton variant='text' animation="wave" width={'100%'} height={'50px'} />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={3}>
                        <Skeleton variant='rectangular' animation="wave" width={'100%'} height={'50px'} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Skeleton variant='rectangular' animation="wave" width={'100%'} height={'50px'} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Skeleton variant='rectangular' animation="wave" width={'100%'} height={'50px'} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Skeleton variant='rectangular' animation="wave" width={'100%'} height={'50px'} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid></>
    );
}

export default Index;
