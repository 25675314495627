import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import {KabupatenProps} from '../../models/Area/KabupatenProps';

class KabupatenServices {
    async getAll(page: number, size: number, sort: string, search: string, provinsiId: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/area/kabupaten?page=${page}&size=${size}&sort=${sort}&search=${search}&provinsiId=${provinsiId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAllArray(provinsiId: string): Promise<AxiosResponse<KabupatenProps[]>> {
        return axios.get(`/api/v1/area/kabupaten/arr?provinsiId=${provinsiId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(kabupatenId: string): Promise<AxiosResponse<KabupatenProps>> {
        return axios.get(`/api/v1/area/kabupaten/${kabupatenId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: KabupatenProps): Promise<AxiosResponse<KabupatenProps>> {
        return axios.post(`/api/v1/area/kabupaten`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(kabupatenId: string, data : KabupatenProps): Promise<AxiosResponse<KabupatenProps>> {
        return axios.put(`/api/v1/area/kabupaten/${kabupatenId}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async delete(kabupatenId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/area/kabupaten/${kabupatenId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new KabupatenServices();