import React from 'react';

const Perhitungan = () => {
    return (
        <div>
            Perhitungan
        </div>
    );
}

export default Perhitungan;
