import { Close, SaveAs } from '@mui/icons-material';
import { AppBar, Button, Container, Dialog, FormControl, Grid, IconButton, Slide, TextField, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ErrorPeriodeProps, PeriodeProps } from '../../models/Forums/PeriodeProps';
import dayjs from 'dayjs';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogPengelolaanPeriodeProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    theme: ThemeStoreProps;
    titleDialog: string;
    errors: ErrorPeriodeProps;
    setErrors: React.Dispatch<React.SetStateAction<ErrorPeriodeProps>>;
    periodeDataDetail: PeriodeProps;
    setPeriodeDataDetail: React.Dispatch<React.SetStateAction<PeriodeProps>>;
    createPeriode: any;
    updatePeriode: any;
}

const DialogPengelolaanPeriode: React.FC<DialogPengelolaanPeriodeProps> = ({
    openModal, 
    setOpenModal,
    theme, 
    titleDialog,
    errors,
    setErrors,
    periodeDataDetail,
    setPeriodeDataDetail,
    createPeriode,
    updatePeriode
}) => {
    const today = dayjs();
    return (
        <Dialog
            fullScreen
            open={openModal}
            onClose={() => setOpenModal(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setOpenModal(false)}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {titleDialog}
                    </Typography>
                    <Button autoFocus startIcon={<Close />} color="inherit" onClick={() => setOpenModal(false)}>
                        Tutup
                    </Button>
                </Toolbar>
            </AppBar>
            <Container>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker 
                                    label={'Tahun'} 
                                    views={['year']} 
                                    name='tahun'
                                    onChange={(e) => {
                                        setPeriodeDataDetail({...periodeDataDetail, tahun: parseInt(e?.format('YYYY') ?? today.format('YYYY'))});
                                        console.log({...periodeDataDetail, tahun: parseInt(e?.format('YYYY') ?? today.format('YYYY'))})
                                    }}
                                    value={periodeDataDetail.tahun !== 0 ? dayjs().year(periodeDataDetail.tahun) : today}
                                />
                            </LocalizationProvider>
                            {(errors.tahun != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.tahun}</Typography> : <></>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            sx={{ marginTop: '15px' }}
                            fullWidth
                            id="deskripsi"
                            label="Deskripsi"
                            name="deskripsi"
                            multiline
                            rows={2}
                            onChange={(e) => setPeriodeDataDetail({...periodeDataDetail, deskripsi: e.target.value})}
                            value={periodeDataDetail.deskripsi}
                        />
                        {(errors.deskripsi != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.deskripsi}</Typography> : <></>}
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                        <Button startIcon={<SaveAs />} variant='contained' color='success' title='simpan' onClick={() => titleDialog.split(' ').includes('Tambah') ? createPeriode() : updatePeriode()}>Simpan</Button>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    );
}

export default DialogPengelolaanPeriode;
