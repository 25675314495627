import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import React from 'react';
import { ProvinsiProps } from '../../models/Area/ProvinsiProps';
import { KabupatenProps } from '../../models/Area/KabupatenProps';
import { KecamatanProps } from '../../models/Area/KecamatanProps';
import { DesaProps } from '../../models/Area/DesaProps';
import { RwProps } from '../../models/Area/RwProps';
import { RtProps } from '../../models/Area/RtProps';
import ProvinsiService from '../../services/Area/ProvinsiService';
import KabupatenService from '../../services/Area/KabupatenService';
import KecamatanService from '../../services/Area/KecamatanService';
import DesaService from '../../services/Area/DesaService';
import RwService from '../../services/Area/RwService';
import RtService from '../../services/Area/RtService';
import { Add } from '@mui/icons-material';
import { TpsProps } from '../../models/Area/TpsProps';
import { errorFormTpsProps, errorFormTpsField, formTpsKordinatProps, formTpsKordinatField } from '../../models/Area/ErrorFormTps';
import TpsService from '../../services/Area/TpsService';
import ModalTps from '../../pages/Area/ModalTps';
import { useRecoilValue } from 'recoil';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { ThemeStoreState } from '../../store';
import Swal from 'sweetalert2';
import { TpsValidationForm } from '../../validation/TpsValidationForm';
import { replaceItemAtIndex } from '../../Utils/helper';

const PengelolaanDataTps = () => {
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState);
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const [loadingSkeletonTable, setLoadingSkeletonTable] = React.useState<boolean>(false);
    const [provinsiData, setProvinsiData] = React.useState<ProvinsiProps[]>([]);
    const [kabupatenData, setKabupatenData] = React.useState<KabupatenProps[]>([]);
    const [kecamatanData, setKecamatanData] = React.useState<KecamatanProps[]>([]);
    const [desaData, setDesaData] = React.useState<DesaProps[]>([]);
    const [rwData, setRwData] = React.useState<RwProps[]>([]);
    const [rtData, setRtData] = React.useState<RtProps[]>([]);
    const [tpsData, setTpsData] = React.useState<TpsProps[]>([]);
    const [formTpsKordinat, setFormTpsKordinat] = React.useState<formTpsKordinatProps>(formTpsKordinatField);
    const [errorForm, setErrorForm] = React.useState<errorFormTpsProps>(errorFormTpsField);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [titleDialog, setTitleDialog] = React.useState<string>('');
    const [settingTable, setSettingTable] = React.useState({
        page: 0,
        size: 10,
        sort: 'tpsId',
        search: '',
        totalElements: 0,
        totalPages: 0
    });

    const [filterArea, setFilterArea] = React.useState<{
        provinsi_id: string; kabupaten_id: string; kecamatan_id: string; desa_id: string; rw_id: string; rt_id: string;
    }>({provinsi_id: '', kabupaten_id: '', kecamatan_id: '', desa_id: '', rw_id: '', rt_id: ''});
    const [helperName, setHelperName] = React.useState<{rw_id: string, nama_rw: string | undefined, rt_id:string, nama_rt: string | undefined}>({rw_id: '', nama_rw: '', rt_id:'', nama_rt: ''})

    async function getProvinsiData () {
        await ProvinsiService.getAllArray().then(res => {
            setProvinsiData(res.data);
            setKabupatenData([]);
            setKecamatanData([]);
            setDesaData([]);
            setRwData([]);
            setRtData([]);
            setTpsData([]);
        }).catch(err => {});
    }
    async function getKabupatenData (provinsiId: string) {
        setFilterArea({...filterArea, provinsi_id: provinsiId, kabupaten_id: '', kecamatan_id: '', desa_id: '', rw_id: '', rt_id: ''});
        setKabupatenData([]);
        setKecamatanData([]);
        setDesaData([]);
        setRwData([]);
        setRtData([]);
        setTpsData([]);
        await KabupatenService.getAllArray(provinsiId).then(res => {
            setKabupatenData(res.data);
        }).catch(err => {});
    }
    async function getKecamatanData (kabupatenId: string) {
        setFilterArea({...filterArea, kabupaten_id: kabupatenId, kecamatan_id: '', desa_id: '', rw_id: '', rt_id: ''});
        setKecamatanData([]);
        setDesaData([]);
        setRwData([]);
        setRtData([]);
        setTpsData([]);
        await KecamatanService.getAllArray(kabupatenId).then(res => {
            setKecamatanData(res.data);
        }).catch(err => {});
    }
    async function getDesaData (kecamatanId: string) {
        setFilterArea({...filterArea, kecamatan_id: kecamatanId, desa_id: '', rw_id: '', rt_id: ''});
        setDesaData([]);
        setRwData([]);
        setRtData([]);
        setTpsData([]);
        await DesaService.getAllArray(kecamatanId).then(res => {
            setDesaData(res.data);
        }).catch(err => {});
    }
    async function getRwData (desaId: string) {
        setFilterArea({...filterArea, desa_id: desaId, rw_id: '', rt_id: ''});
        setRwData([]);
        setRtData([]);
        setTpsData([]);
        await RwService.getAllArr(desaId).then(res => {
            setRwData(res.data);
            setRtData([]);
        }).catch(err => {});
    }
    async function getRtData (rwId: string) {
        setFilterArea({...filterArea, rw_id: rwId, rt_id: ''});
        setHelperName({...helperName, rw_id: rwId, nama_rw: rwData.find(rw => rw.rw_id === rwId)?.nama_rw})
        await RtService.getAllArr(rwId).then(res => {
            setRtData(res.data);
        }).catch(err => {});
    }
    async function getTpsData (rwId: string, rtId: string | null) {
        await TpsService.getAll(settingTable.page, settingTable.size, settingTable.sort, settingTable.search, rwId, rtId).then(res => {
            setSettingTable({...settingTable, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
            setTpsData(res.data.content);
        }).catch(err => {});
    }
    function openAddTps() {
        setFormTpsKordinat({...formTpsKordinatField, rw_id: filterArea.rw_id, rt_id: filterArea.rt_id});
        setErrorForm(errorFormTpsField)
        setTitleDialog("Tambah TPS Baru")
        setOpenModal(true);
    }
    async function openEditTps(tps: TpsProps) {
        await TpsService.getKordinatByTpsId(tps.tps_id).then(res => {
            setFormTpsKordinat({
                tps_id: tps.tps_id,
                rw_id: tps.rw_id,
                rt_id: tps.rt_id,
                nama_tps: tps.nama_tps,
                alamat: tps.alamat,
                kordinat_id: res.data.kordinat_id,
                longitude: res.data.longitude,
                lattitude: res.data.lattitude,
            });
            setErrorForm(errorFormTpsField)
            setTitleDialog("Ubah TPS " + tps.nama_tps)
            setOpenModal(true);
        }).catch(err => {})
    }
    async function createTps() {
        const newErrors: any = {};
        try {
            await TpsValidationForm.validate(formTpsKordinat, {abortEarly:false});
            setErrorForm(errorFormTpsField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await TpsService.createWithKordinat(formTpsKordinat).then(res => {
                setTpsData([...tpsData, {
                    tps_id: res.data.tps_id,
                    rw_id: res.data.rw_id,
                    rt_id: res.data.rt_id,
                    nama_tps: res.data.nama_tps,
                    alamat: res.data.alamat
                }]);
                setSettingTable({...settingTable, totalElements: settingTable.totalElements + 1})
                setMessageSnackbar("TPS berhasil di tambah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("TPS gagal di tambah");
                setOpenSnackbar(true);
            })
        }
    }
    async function updateTps(tps: TpsProps) {
        const newErrors: any = {};
        try {
            await TpsValidationForm.validate(formTpsKordinat, {abortEarly:false});
            setErrorForm(errorFormTpsField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorForm(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await TpsService.putWithKordinat(formTpsKordinat.tps_id, formTpsKordinat).then(res => {
                let index = tpsData.findIndex(p => p.tps_id === tps.tps_id);
                setTpsData(replaceItemAtIndex(tpsData, index, {
                    tps_id: res.data.tps_id,
                    rw_id: res.data.rw_id,
                    rt_id: res.data.rt_id,
                    nama_tps: res.data.nama_tps,
                    alamat: res.data.alamat
                }));
                setMessageSnackbar("TPS berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("TPS gagal di ubah");
                setOpenSnackbar(true);
            })
        }
    }
    function deleteTps(tps: TpsProps) {
        Swal.fire({
            title: 'Hapus TPS',
            text: 'Apakah anda ingin menghapus TPS ' + tps.nama_tps + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                TpsService.delete(tps.tps_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "TPS "+ tps.nama_tps +" sudah dihapus.",
                        icon: "success"
                    });
                    setTpsData(tpsData.filter(r => r.tps_id !== tps.tps_id));
                    setSettingTable({...settingTable, totalElements: settingTable.totalElements - 1})
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: "TPS "+ tps.nama_tps +" gagal dihapus.",
                        icon: "error"
                    });
                    console.error(err);
                })
            }
        });
    }
    React.useEffect(() => {
        if(provinsiData.length === 0) {
            getProvinsiData()
        }

        setTimeout(() => {setLoadingSkeleton(false)}, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterArea.rw_id, filterArea.rt_id]);
    const handleChangePage = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable, page: newPage});
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTable({...settingTable, page: 0, size: +event.target.value});
    };
    return loadingSkeleton ? (
        <Box>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Box component={Paper} elevation={2} sx={{ padding: '20px' }}>
                    <Skeleton sx={{ marginBottom: '10px' }} width={'100%'} height={'50px'} />
                    <Skeleton sx={{ marginBottom: '10px' }} width={'100%'} height={'50px'} />
                    <Skeleton sx={{ marginBottom: '10px' }} width={'100%'} height={'50px'} />
                    <Skeleton sx={{ marginBottom: '10px' }} width={'100%'} height={'50px'} />
                    <Skeleton sx={{ marginBottom: '10px' }} width={'100%'} height={'50px'} />
                    <Skeleton sx={{ marginBottom: '10px' }} width={'100%'} height={'50px'} />
                    <Skeleton sx={{ marginBottom: '10px' }} width={'100%'} height={'50px'} />
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Box component={Paper} elevation={2} sx={{ padding: '20px' }}>
                    <Skeleton sx={{ marginBottom: '10px' }} width={'100%'} height={'50px'} />
                    <Skeleton sx={{ marginTop: '10px' }} width={'100%'} height={'250px'} />
                </Box>
            </Grid>
        </Grid>
    </Box>
    ) : (<Box>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Box component={Paper} elevation={2} sx={{ padding: '20px' }}>
                    <Typography sx={{ marginBottom: '10px' }}>Filter TPS</Typography>
                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                        <InputLabel id="provinsi-select">Provinsi</InputLabel>
                        <Select
                            labelId="provinsi-select"
                            id="provinsi_id"
                            value={filterArea.provinsi_id}
                            disabled={provinsiData.length === 0}
                            label="Provinsi"
                            onChange={(e) => getKabupatenData(e.target.value)}
                        >
                            {
                                provinsiData.map(p => <MenuItem key={p.provinsi_id} value={p.provinsi_id}>{p.nama_provinsi}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                        <InputLabel id="kabupaten-select">Kabupaten</InputLabel>
                        <Select
                            labelId="kabupaten-select"
                            id="kabupaten_id"
                            value={filterArea.kabupaten_id}
                            disabled={kabupatenData.length === 0}
                            label="Kabupaten"
                            onChange={(e) => getKecamatanData(e.target.value)}
                        >
                            {
                                kabupatenData.map(p => <MenuItem key={p.kabupaten_id} value={p.kabupaten_id}>{p.nama_kabupaten}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                        <InputLabel id="kecamatan-select">Kecamatan</InputLabel>
                        <Select
                            labelId="kecamatan-select"
                            id="kecamatan_id"
                            value={filterArea.kecamatan_id}
                            disabled={kecamatanData.length === 0}
                            label="Kecamatan"
                            onChange={(e) => getDesaData(e.target.value)}
                        >
                            {
                                kecamatanData.map(p => <MenuItem key={p.kecamatan_id} value={p.kecamatan_id}>{p.nama_kecamatan}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                        <InputLabel id="desa-select">Desa</InputLabel>
                        <Select
                            labelId="desa-select"
                            id="desa_id"
                            disabled={desaData.length === 0}
                            value={filterArea.desa_id}
                            label="Desa"
                            onChange={(e) => getRwData(e.target.value)}
                        >
                            {
                                desaData.map(p => <MenuItem key={p.desa_id} value={p.desa_id}>{p.nama_desa}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                        <InputLabel id="rw-select">RW</InputLabel>
                        <Select
                            labelId="rw-select"
                            id="rw_id"
                            disabled={rwData.length === 0}
                            value={filterArea.rw_id}
                            label="RW"
                            onChange={(e) => {getRtData(e.target.value); getTpsData(e.target.value, null);}}
                        >
                            {
                                rwData.map(p => <MenuItem key={p.rw_id} value={p.rw_id}>{p.nama_rw}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                        <InputLabel id="rt-select">RT</InputLabel>
                        <Select
                            labelId="rt-select"
                            disabled={rtData.length === 0}
                            id="rt_id"
                            value={filterArea.rt_id}
                            label="RT"
                            onChange={(e) => {setFilterArea({...filterArea, rt_id: e.target.value}); setHelperName({...helperName, rt_id: e.target.value, nama_rt: rtData.find(rt => rt.rt_id === e.target.value)?.nama_rt}); getTpsData(filterArea.rw_id, e.target.value);}}
                        >
                            {
                                rtData.map(p => <MenuItem key={p.rt_id} value={p.rt_id}>{p.nama_rt}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Box component={Paper} elevation={2} sx={{ padding: '20px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ marginBottom: '10px' }}>Data TPS</Typography>
                        {
                            filterArea.rw_id ? (<Button  variant='contained' sx={{ color: theme.color, backgroundColor: theme.backgroundColor }} color='inherit' onClick={() => openAddTps()} startIcon={<Add />}>Tambah</Button>) : (<Button variant='contained' color='primary' onClick={() => openAddTps()} disabled startIcon={<Add />}>Tambah</Button>)
                        }
                        
                    </Box>
                    {
                        loadingSkeletonTable ? (
                            <Box sx={{ marginTop: '2cm' }}>
                                <Skeleton sx={{ marginTop: '10px' }} width={'100%'} height={'250px'} />
                            </Box>
                        ) : 
                        (<Box sx={{ marginTop: '20px' }}>
                            {
                                tpsData.length > 0 ? 
                                (<><TableContainer sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nama Provinsi</TableCell>
                                                <TableCell>Aksi</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                tpsData.map(p => 
                                                <TableRow key={p.tps_id}>
                                                    <TableCell>{p.nama_tps}</TableCell>
                                                    <TableCell><Button size='small' variant='contained' color='warning' onClick={() => openEditTps(p)} sx={{ margin: '5px' }}>Ubah</Button><Button onClick={() => deleteTps(p)} size='small' variant='contained' color='error'>Hapus</Button></TableCell>
                                                </TableRow> 
                                                )
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                    component="div"
                                    count={settingTable.totalElements}
                                    rowsPerPage={settingTable.size}
                                    page={settingTable.page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                /></>) : <Box sx={{ width: '100%', display: 'flex', 'justifyContent': 'center' }}>
                                    <Typography fontSize={'20px'}>Tidak Ada TPS</Typography>
                                </Box>
                            }
                        </Box>)
                    }
                </Box>
                <ModalTps
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    formTpsKordinat={formTpsKordinat}
                    setFormTpsKordinat={setFormTpsKordinat}
                    titleDialog={titleDialog}
                    errorForm={errorForm}
                    createTps={createTps}
                    updateTps={updateTps}
                    helperName={helperName}
                />
                <Snackbar
                    open={openSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={3000}
                    onClose={() => setOpenSnackbar(false)}
                    message={messageSnackbar}
                />
            </Grid>
        </Grid>
    </Box>);
}

export default PengelolaanDataTps;
