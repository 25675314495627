import React from 'react';

const PemungutanBantuanKontak = () => {
    return (
        <div>
            PemungutanBantuanKontak
        </div>
    );
}

export default PemungutanBantuanKontak;
