import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AuthStoreState, ThemeStoreState } from '../../store';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import {LoginRequestProps, LoginRequestField, ErrorLoginRequestProps, ErrorLoginRequestField } from '../../models/Auth/LoginRequestProps';
import { LoginValidateForm } from '../../validation/LoginValidateForm';
import AuthServices from "../../services/Auth/AuthServices";
import { LoginResponseProps } from '../../models/Auth/LoginResponseProps';
import { Alert } from '@mui/material';
import { AlertField, AlertProps } from '../../models/Component/AlertProps';
import axios from 'axios';

const Login = () => {
    const msg: any = new URLSearchParams(useLocation().search).get('msg');
    const defaultTheme = createTheme();
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState );
    const navigate = useNavigate();
    const setAuth = useSetRecoilState<LoginResponseProps>(AuthStoreState);
    const [login, setLogin] = React.useState<LoginRequestProps>(LoginRequestField);
    const [errors, setErrors] = React.useState<ErrorLoginRequestProps>(ErrorLoginRequestField);
    const [alertOption, setAlertOption] = React.useState<AlertProps>(AlertField);
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (msg === 'successFromRegister') {
            setAlertOption({open: true, message: 'Anda Berhasil Registrasi. Silakan Login', severity: 'success'});
        }
        if (msg === 'userNeedLogin') {
            if(localStorage.getItem('role')) {
                localStorage.removeItem('role');
            }
            setAlertOption({open: true, message: 'Sesi Anda sudah berakhir. Anda perlu Login', severity: 'warning'});
        }
        if (msg === 'successLogout') {
            setAlertOption({open: true, message: 'Anda Berhasil Logout', severity: 'success'});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        try {
            await LoginValidateForm.validate(login, {abortEarly: false});
            setErrors(ErrorLoginRequestField);
            JSON.stringify(login);
        } catch(error: any) {
            const newErrors: any = {};
            error.inner.forEach((err: {path: string, message: string}) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
            setLoading(false);
        }
        
        if(errors.username === null && errors.password === null) {
            await AuthServices.login(login.username, login.password).then(res => {
                if(res.status !== 200) {
                    setAlertOption({open: true, message: 'Terjadi Kesalahan. Periksa Kembali form yang anda isi', severity: 'error'})
                }  else {
                    setAuth(res.data);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`;
                    localStorage.setItem('user', JSON.stringify(res.data));
                    navigate('/home');
                }
            }).catch(err => setAlertOption({open: true, message: 'Error: ' + err, severity: 'error'}));
            setLoading(false);
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '70vh' }} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                <Grid item xs={12} sm={8} md={5}>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}><LockOutlinedIcon /></Avatar>
                        <Typography component="h1" variant="h5">Login</Typography>
                        <Grid container spacing={2} sx={{ marginY: '10px' }}>
                            <Grid item xs={12}>
                            {
                                alertOption.open === true ? <Alert severity={alertOption.severity} children={alertOption.message} onClose={() => setAlertOption({...alertOption, open: false})}></Alert> : <></>
                            }
                            </Grid>
                        </Grid>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                disabled={loading}
                                label="Username"
                                name="username"
                                autoComplete="username"
                                value={login.username}
                                onChange={(e) => setLogin({...login, username: e.target.value})}
                                autoFocus
                            />
                            {(errors.username != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.username}</Typography> : <></>}
                            <TextField
                                margin="normal"
                                required
                                disabled={loading}
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={login.password}
                                onChange={(e) => setLogin({...login, password: e.target.value})}
                            />
                            {(errors.password != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.password}</Typography> : <></>}
                            <FormControlLabel disabled={loading} control={<Checkbox value="remember" color="primary" />} label="Remember me" />
                            {
                                loading ? 
                                <LoadingButton loading sx={{ width: '100%', mt: 3, mb: 2, height: '50px' }} variant="outlined">Submit</LoadingButton> : 
                                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Sign In</Button>
                            }
                            <Grid container>
                                <Grid item xs>
                                    <Link href="/forgot" variant="body2">Forgot password?</Link>
                                </Grid>
                                <Grid item>
                                    <Button disabled={loading} onClick={() => navigate('/register')} sx={{ fontSize: '12px', backgroundColor: theme.backgroundColor, color: theme.color }}>Don't have an account? Sign Up</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

export default Login;
