export interface PertanyaanFileProps { 
    pertanyaan_file_id: string;
    pertanyaan_id: string;
    nama_file: string;
    created_at: string;
}

export const PertanyaanFileField: PertanyaanFileProps = { 
    pertanyaan_file_id: '',
    pertanyaan_id: '',
    nama_file: '',
    created_at: '',
}

export interface ErrorPertanyaanFileProps { 
    pertanyaan_file_id: string | null;
    pertanyaan_id: string | null;
    nama_file: string | null;
    created_at: string | null;
}

export const ErrorPertanyaanFileField: ErrorPertanyaanFileProps = { 
    pertanyaan_file_id: null,
    pertanyaan_id: null,
    nama_file: null,
    created_at: null,
}