import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import { CalonLegislatifProps } from '../../models/QuickCount/Senate/CalonLegislatifProps';

class CalonLegislatifServices {
    async getAllPartaiByCalonPemimpin(page: number, size: number, sort: string, search: string, calonLegislatifId: string, relation: Boolean): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/calon-legislatif/partai?page=${page}&size=${size}&sort=${sort}&search=${search}&calonLegislatifId=${calonLegislatifId}&relation=${relation}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAll(page: number, size: number, sort: string, search: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/calon-legislatif?page=${page}&size=${size}&sort=${sort}&search=${search}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(calonLegislatifId: string): Promise<AxiosResponse<CalonLegislatifProps>> {
        return axios.get(`/api/v1/calon-legislatif/${calonLegislatifId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(form: FormData, data: CalonLegislatifProps): Promise<AxiosResponse<CalonLegislatifProps>> {
        return axios.post(`/api/v1/calon-legislatif/${data.nama_calon}/${data.jenis_tingkat_pemilihan_id}/${data.partai_id}`, form, {headers: {'Content-Type': 'multipart/form-data',}});
    }
    
    async put(form: FormData, data : CalonLegislatifProps): Promise<AxiosResponse<CalonLegislatifProps>> {
        return axios.put(`/api/v1/calon-legislatif/${data.calon_legislatif_id}/${data.nama_calon}/${data.jenis_tingkat_pemilihan_id}/${data.partai_id}`, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    async delete(calonLegislatifId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/calon-legislatif/${calonLegislatifId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CalonLegislatifServices();