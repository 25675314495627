export interface JenisPemilihanProps {
    periode_id: string;
    nama_periode: string;
    nama_kategori: string;
    kategori_id: string;
    jenis_pemilihan_id: string;
    nama_pemilihan: string;
    deskripsi: string;
}

export const JenisPemilihanField:JenisPemilihanProps = {
    periode_id: '',
    nama_periode: '',
    nama_kategori: '',
    jenis_pemilihan_id: '',
    kategori_id: '',
    nama_pemilihan: '',
    deskripsi: '',
}

export interface ErrorJenisPemilihanProps {
    periode_id: string | null;
    nama_periode: string | null;
    nama_kategori: string | null;
    jenis_pemilihan_id: string | null;
    kategori_id: string | null;
    nama_pemilihan: string | null;
    deskripsi: string | null;
}

export const ErrorJenisPemilihanField: ErrorJenisPemilihanProps = {
    periode_id: null,
    nama_periode: null,
    nama_kategori: null,
    jenis_pemilihan_id: null,
    kategori_id: null,
    nama_pemilihan: null,
    deskripsi: null,
}