import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import { CalonPemimpinProps } from '../../models/QuickCount/Leader/CalonPemimpinProps';

class CalonPemimpinServices {
    async getAllPartaiByCalonPemimpin(page: number, size: number, sort: string, search: string, calonPemimpinId: string, relation: Boolean): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/calon-pemimpin/partai?page=${page}&size=${size}&sort=${sort}&search=${search}&calonPemimpinId=${calonPemimpinId}&relation=${relation}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAll(page: number, size: number, sort: string, search: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/calon-pemimpin?page=${page}&size=${size}&sort=${sort}&search=${search}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(calonPemimpinId: string): Promise<AxiosResponse<CalonPemimpinProps>> {
        return axios.get(`/api/v1/calon-pemimpin/${calonPemimpinId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(form: FormData, data: CalonPemimpinProps): Promise<AxiosResponse<CalonPemimpinProps>> {
        return axios.post(`/api/v1/calon-pemimpin/${data.nama_calon}/${data.nama_wakil_calon}/${data.kabinet}/${data.jenis_tingkat_pemilihan_id}`, form, {headers: {'Content-Type': 'multipart/form-data',}});
    }
    
    async put(form: FormData, data : CalonPemimpinProps): Promise<AxiosResponse<CalonPemimpinProps>> {
        return axios.put(`/api/v1/calon-pemimpin/${data.calon_pemimpin_id}/${data.nama_calon}/${data.nama_wakil_calon}/${data.kabinet}/${data.jenis_tingkat_pemilihan_id}`, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    async delete(calonPemimpinId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/calon-pemimpin/${calonPemimpinId}`, {headers: {'Content-Type': 'application/json',}});
    }
    
    async addRelation(calonPemimpinId: string, partaiId: string): Promise<AxiosResponse<void>> {
        return axios.post(`/api/v1/calon-pemimpin/addRelation?calonPemimpinId=${calonPemimpinId}&partaiId=${partaiId}`, null, {headers: {'Content-Type': 'application/json',}});
    }
    
    async removeRelation(calonPemimpinId: string, partaiId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/calon-pemimpin/removeRelation?calonPemimpinId=${calonPemimpinId}&partaiId=${partaiId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CalonPemimpinServices();