export interface MonitoringProps {
    monitoring_id: string;
    member_id: string;
    nama_aktivitas: string;
    keterangan: string;
    tanggal: string;
    jam: string;
    accepted: boolean;
    accepted_by: string;
    created_at: string;
    updated_at: string;
}

export const MonitoringField: MonitoringProps = {
    monitoring_id: '',
    member_id: '',
    nama_aktivitas: '',
    keterangan: '',
    tanggal: '',
    jam: '',
    accepted: false,
    accepted_by: '',
    created_at: '',
    updated_at: '',
}

export interface MonitoringFilesProps {
    monitoring_files_id: string;
    monitoring_id: string;
    nama_file: string;
    nama_dokumen: string;
    created_at: string;
}

export const MonitoringFilesField: MonitoringFilesProps = {
    monitoring_files_id: '',
    monitoring_id: '',
    nama_file: '',
    nama_dokumen: '',
    created_at: '',
}

export interface ErrorMonitoringProps {
    monitoring_id: string | null;
    member_id: string | null;
    nama_aktivitas: string | null;
    keterangan: string | null;
    tanggal: string | null;
    jam: string | null;
    accepted: boolean | null;
    accepted_by: string | null;
    created_at: string | null;
    updated_at: string | null;
}

export const ErrorMonitoringField: ErrorMonitoringProps = {
    monitoring_id: null,
    member_id: null,
    nama_aktivitas: null,
    keterangan: null,
    tanggal: null,
    jam: null,
    accepted: null,
    accepted_by: null,
    created_at: null,
    updated_at: null,
}