import React, { ChangeEvent } from 'react';
import { PartaiProps, errorPartaiProps } from '../../models/Institution/PartaiProps';
import { Accordion, AccordionDetails, AccordionSummary, Alert, AppBar, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import { SaveAs, Close, FileUpload, ShortTextOutlined, Person2Outlined, Diversity2Outlined, PhoneOutlined, MapsHomeWorkOutlined, Delete, VpnLock, ExpandMore, Person2Rounded } from '@mui/icons-material';
import BadgeIcon from '@mui/icons-material/Badge';
import { TransitionProps } from '@mui/material/transitions';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import Image from "../../asset/images/default.png";
import { styled } from '@mui/material/styles';
import { DppField, DppProps, errorDppProps } from '../../models/Institution/DppProps';

interface ModalPartai {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    titleDialog: string;
    formPartai: PartaiProps;
    setFormPartai: React.Dispatch<React.SetStateAction<PartaiProps>>
    errorForm: errorPartaiProps;
    messageSnackbar: string;
    theme: ThemeStoreProps;
    createPartai: any;
    updatePartai: any;
    image: File | null;
    setImage: React.Dispatch<React.SetStateAction<File | null>>;
    imagePreview : string | null;
    setImagePreview: React.Dispatch<React.SetStateAction<string | null>>;
    formDpp: DppProps;
    setFormDpp : React.Dispatch<React.SetStateAction<DppProps>>;
    errorFormDpp: errorDppProps;
    setErrorFormDpp: React.Dispatch<React.SetStateAction<errorDppProps>>;
    dppData: DppProps[];
    setDppData: React.Dispatch<React.SetStateAction<DppProps[]>>;
    settingTableDpp: any;
    setSettingTableDpp: any;
    createDpp: any;
    deleteDpp: any;
    handleChangePageDpp: any;
    handleChangeRowsPerPageDpp: any;
    openConfirmationDelete: boolean;
    setOpenConfirmationDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationDelete: React.FC<{dppTemp: DppProps; openConfirmation: boolean; setOpenConfirmation: React.Dispatch<React.SetStateAction<boolean>>; deleteDpp: any;}> =({dppTemp, openConfirmation, setOpenConfirmation, deleteDpp}) => {
    return (
        <Dialog
            open={openConfirmation}
            onClose={() => setOpenConfirmation(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Koonfirmasi Hapus DPP "+ dppTemp.nama_dpp +" ?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Apakah Anda yakin ingin menghapus DPP {dppTemp.nama_dpp} terpilih ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenConfirmation(false)}>Batalkan</Button>
                <Button onClick={() => deleteDpp(dppTemp)} autoFocus>Ya, Hapus</Button>
            </DialogActions>
        </Dialog>
    )
}

const ModalCreateUpdatePartai:React.FC<ModalPartai> = ({ openModal, setOpenModal, titleDialog, formPartai, setFormPartai, errorForm, messageSnackbar, theme, createPartai, updatePartai, image, setImage, imagePreview, setImagePreview, formDpp, setFormDpp, errorFormDpp, setErrorFormDpp,  dppData, setDppData, settingTableDpp, setSettingTableDpp, createDpp, deleteDpp, handleChangePageDpp, handleChangeRowsPerPageDpp, openConfirmationDelete, setOpenConfirmationDelete}) => {

    const [dppTemp, setDppTemp] = React.useState<DppProps>(DppField);

    function uploadImage(event: ChangeEvent<HTMLInputElement>) {
        const selectedFile = event.target.files?.[0] || null;
        setImage(selectedFile);

        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        }
    }

    function pushConfirmation(dpp: DppProps) {
        setDppTemp(dpp);
        setOpenConfirmationDelete(true);
    }

    return (
        <Dialog
            fullScreen
            open={openModal}
            onClose={() => setOpenModal(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setOpenModal(false)}
                    aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {titleDialog}
                    </Typography>
                    <Button autoFocus startIcon={<Close />} color="inherit" onClick={() => setOpenModal(false)}>
                        Tutup
                    </Button>
                </Toolbar>
            </AppBar>
            <ConfirmationDelete dppTemp={dppTemp} openConfirmation={openConfirmationDelete} setOpenConfirmation={setOpenConfirmationDelete} deleteDpp={deleteDpp} />
            <Container>
                {
                    titleDialog.split(" ").includes("Atur") ? 
                    <React.Fragment>
                        <Accordion sx={{ marginTop: '20px' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel-content"
                                id="panel-header"
                            >
                                Informasi Partai
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container sx={{ marginTop: '20px', marginX: '20px' }} spacing={3}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        {
                                            formPartai.gambar !== '' ?
                                            <img src={formPartai.gambar} alt={formPartai.nama_partai} width={'100%'} height={'100%'} /> :
                                            <img src={Image} alt={formPartai.nama_partai} width={'100%'} height={'100%'} />
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Typography variant='h5'>Informasi Partai</Typography>
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} aria-label="contacts">
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemIcon>
                                                        <BadgeIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={formPartai.nama_partai} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemIcon>
                                                        <ShortTextOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary={formPartai.singkatan} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemIcon>
                                                        <Person2Outlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary={formPartai.ketua_umum} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemIcon>
                                                        <Diversity2Outlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary={formPartai.sekjen} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemIcon>
                                                        <PhoneOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary={formPartai.no_telp} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem secondaryAction={<IconButton aria-label="url"><SaveAs /></IconButton>} disablePadding>
                                                <ListItemButton>
                                                    <ListItemIcon>
                                                        <VpnLock />
                                                    </ListItemIcon>
                                                    <ListItemText primary={formPartai.alamat_web} />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemIcon>
                                                        <MapsHomeWorkOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary={formPartai.alamat} />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="tambah-content"
                                id="tambah-header"
                            >
                                Tambah DPP
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Box sx={{ width: '100%', justifyContent: 'center' }}>
                                            <Box sx={{ width: '100%', marginBottom: '20px', height: '300px' }}>
                                                {
                                                    imagePreview !== null ? 
                                                    <img src={imagePreview} alt={'preview'} width={'100%'} height={'100%'} />
                                                    : (formDpp.gambar !== '' ?
                                                    <img src={formDpp.gambar} alt={formDpp.nama_dpp} width={'100%'} height={'100%'} /> :
                                                    <img src={Image} alt={formDpp.nama_dpp} width={'100%'} height={'100%'} />)
                                                }
                                            </Box>
                                            <Grid container spacing={3}>
                                                {(errorFormDpp.gambar != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormDpp.gambar}</Typography> : <></>}
                                                {
                                                    imagePreview !== null ?
                                                <Grid item xs={12}>
                                                    {
                                                        image == null ? <Alert severity="info">File Perlu dibawah 1 MB.</Alert> : image.size / 1000 > 1000 ? 
                                                        <Alert severity="error">File anda terlalu besar! {image == null ? 0 : image.size / 1000} Kb.</Alert> :
                                                        <Alert severity="info">File yang anda Upload berada di {image == null ? 0 : image.size / 1000} Kb.</Alert>
                                                    }
                                                    <Button variant='contained' sx={{ marginTop: '5px' }} fullWidth onClick={() => {
                                                        setImagePreview(null);
                                                        setImage(null);
                                                    }} startIcon={<Delete />} color='error'>Hapus</Button>
                                                </Grid> : 
                                                <Grid item xs={12}>
                                                    <Alert severity="info">File Perlu dibawah 1 MB.</Alert>
                                                    <Button component="label" sx={{ marginTop: '5px' }} tabIndex={-1} variant='contained' fullWidth startIcon={<FileUpload />} color='primary'>Upload<VisuallyHiddenInput type="file" onChange={uploadImage} /></Button>
                                                </Grid>
                                                }
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <BadgeIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    id="nama_dpp"
                                                    label="Nama Dpp"
                                                    placeholder='Nama Dpp'
                                                    name="nama_dpp"
                                                    autoFocus
                                                    onChange={(e) => setFormDpp({...formDpp, nama_dpp: e.target.value})}
                                                    value={formDpp.nama_dpp}
                                                />
                                                {(errorFormDpp.nama_dpp != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormDpp.nama_dpp}</Typography> : <></>}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Person2Rounded />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    id="ketua_dpp"
                                                    label="Ketua DPP"
                                                    placeholder="Ketua DPP"
                                                    name="ketua_dpp"
                                                    autoFocus
                                                    onChange={(e) => setFormDpp({...formDpp, ketua_dpp: e.target.value})}
                                                    value={formDpp.ketua_dpp}
                                                />
                                                {(errorFormDpp.ketua_dpp != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormDpp.ketua_dpp}</Typography> : <></>}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <PhoneOutlined />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    id="no_telp"
                                                    label="Nomor Telepon"
                                                    placeholder='+62 8XX'
                                                    name="no_telp"
                                                    autoFocus
                                                    onChange={(e) => setFormDpp({...formDpp, no_telp: e.target.value})}
                                                    value={formDpp.no_telp}
                                                />
                                                {(errorFormDpp.no_telp != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormDpp.no_telp}</Typography> : <></>}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    multiline
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <MapsHomeWorkOutlined />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    id="alamat"
                                                    label="Alamat"
                                                    placeholder='Alamat DPP'
                                                    name="alamat"
                                                    autoFocus
                                                    onChange={(e) => setFormDpp({...formDpp, alamat: e.target.value})}
                                                    value={formDpp.alamat}
                                                />
                                                {(errorFormDpp.alamat != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorFormDpp.alamat}</Typography> : <></>}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                        {
                                                            titleDialog.split(" ").includes("Ubah") ?
                                                            <Button variant='contained' fullWidth onClick={() => updatePartai()} startIcon={<SaveAs />} color='success'>Ubah</Button> : titleDialog.split(" ").includes("Tambah") ?
                                                            <Button variant='contained' fullWidth onClick={() => createPartai()} startIcon={<SaveAs />} color='success'>Tambah</Button> : <></>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button variant='contained' fullWidth onClick={() => createDpp()} disabled={!Boolean(image) && !Boolean(formDpp.nama_dpp)&& !Boolean(formDpp.ketua_dpp) && !Boolean(formDpp.no_telp)&& !Boolean(formDpp.alamat)} startIcon={<SaveAs />} color='secondary'>Simpan</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Box sx={{ marginTop: '20px' }}>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 540 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Gambar</TableCell>
                                                <TableCell>Nama Dpp</TableCell>
                                                <TableCell>Ketua Dpp</TableCell>
                                                <TableCell>No. Telpon</TableCell>
                                                <TableCell>Aksi</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                            dppData.length !== 0 ? dppData.map(d => 
                                                <TableRow key={d.dpp_id}>
                                                    <TableCell>
                                                        {
                                                            d.gambar == null ? 
                                                            <img style={{ width: '150px', height: '150px' }} alt={d.nama_dpp} src={Image} /> :
                                                            <img style={{ width: '150px', height: '150px' }} alt={d.nama_dpp} src={d.gambar} />
                                                        }
                                                    </TableCell>
                                                    <TableCell>{d.nama_dpp}</TableCell>
                                                    <TableCell>{d.ketua_dpp}</TableCell>
                                                    <TableCell>{d.no_telp}</TableCell>
                                                    <TableCell><Button sx={{ marginLeft: '5px' }} onClick={() => pushConfirmation(d)} size='small' variant='contained' startIcon={<Delete />} color='error'>Hapus</Button></TableCell>
                                            </TableRow> 
                                                )
                                            : <TableRow>
                                                <TableCell colSpan={5} align="center"><Typography variant='h5'>Tidak ada Data DPP</Typography></TableCell>
                                            </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                    component="div"
                                    count={settingTableDpp.totalElements}
                                    rowsPerPage={settingTableDpp.size}
                                    page={settingTableDpp.page}
                                    onPageChange={handleChangePageDpp}
                                    onRowsPerPageChange={handleChangeRowsPerPageDpp}
                                />
                            </Paper>
                        </Box>
                    </React.Fragment>
                    :
                    <Grid container sx={{ marginTop: '20px' }} spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Box sx={{ width: '100%', justifyContent: 'center' }}>
                                <Box sx={{ width: '100%', marginBottom: '20px', height: '300px' }}>
                                    {
                                        imagePreview !== null ? 
                                        <img src={imagePreview} alt={'preview'} width={'100%'} height={'100%'} />
                                        : (formPartai.gambar !== '' ?
                                        <img src={formPartai.gambar} alt={formPartai.nama_partai} width={'100%'} height={'100%'} /> :
                                        <img src={Image} alt={formPartai.nama_partai} width={'100%'} height={'100%'} />)
                                    }
                                </Box>
                                <Grid container spacing={3}>
                                    {
                                        imagePreview !== null ?
                                    <Grid item xs={12}>
                                        <Button variant='contained' fullWidth onClick={() => {
                                            setImagePreview(null);
                                            setImage(null);
                                        }} startIcon={<Delete />} color='error'>Hapus</Button>
                                    </Grid> : 
                                    <Grid item xs={12}>
                                        <Button component="label" tabIndex={-1} variant='contained' fullWidth startIcon={<FileUpload />} color='primary'>Upload<VisuallyHiddenInput type="file" onChange={uploadImage} /></Button>
                                    </Grid>
                                    }
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <BadgeIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        id="nama_partai"
                                        label="Nama Partai"
                                        placeholder='Nama Partai'
                                        name="nama_partai"
                                        autoFocus
                                        onChange={(e) => setFormPartai({...formPartai, nama_partai: e.target.value})}
                                        value={formPartai.nama_partai}
                                    />
                                    {(errorForm.nama_partai != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.nama_partai}</Typography> : <></>}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ShortTextOutlined />
                                                </InputAdornment>
                                            ),
                                        }}
                                        id="singkatan"
                                        label="Singkatan"
                                        placeholder="Singkatan Partai"
                                        name="singkatan"
                                        autoFocus
                                        onChange={(e) => setFormPartai({...formPartai, singkatan: e.target.value})}
                                        value={formPartai.singkatan}
                                    />
                                    {(errorForm.singkatan != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.singkatan}</Typography> : <></>}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Person2Outlined />
                                                </InputAdornment>
                                            ),
                                        }}
                                        id="ketua_umum"
                                        label="Ketua Umum"
                                        name="ketua_umum"
                                        placeholder='Nama Ketua Umum'
                                        autoFocus
                                        onChange={(e) => setFormPartai({...formPartai, ketua_umum: e.target.value})}
                                        value={formPartai.ketua_umum}
                                    />
                                    {(errorForm.ketua_umum != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.ketua_umum}</Typography> : <></>}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Diversity2Outlined />
                                                </InputAdornment>
                                            ),
                                        }}
                                        id="sekjen"
                                        label="Sekretaris Jenderal"
                                        placeholder='Nama Sekretaris Jenderal'
                                        name="sekjen"
                                        autoFocus
                                        onChange={(e) => setFormPartai({...formPartai, sekjen: e.target.value})}
                                        value={formPartai.sekjen}
                                    />
                                    {(errorForm.sekjen != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.sekjen}</Typography> : <></>}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneOutlined />
                                                </InputAdornment>
                                            ),
                                        }}
                                        id="no_telp"
                                        label="Nomor Telepon"
                                        placeholder='+62 8XX'
                                        name="no_telp"
                                        autoFocus
                                        onChange={(e) => setFormPartai({...formPartai, no_telp: e.target.value})}
                                        value={formPartai.no_telp}
                                    />
                                    {(errorForm.no_telp != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.no_telp}</Typography> : <></>}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <VpnLock />
                                                </InputAdornment>
                                            ),
                                        }}
                                        id="alamat_web"
                                        label="Alamat Web Partai"
                                        name="alamat_web"
                                        autoFocus
                                        placeholder='https://www.blabla.com'
                                        onChange={(e) => setFormPartai({...formPartai, alamat_web: e.target.value})}
                                        value={formPartai.alamat_web}
                                    />
                                    {(errorForm.alamat_web != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.alamat_web}</Typography> : <></>}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        multiline
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MapsHomeWorkOutlined />
                                                </InputAdornment>
                                            ),
                                        }}
                                        id="alamat"
                                        label="Alamat"
                                        placeholder='Alamat Utama Partai'
                                        name="alamat"
                                        autoFocus
                                        onChange={(e) => setFormPartai({...formPartai, alamat: e.target.value})}
                                        value={formPartai.alamat}
                                    />
                                    {(errorForm.alamat != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errorForm.alamat}</Typography> : <></>}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            {
                                                titleDialog.split(" ").includes("Ubah") ?
                                                <Button variant='contained' fullWidth onClick={() => updatePartai()} startIcon={<SaveAs />} color='success'>Ubah</Button> : titleDialog.split(" ").includes("Tambah") ?
                                                <Button variant='contained' fullWidth onClick={() => createPartai()} startIcon={<SaveAs />} color='success'>Tambah</Button> : <></>
                                            }
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button variant='contained' fullWidth onClick={() => setOpenModal(false)} startIcon={<Close />} color='secondary'>Tutup</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Container>
        </Dialog>
    );
}

export default ModalCreateUpdatePartai;
