import { Close, SaveAs } from '@mui/icons-material';
import { AppBar, Button, Container, Dialog, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Slide, TextField, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { KategoriProps } from '../../models/Forums/KategoriProps';
import { PeriodeProps } from '../../models/Forums/PeriodeProps';
import { ErrorJenisPemilihanProps, JenisPemilihanProps } from '../../models/Forums/JenisPemilihanProps';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogPengelolaanJenisPemilihanProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    theme: ThemeStoreProps;
    titleDialog: string;
    errors: ErrorJenisPemilihanProps;
    setErrors: React.Dispatch<React.SetStateAction<ErrorJenisPemilihanProps>>;
    kategoriData: KategoriProps[];
    periodeData: PeriodeProps[];
    jenisPemilihanDataDetail: JenisPemilihanProps;
    setJenisPemilihanDataDetail: React.Dispatch<React.SetStateAction<JenisPemilihanProps>>;
    createJenisPemilihan: any;
    updateJenisPemilihan: any;
    getKategori: any;
}

const DialogPengelolaanJenisPemilihan: React.FC<DialogPengelolaanJenisPemilihanProps> = ({
    openModal,
    setOpenModal,
    theme,
    titleDialog,
    errors,
    setErrors,
    kategoriData,
    periodeData,
    jenisPemilihanDataDetail,
    setJenisPemilihanDataDetail,
    createJenisPemilihan,
    updateJenisPemilihan,
    getKategori
}) => {
    return (
        <Dialog
            fullScreen
            open={openModal}
            onClose={() => setOpenModal(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setOpenModal(false)}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {titleDialog}
                    </Typography>
                    <Button autoFocus startIcon={<Close />} color="inherit" onClick={() => setOpenModal(false)}>
                        Tutup
                    </Button>
                </Toolbar>
            </AppBar>
            <Container>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel id="periode-select">Periode</InputLabel>
                            <Select
                                labelId="periode-select"
                                id="periode_id"
                                value={jenisPemilihanDataDetail.periode_id}
                                disabled={periodeData.length === 0}
                                label="Periode"
                                onChange={(e) => {
                                    let temp = periodeData.find(x => x.periode_id === e.target.value);
                                    getKategori(e.target.value);
                                    if(temp) {
                                        setJenisPemilihanDataDetail({...jenisPemilihanDataDetail, periode_id: temp.periode_id, nama_periode: temp.tahun.toString(), kategori_id: '', nama_kategori: ''})
                                    }
                                }}
                            >
                                {
                                    periodeData.map(p => <MenuItem key={p.periode_id} value={p.periode_id}>{p.tahun}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel id="kategori-select">Kategori</InputLabel>
                            <Select
                                labelId="kategori-select"
                                id="kategori_id"
                                value={jenisPemilihanDataDetail.kategori_id}
                                disabled={kategoriData.length === 0}
                                label="Kategori"
                                onChange={(e) => {
                                    let temp = kategoriData.find(x => x.kategori_id === e.target.value);
                                    if(temp) {
                                        setJenisPemilihanDataDetail({...jenisPemilihanDataDetail, kategori_id: temp.kategori_id, nama_kategori: temp.nama_kategori})
                                    }
                                }}
                            >
                                {
                                    kategoriData.map(p => <MenuItem key={p.kategori_id} value={p.kategori_id}>{p.nama_kategori}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                fullWidth
                                id="nama_jenis_pemilihan"
                                label="Nama Jenis Pemilihan"
                                name="nama_jenis_pemilihan"
                                onChange={(e) => setJenisPemilihanDataDetail({...jenisPemilihanDataDetail, nama_pemilihan: e.target.value})}
                                value={jenisPemilihanDataDetail.nama_pemilihan}
                            />
                            {(errors.nama_pemilihan != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.nama_pemilihan}</Typography> : <></>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            fullWidth
                            id="deskripsi"
                            label="Deskripsi"
                            name="deskripsi"
                            multiline
                            rows={2}
                            onChange={(e) => setJenisPemilihanDataDetail({...jenisPemilihanDataDetail, deskripsi: e.target.value})}
                            value={jenisPemilihanDataDetail.deskripsi}
                        />
                        {(errors.deskripsi != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.deskripsi}</Typography> : <></>}
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                        <Button startIcon={<SaveAs />} variant='contained' color='success' title='simpan' onClick={() => titleDialog.split(' ').includes('Tambah') ? createJenisPemilihan() : updateJenisPemilihan()}>Simpan</Button>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    );
}

export default DialogPengelolaanJenisPemilihan;
