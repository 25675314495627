import { Abc, AccountBox, AlternateEmail, AssuredWorkload, CalendarMonth, Cancel, Close, EventAvailable, Female, Home, HowToReg, Info, LockPerson, Male, Phone, SaveAs, Search, Transgender, VerifiedUser, WhatsApp } from '@mui/icons-material';
import { Alert, AppBar, Box, Button, Checkbox, Container, Dialog, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, List, ListItemButton, ListItemIcon, ListItemText, Radio, RadioGroup, Slide, TextField, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import RelawanDetailKonstituen from '../../models/Relawan/RelawanDetailKonstituen';
import Image from "../../asset/images/default.png";
import {InfoWindow, Map, Marker} from '@vis.gl/react-google-maps';
import { ErrorRegisterRequestProps, RegisterRequestProps } from '../../models/Auth/RegisterRequestProps';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import RelawanService from '../../services/Member/RelawanService';

interface DialogDetailKonstituenProps {
    titleDialog: string;
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    theme: ThemeStoreProps;
    detailKonstituen: RelawanDetailKonstituen;
    setDetailKonstituen: React.Dispatch<React.SetStateAction<RelawanDetailKonstituen>>;
    handleSubmit: any;
    register: RegisterRequestProps;
    setRegister: React.Dispatch<React.SetStateAction<RegisterRequestProps>>;
    errors: ErrorRegisterRequestProps;
    setErrors: React.Dispatch<React.SetStateAction<ErrorRegisterRequestProps>>;
    checkBoxHelper: boolean;
    helperCheckbox: any;
    setCheckBoxHelper: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
    setMessageSnackbar: React.Dispatch<React.SetStateAction<string>>;
    verifikasiNik: {nik: string; verified: boolean};
    setVerifikasiNik: React.Dispatch<React.SetStateAction<{nik: string; verified: boolean}>>;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogDetailKonstituen:React.FC<DialogDetailKonstituenProps> = ({ titleDialog, openModal, setOpenModal, theme, detailKonstituen, setDetailKonstituen, handleSubmit, register, setRegister, errors, setErrors, checkBoxHelper, helperCheckbox, setCheckBoxHelper, setOpenSnackbar, setMessageSnackbar, verifikasiNik, setVerifikasiNik }) => {
    const [selectedMarker, setSelectedMarker] = React.useState<any>(null);

    const checkNik = async () => {
        await RelawanService.cekNik(register.nik).then(res => {
            if(res.data) {
                setVerifikasiNik({nik: register.nik, verified: true});
            } else {
                setVerifikasiNik({nik: register.nik, verified: false});
                setMessageSnackbar("NIK sudah terdaftar di Sistem Ini");
                setOpenSnackbar(true);
            }
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "NIK sudah Terdaftar");
            setOpenSnackbar(true);
        });
    }
    
    const removeNik = () => {
        setVerifikasiNik({nik: '', verified: false});
    }

    React.useEffect(() => {
        setSelectedMarker(null);
        setVerifikasiNik({nik: '', verified: false});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const today = dayjs();

    return (
        <Dialog
            fullScreen
            open={openModal}
            onClose={() => setOpenModal(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setOpenModal(false)}
                    aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {titleDialog}
                    </Typography>
                    <Button autoFocus startIcon={<Close />} color="inherit" onClick={() => setOpenModal(false)}>
                        Tutup
                    </Button>
                </Toolbar>
            </AppBar>
            {
                titleDialog.split(' ').includes('Detail') ? 
            <Container>
                <Grid container spacing={3} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Box sx={{ width: '100%', height: '300px' }}>
                            {
                                detailKonstituen !== null ?
                                <img src={detailKonstituen?.member.avatar} alt={detailKonstituen?.ktp.nama} width={'100%'} /> :
                                <img src={Image} alt={'default'} width={'100%'} />
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={8}>
                        <Typography variant='h6'>Informasi Lokasi Konstituen</Typography>
                        <Map
                            defaultZoom={10}
                            style={{ width: '100%', height: '320px' }}
                            defaultCenter={ { lat: detailKonstituen.map.length > 0 ? parseFloat(detailKonstituen.map[0].lattitude) : process.env.REACT_APP_MAPS_LATTITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LATTITUDE) : -4.55858490, lng: detailKonstituen.map.length > 0 ? parseFloat(detailKonstituen.map[0].longitude) : process.env.REACT_APP_MAPS_LONGITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LONGITUDE) : 105.40680790 } }
                            >
                            {
                                detailKonstituen.map.length > 0 ? detailKonstituen.map.map(a => <Marker position={{lat: parseFloat(a.lattitude), lng: parseFloat(a.longitude)}} icon={{url: 'http://maps.google.com/mapfiles/kml/pal3/icon56.png',scaledSize: new google.maps.Size(32, 32)}} key={a.area_member_id} onClick={() => setSelectedMarker({name: "Rumah", lat: a.lattitude, lng: a.longitude, alamat:a.nama_tps})} />) : <></>
                            }
                            {selectedMarker && (
                                <InfoWindow
                                    position={{ lat: parseFloat(selectedMarker.lat), lng: parseFloat(selectedMarker.lng) }}
                                    onCloseClick={() => setSelectedMarker(null)}
                                    headerContent={selectedMarker.name}
                                >
                                    <div>
                                        <h4>{selectedMarker.name}</h4>
                                        <p>Alamat: {selectedMarker.alamat}</p>
                                    </div>
                                </InfoWindow>
                            )}
                        </Map>
                        <Typography variant='h6' sx={{ marginTop: '20px' }}>Informasi Referensi</Typography>
                        <List component="nav" aria-label="main mailbox folders">
                            <ListItemButton>
                                <ListItemIcon><Abc /></ListItemIcon>
                                <ListItemText primary={detailKonstituen?.referensi.kode_referensi ?? 'Belum Ada Kode Referensi'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><EventAvailable /></ListItemIcon>
                                <ListItemText primary={detailKonstituen?.referensi.created_at ?? 'Belum Ada Kode Referensi'} />
                            </ListItemButton>
                        </List>
                        <Typography variant='h6' sx={{ marginTop: '20px' }}>Informasi Profil</Typography>
                        <List component="nav" aria-label="main mailbox folders">
                            <ListItemButton>
                                <ListItemIcon><AccountBox /></ListItemIcon>
                                <ListItemText primary={detailKonstituen?.ktp.nama ?? 'Belum Ada Nama'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon>{ detailKonstituen?.ktp.jenis_kelamin === null ? <Transgender /> : detailKonstituen?.ktp.jenis_kelamin === 'PRIA' ? <Male /> : <Female /> }</ListItemIcon>
                                <ListItemText primary={detailKonstituen?.ktp.jenis_kelamin ?? 'Belum Ada Jenis Kelamin'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><CalendarMonth /></ListItemIcon>
                                <ListItemText primary={detailKonstituen?.ktp.tempat_lahir + ', ' + detailKonstituen?.ktp.tanggal_lahir ?? 'Belum Ada Kode Referensi'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><AlternateEmail /></ListItemIcon>
                                <ListItemText primary={detailKonstituen?.member.email ?? 'Belum Ada Email'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><Phone /></ListItemIcon>
                                <ListItemText primary={detailKonstituen?.member.no_hp ?? 'Belum Ada Nomor HP'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><WhatsApp /></ListItemIcon>
                                <ListItemText primary={detailKonstituen?.member.no_wa ?? 'Belum Ada Nomor WhatsApp'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><VerifiedUser /></ListItemIcon>
                                <ListItemText primary={detailKonstituen?.username ?? 'Belum Ada Username'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><AssuredWorkload /></ListItemIcon>
                                <ListItemText primary={detailKonstituen?.member.hak_pilih ? 'Ada Hak Pilih di TPS' : 'Tidak ada Hak Pilih'} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon><Home /></ListItemIcon>
                                <ListItemText primary={detailKonstituen?.ktp.alamat ?? 'Belum Ada Alamat'} />
                            </ListItemButton>
                        </List>
                        <Typography variant='h6' sx={{ marginTop: '20px' }}>Informasi Role</Typography>
                        <List component="nav" aria-label="main role">
                            {
                                detailKonstituen?.role.length === 0 ? <ListItemButton>
                                <ListItemIcon><LockPerson /></ListItemIcon>
                                <ListItemText primary={'Belum Ada Role'} />
                            </ListItemButton> : detailKonstituen?.role.map(r => 
                                <ListItemButton key={r.role_id}>
                                    <ListItemIcon><LockPerson /></ListItemIcon>
                                    <ListItemText primary={r.nama_role.replaceAll('_', ' ')} />
                                </ListItemButton>
                            )}
                        </List>
                    </Grid>
                </Grid>
            </Container> : <Container sx={{ paddingTop: '10px' }}>
                <Grid container spacing={2} sx={{ marginTop: '10px', marginBottom: '20px', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={12} md={12} lg={8}>
                    {
                        verifikasiNik.nik === '' ? 
                        <Alert severity='info' variant='filled' icon={<Info />}>Silakan isi dan Klik Verifikasi NIK</Alert> : verifikasiNik.verified ? <Alert severity='success' variant='filled' icon={<Info />}>NIK belum terdaftar. Silakan Lengkapi Form dibawah ini</Alert> : <Alert severity='error' variant='filled' icon={<Info />}>NIK sudah terdaftar di sistem ini.</Alert>
                    }
                    </Grid>
                    <Grid item xs={12} md={12} lg={8}>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        label={'Nomor Induk Kependudukan (NIK)'}
                                        id="nik"
                                        name="nik"
                                        autoFocus
                                        disabled={verifikasiNik.verified}
                                        onChange={(e) => setRegister({...register, nik: e.target.value})}
                                        value={register.nik}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {
                                                        verifikasiNik.verified ? 
                                                        <IconButton onClick={() => removeNik()}>
                                                            <Cancel />
                                                        </IconButton>
                                                        :
                                                        <IconButton onClick={() => checkNik()}>
                                                            <Search />
                                                        </IconButton>
                                                    }
                                                </InputAdornment>
                                            ),
                                        }}
                                        helperText={register.nik.length + '/16 Karakter'}
                                    />
                                    {(errors.nik != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.nik}</Typography> : <></>}
                                </Grid>
                                {
                                    !verifikasiNik.verified ? <></> : <React.Fragment>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="nama"
                                            label="Nama"
                                            name="nama"
                                            autoComplete="nama"
                                            onChange={(e) => setRegister({...register, nama: e.target.value})}
                                            value={register.nama}
                                        />
                                        {(errors.nama != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.nama}</Typography> : <></>}
                                    </Grid>
                                    <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel id="gender">Jenis Kelamin</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="gender"
                                            defaultValue="WANITA"
                                            name="radio-buttons-group"
                                            onChange={(e) => setRegister({...register, jenis_kelamin: e.target.value})}
                                            value={register.jenis_kelamin}
                                        >
                                            <FormControlLabel value="WANITA" control={<Radio />} label="Perempuan" />
                                            <FormControlLabel value="PRIA" control={<Radio />} label="Laki-Laki" />
                                        </RadioGroup>
                                        {(errors.jenis_kelamin != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.jenis_kelamin}</Typography> : <></>}
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            autoComplete="tempat_lahir"
                                            name="tempat_lahir"
                                            required
                                            fullWidth
                                            id="tempat_lahir"
                                            label="Tempat Lahir"
                                            onChange={(e) => setRegister({...register, tempat_lahir: e.target.value})}
                                            value={register.tempat_lahir}
                                        />
                                        {(errors.tempat_lahir != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.tempat_lahir}</Typography> : <></>}
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                defaultValue={today}
                                                label="Tanggal Lahir"
                                                name='tanggal_lahir'
                                                disableFuture
                                                views={['year', 'month', 'day']}
                                                onChange={(e) => setRegister({...register, tanggal_lahir: (e?.format('YYYY-MM-DD') ?? today.format('YYYY-MM-DD'))})}
                                            />
                                        </LocalizationProvider>
                                        {(errors.tanggal_lahir != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.tanggal_lahir}</Typography> : <></>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="alamat"
                                            label="Alamat"
                                            name='alamat'
                                            required
                                            fullWidth
                                            multiline
                                            maxRows={6}
                                            onChange={(e) => setRegister({...register, alamat: e.target.value})}
                                            value={register.alamat}
                                        />
                                        {(errors.alamat != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.alamat}</Typography> : <></>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="kode_pos"
                                            fullWidth
                                            required
                                            label="Kode Pos"
                                            name='kode_pos'
                                            onChange={(e) => setRegister({...register, kode_pos: e.target.value})}
                                            value={register.kode_pos}
                                        />
                                        {(errors.kode_pos != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.kode_pos}</Typography> : <></>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="no_hp"
                                            label="Nomor HP"
                                            name="no_hp"
                                            autoComplete="no_hp"
                                            onChange={(e) => 
                                                (checkBoxHelper) ? setRegister({...register, no_wa: e.target.value, no_hp: e.target.value}) : setRegister({...register, no_hp: e.target.value})   
                                            }
                                            value={register.no_hp}
                                        />
                                        {(errors.no_hp != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.no_hp}</Typography> : <></>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="no_wa"
                                            label="Nomor Whatsapp"
                                            name="no_wa"
                                            autoComplete="no_wa"
                                            value={register.no_wa}
                                            onChange={(e) => {setRegister({...register, no_wa: e.target.value}); setCheckBoxHelper(false);}}
                                        />
                                        {(errors.no_wa != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.no_wa}</Typography> : <></>}
                                        <FormControlLabel control={<Checkbox checked={checkBoxHelper} onChange={helperCheckbox} />} label="Nomor Wa saya sama dengan Nomor HP" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            onChange={(e) => setRegister({...register, email: e.target.value})}
                                            value={register.email}
                                        />
                                        {(errors.email != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.email}</Typography> : <></>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="username"
                                            label="Username"
                                            name="username"
                                            autoComplete="username"
                                            onChange={(e) => setRegister({...register, username: e.target.value})}
                                            value={register.username}
                                        />
                                        {(errors.username != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.username}</Typography> : <></>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                            onChange={(e) => setRegister({...register, password: e.target.value})}
                                            value={register.password}
                                        />
                                        {(errors.password != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.password}</Typography> : <></>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel control={<Checkbox checked={register.hak_pilih} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRegister({...register, hak_pilih: event.target.checked})} />} label="Saya Memiliki Hak Pilih di TPS Alamat Tinggal" />
                                        {(errors.hak_pilih != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.hak_pilih}</Typography> : <></>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Alert severity='error' icon={<HowToReg />} variant='filled'>Jumlah konstituen Anda akan bertambah, namun verifikasi NIK tetap akan dilakukan. Jika NIK tidak valid sesuai data Dukcapil, maka anggota dengan NIK {register.nik} akan dihapus.</Alert>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} startIcon={<SaveAs />} title='Simpan'>Simpan</Button>
                                    </Grid>
                                    </React.Fragment>
                                }
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            }
        </Dialog>
    );
}

export default DialogDetailKonstituen;
