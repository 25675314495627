export interface errorFormTpsProps {
    tps_id: string;
    rw_id: string;
    rt_id: string;
    nama_tps: string;
    alamat: string;
    kordinat_id: string;
    longitude: string;
    lattitude: string;
}

export const errorFormTpsField:errorFormTpsProps  = {
    tps_id: '',
    rw_id: '',
    rt_id: '',
    nama_tps: '',
    alamat: '',
    kordinat_id: '',
    longitude: '',
    lattitude: '',
};

export interface formTpsKordinatProps {
    tps_id: string;
    rw_id: string;
    rt_id?: string | null;
    nama_tps: string;
    alamat: string;
    kordinat_id: string;
    longitude: string;
    lattitude: string;
}

export const formTpsKordinatField:formTpsKordinatProps  = {
    tps_id: '',
    rw_id: '',
    rt_id: '',
    nama_tps: '',
    alamat: '',
    kordinat_id: '',
    longitude: '',
    lattitude: '',
};