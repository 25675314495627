import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isTokenExpired } from '../Utils/jwt';
import { LoginResponseField, LoginResponseProps } from '../models/Auth/LoginResponseProps';
import { useRecoilState } from 'recoil';
import { AuthStoreState } from '../store';

interface PrivateRouteProps {
    children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const user: string | null = localStorage.getItem("user");
    const [auth, setAuth] = useRecoilState<LoginResponseProps>(AuthStoreState);
    const location = useLocation();

    let isAuthenticated: boolean = false;

    if(user !== null) {
        let temp: LoginResponseProps = JSON.parse(user);
        isAuthenticated = (temp.access_token !== null) && !isTokenExpired(temp.access_token);
    }

    if(auth !== null) {
        if (auth.access_token !== '') {
            isAuthenticated = (auth.access_token !== null) && !isTokenExpired(auth.access_token);
        }
    }
    
    if (!isAuthenticated) {
        if(user !== null) {
            localStorage.removeItem("user");
        }

        if(auth !== null) {
            if (auth.access_token !== '') {
                setAuth(LoginResponseField);
            }
        }

        return <Navigate to="/login?msg=userNeedLogin" state={{ from: location }} />;
    } else {
        if(user !== null && auth == null) {
            setAuth(JSON.parse(user));
        }
        if(auth === null && auth !== null) {
            localStorage.setItem("user", JSON.stringify(auth));
        }
    }

    return <>{children}</>;
};

export default PrivateRoute;