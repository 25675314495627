import { Close, SaveAs } from '@mui/icons-material';
import { AppBar, Button, Container, Dialog, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Slide, TextField, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { ErrorKategoriProps, KategoriProps } from '../../models/Forums/KategoriProps';
import { PeriodeProps } from '../../models/Forums/PeriodeProps';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogPengelolaanKategoriProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    theme: ThemeStoreProps;
    titleDialog: string;
    errors: ErrorKategoriProps;
    setErrors: React.Dispatch<React.SetStateAction<ErrorKategoriProps>>;
    kategoriDataDetail: KategoriProps;
    setKategoriDataDetail: React.Dispatch<React.SetStateAction<KategoriProps>>;
    createKategori: any;
    updateKategori: any;
    periodeData: PeriodeProps[];
}

const DialogPengelolaanKategori: React.FC<DialogPengelolaanKategoriProps> = ({
    openModal, 
    setOpenModal,
    theme, 
    titleDialog,
    errors,
    setErrors,
    kategoriDataDetail,
    setKategoriDataDetail,
    createKategori,
    updateKategori,
    periodeData
}) => {
    return (
        <Dialog
            fullScreen
            open={openModal}
            onClose={() => setOpenModal(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: theme.backgroundColor, color: theme.color }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setOpenModal(false)}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {titleDialog}
                    </Typography>
                    <Button autoFocus startIcon={<Close />} color="inherit" onClick={() => setOpenModal(false)}>
                        Tutup
                    </Button>
                </Toolbar>
            </AppBar>
            <Container>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="periode-select">Periode</InputLabel>
                            <Select
                                labelId="periode-select"
                                id="periode_id"
                                name='periode-select'
                                value={kategoriDataDetail.periode_id}
                                disabled={periodeData.length === 0}
                                label="Periode"
                                onChange={(e) => {
                                    let temp = periodeData.find(x => x.periode_id === e.target.value);
                                    if(temp) {
                                        setKategoriDataDetail({...kategoriDataDetail, periode_id: temp.periode_id, nama_periode: temp.tahun.toString()})
                                    }
                                }}
                            >
                                {
                                    periodeData.map(p => <MenuItem key={p.periode_id} value={p.periode_id}>{p.tahun}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                sx={{ marginTop: '15px' }}
                                fullWidth
                                id="nama_kategori"
                                label="Nama Kategori"
                                name="nama_kategori"
                                onChange={(e) => setKategoriDataDetail({...kategoriDataDetail, nama_kategori: e.target.value})}
                                value={kategoriDataDetail.nama_kategori}
                            />
                            {(errors.nama_kategori != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.nama_kategori}</Typography> : <></>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            sx={{ marginTop: '15px' }}
                            fullWidth
                            id="deskripsi"
                            label="Deskripsi"
                            name="deskripsi"
                            multiline
                            rows={2}
                            onChange={(e) => setKategoriDataDetail({...kategoriDataDetail, deksripsi: e.target.value})}
                            value={kategoriDataDetail.deksripsi}
                        />
                        {(errors.deksripsi != null) ? <Typography sx={{ fontSize: '10px', color: 'red' }}>{errors.deksripsi}</Typography> : <></>}
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                        <Button startIcon={<SaveAs />} variant='contained' color='success' title='simpan' onClick={() => titleDialog.split(' ').includes('Tambah') ? createKategori() : updateKategori()}>Simpan</Button>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    );
}

export default DialogPengelolaanKategori;
