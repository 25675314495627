import { Button, Chip, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';
import ListMemberProps from '../../models/Member/ListMemberProps';
import { ProvinsiProps } from '../../models/Area/ProvinsiProps';
import { KabupatenProps } from '../../models/Area/KabupatenProps';
import { KecamatanProps } from '../../models/Area/KecamatanProps';
import { DesaProps } from '../../models/Area/DesaProps';
import { RwProps } from '../../models/Area/RwProps';
import { RtProps } from '../../models/Area/RtProps';
import { TpsProps } from '../../models/Area/TpsProps';
import { AuthStoreState, ThemeStoreState } from '../../store';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { useRecoilValue } from 'recoil';
import ProvinsiService from '../../services/Area/ProvinsiService';
import KabupatenService from '../../services/Area/KabupatenService';
import KecamatanService from '../../services/Area/KecamatanService';
import DesaService from '../../services/Area/DesaService';
import RwService from '../../services/Area/RwService';
import RtService from '../../services/Area/RtService';
import TpsService from '../../services/Area/TpsService';
import { Add, Cancel, Delete, Home, Person, Search } from '@mui/icons-material';
import AdminService from '../../services/Member/AdminService';
import AreaMemberService from '../../services/Area/AreaMemberService';
import { AreaMemberField, AreaMemberProps, ErrorAreaMemberField, ErrorAreaMemberProps } from '../../models/Area/AreaMemberProps';
import DialogAdminAreaMember from '../../pages/Admin/DialogAdminAreaMember';
import { AreaMemberValidateForm } from '../../validation/AreaMemberValidation';
import DetailMember from '../../pages/Admin/DetailMember';
import Swal from 'sweetalert2';
import { ErrorRegisterRequestField, ErrorRegisterRequestProps, RegisterRequestField, RegisterRequestProps } from '../../models/Auth/RegisterRequestProps';
import { RegistrationValidateForm } from '../../validation/RegistrationValidateForm';
import { ErrorProfileRequestField, ErrorProfileRequestProps, ProfileRequestField, ProfileRequestProps } from '../../models/Profile/ProfileRequestProps';
import { ProfilValidateForm } from '../../validation/ProfilValidateForm';
import ProfileService from '../../services/Auth/ProfileService';
import { ProfileResponseField, ProfileResponseProps } from '../../models/Profile/ProfileResponseProps';
import { replaceItemAtIndex } from '../../Utils/helper';
import { LoginResponseProps } from '../../models/Auth/LoginResponseProps';

const AdminPengelolaanDataAnggota = () => {
    // Area 
    const [settingTableArea, setSettingTableArea] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'areaMemberId',
        search: '',
        totalElements: 0,
        totalPages: 0,
    });
    const [loadingSkeletonArea, setLoadingSkeletonArea] = React.useState<boolean>(true);
    const [loadingSkeletonTableArea, setLoadingSkeletonTableArea] = React.useState<boolean>(false);
    const [openModalArea, setOpenModalArea] = React.useState<boolean>(false);
    const [formAreaMember, setFormAreaMember] = React.useState<AreaMemberProps>(AreaMemberField);
    const [errorFormArea, setErrorFormArea] = React.useState<ErrorAreaMemberProps>(ErrorAreaMemberField);
    const [areaData, setAreaData] = React.useState<AreaMemberProps[]>([]);
    
    const openAreaDetail = async (member: ListMemberProps) => {
        await getAllArea(member);
        setTitleDialog("Detail Area " + member.nama);
        setFormAreaMember({...formAreaMember, member_id: member.member_id})
        setOpenModalArea(true);
    }

    const getAllArea = async (member?: ListMemberProps) => {
        setLoadingSkeletonTableArea(true);
        await AreaMemberService.getAll(settingTableArea.page, settingTableArea.size, settingTableArea.sort, settingTableArea.search, member !== undefined ? member.member_id : formAreaMember.member_id).then(res => {
            setAreaData(res.data.content);
            setSettingTableArea({...settingTableArea, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
            setLoadingSkeletonTableArea(false);
        }).catch(err => {
            setLoadingSkeletonTableArea(false);
            setMessageSnackbar(err.response.data.message ?? 'Tidak dapat mengambil data')
            setOpenSnackbar(true);
        });
    }
    const [provinsiDataArea, setProvinsiDataArea] = React.useState<ProvinsiProps[]>([]);
    const [kabupatenDataArea, setKabupatenDataArea] = React.useState<KabupatenProps[]>([]);
    const [kecamatanDataArea, setKecamatanDataArea] = React.useState<KecamatanProps[]>([]);
    const [desaDataArea, setDesaDataArea] = React.useState<DesaProps[]>([]);
    const [rwDataArea, setRwDataArea] = React.useState<RwProps[]>([]);
    const [rtDataArea, setRtDataArea] = React.useState<RtProps[]>([]);
    const [tpsDataArea, setTpsDataArea] = React.useState<TpsProps[]>([]);

    const createAlamat = async () => {
        const newErrors: any = {};
        try {
            await AreaMemberValidateForm.validate(formAreaMember, {abortEarly:false});
            setErrorFormArea(ErrorAreaMemberField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorFormArea(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await AreaMemberService.create(formAreaMember).then(res => {
                if(formAreaMember.alamat_utama) {
                    let temp: AreaMemberProps[] = [];
                    areaData.forEach(am => temp.push({...am, alamat_utama: false}));
                    setAreaData([...temp, res.data]);
                } else {
                    setAreaData([...areaData, res.data]);
                }
                setSettingTableArea({...settingTableArea, totalElements: settingTableArea.totalElements + 1})
                setMessageSnackbar("Alamat berhasil di tambah");
                setOpenSnackbar(true);
                setTitleDialog('Detail Area ' + titleDialog.split(' ').pop());
            }).catch(err => {
                setMessageSnackbar("Alamat gagal di tambah");
                setOpenSnackbar(true);
            })
        }
    }
    const jadikanAlamatUtama = async (areaMember: AreaMemberProps, status: boolean) => {
        AreaMemberService.put(areaMember.area_member_id, status).then(res => {
            let temp: AreaMemberProps[] = [];
            areaData.forEach(am => {
                temp.push({
                    ...am, 
                    alamat_utama: am.area_member_id === areaMember.area_member_id ? true : false
                });}
            );
            setAreaData(temp);
            setMessageSnackbar('Alamat Berhasil dijadikan utama');
            setOpenSnackbar(true);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? 'Tidak dapat mengubah status alamat ');
            setOpenSnackbar(true);
        });
    }
    const removeAlamat = async (areaMember: AreaMemberProps) => {
        await AreaMemberService.delete(areaMember.area_member_id).then(res => {
            setAreaData(areaData.filter(r => r.area_member_id !== areaMember.area_member_id));
            setSettingTableArea({...settingTableArea, totalElements: settingTable.totalElements - 1});
            setMessageSnackbar("Alamat berhasil di hapus");
            setOpenSnackbar(true);
        }).catch(err => {
            setMessageSnackbar("Alamat gagal di hapus");
            setOpenSnackbar(true);
        });
    }
    function getKabupatenArea(id?: string | null) {
        setLoadingSkeletonArea(true);
        KabupatenService.getAllArray(id == null ? formAreaMember.provinsi_id : id).then(res => setKabupatenDataArea(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonArea(false);
    }
    function getKecamatanArea(id?: string | null) {
        setLoadingSkeletonArea(true);
        KecamatanService.getAllArray(id == null ? formAreaMember.kabupaten_id : id).then(res => setKecamatanDataArea(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonArea(false);
    }
    function getDesaArea(id?: string | null) {
        setLoadingSkeletonArea(true);
        DesaService.getAllArray(id == null ? formAreaMember.kecamatan_id : id).then(res => setDesaDataArea(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonArea(false);
    }
    function getRwArea(id?: string | null) {
        setLoadingSkeletonArea(true);
        RwService.getAllArr(id == null ? formAreaMember.desa_id : id).then(res => setRwDataArea(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonArea(false);
    }
    function getRtArea(id?: string | null) {
        setLoadingSkeletonArea(true);
        RtService.getAllArr(id == null ? formAreaMember.rw_id : id).then(res => setRtDataArea(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonArea(false);
    }
    function getTpsArea(rwid?: string | null, rtid?: string | null) {
        setLoadingSkeletonArea(true);
        TpsService.getAllArr(rwid == null ? formAreaMember.rw_id : rwid, rtid == null ? formAreaMember.rt_id : rtid).then(res => setTpsDataArea(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeletonArea(false);
    }
    const handleChangePageArea = (event: unknown, newPage: number) => {
        setLoadingSkeletonTableArea(true);
        setSettingTableArea({...settingTableArea,  page: newPage});
        setLoadingSkeletonTableArea(false);
    };
    const handleChangeRowsPerPageArea = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTableArea({...settingTableArea, page: 0, size: +event.target.value});
    };

    // Member 
    const auth = useRecoilValue<LoginResponseProps>(AuthStoreState);
    const [register, setRegister] = React.useState<RegisterRequestProps>(RegisterRequestField);
    const [errors, setErrors] = React.useState<ErrorRegisterRequestProps>(ErrorRegisterRequestField);
    const [search, setSearch] = React.useState<string>('');
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const [loadingSkeletonTable, setLoadingSkeletonTable] = React.useState<boolean>(false);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [titleDialog, setTitleDialog] = React.useState<string>('');
    const [settingTable, setSettingTable] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        roleId: string;
        provinsiId: string;
        kabupatenId: string;
        kecamatanId: string;
        desaId: string;
        rwId: string;
        rtId: string;
        tpsId: string;
        hakPilih: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'memberId',
        search: '',
        roleId: 'null',
        provinsiId: '',
        kabupatenId: '',
        kecamatanId: '',
        desaId: '',
        rwId: '',
        rtId: '',
        tpsId: '',
        hakPilih: 'null',
        totalElements: 0,
        totalPages: 0,
    });
    const [memberData, setMemberData] = React.useState<ListMemberProps[]>([]);
    const [memberDataDetail, setMemberDataDetail] = React.useState<ProfileResponseProps>(ProfileResponseField);
    const [provinsiData, setProvinsiData] = React.useState<ProvinsiProps[]>([]);
    const [kabupatenData, setKabupatenData] = React.useState<KabupatenProps[]>([]);
    const [kecamatanData, setKecamatanData] = React.useState<KecamatanProps[]>([]);
    const [desaData, setDesaData] = React.useState<DesaProps[]>([]);
    const [rwData, setRwData] = React.useState<RwProps[]>([]);
    const [rtData, setRtData] = React.useState<RtProps[]>([]);
    const [tpsData, setTpsData] = React.useState<TpsProps[]>([]);
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState);

    const getAllData = () => {
        setLoadingSkeletonTable(true);
        AdminService.getAllMember(settingTable.page, settingTable.size, settingTable.sort, settingTable.search, settingTable.roleId, settingTable.provinsiId, settingTable.kabupatenId, settingTable.kecamatanId, settingTable.desaId, settingTable.rwId, settingTable.rtId, settingTable.tpsId, settingTable.hakPilih).then(res => {
            setMemberData(res.data.content);
            setSettingTable({...settingTable, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
            setLoadingSkeletonTable(false);
        }).catch(err => {
            setMessageSnackbar("Tidak Ada Jaringan");
            setOpenSnackbar(true);
            setLoadingSkeletonTable(false);
        })
    }
    function getProvinsi() {
        if(provinsiData.length === 0) {
            setLoadingSkeleton(true);
            ProvinsiService.getAllArray().then(res => {setProvinsiData(res.data); setProvinsiDataArea(res.data);}).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
            setLoadingSkeleton(false);
        }
    }
    function getKabupaten(id?: string | null) {
        setLoadingSkeleton(true);
        KabupatenService.getAllArray(id == null ? settingTable.provinsiId : id).then(res => setKabupatenData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getKecamatan(id?: string | null) {
        setLoadingSkeleton(true);
        KecamatanService.getAllArray(id == null ? settingTable.kabupatenId : id).then(res => setKecamatanData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getDesa(id?: string | null) {
        setLoadingSkeleton(true);
        DesaService.getAllArray(id == null ? settingTable.kecamatanId : id).then(res => setDesaData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getRw(id?: string | null) {
        setLoadingSkeleton(true);
        RwService.getAllArr(id == null ? settingTable.desaId : id).then(res => setRwData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getRt(id?: string | null) {
        setLoadingSkeleton(true);
        RtService.getAllArr(id == null ? settingTable.rwId : id).then(res => setRtData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    function getTps(rwid?: string | null, rtid?: string | null) {
        setLoadingSkeleton(true);
        TpsService.getAllArr(rwid == null ? settingTable.rwId : rwid, rtid == null ? settingTable.rtId : rtid).then(res => setTpsData(res.data)).catch(err => {setOpenSnackbar(true); setMessageSnackbar("Terdapat Error saat Ambil Data")});
        setLoadingSkeleton(false);
    }
    const handleChangePage = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable,  page: newPage});
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTable({...settingTable, page: 0, size: +event.target.value});
    };
    const [avatar, setAvatar] = React.useState<string>('');
    const openAddMember = () => {
        setTitleDialog("Tambah Anggota");
        setOpenModal(true);
    }
    const openProfilDetail = async (member: ListMemberProps) => {
        await ProfileService.get(member.member_id).then(res=> {
            setMemberDataDetail(res.data);
            setAvatar(member.avatar)
            setForm(res.data);
            setTitleDialog("Detail Profil " + member.nama);
            setOpenModal(true);
            setLoading(false);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Terjadi kesalahan")
            setOpenSnackbar(true);
            setLoading(false);
        });
    }
    const deleteMember = (member: ListMemberProps) => {
        Swal.fire({
            title: 'Hapus Anggota',
            text: 'Apakah anda ingin menghapus Anggota ' + member.nama + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                AdminService.removeMember(member.member_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Anggota "+ member.nama +" sudah dihapus.",
                        icon: "success"
                    });
                    setMemberData(memberData.filter(r => r.member_id !== member.member_id));
                    setSettingTable({...settingTable, totalElements: settingTable.totalElements - 1})
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: err.response.data.message ?? "Anggota "+ member.nama +" gagal dihapus.",
                        icon: "error"
                    });
                    console.error(err);
                })
            }
        });
    }
    const clearFilter = () => {
        setSettingTable({
            ...settingTable, 
            provinsiId: '',
            kabupatenId: '',
            kecamatanId: '',
            desaId: '',
            rwId: '',
            rtId: '',
            tpsId: '',
            hakPilih: 'null',
        });
        setKabupatenData([]);
        setKecamatanData([]);
        setDesaData([]);
        setRwData([]);
        setRtData([]);
        setTpsData([]);
    }
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await RegistrationValidateForm.validate(register, {abortEarly: false});
            setErrors(ErrorRegisterRequestField);
            JSON.stringify(register);
        } catch(error: any) {
            const newErrors: any = {};
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }

        if(errors.nik === null && errors.nama === null && errors.jenis_kelamin === null && errors.tempat_lahir === null && errors.tanggal_lahir === null && errors.alamat === null && errors.kode_pos === null && errors.email === null && errors.no_hp === null && errors.no_wa === null && errors.hak_pilih === null && errors.username === null && errors.password === null && errors.nama_partai === null) {
            await AdminService.addMember(register).then(res => {
                setMemberData([...memberData, {
                    member_id: res.data.member_id,
                    relawan_id: '',
                    konstituen_id: '',
                    nama: res.data.nama,
                    jenis_kelamin: res.data.jenis_kelamin,
                    tempat_lahir: res.data.tempat_lahir,
                    tanggal_lahir: res.data.tanggal_lahir,
                    username: res.data.username,
                    avatar: '',
                    no_hp: res.data.no_hp,
                    no_wa: res.data.no_wa,
                    longitude: '',
                    lattitude: '',
                    alamat: res.data.alamat,
                    hak_pilih: res.data.hak_pilih,
                    punya_relawan: false
                }]);
                setSettingTable({...settingTable, totalElements: settingTable.totalElements + 1});
                setMessageSnackbar('Anggota Berhasil di tambah');
                setOpenSnackbar(true);
                setRegister(RegisterRequestField);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar(err.response.data.message ?? 'Anggota Tidak berhasil ditambah');
                setOpenSnackbar(true);
            })
        }

    };
    const [tempDataProfil, setTempDataProfil] = React.useState<ProfileResponseProps>(ProfileResponseField);
    const [checkBoxHelper, setCheckBoxHelper] = React.useState<boolean>(false);

    const helperCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckBoxHelper(event.target.checked);
        if(event.target.checked) {
            setRegister({...register, no_wa: register.no_hp});
        }
    }

    // Detail Member 
    const [form, setForm] = React.useState<ProfileRequestProps>(ProfileRequestField);
    const [editForm, setEditForm] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [errorsProfil, setErrorsProfil] = React.useState<ErrorProfileRequestProps>(ErrorProfileRequestField);
    const [image, setImage] = React.useState<File | null>(null);
    const [imagePreview, setImagePreview] = React.useState<string | null>(null);

    function uploadImage(event: ChangeEvent<HTMLInputElement>) {
        const selectedFile = event.target.files?.[0] || null;
        setImage(selectedFile);

        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        }
    }

    const handleSubmitProfil = async () => {
        const newErrors: any = {};
        try {
            ProfilValidateForm.validate(form, {abortEarly: false});
            setErrorsProfil(ErrorProfileRequestField);
        }catch(error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorsProfil(newErrors);
        }
        
        if(Object.keys(newErrors).length === 0) {
            const data = new FormData();
            data.append('data', JSON.stringify(form));
            if(image) {
                data.append('image', image)
            }
            await ProfileService.put(data).then(res => {
                setForm({
                    ...form, 
                    member_id: res.data.member_id,
                    nik: res.data.nik,
                    nama: res.data.nama,
                    jenis_kelamin: res.data.jenis_kelamin,
                    tempat_lahir: res.data.tempat_lahir,
                    tanggal_lahir: res.data.tanggal_lahir,
                    alamat: res.data.alamat,
                    kode_pos: res.data.kode_pos,
                    email: res.data.email,
                    no_hp: res.data.no_hp,
                    no_wa: res.data.no_wa,
                    username: res.data.username,
                    hak_pilih: res.data.hak_pilih
                });
                let index = memberData.findIndex(x => x.member_id === form.member_id);
                let data = memberData.find(x => x.member_id === form.member_id);
                setMemberData(replaceItemAtIndex(memberData, index, {...data, 
                    member_id: res.data.member_id,
                    nama: res.data.nama,
                    jenis_kelamin: res.data.jenis_kelamin,
                    tempat_lahir: res.data.tempat_lahir,
                    tanggal_lahir: res.data.tanggal_lahir,
                    username: res.data.username,
                    avatar: imagePreview !== null ? imagePreview : avatar,
                    no_hp: res.data.no_hp,
                    no_wa: res.data.no_wa,
                    alamat: res.data.alamat,
                    hak_pilih: res.data.hak_pilih,
                }))
                setEditForm(false);
                setMessageSnackbar("Profil berhasil diubah");
                setOpenSnackbar(true);
            }).catch(err => {
                setMessageSnackbar(err.response.data.message ?? "Profil gagal diubah");
                setOpenSnackbar(true);
                setEditForm(false);
            });
            setAvatar(imagePreview !== null ? imagePreview : avatar);
            setImagePreview(null);
            setImage(null);
        }
    }

    function cancelResetForm () {
        setForm({
            member_id: tempDataProfil.member_id,
            nik: tempDataProfil.nik,
            nama: tempDataProfil.nama,
            jenis_kelamin: tempDataProfil.jenis_kelamin,
            tempat_lahir: tempDataProfil.tempat_lahir,
            tanggal_lahir: tempDataProfil.tanggal_lahir,
            alamat: tempDataProfil.alamat,
            kode_pos: tempDataProfil.kode_pos,
            email: tempDataProfil.email,
            no_hp: tempDataProfil.no_hp,
            no_wa: tempDataProfil.no_wa,
            username: tempDataProfil.username,
            hak_pilih: tempDataProfil.hak_pilih
        });
    }

    React.useEffect(() => {
        if (openModal) {
            if(titleDialog.split(' ').includes('Detail')) {
                getAllArea();
            }
        } else {
            getProvinsi();
            getAllData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ settingTable.page, settingTable.size, settingTable.sort, settingTable.search, settingTable.provinsiId, settingTable.kabupatenId, settingTable.kecamatanId, settingTable.desaId, settingTable.rwId, settingTable.rtId, settingTable.tpsId, settingTable.hakPilih, settingTableArea.page, settingTableArea.search, settingTableArea.size, settingTableArea.sort]);

    return loadingSkeleton ? <Skeleton animation={'wave'} width={'100%'} height={'500px'}></Skeleton> : (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: '10px' }}>
                <Grid container spacing={2} sx={{ paddingBottom: '20px' }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography variant='h3' fontSize={'24px'}>Anggota SIPT</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField fullWidth id="cari" label="Cari" variant="outlined" value={search} onChange={(e) => setSearch(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <Button fullWidth startIcon={<Search />} title='cari' variant='contained' color='success' onClick={() => setSettingTable({...settingTable, search: search})}>Cari</Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <Button fullWidth startIcon={<Add />} title='tambah' variant='contained' color='info' onClick={() => openAddMember()}>Tambah</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2} sx={{ paddingBottom: '20px' }}>
                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="provinsi-select">Provinsi</InputLabel>
                            <Select
                                labelId="provinsi-select"
                                id="provinsi_id"
                                value={settingTable.provinsiId}
                                disabled={provinsiData.length === 0}
                                label="Provinsi"
                                onChange={(e) => {setSettingTable({...settingTable, provinsiId: e.target.value, kecamatanId: '', kabupatenId: '', desaId: '', rwId: '', rtId: '', tpsId: ''}); getKabupaten(e.target.value);setKecamatanData([]); setDesaData([]); setRwData([]); setRtData([]); setTpsData([]); }}
                            >
                                {
                                    provinsiData.map(p => <MenuItem key={p.provinsi_id} value={p.provinsi_id}>{p.nama_provinsi}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="kabupaten-select">Kabupaten</InputLabel>
                            <Select
                                labelId="kabupaten-select"
                                id="kabupaten_id"
                                value={settingTable.kabupatenId}
                                disabled={kabupatenData.length === 0}
                                label="Kabupaten"
                                onChange={(e) => {setSettingTable({...settingTable, kabupatenId: e.target.value, kecamatanId: '', desaId: '', rwId: '', rtId: '', tpsId: ''}); getKecamatan(e.target.value); setDesaData([]); setRwData([]); setRtData([]); setTpsData([]);}}
                            >
                                {
                                    kabupatenData.map(p => <MenuItem key={p.kabupaten_id} value={p.kabupaten_id}>{p.nama_kabupaten}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="kecamatan-select">Kecamatan</InputLabel>
                            <Select
                                labelId="kecamatan-select"
                                id="kecamatan_id"
                                value={settingTable.kecamatanId}
                                disabled={kecamatanData.length === 0}
                                label="Kecamatan"
                                onChange={(e) => {setSettingTable({...settingTable, kecamatanId: e.target.value, desaId: '', rwId: '', rtId: '', tpsId: ''}); getDesa(e.target.value); setRwData([]); setRtData([]); setTpsData([]);}}
                            >
                                {
                                    kecamatanData.map(p => <MenuItem key={p.kecamatan_id} value={p.kecamatan_id}>{p.nama_kecamatan}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="desa-select">Desa</InputLabel>
                            <Select
                                labelId="desa-select"
                                id="desa_id"
                                value={settingTable.desaId}
                                disabled={desaData.length === 0}
                                label="Desa"
                                onChange={(e) => {setSettingTable({...settingTable, desaId: e.target.value, rwId: '', rtId: '', tpsId: ''}); getRw(e.target.value); setRtData([]); setTpsData([]);}}
                            >
                                {
                                    desaData.map(p => <MenuItem key={p.desa_id} value={p.desa_id}>{p.nama_desa}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="rw-select">RW</InputLabel>
                            <Select
                                labelId="rw-select"
                                id="rw_id"
                                value={settingTable.rwId}
                                disabled={rwData.length === 0}
                                label="RW"
                                onChange={(e) => {setSettingTable({...settingTable, rwId: e.target.value, rtId: '', tpsId: ''}); getRt(e.target.value); getTps(e.target.value, null); }}
                            >
                                {
                                    rwData.map(p => <MenuItem key={p.rw_id} value={p.rw_id}>{p.nama_rw}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="rt-select">RT</InputLabel>
                            <Select
                                labelId="rt-select"
                                id="rt_id"
                                value={settingTable.rtId}
                                disabled={rtData.length === 0}
                                label="RT"
                                onChange={(e) => {setSettingTable({...settingTable, rtId: e.target.value, tpsId: ''}); getTps(settingTable.rwId, e.target.value)}}
                            >
                                {
                                    rtData.map(p => <MenuItem key={p.rt_id} value={p.rt_id}>{p.nama_rt}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} sx={{ paddingX: '5px' }}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="tps-select">TPS</InputLabel>
                            <Select
                                labelId="tps-select"
                                id="tps_id"
                                value={settingTable.tpsId}
                                disabled={tpsData.length === 0}
                                label="TPS"
                                onChange={(e) => setSettingTable({...settingTable, tpsId: e.target.value})}
                            >
                                {
                                    tpsData.map(p => <MenuItem key={p.tps_id} value={p.tps_id}>{p.nama_tps}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel id="hak_pilih-select">Hak Pilih</InputLabel>
                            <Select
                                labelId="hak_pilih-select"
                                id="hak_pilih_id"
                                value={settingTable.hakPilih}
                                label="Hak Pilih Member"
                                onChange={(e) => setSettingTable({...settingTable, hakPilih: e.target.value})}
                            >
                                <MenuItem value={'null'}>{'Semua Hak Pilih'}</MenuItem>
                                <MenuItem value={'true'}>{'Punya Hak Milik'}</MenuItem>
                                <MenuItem value={'false'}>{'Tidak ada Hak Pilih'}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        settingTable.provinsiId !== '' ? 
                        <Grid item xs={12} sm={12} md={4} lg={3} justifyContent={'center'} alignItems={'center'}>
                            <Button color='error' variant='contained' title='Bersihkan Filter' startIcon={<Cancel />} onClick={() => clearFilter()}>Clear Filter</Button>
                        </Grid> : <></>
                    }
                </Grid>
                <DialogAdminAreaMember 
                    createAlamat={createAlamat}
                    titleDialog={titleDialog}
                    setTitleDialog={setTitleDialog}
                    formAreaMember={formAreaMember}
                    setFormAreaMember={setFormAreaMember}
                    messageSnackbar={messageSnackbar}
                    errorFormArea={errorFormArea}
                    setErrorFormArea={setErrorFormArea}
                    theme={theme}
                    getKabupatenArea={getKabupatenArea}
                    getKecamatanArea={getKecamatanArea}
                    getDesaArea={getDesaArea}
                    getRwArea={getRwArea}
                    getRtArea={getRtArea}
                    getTpsArea={getTpsArea}
                    provinsiDataArea={provinsiDataArea}
                    kabupatenDataArea={kabupatenDataArea}
                    kecamatanDataArea={kecamatanDataArea}
                    desaDataArea={desaDataArea}
                    rwDataArea={rwDataArea}
                    rtDataArea={rtDataArea}
                    tpsDataArea={tpsDataArea}
                    loadingSkeletonTableArea={loadingSkeletonTableArea}
                    areaData={areaData}
                    setAreaData={setAreaData}
                    loadingSkeletonArea={loadingSkeletonArea}
                    setLoadingSkeletonArea={setLoadingSkeletonArea}
                    openModalArea={openModalArea}
                    setOpenModalArea={setOpenModalArea}
                    settingTableArea={settingTableArea}
                    setSettingTableArea={setSettingTableArea}
                    handleChangePageArea={handleChangePageArea}
                    handleChangeRowsPerPageArea={handleChangeRowsPerPageArea}
                    jadikanAlamatUtama={jadikanAlamatUtama}
                    removeAlamat={removeAlamat}
                    openSnackbar={openSnackbar}
                    setOpenSnackbar={setOpenSnackbar}
                    setMessageSnackbar={setMessageSnackbar}
                    setKabupatenDataArea={setKabupatenDataArea}
                    setKecamatanDataArea={setKecamatanDataArea}
                    setDesaDataArea={setDesaDataArea}
                    setRwDataArea={setRwDataArea}
                    setRtDataArea={setRtDataArea}
                    setTpsDataArea={setTpsDataArea}
                />
                <DetailMember 
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    titleDialog={titleDialog}
                    theme={theme}
                    memberDataDetail={memberDataDetail}
                    handleSubmit={handleSubmit}
                    register={register}
                    setRegister={setRegister}
                    errors={errors}
                    checkBoxHelper={checkBoxHelper}
                    helperCheckbox={helperCheckbox}
                    setCheckBoxHelper={setCheckBoxHelper}
                    loading={loading}
                    imagePreview={imagePreview}
                    setImagePreview={setImagePreview}
                    image={image}
                    setImage={setImage}
                    editForm={editForm}
                    setEditForm={setEditForm}
                    uploadImage={uploadImage}
                    form={form}
                    setForm={setForm}
                    cancelResetForm={cancelResetForm}
                    setTempDataProfil={setTempDataProfil}
                    handleSubmitProfil={handleSubmitProfil}
                    avatar={avatar}
                    setAvatar={setAvatar}
                    errorsProfil={errorsProfil}
                    setErrorsProfil={setErrorsProfil}
                />
            </Grid>
            {
            loadingSkeletonTable ? <Skeleton animation={'wave'} width={'100%'} height={'500px'} /> :
            <Grid item xs={12} sm={12} md={12}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 540 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nama</TableCell>
                                    <TableCell>Jenis Kelamin</TableCell>
                                    <TableCell>TTL</TableCell>
                                    <TableCell>Username</TableCell>
                                    <TableCell>Aksi</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                memberData.length !== 0 ? memberData.map(p => 
                                <TableRow key={p.member_id}>
                                    <TableCell>{p.nama}</TableCell>
                                    <TableCell>{p.jenis_kelamin}</TableCell>
                                    <TableCell>{p.tempat_lahir + ', ' + p.tanggal_lahir}</TableCell>
                                    <TableCell>{p.username}</TableCell>
                                    <TableCell>
                                        {
                                            auth.member_id === p.member_id ? <Chip label={'Anda Sendiri'} color='success' variant='outlined' /> : <React.Fragment>
                                                <Button sx={{ marginLeft: '5px' }} onClick={() => openProfilDetail(p)} size='small' variant='contained' startIcon={<Person />} color='success'>Profil</Button>
                                                <Button sx={{ marginLeft: '5px' }} onClick={() => openAreaDetail(p)} size='small' variant='contained' startIcon={<Home />} color='info'>Alamat</Button>
                                                <Button sx={{ marginLeft: '5px' }} onClick={() => deleteMember(p)} size='small' variant='contained' startIcon={<Delete />} color='error'>Hapus</Button>
                                            </React.Fragment>
                                        }
                                    </TableCell>
                                </TableRow> 
                                )
                                : <TableRow>
                                    <TableCell colSpan={6}>
                                        <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                    component="div"
                    count={settingTable.totalElements}
                    rowsPerPage={settingTable.size}
                    page={settingTable.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Paper>
            </Grid>
            }
            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                message={messageSnackbar}
            />
        </Grid>
    );
}

export default AdminPengelolaanDataAnggota;
