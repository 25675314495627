import { Grid, Typography } from '@mui/material';
import UnAuthorizedIcon from "../../asset/icon/undraw_security_re_a2rk.svg";

const UnAuthorized = () => {
    return (
        <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
        >
            <Grid item md={6} style={{ textAlign: 'center' }}>
                <img
                    src={UnAuthorizedIcon}
                    style={{ width: '100%' }}
                    alt='notfound'
                />
                <Typography variant='h5'>Akses tidak diizinkan</Typography>
            </Grid>
        </Grid>
    );
}

export default UnAuthorized;
