import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import { PertanyaanProps } from '../../models/Bantuan/PertanyaanProps';

class PertanyaanServices {
    async getAll(page: number, size: number, sort: string, search: string, memberId?: string, roleId?: string, kategoriPertanyaanId?:string, priority?: string, status?: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/bantuan/pertanyaan?page=${page}&size=${size}&sort=${sort}&search=${search}${memberId !== undefined ? '&memberId=' + memberId : ''}${roleId !== undefined ? '&roleId=' + roleId : ''}${kategoriPertanyaanId !== undefined ? '&kategoriPertanyaanId=' + kategoriPertanyaanId : ''}${priority !== undefined ? '&priority=' + priority : ''}${status !== undefined ? '&status=' + status : ''}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(pertanyaanId: string): Promise<AxiosResponse<PertanyaanProps>> {
        return axios.get(`/api/v1/blog/bantuan/pertanyaan/${pertanyaanId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: PertanyaanProps): Promise<AxiosResponse<PertanyaanProps>> {
        return axios.post(`/api/v1/bantuan/pertanyaan`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(pertanyaanId: string, data : PertanyaanProps): Promise<AxiosResponse<PertanyaanProps>> {
        return axios.put(`/api/v1/bantuan/pertanyaan/${pertanyaanId}`, data, { headers: {'Content-Type': 'application/json', },});
    }

    async putStatus(pertanyaanId: string, status: string): Promise<AxiosResponse<void>> {
        return axios.put(`/api/v1/bantuan/pertanyaan/status?pertanyaanId=${pertanyaanId}&status=${status}`, null, { headers: {'Content-Type': 'application/json', },});
    }

    async delete(pertanyaanId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/bantuan/pertanyaan/${pertanyaanId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PertanyaanServices();