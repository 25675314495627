import React, { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { AuthStoreState, ThemeStoreState } from '../../store';
import { LoginResponseProps } from '../../models/Auth/LoginResponseProps';
import MonitoringService from '../../services/Monitoring/MonitoringService';
import { ErrorMonitoringField, ErrorMonitoringProps, MonitoringField, MonitoringFilesProps, MonitoringProps } from '../../models/Monitoring/MonitoringProps';
import MonitoringFilesService from '../../services/Monitoring/MonitoringFilesService';
import { Button, Grid, Paper, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import DialogDetailActivity from '../../pages/Relawan/DialogDetailActivity';
import { MonitoringValidateForm } from '../../validation/MonitoringValidateForm';
import Swal from 'sweetalert2';
import { replaceItemAtIndex } from '../../Utils/helper';
import { RichTextEditorRef } from 'mui-tiptap';

const RelawanLaporanAktivitas = () => {
    const rteRef = useRef<RichTextEditorRef>(null);
    const authStore = useRecoilValue<LoginResponseProps>(AuthStoreState);
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState)
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const [loadingSkeletonTable, setLoadingSkeletonTable] = React.useState<boolean>(true);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [monitoringData, setMonitoringData] = React.useState<MonitoringProps[]>([]);
    const [monitoringFilesData, setMonitoringFilesData] = React.useState<MonitoringFilesProps[]>([]);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [titleDialog, setTitleDialog] = React.useState<string>('');
    const [registerFormMonitoring, setRegisterFormMonitoring] = React.useState<MonitoringProps>(MonitoringField);
    const [errorFormMonitoring, setErrorFormMonitoring] = React.useState<ErrorMonitoringProps>(ErrorMonitoringField);
    const [settingTable, setSettingTable] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'monitoringId',
        search: '',
        totalElements: 0,
        totalPages: 0,
    });

    React.useEffect(() => {
        getAllMonitoring();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingTable.page, settingTable.search, settingTable.size, settingTable.sort]);

    async function getAllMonitoring () {
        await MonitoringService.getAllByMemberId(settingTable.page, settingTable.size, settingTable.sort, settingTable.search, authStore.member_id).then(res => {
            setMonitoringData(res.data.content);
            setSettingTable({...settingTable, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak Dapat Terhubung ke Server");
            setOpenSnackbar(true);
        }).finally(() => {setLoadingSkeleton(false); setLoadingSkeletonTable(false);})
    }
    const openAdd = () => {
        setTitleDialog("Tambah Laporan Kegiatan");
        setRegisterFormMonitoring(MonitoringField);
        setOpenModal(true);
    }
    const openEdit = (m: MonitoringProps) => {
        setTitleDialog("Ubah Aktivitas");
        setRegisterFormMonitoring(m);
        setErrorFormMonitoring(ErrorMonitoringField);
        setOpenModal(true);
    }
    // const openDetail = async (m: MonitoringProps) => {
    //     await MonitoringFilesService.getAllByMonitoringId(m.monitoring_id).then(res => {
    //         setMonitoringFilesData(res.data);
    //         setRegisterFormMonitoring(m);
    //         setTitleDialog("Detail Laporan Kegiatan " + m.nama_aktivitas);
    //         setOpenModal(true);
    //     }).catch(err => {
    //         setMessageSnackbar(err.response.data.message ?? "Tidak Dapat Terhubung ke Server");
    //         setOpenSnackbar(true);
    //     });
    // }
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await MonitoringValidateForm.validate(registerFormMonitoring, {abortEarly: false});
            setErrorFormMonitoring(errorFormMonitoring);
            JSON.stringify(registerFormMonitoring);
        } catch(error: any) {
            const newErrors: any = {};
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorFormMonitoring(newErrors);
        }

        if(errorFormMonitoring.nama_aktivitas === null && errorFormMonitoring.tanggal === null && errorFormMonitoring.jam === null && errorFormMonitoring.keterangan === null) {
            await MonitoringService.create({...registerFormMonitoring, member_id: authStore.member_id, keterangan: rteRef.current?.editor?.getHTML() ?? ""}).then(res => {
                setRegisterFormMonitoring(MonitoringField);
                setMonitoringData([...monitoringData, res.data]);
                setSettingTable({...settingTable, totalElements: settingTable.totalElements + 1});
                setMessageSnackbar("Laporan Kegiata Berhasil ditambah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar(err.response.data.message ?? "Tidak Dapat Terhubung ke Server");
                setOpenSnackbar(true);
            })
        }
    };
    const updateMonitoring = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newErrors: any = {};
        try {
            await MonitoringValidateForm.validate(registerFormMonitoring, {abortEarly:false});
            setErrorFormMonitoring(errorFormMonitoring);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrorFormMonitoring(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await MonitoringService.put(registerFormMonitoring.monitoring_id, {...registerFormMonitoring, member_id: authStore.member_id, keterangan: rteRef.current?.editor?.getHTML() ?? ""}).then(res => {
                let index = monitoringData.findIndex(p => p.monitoring_id === registerFormMonitoring.monitoring_id);
                setMonitoringData(replaceItemAtIndex(monitoringData, index, {...registerFormMonitoring, keterangan: rteRef.current?.editor?.getHTML() ?? ""}));
                setMessageSnackbar("Aktivitas berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Aktivitas gagal di ubah");
                setOpenSnackbar(true);
            })
        }
    }
    const deleteMonitoring = (m: MonitoringProps) => {
        Swal.fire({
            title: 'Hapus Aktivitas ?',
            text: 'Apakah anda ingin menghapus aktivitas ' + m.nama_aktivitas + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                MonitoringService.delete(m.monitoring_id).then(res => {
                    setMonitoringData([...monitoringData.filter(x => x.monitoring_id !== m.monitoring_id)]);
                    setSettingTable({...settingTable, totalElements: settingTable.totalElements - 1});
                    setMessageSnackbar("Laporan Kegiata Berhasil dihapus");
                    setOpenSnackbar(true);
                }).catch(err => {
                    setMessageSnackbar(err.response.data.message ?? "Tidak Dapat Terhubung ke Server");
                    setOpenSnackbar(true);
                });
            }
        });
    }
    const handleChangePage = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable,  page: newPage});
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable, page: 0, size: +event.target.value});
        setLoadingSkeletonTable(false);
    };
    const uploadImage = async (monitoringId: string, x: File) => {
        const data = new FormData();
        data.append("image", x);
        return await MonitoringFilesService.create(monitoringId, data).then(res=>{
            return res.data.nama_file;
        }).catch(err=>{
            setMessageSnackbar(err.response.data.message ?? "Tidak ada jaringan");
            setOpenSnackbar(true);
        })
    }

    return loadingSkeleton ? (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                        <Skeleton width={'100%'} height={'50px'} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Skeleton width={'100%'} height={'50px'} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Skeleton width={'100%'} height={'500px'} />
            </Grid>
        </Grid>
    ) : (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <Typography variant='h5' sx={{ justifyContent: 'center', alignItem: 'center' }}>Laporan Aktivitas Anda</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button variant='contained' sx={{ marginLeft: '5px', backgroundColor: theme.backgroundColor, color: theme.color }}  onClick={() => openAdd()} startIcon={<Add />} color='inherit'>Tambah</Button>
                    </Grid>
                </Grid>
            </Grid>
            {
                loadingSkeletonTable ? <Skeleton width={'100%'} height={'500px'} /> :
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    {
                        loadingSkeletonTable ? <Skeleton width={'100%'} height={'500px'} /> :
                        <Grid item xs={12} sm={12} md={12}>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 540 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Tanggal</TableCell>
                                                <TableCell>Jam</TableCell>
                                                <TableCell>Nama Aktivitas</TableCell>
                                                <TableCell>Aksi</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                            monitoringData.length !== 0 ? monitoringData.map(p => 
                                                <TableRow key={p.monitoring_id}>
                                                    <TableCell>{p.tanggal}</TableCell>
                                                    <TableCell>{p.jam}</TableCell>
                                                    <TableCell>{p.nama_aktivitas}</TableCell>
                                                    <TableCell>
                                                        <Button sx={{ marginLeft: '5px' }} onClick={() => openEdit(p)} size='small' variant='contained' startIcon={<Edit />} color='primary'>Ubah</Button>
                                                        <Button sx={{ marginLeft: '5px' }} onClick={() => deleteMonitoring(p)} size='small' variant='contained' startIcon={<Delete />} color='error'>Hapus</Button>
                                                    </TableCell>
                                                </TableRow> 
                                                )
                                            : <TableRow>
                                                <TableCell colSpan={6}>
                                                    <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                                </TableCell>
                                            </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                                    component="div"
                                    count={settingTable.totalElements}
                                    rowsPerPage={settingTable.size}
                                    page={settingTable.page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                        }
                </Grid>
            </Grid>
            }
            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                message={messageSnackbar}
            />
            <DialogDetailActivity 
                openModal={openModal}
                setOpenModal={setOpenModal}
                theme={theme}
                titleDialog={titleDialog}
                handleSubmit={handleSubmit}
                updateMonitoring={updateMonitoring}
                registerFormMonitoring={registerFormMonitoring}
                setRegisterFormMonitoring={setRegisterFormMonitoring}
                errorFormMonitoring={errorFormMonitoring}
                setErrorFormMonitoring={setErrorFormMonitoring}
                monitoringFilesData={monitoringFilesData}
                setMonitoringFilesData={setMonitoringFilesData}
                rteRef={rteRef}
                uploadImage={uploadImage}
            />
        </Grid>
    );
}

export default RelawanLaporanAktivitas;
