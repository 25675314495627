import { AccountBox, AlternateEmail, AssuredWorkload, CalendarMonth, Female, Home, Male, Phone, Transgender, VerifiedUser, WhatsApp } from '@mui/icons-material';
import { Box, Chip, Container, Grid, List, ListItemButton, ListItemIcon, ListItemText, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { InfoWindow, Map, Marker } from '@vis.gl/react-google-maps';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { LoginResponseProps } from '../../models/Auth/LoginResponseProps';
import { AuthStoreState } from '../../store';
import Image from "../../asset/images/cat.jpg";
import SaksiService from '../../services/Member/SaksiService';
import { BerandaSaksiResponseDTO, BerandaSaksiResponseDTOField } from '../../models/Saksi/BerandaSaksiResponseDTO';

const Saksi = () => {
    const [selectedMarker, setSelectedMarker] = React.useState<any>(null);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [saksiDetail, setSaksiDetail] = React.useState<BerandaSaksiResponseDTO>(BerandaSaksiResponseDTOField);
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const authStore = useRecoilValue<LoginResponseProps>(AuthStoreState);

    async function getBeranda() {
        await SaksiService.beranda(authStore.member_id).then(res => {
            setSaksiDetail(res.data);
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak ada jaringan");
            setOpenSnackbar(true);
        }).finally(() => setLoadingSkeleton(false));
    }

    React.useEffect(() => {
        getBeranda();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loadingSkeleton ? (
        <Container>
            <Skeleton animation={'wave'} width={'100%'} height={'500px'}></Skeleton>
        </Container>
    ) : (
        <Container>
            <Grid container spacing={3} sx={{ marginY: '20px' }}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='h6'>Profil</Typography>
                            <Box sx={{ width: '100%' }}>
                                {
                                    saksiDetail !== null ?
                                    <img src={saksiDetail?.member.avatar !== null ? saksiDetail?.member.avatar : Image} alt={saksiDetail?.ktp.nama} width={'100%'} /> :
                                    <img src={Image} alt={'default'} width={'100%'} />
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='h6'>Informasi Profil Saksi</Typography>
                            <List component="nav" aria-label="main mailbox folders">
                                <ListItemButton>
                                    <ListItemIcon><AccountBox /></ListItemIcon>
                                    <ListItemText primary={saksiDetail?.ktp.nama ?? 'Belum Ada Nama'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon>{ saksiDetail?.ktp.jenis_kelamin === null ? <Transgender /> : saksiDetail?.ktp.jenis_kelamin === 'PRIA' ? <Male /> : <Female /> }</ListItemIcon>
                                    <ListItemText primary={saksiDetail?.ktp.jenis_kelamin ?? 'Belum Ada Jenis Kelamin'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><CalendarMonth /></ListItemIcon>
                                    <ListItemText primary={saksiDetail?.ktp.tempat_lahir + ', ' + saksiDetail?.ktp.tanggal_lahir ?? 'Belum Ada Kode Referensi'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><AlternateEmail /></ListItemIcon>
                                    <ListItemText primary={saksiDetail?.member.email ?? 'Belum Ada Email'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><Phone /></ListItemIcon>
                                    <ListItemText primary={saksiDetail?.member.no_hp ?? 'Belum Ada Nomor HP'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><WhatsApp /></ListItemIcon>
                                    <ListItemText primary={saksiDetail?.member.no_wa ?? 'Belum Ada Nomor WhatsApp'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><VerifiedUser /></ListItemIcon>
                                    <ListItemText primary={saksiDetail?.username ?? 'Belum Ada Username'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><AssuredWorkload /></ListItemIcon>
                                    <ListItemText primary={saksiDetail?.member.hak_pilih ? 'Ada Hak Pilih di TPS' : 'Tidak ada Hak Pilih'} />
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon><Home /></ListItemIcon>
                                    <ListItemText primary={saksiDetail?.ktp.alamat ?? 'Belum Ada Alamat'} />
                                </ListItemButton>
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='h6'>Informasi Lokasi Rumah Saksi & TPS</Typography>
                            <Map
                                defaultZoom={10}
                                style={{ width: '100%', height: '420px' }}
                                defaultCenter={ { lat: saksiDetail.alamat_rumah.lattitude !== null ? parseFloat(saksiDetail.alamat_rumah.lattitude) : process.env.REACT_APP_MAPS_LATTITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LATTITUDE) : -4.55858490, lng: saksiDetail.alamat_rumah.longitude !== null ? parseFloat(saksiDetail.alamat_rumah.longitude) : process.env.REACT_APP_MAPS_LONGITUDE !== undefined ? parseFloat(process.env.REACT_APP_MAPS_LONGITUDE) : 105.40680790 } }
                            >
                                {
                                    <Marker position={{lat: parseFloat(saksiDetail.alamat_rumah.lattitude), lng: parseFloat(saksiDetail.alamat_rumah.longitude)}} icon={{
                                    url: 'http://maps.google.com/mapfiles/kml/pal3/icon56.png',
                                    scaledSize: new google.maps.Size(32, 32)
                                    }} onClick={() => setSelectedMarker({name: "Rumah", lat: saksiDetail.alamat_rumah.lattitude, lng: saksiDetail.alamat_rumah.longitude, alamat:saksiDetail.alamat_rumah.nama_desa + ', ' + saksiDetail.alamat_rumah.nama_kecamatan + ', ' + saksiDetail.alamat_rumah.nama_kabupaten + ', ' + saksiDetail.alamat_rumah.nama_provinsi})} />
                                }
                                {
                                    <Marker position={{lat: parseFloat(saksiDetail.alamat_tps.lattitude), lng: parseFloat(saksiDetail.alamat_tps.longitude)}} icon={{
                                    url: 'http://maps.google.com/mapfiles/kml/pal3/icon21.png',
                                    scaledSize: new google.maps.Size(32, 32)
                                    }} onClick={() => setSelectedMarker({name: saksiDetail.alamat_tps.nama_tps.split(" ").includes('TPS') ? saksiDetail.alamat_tps.nama_tps : "TPS " + saksiDetail.alamat_tps.nama_tps, lat: saksiDetail.alamat_tps.lattitude, lng: saksiDetail.alamat_tps.longitude, alamat: saksiDetail.alamat_tps.alamat})} />
                                }
                                {selectedMarker && (
                                    <InfoWindow
                                        position={{ lat: parseFloat(selectedMarker.lat), lng: parseFloat(selectedMarker.lng) }}
                                        onCloseClick={() => setSelectedMarker(null)}
                                    >
                                        <div>
                                            <h4>{selectedMarker.name}</h4>
                                            <p>Alamat: {selectedMarker.alamat}</p>
                                        </div>
                                    </InfoWindow>
                                )}
                            </Map>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginBottom: '50px' }}>
                <Grid item xs={12} sm={12}>
                    <Typography variant='h6'>Informasi Lokasi TPS</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Provinsi</TableCell>
                                <TableCell>Kabupaten</TableCell>
                                <TableCell>Kecamatan</TableCell>
                                <TableCell>Desa</TableCell>
                                <TableCell>RW</TableCell>
                                <TableCell>RT</TableCell>
                                <TableCell>TPS</TableCell>
                                <TableCell>Alamat</TableCell>
                            </TableRow>
                        </TableHead>
                            <TableBody>
                                    <TableRow>
                                        <TableCell>{saksiDetail.alamat_tps.nama_provinsi}</TableCell>
                                        <TableCell>{saksiDetail.alamat_tps.nama_kabupaten}</TableCell>
                                        <TableCell>{saksiDetail.alamat_tps.nama_kecamatan}</TableCell>
                                        <TableCell>{saksiDetail.alamat_tps.nama_desa}</TableCell>
                                        <TableCell>{saksiDetail.alamat_tps.nama_rw}</TableCell>
                                        <TableCell>{saksiDetail.alamat_tps.nama_rt}</TableCell>
                                        <TableCell>{saksiDetail.alamat_tps.nama_tps}</TableCell>
                                        <TableCell>{saksiDetail.alamat_tps.alamat}</TableCell>
                                    </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant='h6'>Informasi Lokasi Rumah</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Provinsi</TableCell>
                                <TableCell>Kabupaten</TableCell>
                                <TableCell>Kecamatan</TableCell>
                                <TableCell>Desa</TableCell>
                                <TableCell>RW</TableCell>
                                <TableCell>RT</TableCell>
                                <TableCell>TPS</TableCell>
                                <TableCell>Utama</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{saksiDetail.alamat_rumah.nama_provinsi}</TableCell>
                                <TableCell>{saksiDetail.alamat_rumah.nama_kabupaten}</TableCell>
                                <TableCell>{saksiDetail.alamat_rumah.nama_kecamatan}</TableCell>
                                <TableCell>{saksiDetail.alamat_rumah.nama_desa}</TableCell>
                                <TableCell>{saksiDetail.alamat_rumah.nama_rw}</TableCell>
                                <TableCell>{saksiDetail.alamat_rumah.nama_rt}</TableCell>
                                <TableCell>{saksiDetail.alamat_rumah.nama_tps}</TableCell>
                                <TableCell>{saksiDetail.alamat_rumah.alamat_utama ? <Chip label={'Utama'} variant="outlined" color="primary" /> : <Chip label={'Bukan'} variant="outlined" color="error" />}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Saksi;
