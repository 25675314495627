export interface ListSaksiProps {
    member_id: string;
    saksi_id: string;
    nama: string;
    username: string;
    avatar: string;
    no_hp: string;
    no_wa: string;
    hak_pilih: boolean;
    nama_provinsi: string;
    nama_kabupaten: string;
    nama_kecamatan: string;
    nama_desa: string;
    nama_rw: string;
    nama_rt: string;
    tps_id : string;
    nama_tps: string;
    longitude: string;
    lattitude: string;
}

export const ListSaksiField : ListSaksiProps = {
    member_id: '',
    saksi_id: '',
    nama: '',
    username: '',
    avatar: '',
    no_hp: '',
    no_wa: '',
    hak_pilih: false,
    nama_provinsi: '',
    nama_kabupaten: '',
    nama_kecamatan: '',
    nama_desa: '',
    nama_rw: '',
    nama_rt: '',
    tps_id : '',
    nama_tps: '',
    longitude: '',
    lattitude: ''
}