export interface KategoriProps {
    kategori_id: string;
    periode_id: string;
    nama_kategori: string;
    nama_periode: string;
    deksripsi: string;
}

export const KategoriField: KategoriProps = {
    kategori_id: '',
    periode_id: '',
    nama_periode: '',
    nama_kategori: '',
    deksripsi: '',
}

export interface ErrorKategoriProps {
    kategori_id: string | null;
    periode_id: string | null;
    nama_kategori: string | null;
    nama_periode: string | null;
    deksripsi: string | null;
}

export const ErrorKategoriField: ErrorKategoriProps = {
    kategori_id: null,
    periode_id: null,
    nama_periode: null,
    nama_kategori: null,
    deksripsi: null,
}