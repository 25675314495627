import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import CardPortal from '../../components/CardPortal';
import BlogPostProps from '../../models/Blog/BlogPostProps';

const kategoriList = [
    {
        id: 1, 
        nama: 'Kategori A'
    },
    {
        id: 2, 
        nama: 'Kategori B'
    },
    {
        id: 3, 
        nama: 'Kategori C'
    },
    {
        id: 4, 
        nama: 'Kategori D'
    },
    {
        id: 5, 
        nama: 'Kategori E'
    }
];
const beritaList: BlogPostProps[] = [
    {
        blog_post_id: "1",
        member_id: "",
        nama_member: "",
        title: "Jogetin Aja",
        slug: "",
        summary: "",
        content: "",
        published_at: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        published: true,
        visited: 100,
        meta: [],
        category: [],
        tag: [],
    },
    {
        blog_post_id: "2",
        member_id: "",
        nama_member: "",
        title: "Haha",
        slug: "",
        summary: "",
        content: "",
        published_at: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        published: true,
        visited: 100,
        meta: [],
        category: [],
        tag: [],
    },
];
const Kategori: React.FC = () => {
    const [kategoriChoose, setKategoriChoose] = React.useState<number>(1);
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5'>Kategori yang tersedia:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {
                            kategoriList.map(k => 
                                <Grid item xs={4} key={k.id}>
                                    <Paper onClick={() => setKategoriChoose(k.id)} elevation={1} sx={{ padding: '10px', cursor: 'pointer'}}>
                                        {k.nama}
                                    </Paper>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Berita / Artikel {kategoriList.find(k => k.id === kategoriChoose)?.nama} </Typography>
                </Grid>
                {
            beritaList.map(berita => 
                <Grid item xs={12} key={berita.blog_post_id}>
                    <CardPortal
                        blog_post_id={berita.blog_post_id}
                        member_id={berita.member_id}
                        nama_member={berita.nama_member}
                        title={berita.title}
                        slug={berita.slug}
                        summary={berita.summary}
                        content={berita.content}
                        published_at={berita.published_at}
                        created_at={berita.created_at}
                        updated_at={berita.updated_at}
                        deleted_at={berita.deleted_at}
                        published={berita.published}
                        visited={berita.visited}
                        meta={berita.meta}
                        category={berita.category}
                        tag={berita.tag}
                    />
                </Grid>
            )
        }
            </Grid>
        </Box>
    );
}

export default Kategori;
