import { Button, Container, Grid, Paper, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import React from 'react';
import ThemeStoreProps from '../../models/Store/ThemeStoreProps';
import { Add, Delete, Edit } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { replaceItemAtIndex } from '../../Utils/helper';
import { ThemeStoreState } from '../../store';
import { useRecoilValue } from 'recoil';
import { ErrorPeriodeField, ErrorPeriodeProps, PeriodeField, PeriodeProps } from '../../models/Forums/PeriodeProps';
import PeriodeService from '../../services/Forum/PeriodeService';
import { PeriodeValidateForm } from '../../validation/ForumValidateForm';
import DialogPengelolaanPeriode from '../../pages/Superadmin/DialogPengelolaanPeriode';

const SuperadminPengelolaanPeriode = () => {
    const [errors, setErrors] = React.useState<ErrorPeriodeProps>(ErrorPeriodeField);
    const [loadingSkeleton, setLoadingSkeleton] = React.useState<boolean>(true);
    const [loadingSkeletonTable, setLoadingSkeletonTable] = React.useState<boolean>(false);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const [messageSnackbar, setMessageSnackbar] = React.useState<string>('');
    const [titleDialog, setTitleDialog] = React.useState<string>('');
    const [settingTable, setSettingTable] = React.useState<{
        page: number;
        size: number;
        sort: string;
        search: string;
        totalElements: number;
        totalPages: number;
    }>({
        page: 0,
        size: 10,
        sort: 'periodeId',
        search: '',
        totalElements: 0,
        totalPages: 0,
    });
    
    const [periodeData, setPeriodeData] = React.useState<PeriodeProps[]>([]);
    const [periodeDataDetail, setPeriodeDataDetail] = React.useState<PeriodeProps>(PeriodeField);
    const theme = useRecoilValue<ThemeStoreProps>(ThemeStoreState);

    const getAll = async () => {
        await PeriodeService.getAll(settingTable.page, settingTable.size, settingTable.sort, settingTable.search).then(res => {
            setPeriodeData(res.data.content);
            setSettingTable({...settingTable, totalElements: res.data.totalElements, totalPages: res.data.totalPages});
        }).catch(err => {
            setMessageSnackbar(err.response.data.message ?? "Tidak ada jaringan");
            setOpenSnackbar(true);
        }).finally(() => {
            setLoadingSkeleton(false);
            setLoadingSkeletonTable(false);
        });
    }
    const openAdd = () => {
        setTitleDialog("Tambah Periode");
        setOpenModal(true);
    }
    const openById = (periode: PeriodeProps) => {
        setPeriodeDataDetail(periode);
        setTitleDialog("Ubah Periode");
        setOpenModal(true);
    }
    const createPeriode = async () => {
        const newErrors: any = {};
        try {
            await PeriodeValidateForm.validate(periodeDataDetail, {abortEarly:false});
            setErrors(ErrorPeriodeField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await PeriodeService.create(periodeDataDetail).then(res => {
                setPeriodeData([...periodeData, res.data]);
                setSettingTable({...settingTable, totalElements: settingTable.totalElements + 1})
                setMessageSnackbar("Periode berhasil di tambah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar(err.response.data.message ?? "Periode gagal di tambah");
                setOpenSnackbar(true);
            })
        }
    }
    const updatePeriode = async () => {
        const newErrors: any = {};
        try {
            await PeriodeValidateForm.validate(periodeDataDetail, {abortEarly:false});
            setErrors(ErrorPeriodeField);
        } catch (error: any) {
            error.inner.forEach((err: any) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }

        if(Object.keys(newErrors).length === 0) {
            await PeriodeService.put(periodeDataDetail.periode_id, periodeDataDetail).then(res => {
                let index = periodeData.findIndex(p => p.periode_id === periodeDataDetail.periode_id);
                setPeriodeData(replaceItemAtIndex(periodeData, index, res.data));
                setMessageSnackbar("Periode berhasil di ubah");
                setOpenSnackbar(true);
                setOpenModal(false);
            }).catch(err => {
                setMessageSnackbar("Periode gagal di ubah");
                setOpenSnackbar(true);
            })
        }
    }
    const deletePeriode = async (periode: PeriodeProps) => {
        Swal.fire({
            title: 'Hapus Periode',
            text: 'Apakah anda ingin menghapus Periode ' + periode.tahun + " dari Sistem ? ",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: "Ya, Hapuskan",
            cancelButtonText: "Batalkan",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                PeriodeService.delete(periode.periode_id).then(res => {
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Periode "+ periode.tahun +" sudah dihapus.",
                        icon: "success"
                    });
                    setPeriodeData(periodeData.filter(p => p.periode_id !== periode.periode_id));
                    setSettingTable({...settingTable, totalElements: settingTable.totalElements - 1})
                }).catch(err => {
                    Swal.fire({
                        title: "Gagal!",
                        text: err.response.data.message ?? "Periode "+ periode.tahun +" gagal dihapus.",
                        icon: "error"
                    });
                })
            }
        });
    }

    React.useEffect(() => {
        getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePage = (event: unknown, newPage: number) => {
        setLoadingSkeletonTable(true);
        setSettingTable({...settingTable,  page: newPage});
        setLoadingSkeletonTable(false);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettingTable({...settingTable, page: 0, size: +event.target.value});
    };

    return loadingSkeleton ? (<Container>
        <Skeleton animation="wave" width={'100%'} height={'576px'} variant='rounded'></Skeleton>
    </Container>) : (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: '10px' }}>
                    <Grid container spacing={2} sx={{ paddingBottom: '20px' }}>
                        <Grid item xs={8} sm={8}>
                            <Typography variant='h3' fontSize={'24px'}>Periode</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} display={'flex'} justifyContent={'flex-end'}>
                            <Button startIcon={<Add />} onClick={() => openAdd()} title='tambah' color='info' variant='contained'>Tambah</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {
                loadingSkeletonTable ? <Skeleton animation={'wave'} width={'100%'} height={'500px'} /> :
                <Grid item xs={12} sm={12} md={12}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 540 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Periode</TableCell>
                                        <TableCell>Deskripsi</TableCell>
                                        <TableCell>Aksi</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    periodeData.length !== 0 ? periodeData.map(p => 
                                    <TableRow key={p.periode_id}>
                                        <TableCell>{p.tahun}</TableCell>
                                        <TableCell>{p.deskripsi}</TableCell>
                                        <TableCell>
                                            <Button sx={{ marginLeft: '5px' }} onClick={() => openById(p)} size='small' variant='contained' startIcon={<Edit />} color='success'>Ubah</Button>
                                            <Button sx={{ marginLeft: '5px' }} onClick={() => deletePeriode(p)} size='small' variant='contained' startIcon={<Delete />} color='error'>Hapus</Button>
                                        </TableCell>
                                    </TableRow> 
                                    )
                                    : <TableRow>
                                        <TableCell colSpan={6}>
                                            <Typography variant='h6' fontSize={'16px'} textAlign={'center'}>Tidak Ada Data</Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100, 250, 500, 1000]}
                        component="div"
                        count={settingTable.totalElements}
                        rowsPerPage={settingTable.size}
                        page={settingTable.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Paper>
                </Grid>
                }
                <Snackbar
                    open={openSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={3000}
                    onClose={() => setOpenSnackbar(false)}
                    message={messageSnackbar}
                />
                <DialogPengelolaanPeriode 
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    theme={theme}
                    titleDialog={titleDialog}
                    errors={errors}
                    setErrors={setErrors}
                    periodeDataDetail={periodeDataDetail}
                    setPeriodeDataDetail={setPeriodeDataDetail}
                    createPeriode={createPeriode}
                    updatePeriode={updatePeriode}
                />
            </Grid>
        </Container>
    );
}

export default SuperadminPengelolaanPeriode;
