import React from 'react';

const KonstituenPendaftaranPemilih = () => {
    return (
        <div>
            KonstituenPendaftaranPemilih
        </div>
    );
}

export default KonstituenPendaftaranPemilih;
