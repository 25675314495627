import axios, { AxiosResponse } from 'axios';
import { PaginationResponseProps } from '../../models/PaginationResponseProps';
import {PeriodeProps} from '../../models/Forums/PeriodeProps';

class PeriodeServices {
    async getAllArr(): Promise<AxiosResponse<PeriodeProps[]>> {
        return axios.get(`/api/v1/forum/periode/arr`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getAll(page: number, size: number, sort: string, search: string): Promise<AxiosResponse<PaginationResponseProps>> {
        return axios.get(`/api/v1/forum/periode?page=${page}&size=${size}&sort=${sort}&search=${search}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async getById(periodeId: string): Promise<AxiosResponse<PeriodeProps>> {
        return axios.get(`/api/v1/forum/periode/${periodeId}`,{headers: {'Content-Type': 'application/json',}});
    }
    
    async create(data: PeriodeProps): Promise<AxiosResponse<PeriodeProps>> {
        return axios.post(`/api/v1/forum/periode`, data, {headers: {'Content-Type': 'application/json',}});
    }

    async put(periodeId: string, data : PeriodeProps): Promise<AxiosResponse<PeriodeProps>> {
        return axios.put(`/api/v1/forum/periode/${periodeId}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async delete(periodeId: string): Promise<AxiosResponse<void>> {
        return axios.delete(`/api/v1/forum/periode/${periodeId}`, {headers: {'Content-Type': 'application/json',}});
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PeriodeServices();