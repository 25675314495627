export interface RegisterRequestProps {
    nik: string;
    nama: string;
    jenis_kelamin: string;
    tempat_lahir: string;
    tanggal_lahir: string;
    alamat: string;
    kode_pos: string;
    email: string;
    no_hp: string;
    no_wa: string;
    hak_pilih: boolean;
    username: string;
    password: string;
    nama_partai: string;
    referensi_kode: string;
}

export const RegisterRequestField: RegisterRequestProps = {
    nik: "",
    nama: "",
    jenis_kelamin: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    alamat: "",
    kode_pos: "",
    email: "",
    no_hp: "",
    no_wa: "",
    hak_pilih: true,
    username: "",
    password: "",
    nama_partai: "",
    referensi_kode: ""
}

export interface ErrorRegisterRequestProps {
    nik: string | null;
    nama: string | null;
    jenis_kelamin: string | null;
    tempat_lahir: string | null;
    tanggal_lahir: string | null;
    alamat: string | null;
    kode_pos: string | null;
    email: string | null;
    no_hp: string | null;
    no_wa: string | null;
    hak_pilih: string | null;
    username: string | null;
    password: string | null;
    nama_partai: string | null;
    referensi_kode: string | null;
}

export const ErrorRegisterRequestField: ErrorRegisterRequestProps = {
    nik: null,
    nama: null,
    jenis_kelamin: null,
    tempat_lahir: null,
    tanggal_lahir: null,
    alamat: null,
    kode_pos: null,
    email: null,
    no_hp: null,
    no_wa: null,
    hak_pilih: null,
    username: null,
    password: null,
    nama_partai: null,
    referensi_kode: null,
}